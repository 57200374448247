import React, { Component } from 'react';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';

import { allFieldsHaveValues, allFieldsHaveValues_new } from 'util';
import api from '@api';
import { Input, Label, Select, Textarea } from 'shared/form';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class SMTPEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: []
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });

		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		//{
		api.admin.smtp_get().then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'host',
				'port',
				'type',
				'defaultMess'
			]);

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {}; /*{
      'addressFrom':data.storage.title,
      'addressTo':data.delivery.title,
      'material':data.material,
      'cargoCount':1,//tā vismaz spriežu pēc vecā koda - 'uzd_cargo_count'
      'typeID':9
    }*/
		let try2Final = {};
		const dataToSendAsDefaults = ['host', 'port', 'type', 'defaultMess']; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			host: 'Servera adrese',
			port: 'Ports',
			type: 'Tips',
			defaultMess: 'Noklusētā e-pasta ziņa'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['host', 'port', 'type', 'defaultMess'],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		//return;

		api.admin.smtp_save(finalToSend).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				//this.setData();
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	onDriverSelect(driver) {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	}

	onDeptSelect = departments => {
		// if (departments != "") {
		const deptForSave = departments.map(dept => dept.value);
		this.setDataToState('departments', deptForSave);
		// }
	};

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	render() {
		const { loading, host, port, type, defaultMess } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<h2>SMTP servera konfigurācija</h2>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Servera adrese' name='host' />
							<Input
								inputClass='col-sm-8'
								name='host'
								value={host}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Servera ports' name='port' />
							<Input
								inputClass='col-sm-8'
								name='port'
								value={port}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Šifrēšanas veids' name='type' />

							<Select
								selectClass='col-sm-8'
								name='type'
								handleChange={this.handleChange}
								value={type}
								placeholder='Izvēlies vērtību'
								options={[
									{ value: 'SSL', label: 'SSL' },
									{ value: 'STARTTLS', label: 'STARTTLS' },
									{ value: 'TLS', label: 'TLS' }
								]}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Noklusētā e-pasta ziņa'
								name='defaultMess'
							/>
							<Textarea
								inputClass='col-sm-8'
								rows={5}
								name='defaultMess'
								value={defaultMess}
								handleChange={this.handleChange}
							/>
						</div>
						{checkPermission('edit', 'smtp') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default SMTPEdit;
