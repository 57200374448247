import React from 'react';
import { Input, Label } from '../../../shared/form';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { getCargoNaming, getClosestSmall } from './helpers';
import { formatNumber } from 'util';
import cssStyles from './styles.module.css';

const InfoRow = (props) => {
	const {prognosedDistanceAndTime, material, amount, finalPrice, materialTransportPrice, carrierPriceAmount} = props;
	if (!prognosedDistanceAndTime.len || !prognosedDistanceAndTime.time || !material || !amount) {
		return <></>;
	}

	const lessThanOneFullAmount = materialTransportPrice?.volume && amount && materialTransportPrice.volume > amount;

	let textInfo = '';

	if (lessThanOneFullAmount) {
		textInfo = `1 pilnas kravas piegādes distance, veikšanas laiks`;
	} else {
		let cnt = Math.ceil(amount / materialTransportPrice?.volume);
		if (isNaN(cnt)) {
			cnt = 1;
		}
		const cargo = getCargoNaming(cnt);
		textInfo = `${cnt} ${cargo} (${material?.title} - ${amount}) piegādes distance, veikšanas laiks`;
	}

	const priceFinal = materialTransportPrice?.volume * carrierPriceAmount;

	// console.log('priceFinal', priceFinal, prognosedDistanceAndTime.len, getClosestSmall(prognosedDistanceAndTime.len), carrierPriceAmount)

	let distanceToShow = prognosedDistanceAndTime.len;
	if (distanceToShow >= 200) {
		distanceToShow /= 2;
	}

	return <>{prognosedDistanceAndTime.len && prognosedDistanceAndTime.time && (
		<div className='row'>
			<Label
				labelClass='col-sm-4'
				title={textInfo}
			/>
			<div className='col-sm-8'>
				{prognosedDistanceAndTime.len && (
					<span className={cssStyles.routeContainer} onClick={props.handleToggleModal}>
						<OverlayTrigger
							placement='top'
							overlay={
								<Tooltip id='tooltip-right'>
									optimālais attālums starp izvēlētajām
									adresēm
								</Tooltip>
							}>
							<i className='fas fa-route'></i>
						</OverlayTrigger>{' '}
						{distanceToShow} km
					</span>
				)}{' '}
				{prognosedDistanceAndTime.time && (
					<>
						<OverlayTrigger
							placement='top'
							overlay={
								<Tooltip id='tooltip-right'>
									prognozējamais laiks optimālā attāluma
									veikšanai
								</Tooltip>
							}>
							<i className='fas fa-clock'></i>
						</OverlayTrigger>{' '}
						{prognosedDistanceAndTime.time}
					</>
				)}{' '}
				{finalPrice && (
					<>
						<OverlayTrigger
							placement='top'
							overlay={
								<Tooltip id='tooltip-right'>
									tiek rēķinātas ņemot vērā minimālo
									pārvadāšanas apjomu {'{'}MATERIĀLA KARTIŅA:
									PĀRVADĀŠANAS APJOMS{'}'}+MĒRVIENĪBA
								</Tooltip>
							}>
							<i className='fas fa-euro-sign'></i>
						</OverlayTrigger>{' '}
						{finalPrice} €/t
					</>
				)}
			</div>
		</div>
	)}
	{priceFinal > 0 && (
		<div className='row'>
			<Label
				labelClass='col-sm-4'
				title='Visa pasūtījuma apjoma prognozējamā transporta summa'
			/>
			<div className='col-sm-8'>
				{formatNumber(priceFinal, 3)/* Math.round((finalPrice * amount + Number.EPSILON) * 100) / 100 */}{' '}
				€
			</div>
		</div>
	)}
	{/* finalPrice && (
		<div className='row'>
			<Label
				labelClass='col-sm-4'
				title={
					<span>
						Transporta cena materiālam
					</span>}
			/>
			<div className='col-sm-8'>{finalPrice} €/t
			</div>
		</div>
	) */}</>
}

export default InfoRow
