import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../../shared/form';
import api from '../../../api';
import { toast } from 'react-toastify';

import { allFieldsHaveValues_new, formatNumber, isNumeric } from 'util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';

class CreateNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			toSaveData: {},
			modalType: this.props.modalType
		};
	}

	componentDidMount() {
		this.setState({ isLoading: false, error: false });
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		let inputValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (inputValue.length > 0 && name == 'regNr') { // validate phone, laikam jau nē - "+371", "+370"?
			if (isNumeric(inputValue) == false) {
				return false;
			}
			inputValue = inputValue.trim();
		}

		this.setDataToState(name, inputValue);
	};

	formatAsString = event => {
		const number = event.target.value;
		const name = event.target.name;

		this.setDataToState(name, formatNumber(number));
	};

	saveData = e => {
		e.preventDefault();

		const { toSaveData, modalType } = this.state;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati nav ievadīti', { autoClose: 3500 });
			return;
		}

		console.log('to save', JSON.stringify(toSaveData));

		const nameMap = {
			carrierID: 'Nosaukums',
			regNr: 'Reģistrācijas Nr.',
			vilcejs: 'Vilcēja Nr.',
			driverName: 'Vārds',
			surname: 'Uzvārds',
			phone: 'Tālrunis'
		};

		const requiredFieldsMap = {
			newCarrier: ['carrierID', 'regNr'],
			newDriver: ['driverName', 'surname', 'vilcejs']
		};

		const requiredFields = requiredFieldsMap[modalType];

		const confirmAllFieldsHasValues = allFieldsHaveValues_new(requiredFields, toSaveData, nameMap, true);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		if (modalType == 'newCarrier') {
			api.admin.carrier_create(toSaveData).then(response => {
				if (response.status == 'ok') {
					toast.success('Pārvadātāja dati saglabāti');
					setTimeout((ev) => this.props.handleCloseModal(ev, response.data[0], 'client'), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 7500 }
					);
				}
			});
		}

		if (modalType == 'newDriver') {
			api.admin.driver_create({ data: toSaveData, carrier: this.props.client }).then(response => {
				if (response.status == 'ok') {
					toast.success('Šofera dati saglabāti');
					setTimeout((ev) => this.props.handleCloseModal(ev, response, 'driver'), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 7500 }
					);
				}
			});
		}
	};

	render() {
		const {
			isLoading,
			carrierID,
			regNr,
			vilcejs,
			driverName,
			surname,
			phone,
			modalType,
		} = this.state;

		return (
			<div className='bodyWrapper'>
				{isLoading ? (
					<Loading />
				) : (
					<div>
						{modalType == 'newCarrier' && (
							<><div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Nosaukums'
										name='carrierID'
										required={true} />
									<Input
										inputClass='col-sm-8'
										name='carrierID'
										value={carrierID}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Reģistrācijas Nr.'
										name='regNr'
										required={true} />
									<Input
										inputClass='col-sm-8'
										name='regNr'
										blur={this.formatAsString}
										value={regNr}
										handleChange={this.handleChange}
									/>
								</div>
								</>
						)}
						{modalType == 'newDriver' && (
							<><div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Vārds'
										name='driverName'
										required={true} />
									<Input
										inputClass='col-sm-8'
										name='driverName'
										value={driverName}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Uzvārds'
										name='surname'
										required={true} />
									<Input
										inputClass='col-sm-8'
										name='surname'
										value={surname}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Vilcēja Nr.'
										name='vilcejs'
										required={true} />
									<Input
										inputClass='col-sm-8'
										name='vilcejs'
										value={vilcejs}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Tālrunis'
										name='phone' />
									<Input
										inputClass='col-sm-8'
										name='phone'
										value={phone}
										handleChange={this.handleChange}
									/>
								</div></>
						)}
						{checkPermission('edit', 'transport') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default CreateNew;
