import React, { Component, Suspense } from 'react';
import Loading from 'shared/loading';
import CalendarOneDay from './calendar';
//import Calendar from './calendarMonth';
import ErrorOnLoading from 'shared/errorOnLoad';

const Calendar = React.lazy(() => import('./calendarMonth'));

const pad = n => (n < 10 ? `0${n}` : n);

class CalendarMainView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: false,
			calendarData: [],
			currDate: new Date(new Date().setHours(0, 0, 0, 0)), //set to midnight
			showModal: true,
			toShowDate: true
		};
	}

	handleSelect = key => {
		this.setState({ key, data: null });
	};

	onDateSelection = dateObject => {
		if (dateObject.getTime() === this.state.currDate.getTime()) {
			this.setState({ showModal: false, toShowDate: true });
		} else {
			this.setState({ currDate: dateObject, showModal: false, toShowDate: true });
		}
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		const { error, loading, currDate, showModal, toShowDate } = this.state;

		const datestring = `${pad(currDate.getDate())}.${pad(
			currDate.getMonth() + 1
		)}.${currDate.getFullYear()}.`;

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								{/*checkPermission('izpilde','view')*/}
								<button
									className='btn btn-success'
									onClick={this.handleToggleModal}>
									{datestring}
								</button>
								{/* toShowDate ? <div>Pašlaik tiek skatīts </div> : '' */}

								<Suspense fallback={null}>
									<Calendar
										selectDate={this.onDateSelection}
										showModal={showModal}
										handleCloseModal={this.handleToggleModal}
									/>
								</Suspense>
								<CalendarOneDay dateToShow={currDate} />
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default CalendarMainView;
