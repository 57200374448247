import React from 'react';
import api from '@api';
import Tabulator from 'shared/table/Tabulator';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';

const distanceFormatter = (cell) => {
    const {distance} = cell._cell.row.data;
    
    return `${parseFloat(distance*2).toFixed(2)} km`
}

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	return `${parseFloat(val).toFixed(digits)} €/t`;
};

const columns = [
	{
		title: 'Noliktava',
		field: 'storage',
		width: 250
    },
	/* {
		title: 'Noliktava',
		field: 'route_to',
		width: 250
    }, */
	{
		title: 'Distance, km',
		field: 'distance',
        formatter: (cell) => distanceFormatter(cell),
		width: 150
    },
    {
		title: 'Amonija nitrāts 34.4',
		field: 'Amonija nitrāts 34.4',
        formatter: (cell) => amountFormatter(cell, 'Amonija nitrāts 34.4', 2),
		width: 150
    },
    {
		title: 'Kvieši',
		field: 'Kvieši',
        formatter: (cell) => amountFormatter(cell, 'Kvieši', 2),
		width: 150
    },
    {
		title: 'Rapšu rauši (fasēti)',
		field: 'Rapšu rauši (fasēti)',
        formatter: (cell) => amountFormatter(cell, 'Rapšu rauši (fasēti)', 2),
		width: 150
    }
    /* ,
	{
		title: 'Attāluma veikšanas laiks',
		field: 'duration',
		width: 200
	} */
];

class TransportationPriceRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expenses: [],
            loading: true,
            address: ''
		};
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getData();
        }
    }

    getData = () => {
        const {id} = this.props.match.params;
        api.transportation.job(id)
            .then(res => {
                const {meta: {a_nosaukums, request_status, request_initiated_time, request_finished}, expenses, materials} = res;

                let finalExps = expenses.map(entry => {
                    let finalData = {
                        ...entry
                    }

                    for(let i=0; i< materials.length; i++) {
                        let price = materials[i].km_price;
    
                        if (entry && entry.distance && materials[i].kategorija === 1/* 'Graudi, Rapsis' */ || materials[i].kategorija === 4/* 'MM' */) {
                            if (entry.distance > 100) {
                                price = 0.8;
                            } else if (entry.distance > 40) {
                                price = 0.85;
                            } else {
                                price = 0.9;
                            }
                        }
                        
                        const exp = Math.round(((entry.distance*2*price)/materials[i].volume + Number.EPSILON) * 100) / 100;

                        finalData[materials[i].materiala_nosaukums] = exp;
                    }
                    
                    return finalData
                })

                this.setState({expenses: finalExps, address: a_nosaukums});
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }
    
    
    render() {
        const {loading, expenses, address} = this.state;

        if (loading) {
            return '';
        }

        return <div style={{padding:15}}>
        <div style={{fontWeight: 'bold', fontSize: '130%', paddingBottom:'15px'}}>
        Pieprasījuma adrese: {address}
        </div>

        <Tabulator
			columns={columns}
			options={expenses.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={expenses}
        />
        </div>
    }
}

export default TransportationPriceRequest
