import api from '../api';

const orders = {
	actions: orderId => api.get(`order/actions?order=${orderId}`),

	overview: () => api.get(`order/overview`),

	new: ({ clientRegNr, planningOrderId }) => api.get(`order/new_order?client=${clientRegNr}&planning_order=${planningOrderId}`),

	//vrb šos var apvienot front-end pusē? pēc tam backend apstrādāt loģiku?
	updateOrder: ({ order, data }) => api.post(`order/save`, { order, data }),

	saveNewOrder: data => api.post(`order/save`, { data }),

	info: order => api.get(`order/data_for_edit?order=${order}`),

	copy: order => api.get(`order/data_for_copy?order=${order}`),

	transportationPrices: ({objFrom, objTo}) => api.get(`order/transportation_price?from=${objFrom}&to=${objTo}`),

	materialTransportPrice: (id) => api.get(`materials/transportation_price?id=${id}`),

	ordersDept: ({ dept, rows, year, client, howMData, page, section }) =>
		api.get(
			`order/department?n=${dept}&section=${section}&rows=${rows}&page=${page}&y=${year}&klients=${client}&howMData=${howMData}`
		),

	changeStatus: ({ order, status }) => api.post(`order/update_status`, { order, status }),

	delete: order => api.post(`order/delete`, { id: order }),

	setViewed: order => api.post(`order/set_as_viewed`, { order }),

	infoNewTask: order => api.get(`tasks/info_for_new?order=${order}&type=1`),

	historicPrices: ({ storage, target, material }) =>
		api.get(
			`order/historic_orderer_price?storage=${storage}&target=${target}&material=${material}`
		),

	currentPrices: () =>
		api.get('materials/current_price_entries'),

	setToIzpilde: order => api.post(`order/set_to_izpilde`, { order }),

	saveTask: ({ order, data }) => api.post(`tasks/save`, { order, data }),

	setAsArchivedOrdererPrice: id => api.post(`order/archive_orderer_price`, { id })
};

export default orders;
