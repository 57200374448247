import React, {Component} from 'react';
import api from '@api';
import { deliveryPDFlink, MILISECONDS_BEFORE_CLOSE } from '../../../config';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import { Input, Radio, Select, Label } from '../../../shared/form';
import { checkPermission } from '../../../permissions';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';

class Lading extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailTemplates: [],
			ladingTemplates: [],
			carrierOtherMail: '',
			storageOtherMail: '',
		};
	}

	handleSendEmail = () => {
		const {emailTo, emailTemplate, ladingTemplate, deliveryId, deliveryInvoiceNr, paymentDueDate, unloadDate } = this.props;
		const {carrierMail, storageMail} = this.props.data;
		const {carrierOtherMail, storageOtherMail} = this.state;

		if (deliveryInvoiceNr === '' || paymentDueDate === '' || unloadDate === '' ) {
			toast.warn('Nav aizpildīti visi formas lauki!', {
				autoClose: 7500
			});
			return;
		}

		let addressTo = '';
		if (emailTo === 'carrier') {
			addressTo = carrierMail !== carrierOtherMail && carrierOtherMail !== '' ? carrierOtherMail : 'CARRIER';
		}

		if (emailTo === 'storage') {
			addressTo = storageMail !== storageOtherMail && storageOtherMail !== '' ? storageOtherMail : 'STORAGE';
		}

		if (emailTo === 'other') {
			addressTo = this.props.emailToOther;
		}

		if (addressTo !== 'CARRIER' && !addressTo.includes('@')) {
			toast.warn('Ievadīta nekorekta e-pasta adrese');
			return;
		}

		const finalToSend = {
			emailTo: addressTo,
			template: emailTemplate,
			ladingTemplate,
			deliveryId,
			deliveryInvoiceNr,
			paymentDueDate,
			unloadDate,
		};

		api.deliveries.sendEmail({ data: finalToSend }).then(response => {
			if (response.status == 'success') {
				toast.success('E-pasts nosūtīts!');
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`E-pastu neizdevās nosūtīt! Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	}

	handleOpenInvoice = () => {
		const { ladingTemplate, deliveryId, deliveryInvoiceNr, paymentDueDate, unloadDate } = this.props;
		const url = `${deliveryPDFlink}?tmp=${ladingTemplate}&task=${deliveryId}&inv=${deliveryInvoiceNr}&due=${paymentDueDate}&unload=${unloadDate}`;

		window.open(url, '_blank');
	};

	componentDidMount() {
		api.admin.get_email_templates('EMAIL_PDF_LADING').then(response => {
			this.setState({ladingTemplates: response});
		})
	}

	setTemplates = () => {
		this.setState({emailTemplates: []});
		if (this.props.emailTo === '') {
			return;
		}

		const cat = {carrier: 'EMAIL_TEXT_INVOICE_CARRIER', storage: 'EMAIL_TEXT_INVOICE_STORAGE', other: 'EMAIL_TEXT_INVOICE_SPECIFIC'}[this.props.emailTo];

		api.admin.get_email_templates(cat).then(response => {
			this.setState({emailTemplates: response});
		})
	};

	setEmails = () => {
		this.setState({carrierOtherMail: '', storageOtherMail: ''});
		if (this.props.emailTo === '') {
			return;
		}

		const {carrierMail, storageMail} = this.props.data;

		this.setState({carrierOtherMail: carrierMail, storageOtherMail: storageMail});
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.emailTo !== prevProps.emailTo) {
			this.setTemplates();
			this.setEmails();
		}
	}

	handleChange = event => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	render() {
		const {ladingTemplates} = this.state;

		return <div className='row' style={{ margin: '10px auto' }}>
			<div className='row'>
				<Label
					labelClass='col-sm-3'
					title='Līguma Nr.'
					name='deliveryInvoiceNr'
					required={true}
				/>
				<Input
					inputClass='col-sm-8'
					name='deliveryInvoiceNr'
					value={this.props.deliveryInvoiceNr}
					handleChange={(ev) => this.props.handleChange(ev, 'deliveryInvoiceNr')}
				/>
			</div>
			<div className='row'>
				<Label
					labelClass='col-sm-3'
					title='Plānotais izkraušanas datums'
					name='unloadDate'
					required={true}
				/>
				<div className='col-sm-8'>
					<Datetime
						locale='lv'
						timeFormat={false}
						closeOnSelect={true}
						onChange={this.props.handleDateChange('unloadDate')}
						defaultValue={this.props.unloadDate}
					/>
				</div>
			</div>
			<div className='row'>
				<Label
					labelClass='col-sm-3'
					title='Apmaksas termiņš'
					name='paymentDueDate'
					required={true}
				/>
				<Input
					inputClass='col-sm-8'
					name='paymentDueDate'
					value={this.props.paymentDueDate}
					handleChange={(ev) => this.props.handleChange(ev, 'paymentDueDate')}
				/>
			</div>
			<div className='row'>
				<Label
					labelClass='col-sm-3'
					title='Adresāts'
					name='emailTo'
					required={true}
				/>
				<Select
					handleChange={(ev) => this.props.handleChange(ev, 'emailTo')}
					name='emailTo'
					value={this.props.emailTo}
					placeholder='Izvēlies adresātu'
					selectClass='col-sm-8'
					options={[
						{ value: 'carrier', label: 'Pārvadātājam', disabled: !this.props.data.carrierMail },
						{ value: 'storage', label: 'Atkraušanas noliktavai' },
						{ value: 'other', label: 'Citam adresātam' }
					]}
				/>
			</div>
			{this.props.emailTo == 'carrier' &&
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title=''
						name='carrierOtherMail'
					/>
					<Input
						type='text'
						inputClass='col-sm-8'
						name='carrierOtherMail'
						placeholder='paraugs@email.lv'
						value={this.state.carrierOtherMail}
						handleChange={this.handleChange}
					/>
				</div>
			}
			{this.props.emailTo == 'storage' &&
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title=''
						name='storageOtherMail'
					/>
					<Input
						type='text'
						inputClass='col-sm-8'
						name='storageOtherMail'
						placeholder='paraugs@email.lv'
						value={this.state.storageOtherMail}
						handleChange={this.handleChange}
					/>
				</div>
			}
			{this.props.emailTo == 'other' &&
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title=''
						name='emailToOther'
					/>
					<Input
						type='text'
						inputClass='col-sm-8'
						name='emailToOther'
						placeholder='paraugs@email.lv'
						value={this.props.emailToOther}
						handleChange={(ev) => this.props.handleChange(ev, 'emailToOther')}
					/>
				</div>
			}
			{this.state.emailTemplates.length > 0 &&
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title='E-pasta veidne:'
						name='emailTemplate'
						required={true}
					/>
					<Select
						selectClass='col-sm-8'
						handleChange={(ev) => this.props.handleChange(ev, 'emailTemplate')}
						name='emailTemplate'
						value={this.props.emailTemplate}
						options={this.state.emailTemplates.map(tpl => ({value: tpl.id, label: tpl.name}))}
					/>
				</div>
			}
			{this.state.ladingTemplates.length > 0 &&
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title='Pavadzīmes veidne:'
						name='ladingTemplate'
						required={true}
					/>
					<Select
						selectClass='col-sm-8'
						handleChange={(ev) => this.props.handleChange(ev, 'ladingTemplate')}
						name='ladingTemplate'
						value={this.props.ladingTemplate}
						options={ladingTemplates.map(tpl => ({value: tpl.id, label: tpl.name}))}
					/>
				</div>
			}
			<button
				disabled={!checkPermission('edit') || !this.props.ladingTemplate}
				type='button'
				className='btn btn-default'
				style={{ marginTop: 10}}
				onClick={this.handleOpenInvoice}>
				<i className='fas fa-file'></i> Skatīt PDF
			</button>
			<button
				disabled={!checkPermission('edit') || !this.props.emailTo || (this.props.emailTo == 'other' && !this.props.emailToOther) || !this.props.emailTemplate || !this.props.ladingTemplate}
				type='button'
				className='btn btn-primary'
				style={{ marginTop: 10, marginLeft: 3  }}
				onClick={this.handleSendEmail}>
				Sūtīt
			</button>
		</div>
	}
}
export default Lading;
