import React, { Component } from 'react';
import Modal from '@sections/orders/actions/modal';
import UzdevumiModal from 'sections/orders/uzdevumiModal';
import VedumiModal from 'sections/orders/vedumiModal';
import api from '../../../api';
import { formatNumber , customTitle } from 'util';
import { CustomModal } from 'shared/modal';
import AddressEdit from '@sections/admin/forms/addresses';
import StorageEdit from '@sections/admin/forms/storage';
import Loading from 'shared/loading';
import tableSummary from './summary';
import setRespectTabs from 'shared/tabs/setRespectTabs';

import Table from './tableComponent';
import { checkPermission } from 'permissions';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { loadData } from '../slice';

import * as formatter from './formatters';

//var equal = require('fast-deep-equal');
//import { reactFormatter } from 'react-tabulator/lib/Utils';

const handleOpenOrderer = cell => {
	//console.log('cell', cell);
	window.open(`#/client/${cell.getValue().regNr}`, '_self');
};

const generateSums = tableData => {
	let sumObject = {};

	const fieldsToSum = [
		'remainder',
		'orderedAmount',
		'plannedAmount',
		'deliveredByFact',
		'expenses',
		'numberOfTasks',
		'numberOfVedumi',
		'inTasks'
	];

	for (let i = 0; i < tableData.length; i++) {
		const { measurementUnit, ...tableRow } = tableData[i];

		for (let y = 0; y < fieldsToSum.length; y++) {
			let theField = fieldsToSum[y];
			if (parseFloat(tableRow[theField]) <= 0) {
				continue;
			}
			if (theField in tableRow) {
				if (theField in sumObject) {
					if (measurementUnit in sumObject[theField]) {
						sumObject[theField][measurementUnit] += parseFloat(tableRow[theField]);
					} else {
						sumObject[theField][measurementUnit] = parseFloat(tableRow[theField]);
					}
				} else {
					sumObject[theField] = { [measurementUnit]: parseFloat(tableRow[theField]) };
				}
			}
		}
	}

	//const res = Object.keys(sumObject).map(field => `${sums[meas]} ${meas}`);
	let finalArr = {};
	for (let field in sumObject) {
		const res = Object.keys(sumObject[field])
			.map(meas => `${formatNumber(sumObject[field][meas], 3)} ${meas}`)
			.join(', ');
		finalArr[field] = res;
	}
	finalArr['numberOfResults'] = tableData.length;

	//console.log(sums, res);

	//return res.join(', ');

	return finalArr;
};

class OrderTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			loading: true,
			currentlyEditingUser: null,
			openedModal: '',
			addressID: null,
			additionalColumnsVisible: true
		};
	}

	componentDidMount() {
		//this.props.loadData();
	}

	handleSetAsViewed(cellData) {
		if (cellData.isChecked !== 0) {
			return;
		}

		if (!checkPermission('edit')) {
			return;
		}

		const { orderId: order } = cellData;
		api.orders.setViewed(order).then(response => {
			if (response.status === 'ok') {
				toast.success('Atzīmēts kā apskatīts');
				setRespectTabs();
				this.props.loadData();
			} else {
				toast.info('Radās kļūda, saglabājot datus');
			}
		});
	}

	handleOpenModal = (cellData, modalToOpen) => {
		//e.stopPropagation();
		//event.preventDefault();
		//._cell.row.data.orderId
		//debugger;
		//const cellData = cell.getRow().getData();

		this.setState({
			showModal: true,
			openedModal: modalToOpen,
			orderId: cellData.orderId,
			orderNr: cellData.orderNr,
			remainder: cellData.remainder,
			addressID: cellData.delivery.address,
			storageID: cellData.storage.id
		});
	};

	handleCloseModal = (ev, update = false) => {
		this.setState({ openedModal: '' });

		if (update) {
			this.props.loadData();
		}
	};

	columns = [
		{
			title: 'Nr',
			headerSort: true,
			headerVertical: 'flip',
			field: 'npk',
			width: 45,
			formatter: cell => formatter.npkFormatter(cell),
			headerTooltip: 'N.p.k.',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334'),
			cellClick: (e, cell) => this.handleSetAsViewed(e, cell)
		},
		{
			title: 'Darb.',
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			field: 'orderId',
			cellClick: (e, cell) => {
				this.handleOpenModal(e, cell, 'actions');
			},
			formatter: () => formatter.actionsButtonFormatter(),
			headerSort: false,
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		},
		{
			title: 'Uzd.',
			headerVertical: 'flip',
			width: 45,
			cssClass: 'orderedAmount',
			field: 'numberOfTasks',
			cellClick: (e, cell) => this.handleOpenModal(e, cell, 'tasks'),
			headerTooltip: 'Uzdevumu skaits',
			titleFormatter: column => customTitle(column, 'fas fa-tasks', '#333334')
		},
		{
			title: 'Ved.',
			headerVertical: 'flip',
			width: 45,
			cssClass: 'deliveredAmount',
			field: 'numberOfVedumi',
			cellClick: (e, cell) => this.handleOpenModal(e, cell, 'vedumi'),
			headerTooltip: 'Vedumu skaits',
			titleFormatter: column => customTitle(column, 'fas fa-tasks', '#333334')
		},
		{
			title: 'Pas. nr.',
			headerVertical: 'flip',
			headerSort: false,
			field: 'orderNr',
			formatter: cell => formatter.nrFormatter(cell),
			headerTooltip: '',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		},
		{
			title: 'Pasūtītājs',
			cssClass: 'shrinkLines',
			headerSort: false,
			field: 'orderer',
			cellClick: (e, cell) => handleOpenOrderer(cell),
			formatter: cell => formatter.addressFormatter({ cell, type: 'orderer' }),
			width: 140,
			headerTooltip: 'Pasūtītājs',
			titleFormatter: column => customTitle(column, 'fas fa-building', '#333334')
		},
		{
			title: 'Noliktava',
			cssClass: 'shrinkLines',
			headerSort: false,
			field: 'storage',
			cellClick: (e, cell) => {
				this.handleOpenModal(e, cell, 'storage');
			},
			formatter: cell => formatter.addressFormatter({ cell, type: 'storage' }),
			width: 140,
			headerTooltip: 'Noliktava',
			titleFormatter: column => customTitle(column, 'fas fa-warehouse', '#333334')
		},
		{
			title: 'Piegādes<br>adrese',
			cssClass: 'shrinkLines',
			headerSort: false,
			field: 'delivery',
			cellClick: (e, cell) => {
				this.handleOpenModal(e, cell, 'address');
			},
			formatter: cell => formatter.addressFormatter({ cell, type: 'delivery' }),
			width: 140,
			headerTooltip: 'Piegādes adrese',
			titleFormatter: column => customTitle(column, 'fas fa-map-pin', '#333334')
		},
		{
			title: 'Materiāls/<br>pieg. iekļ.<br>cenā/<br>Piezīmes',
			cssClass: 'shrinkLines',
			headerSort: false,
			field: 'material',
			formatter: cell => formatter.materialFormatter(cell),
			width: 140,
			headerTooltip: 'Materiāls, piezīmes',
			titleFormatter: column => customTitle(column, 'fas fa-clipboard', '#333334')
		},
		{
			title: 'Pasūtīts',
			headerVertical: 'flip',
			cssClass: 'orderedAmount',
			field: 'orderedAmount',
			formatter: cell => formatter.amountFormatter(cell, 'orderedAmount'),
			headerTooltip: 'Pasūtītais daudzums',
			titleFormatter: column =>
				customTitle(
					column,
					'fas fa-shipping-fast',
					'#e8e8e8',
					'; text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
				)
		},
		{
			title: 'Atlikums',
			headerVertical: 'flip',
			cssClass: 'remainingAmount',
			field: 'remainder',
			formatter: cell => formatter.amountFormatter(cell, 'remainder'),
			headerTooltip: 'Pasūtījuma atlikums',
			titleFormatter: column =>
				customTitle(
					column,
					'fas fa-shipping-fast',
					'#FFCCCC',
					'; text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
				)
		},
		{
			title: 'Uzd. atdots',
			headerVertical: 'flip',
			cssClass: 'amountInTasks',
			field: 'inTasks',
			formatter: cell => formatter.amountFormatter(cell, 'inTasks'),
			headerTooltip: 'Daudzums, kas pašlaik ir uzdevumos',
			titleFormatter: column =>
				customTitle(
					column,
					'fas fa-shipping-fast',
					'#FFCC66',
					'; text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
				)
		},
		{
			title: 'Aizvests',
			headerVertical: 'flip',
			cssClass: 'deliveredAmount',
			field: 'deliveredByFact',
			formatter: cell => formatter.amountFormatter(cell, 'deliveredByFact'),
			headerTooltip: 'Aizvestais daudzums',
			titleFormatter: column =>
				customTitle(
					column,
					'fas fa-shipping-fast',
					'#CCFF99',
					'; text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
				)
		},
		{
			title: 'Izmaksas<br>EUR',
			width: 55,
			headerVertical: 'flip',
			field: 'expenses',
			headerTooltip: 'Izmaksas, EUR',
			titleFormatter: column => customTitle(column, 'fas fa-euro-sign', '#333334')
		},
		{
			title: 'Statuss',
			headerVertical: 'flip',
			field: 'status',
			formatter: cell => formatter.statusFormatter(cell),
			width: 110,
			headerTooltip: 'Statuss',
			titleFormatter: column => customTitle(column, 'fas fa-signal', '#333334')
		},
		{
			title: 'Piegādāt līdz',
			headerVertical: 'flip',
			field: 'deliverTill',
			formatter: cell => formatter.deliverTillFormatter(cell),
			width: 110,
			headerTooltip: 'Piegādāt līdz',
			titleFormatter: column => customTitle(column, 'fas fa-clock', '#333334')
		},
		{
			name: 'prognozed_route_length',
			title: 'Optimālais attālums starp noliktavu un piegādes vietu',
			headerVertical: 'flip',
			bottomCalcParams: { precision: 3 },
			width: 70,
			field: 'prognozed_route_length',
			headerTooltip: 'Optimālais attālums starp noliktavu un piegādes vietu',
			titleFormatter: column => customTitle(column, 'fas fa-route', '#333334')
		},
		{
			name: 'prognozed_travel_time',
			title: 'Prognozējamais laiks optimālā attāluma veikšanai',
			headerVertical: 'flip',
			width: 70,
			field: 'prognozed_travel_time',
			headerTooltip: 'Prognozējamais laiks optimālā attāluma veikšanai',
			titleFormatter: column => customTitle(column, 'fas fa-user-clock', '#333334')
		},
		{
			name: 'cost_per_unit',
			title: 'Transporta cena materiālam',
			field: 'cost_per_unit',
			headerVertical: 'flip',
			width: 75,
			headerTooltip: 'Transporta cena materiālam',
			titleFormatter: column => customTitle(column, 'fas fa-euro-sign', '#333334')
		},
	];

	toggleColumns = () => {
		this.setState(currState => ({
			additionalColumnsVisible: !currState.additionalColumnsVisible
		}))
	}

	render() {
		const {
			orderId,
			orderNr,
			openedModal,
			remainder,
			searching,
			storageID,
			addressID
		} = this.state;
		const {orders} = this.props;

		const summs = generateSums(orders);
		const shouldCheckVisiblity = !this.state.additionalColumnsVisible;

		const columns = this.columns.filter(col => {
			if (!shouldCheckVisiblity) {
				return true;
			}

			if (col.name) { // meh. of course, we should check if column is needed, but it works for now
				return false;
			}

			return true;
		})

		return (
			<div>
				{searching ? (
					<Loading />
				) : (
					<div>
						{tableSummary(summs)}
						<div style={{ padding: 3 }} />
						{/* <div
							style={{
								float: 'right'
							}}>
							<button type='button' className='btn btn-primary btn-sm' onClick={this.toggleColumns}>
								Mainīt redzamību
							</button>
						</div> */}
						<Table
							modalOpeningHandler={this.handleOpenModal}
							setAsViewedHandler={this.handleSetAsViewed}
						/>
					</div>
				)}

				{openedModal === 'actions' && (
					<Modal
						showModal={openedModal === 'actions'}
						orderId={orderId}
						remainder={remainder}
						closeModalWithoutReload={this.handleCloseModal}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'tasks' && (
					<UzdevumiModal
						showModal={openedModal === 'tasks'}
						orderId={orderId}
						orderNr={orderNr}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'vedumi' && (
					<VedumiModal
						showModal={openedModal === 'vedumi'}
						orderId={orderId}
						orderNr={orderNr}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'address' && (
					<CustomModal
						title='Adreses datu labošana'
						showModal={openedModal === 'address'}
						handleCloseModal={this.handleCloseModal}
						content={<AddressEdit id={addressID} />}
					/>
				)}
				{openedModal === 'storage' && (
					<CustomModal
						title='Noliktavas datu labošana'
						showModal={openedModal === 'storage'}
						handleCloseModal={this.handleCloseModal}
						content={<StorageEdit id={storageID} />}
					/>
				)}
			</div>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		loadData: () => {
			dispatch(loadData());
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		orders: state.orders.orders
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(OrderTable);
