import api from '../api';

const mergeOtherInvoiceParams = params => {
	let url = '';

	['storned', 'no_horizon', 'horizon_changed', 'open'].forEach(item => {
		if (params[item]) {
			url += `&${item}`;
		}
	});

	return url;
};

const bills = {
	getAllBills: client => api.get(`invoice/all?klients=${client}`),
	getAllBills2: nr => api.get(`invoice/all?nr=${nr}&not=yes`),
	getAllBills3: ({ count, maks, nr, offset, client, date_from, date_till, ...otherParams }) =>
		api.get(
			`invoice/all?nr=${nr}&loadCount=${count}&maks=${maks}&offset=${offset}&klients=${client}&date_from=${date_from}&date_till=${date_till}${mergeOtherInvoiceParams(
				otherParams
			)}`
		),

	actions: nr => api.get(`invoice/${nr}`),

	importToHorizonAll: () => api.post(`horizon/mass_import`),

	free_pieg: ({ payer, type }) =>
		api.get(`invoice/allFreePiegadesForInvoice?maks=${payer}&type=${type}`),

	data_edit: ({ payer, type, order }) => {
		return api.get(`invoice/specific?maks=${payer}&type=${type}&pasId=${order}&do=y`);
	},

	getBillCount: ({ maks, nr, client, date_from, date_till, ...otherParams }) =>
		api.get(
			`invoice/counter?maks=${maks}&nr=${nr}&client=${client}&date_from=${date_from}&date_till=${date_till}${mergeOtherInvoiceParams(
				otherParams
			)}`
		),

	delete: bill => api.post(`invoice/delete`, { bill }),

	update: data => api.post(`invoice/update`, { data }),

	addDelivery: ({ pvz, order, newStatus }) =>
		api.post(`invoice/add_delivery`, { pvz, order, newStatus }),

	importHorizon: ({ pvz, creditor }) => api.post(`horizon/import`, { pvz, creditor }),

	checkBills: invoices => api.post(`horizon/check_bills`, { invoices }),

	diffPayerInfo: invoice => api.get(`client/different_payer/info?id=${invoice}`),

	diffPayerSave: data => api.post(`client/different_payer/save`, data),

	diffPayerRemove: data => api.post(`client/different_payer/remove`, data),

	deliveries: id => api.get(`deliveries/invoice?id=${id}`),

	sendClient: ({ billId, defaultMessage, includeDeliveriesDoc }) =>
		api.post(`invoice/email/send`, {
			billId,
			message: defaultMessage,
			send_confirmation: includeDeliveriesDoc
		})
};

export default bills;
