import React, { Component } from 'react';
import api from '@api';
import { horizonCheck } from 'permissions';

class HorizonSync extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: false,
			status: false
		};
	}

	saveData = () => {
		this.setState({ loading: true, error: false, status: true });

		api.admin
			.horizon_sync()
			.then(response => {
				this.setState({ error: false, loading: false });
			})
			.catch(() => {
				this.setState({ error: true, loading: false });
			});
	};

	render() {
		const { loading, error, status } = this.state;

		return (
			<div className='bodyWrapper'>
				<h2>Klientu datu sinhronizācija ar Horizon datubāzi</h2>
				{status && (
					<span>
						{error ? (
							<div className='alert alert-danger' role='alert'>
								Notika kļūda datu sinhronizācijas laikā
							</div>
						) : (
							<div>
								{loading ? (
									''
								) : (
									<div className='alert alert-success' role='alert'>
										Dati veiksmīgi sinhronizēti
									</div>
								)}
							</div>
						)}
					</span>
				)}
				{loading && (
					<div className='progress'>
						<div
							className='progress-bar progress-bar-striped active'
							role='progressbar'
							style={{ width: '100%' }}></div>
					</div>
				)}
				{horizonCheck() && (
					<button
						type='button'
						disabled={loading}
						className='btn btn-primary'
						onClick={this.saveData}>
						Veikt sinhronizāciju
					</button>
				)}
			</div>
		);
	}
}

export default HorizonSync;
