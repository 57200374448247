import api from '../api';

const mergeOtherInvoiceParams = params => {
	let url = '';

	['storned', 'completed', 'horizon_booked', 'horizon_template', 'logistics_template'
	].forEach(item => {
		if (params[item]) {
			url += `&${item}`;
		}
	});

	return url;
};

// todo: izvilkt ārā funkcijā, eksportu padot kā parametru
const reports = {
	invoices: (start, end) => api.get(`reports/invoices?start=${start}&end=${end}`),
	invoicesComplex: ({start, end, completed, horizon_booked}) => api.get(`reports/invoice_deep?start=${start}&end=${end}&completed=${completed}&horizon_booked=${horizon_booked}`),
	invoicesComplex2: ({maks, carrier, nr, start, end, date_from, date_till, delivery_from, delivery_till, ...otherParams }) =>
		api.get(`reports/invoice_deep?maks=${maks}&carrier=${carrier}&nr=${nr}&start=${start}&end=${end}&date_from=${date_from}&date_till=${date_till}&delivery_from=${delivery_from}&delivery_till=${delivery_till}
		${mergeOtherInvoiceParams(otherParams)}`),

	monthly_summary: (start, end, level) => api.get(`reports/monthly_reports?start=${start}&end=${end}&level=${level}`),
	orders: (start, end) => api.get(`reports/orders?start=${start}&end=${end}`),
	registeredOrders: (start, end) => api.get(`reports/registered_orders?start=${start}&end=${end}`),
	ordererPrices: (start, end) => api.get(`reports/orderer_prices?start=${start}&end=${end}`),
	agroDept: (start, end) => api.get(`reports/agro_department?start=${start}&end=${end}`),
	drivers: (start, end, level) => api.get(`reports/drivers?start=${start}&end=${end}&mode=${level}`),
	transporters: (start, end, level) => api.get(`reports/transporters?start=${start}&end=${end}&mode=${level}`),
	timeLength: (start, end, level) => api.get(`reports/length_time?start=${start}&end=${end}&mode=${level}`),
	ordersDeliveries: (start, end) => api.get(`reports/orders_deliveries?start=${start}&end=${end}`),

	invoices_exp: (start, end) => api.get(`reports/invoices?start=${start}&end=${end}&export`),
	monthly_summary_exp: (start, end, level) => api.get(`reports/monthly_reports?start=${start}&end=${end}&level=${level}&export`),
	orders_exp: (start, end) => api.get(`reports/orders?start=${start}&end=${end}&export`),
	invoicesComplex_exp: (start, end) => api.get(`reports/invoice_deep?start=${start}&end=${end}&export`),
	ordererPrices_exp: (start, end) => api.get(`reports/orderer_prices?start=${start}&end=${end}&export`),
	registeredOrders_exp: (start, end) => api.get(`reports/registered_orders?start=${start}&end=${end}&export`),
	ordersDeliveries_exp: (start, end) => api.get(`reports/orders_deliveries?start=${start}&end=${end}&export`),
};

export default reports;
