import React, { Component } from 'react';
import api from '../../../api';
import { Input, Label, Select, Textarea, Radio, Check } from '../../../shared/form';
import { toast } from 'react-toastify';
import { allFieldsHaveValues_new, formatNumber, isNumeric, reloadPage, getCurrPage } from 'util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';

class ShipEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			toSaveData: {}
		};
	}

	setData = () => {
		const { id } = this.props;

		api.admin.ship_get(id).then(response => {
			this.setState({
				ship: response.title,
				id: response.id,
				isLoading: false,
				error: false
			});
		});
	};

	componentDidMount() {
		if (this.props.id) {
			this.setData();
			return
		}
		this.setState({ isLoading: false, error: false });
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		let inputValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		e.preventDefault();

		const { toSaveData } = this.state;
		const shipId = this.state.id;
		const currPage = getCurrPage();

		if (Object.keys(toSaveData).length === 0) {
			const msg = shipId ? 'Dati nav mainīti' : 'Dati nav ievadīti';
			toast.info(msg, { autoClose: 3500 });

			return;
		}

		console.log('to save', JSON.stringify(toSaveData));

		const nameMap = {
			ship: 'Kuģis'
		};

		const requiredFields = [
			'ship'
		];

		const confirmAllFieldsHasValues = allFieldsHaveValues_new(requiredFields, toSaveData, nameMap, true);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		const finalToSend = Object.assign({}, {data: toSaveData, id: shipId});

		api.admin.ship_save(finalToSend).then(response => {
			if (response.status == 'ok') {
				toast.success('Kuģa dati saglabāti');
				if (currPage == 'ships') {
					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				} else {
					setTimeout((ev) => this.props.handleCloseModal(ev, response.data, 'ship'), MILISECONDS_BEFORE_CLOSE);
				}
			} else {
				toast.warn(
					`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
					{ autoClose: 7500 }
				);
			}
		});
	};

	render() {
		const { isLoading, ship } = this.state;

		return (
			<div className='bodyWrapper'>
				{isLoading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Nosaukums'
								name='ship'
								required={true} />
							<Input
								inputClass='col-sm-8'
								name='ship'
								value={ship}
								handleChange={this.handleChange}
							/>
						</div>

						{checkPermission('edit', 'transport') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default ShipEdit;
