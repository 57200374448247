import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import ErrorOnLoading from 'shared/errorOnLoad';
import NoData from 'shared/noDataNotification';
import { Select } from 'shared/form';

const statusFormatter = cell => {
	const { code } = cell.getRow().getData();

	if (code == 0) {
		return '<span style="color: green;">OK</span>';
	}
	if (code == 2) {
		return 'Info';
	}

	if (code == 1) {
		return '<span style="color: red;">Kļūda</span>';
	}

	return 'Nezināms statuss';
};

class AuditTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			currentUser: '',
			users: [],
			error: false
		};
	}

	setupData() {
		this.setState({ loading: true, error: false });

		const { currentUser } = this.state;

		api.admin.monitoring_actions(currentUser).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	setUpUsers() {
		//this.setState({ loading: true, error: false })

		api.admin.monitoring_users().then(response => {
			this.setState({
				//loading: false, error: false,
				users: response
			});
		});
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;

		this.setState({ currentUser: inputValue }, () => this.setupData());
	};

	componentDidMount() {
		this.setupData();
		this.setUpUsers();
	}

	//`action`, description, sql_query, payload, `code`, DATE_FORMAT(action_date, '%H:%i') as action_date, `user`, ip
	columns = [
		//{ title: 'ID', field: 'id' },
		{ title: 'Statuss', field: 'code', formatter: cell => statusFormatter(cell) },
		{ title: 'Darbība', field: 'action', width: 100 },
		{ title: 'Apraksts', field: 'description', width: 350, responsive: 2 },
		//{ title: 'SQL', field: 'sql_query' },
		{ title: 'Parametri', field: 'payload', width: 350, responsive: 1 },
		{ title: 'Laiks', field: 'action_date', width: 100 },
		{ title: 'Lietotājs', field: 'username', width: 150 },
		{ title: 'IP adrese', field: 'ip', width: 100 }
	];

	render() {
		const { data, error, loading, currentUser, users } = this.state;

		return (
			<div className='bodyWrapper'>
				<Select
					selectClass='col-sm-3'
					name='category'
					handleChange={this.handleChange}
					value={currentUser}
					placeholder='Izvēlies darbinieku'
					options={users.map(user => ({
						value: user.id,
						label: `${user.name} ${user.surname}`
					}))}
				/>
				<div className='clearBoth' />
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<NoData />
								) : (
									<Tabulator
										columns={this.columns}
										options={{ pagination: false }}
										data={data}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default AuditTable;
