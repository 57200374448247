/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import api from '@api';
import { Select } from '../../shared/form';
import { CustomModal } from 'shared/modal';
import DriverMessage from './driverMessage';
import { toast } from 'react-toastify';
import TaskEdit from '../orders/taskEdit';
import { checkPermission } from '../../permissions';
import './header.css';
import { reloadPage } from 'util';
import { MILISECONDS_BEFORE_CLOSE } from 'config';
import setRespectTabs from 'shared/tabs/setRespectTabs';

const formatDate = dateStr => {
	if (!dateStr) {
		return '';
	}
	return `${dateStr.substring(6, 8)}.${dateStr.substring(4, 6)}.${dateStr.substring(0, 4)}.`;
};

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: 0,
			loading: true,
			data: {},
			mappostTaskState: 0,
			openedModal: false,
			showModal: ''
		};
	}

	componentDidMount() {
		this.setState({
			data: this.props.data,
			mappostTaskState: this.props.data.mappostTaskState
		});
	}

	handleChange = event => {
		const userActionConfirmation = window.confirm('Vai Jūs tiešām vēlaties mainīt statusu?');
		if (userActionConfirmation == false) {
			return;
		}
		const state = event.target.value;
		const { taskId: task, taskIzpilde } = this.props.data;
		//taskIzpilde - Mappost vai cits riks

		if (state == 4) {
			//uzdevuma labošana
			this.setState({ showModal: 'taskEdit' });
			return;
		}

		//todo: ja viss normāli apdeitojas, tad jāapdeito attiecīgā lapa (izpilde_soferi gadījumā visa LLIS sadaļa)
		if (taskIzpilde == 'MAPPOST') {
			api.tasks
				.changeMappostStatus({ task, state }) //task, state
				.then(response => {
					if (response.status == 'error') {
						toast.warn(response.message, { autoClose: 10000 });
					} else if (response.status == 'ok') {
						toast.success('Dati saglabāti');
						this.setState({ mappostTaskState: state });
						setRespectTabs();
						this.props.resetData(false);
						// setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					} else {
						toast.warn('Ir problēmas!', { autoClose: 7000 });
					}
				});
		} else {
			api.tasks
				.changeStatus({ task, state }) //task, state
				.then(response => {
					if (response.status == 'error') {
						toast.warn(response.message, { autoClose: 10000 });
					} else if (response.status == 'ok') {
						toast.success('Dati saglabāti');
						this.setState({ mappostTaskState: state });
						setRespectTabs();
						console.log('props', this.props)
						this.props.resetData(false);
						// setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					} else {
						toast.warn('Ir problēmas!', { autoClose: 7000 });
					}
				});
		}
	};

	handleSendMessageDriverOpen = () => {
		//jābūt task un driver
		this.setState({ showModal: 'driverMessage' });
	};

	handleCloseModal = () => {
		this.setState({ showModal: '' });
		this.props.resetData(false);
	};

	getStateColor(mpState) {
		if (mpState == 1) {
			return '#CC3333';
		}
		if (mpState == 2) {
			return '#336633';
		}
		if (mpState == 3) {
			return '#FFFF33';
		}
		if (mpState == 4) {
			return '#336633';
		}
		if (mpState == 9) {
			return '#FFF';
		}
	}

	setData() {
		const { data } = this.state;
	}

	render() {
		const { data, mappostTaskState, showModal } = this.state;
		const { mode } = this.props;

		//const mappostTaskState = this.state.

		let taskNr;
		let options;

		if (data.taskIzpilde == 'NONE') {
			taskNr = (
				<div>
					LOĢ-&gt;ŠOF
					<br />#{data.taskId}
				</div>
			);
		} else {
			taskNr = (
				<div>
					LOĢ-&gt;{data.taskIzpilde}
					<br />#{data.taskId}/{data.mappostTaskId}
				</div>
			);
		}

		//{"description":"Rap\u0161u rau\u0161i, ","notes":"29.11.2018 \nNo r\u012bta uzkraujamies... Cenas iepriek\u0161\u0113j\u0101s !","carrierOrg":"ZIEDI\u0145I Z.S., RAMANIS ANDRIS","taskId":27866,"taskIzpilde":"MAPPOST","isMappostTask":true,"mappostTaskId":8374584,"mappostTaskState":1,"taskStartDate":"20181129","driver":"ALDIS LATAITIS","taskDescription":"Rap\u0161u rau\u0161i, [Pas\u016bt. PALSA, SIA], ","taskDaudz":25,"taskDaudzUnit":"t","worker":"L. Sni\u0137ers","mappostDriverId":10407,"orderedAmount":"25.000 t","givenAmount":"25.000 t","deliveredAmount":"0.000 t"}

		const summs = (
			<div>
				Pasūtīts: {data.orderedAmount}
				<br />
				Atdots: {data.givenAmount}
				<br />
				Aizvests: {data.deliveredAmount}
			</div>
		);

		if (mode == 'normal') {
			options = [
				{ label: 'Aktīvs', value: 1, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Aizvests', value: 2, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Arhivēts', value: 3, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Labot uzdevumu', value: 4, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Dzēst', value: 9, disabled: !checkPermission('delete', 'tasks') }
			];
		} else if (mode == 'archive') {
			options = [
				{ label: 'Aktīvs', value: 1, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Aizvests', value: 2, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Arhivēts', value: 3, disabled: !checkPermission('edit', 'tasks') }
			];
		} else if (mode == 'piegade' || mode == 'calendar' || mode == 'aizvestais') {
			options = [
				{ label: 'Aktīvs', value: 1, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Aizvests', value: 2, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Arhivēts', value: 3, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Labot uzdevumu', value: 4, disabled: !checkPermission('edit', 'tasks') },
				{ label: 'Dzēst', value: 9, disabled: !checkPermission('delete', 'tasks') }
			];
		}

		const bgColor = this.getStateColor(data.mappostTaskState);
		const fontColor = data.mappostTaskState == 3 ? 'black' : 'white';

		const selectElement = (
			<Select
				disabled={!checkPermission('edit', 'tasks')}
				innerClass='form-control input-sm'
				handleChange={this.handleChange}
				name='mappostTaskState'
				value={mappostTaskState}
				placeholder='Izvēlies vērtību'
				options={options}
			/>
		);

		return (
			<div>
				<div className='headerContainer'>
					<div className='headerPart dateSection'>{formatDate(data.taskStartDate)}</div>
					<div className='headerPart taskNrSection'>{taskNr}</div>
					<div className='headerPart descriptionSection'>
						šoferis: <b>{data.driver}</b>
						{data.carrierOrg !== '' ? <span> - {data.carrierOrg}</span> : ''}
					</div>
					<div className='headerPart selectSection' style={{ backgroundColor: bgColor }}>
						{selectElement}
					</div>
					<div
						className='headerPart summariesSection'
						style={{ backgroundColor: bgColor, color: fontColor, fontWeight: 'bold' }}>
						{summs}
					</div>
					{data.isMappostTask && data.isMappostEnabled && mode !== 'archive' ? (
						<div className='headerPart sendSection'>
							<button
								type='button'
								className='btn btn-default btn-sm'
								onClick={this.handleSendMessageDriverOpen}>
								<i className='fas fa-envelope'></i> Nosūtīt ziņu šoferim
							</button>
						</div>
					) : (
						''
					)}
				</div>
				<CustomModal
					title='Ziņa šoferim'
					showModal={showModal == 'driverMessage'}
					handleCloseModal={this.handleCloseModal}
					content={
						<DriverMessage
							task={data.taskId}
							driver={data.mappostDriverId}
							handleCloseModal={this.handleCloseModal}
						/>
					}
				/>
				<CustomModal
					title='Uzdevuma labošana'
					showModal={showModal == 'taskEdit'}
					handleCloseModal={this.handleCloseModal}
					content={
						<TaskEdit task={data.taskId} handleCloseModal={this.handleCloseModal} />
					}
				/>
			</div>
		);
	}
}

export default Header;
