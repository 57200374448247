import React, { Component } from 'react';

import api from '@api';
import Loading from 'shared/loading';
import moment from 'moment';

import Filter from './filter';
import Table from './table';
import { apiEndpoint } from 'config';
import { sumByKey } from '../helpers';

class MonthlySummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			level: 'material',
			status: 'init',
			format: 'table',
			start: moment().startOf('month'),
			end: moment(),
			filters: {
				client: '', department: '', material: '', storage: '', destination: ''
			}
		};
	}

	setup() {
		const { start, end, level } = this.state;
		this.setState({ status: 'search' });

		api.reports
			.monthly_summary(start.format('YYYYMMDD'), end.format('YYYYMMDD'), level)
			.then(response => {
				this.setState({ status: 'loadedData', data: response });
			});
	}

	exportData = () => {
		const { start, end, level } = this.state;

		const url = `${apiEndpoint}reports/monthly_reports?start=${start.format(
			'YYYYMMDD'
		)}&end=${end.format('YYYYMMDD')}&level=${level}&export`;
		window.open(url, '_blank');
	};

	componentDidMount() {
		this.setup();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.start !== this.state.start ||
			prevState.end !== this.state.end ||
			prevState.level !== this.state.level
		) {
			this.setup();
		}
	}

	handleLevelChange = newFormat => {
		this.setState({ level: newFormat });
	};

	setDates = (start, end) => {
		this.setState({
			start,
			end
		});
	};

	handleFilterChange = (ev, field) => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				[name]: inputValue
			}
		}))
	}

	render() {
		const { status, data, format, start, end, level, filters } = this.state;

		if (status === 'search') {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				<Filter
					start={start}
					end={end}
					level={level}
					levelChange={this.handleLevelChange}
					applyCallback={this.setDates}
					exportData={this.exportData}
					filters={filters}
					filterCB={this.handleFilterChange}
				/>
				<hr className='divider' />
				{format === 'table' && <Table data={data} level={level} filters={filters} />}
				<div className='report_overview'>
					<h4>Kopsavilkums</h4>
					<div>Ierakstu skaits: {data.length}</div>
					<div>Izmaksas: {sumByKey(data, 'expenses').toFixed(2)}</div>
				</div>
			</div>
		);
	}
}

export default MonthlySummary;
