import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import { getCurrPage, reloadPage } from '../../util';
import { CustomModal } from 'shared/modal';
import Filter from './index.js';
import Loading from 'shared/loading';
import { toast } from 'react-toastify';

const mapper = {
	// 'dateFrom':'no',
	//  'dateTill':'līdz',

	departments: 'nodaļas',
	orderer: 'pasūtītājs',
	storage: 'noliktava',
	destination: 'piegādes adrese',
	material: 'materiāls',
	orderNr: 'pasūtījuma nr.',
	pvz: 'PVZ',
	carrier: 'pārvadātājs',
	driver: 'šoferis',
	car: 'auto',
	paymentType: 'apmaksas nosacījumi',
	deliveryType: 'piegādes veids',
	orderType: 'pasūtījuma veids',
	taskType: 'uzdevumu izpildes veids'
};

const typeMapper = {
	unfinished: 'visas nepabeigtās piegādes',
	bill: 'visas piegādes, kas gatavas apmaksai',
	archive: 'visas piegādes, kuras var arhivēt',
	woOrdererPrice: 'visas piegādes bez apstiprinātas pasūtītāja cenas',
	woInvoice: 'visas piegādes, kam nav ienākošā rēķina'
};

const typeMapperOrder = {
	woOrdererPriceOrder: 'visi pasūtījumi bez apstiprinātas pasūtītāja cenas'
};

const paymentTypeMapper = {
	included: 'piegāde iekļauta produkta cenā',
	client: 'maksā klients'
};

const taskTypeMapper = {
	with: 'ar Mappost',
	without: 'bez Mappost'
};

const formatDate = dateStr => {
	if (!dateStr) {
		return '';
	}
	return `${dateStr.substring(6, 8)}.${dateStr.substring(4, 6)}.${dateStr.substring(0, 4)}.`;
};

const form = (inputDat, data) => {
	const period = (
		<span>
			periods:{' '}
			<b>
				{formatDate(inputDat['dateFrom'])}-{formatDate(inputDat['dateTill'])}
			</b>
		</span>
	);

	let data1 = data
		.map((entity, int) => {
			if (inputDat[entity] == null) {
				return '';
			}

			const name = entity in mapper ? mapper[entity] : entity;
			let value =
				typeof inputDat[entity] == 'object'
					? inputDat[entity].join(', ')
					: inputDat[entity];
			if (entity == 'deliveryType' && Object.keys(typeMapper).includes(value)) {
				value = typeMapper[value];
			}
			if (entity == 'orderType' && Object.keys(typeMapperOrder).includes(value)) {
				value = typeMapperOrder[value];
			}
			if (entity == 'paymentType' && Object.keys(paymentTypeMapper).includes(value)) {
				value = paymentTypeMapper[value];
			}
			if (entity == 'taskType' && Object.keys(taskTypeMapper).includes(value)) {
				value = taskTypeMapper[value];
			}

			if (value == '') {
				return '';
			}

			return (
				<span key={int}>
					{name}: <b>{value}</b>
				</span>
			);
		})
		.filter(ent => ent != '');

	if (data1.length < 1) {
		return period;
	}

	data1 = data1.reduce((acc, x) => (acc === null ? [x] : [acc, '; ', x]), null);

	return (
		<span>
			{period}; {data1}
		</span>
	);
};

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes, includeIfNotFound = false) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] = initialData[key];
		} else {
			dataForReturn[key] = '';
		}
	}

	return dataForReturn;
};

export default class FilterText extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			toSaveData: {},
			data: {},
			options: [],
			showModal: false
		};
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	setData = () => {
		const currPage = getCurrPage();
		api.filter.formatted(currPage).then(response => {
			//https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
			if (this._isMounted) {
				const newAttrs = getDataFromAPIResponse(
					response,
					[
						'orderer',
						'storage',
						'destination',
						'material',
						'orderNr',
						'pvz',
						'carrier',
						'car',
						'paymentType',
						'driver',
						'departments',
						'dateFrom',
						'dateTill',
						'deliveryType',
						'orderType',
						'taskType'
					],
					true
				); //vēl vajag datumus
				const newState = Object.assign(
					{ loading: false, error: false, data: response },
					response,
					newAttrs
				);
				this.setState(newState);
			}
		});
	};

	handleDelete = () => {
		const currPage = getCurrPage();

		api.filter.save({ section: currPage, data: {} }).then(response => {
			//console.log('resp from update',response);
			if (response.status == 'success') {
				toast.success('Filtrs notīrīts');
				setTimeout(() => reloadPage(), 1500);
			} else {
				toast.warn('Filtrs netika notīrīts', { autoClose: 7500 });
			}
		});
	};

	componentDidMount() {
		this._isMounted = true;
		this.setData();
	}

	handleEdit = () => {
		this.setState({ showModal: true });
	};

	handleCloseModal = () => {
		this.setState({ showModal: false });
	};

	render() {
		/* const {
			orderer,
			storage,
			destination,
			material,
			orderNr,
			pvz,
			carrier,
			driver,
			departments,
			dateFrom,
			dateTill
		} = this.state; */
		const { loading, isLoading, options, showModal } = this.state;

		//['orderer','storage','deliveryAddress','material','orderNr','pvz','carrier','driver','departments']

		//todo: kā strādās this.onDataSelect(selected[0],'departments')} nodaļām - multiselect???

		const { options: op2, ...rest } = this.state;

		// return "";
		return (
			<div style={{ paddingBottom: '7px' }}>
				{loading ? (
					<Loading />
				) : (
					<div>
						<span onClick={this.handleEdit} style={{ cursor: 'pointer' }}>
							<i className='fas fa-pencil-alt'></i> Filtrs:{' '}
							{form(rest, [
								'orderer',
								'storage',
								'destination',
								'material',
								'orderNr',
								'pvz',
								'carrier',
								'car',
								'paymentType',
								'driver',
								'departments',
								'deliveryType',
								'orderType',
								'taskType'
							])}{' '}
						</span>{' '}
						<span onClick={this.handleDelete} style={{ cursor: 'pointer' }}>
							<i className='fas fa-trash'></i> notīrīt
						</span>
					</div>
				)}
				<CustomModal
					title='Filtrs'
					showModal={showModal}
					handleCloseModal={this.handleCloseModal}
					content={<Filter />}
				/>
			</div>
		);
	} //{JSON.stringify(rest,null, 2)}
}
