import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	id: null,
	reg_nr: null,
	active_client: false
};

const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		setClient: (state, action) => {
			const { id, reg_nr, active_client } = action.payload;
			state.id = id;
			state.reg_nr = reg_nr;
			state.active_client = active_client;
		},
		removeClient: (state, action) => {
			return { ...initialState };
		}
	}
});

const { actions, reducer } = clientSlice;

export const { setClient, removeClient } = actions;

export default reducer;
