import React, { Fragment } from 'react';

import { Bar } from '@iftek/react-chartjs-3';

const chartDataClientsWithLargestDates = data => {
	const newData = [...data];
	const dataForChart = newData.sort((a, b) => b.apstradesLaiks - a.apstradesLaiks).slice(0, 50);

	const dates = [];
	const clients = [];

	dataForChart.forEach(element => {
		dates.push(element.apstradesLaiks);
		clients.push(element.client);
	});

	return {
		labels: clients,
		datasets: [
			{
				label: 'Dienu skaits',
				backgroundColor: 'rgba(54, 162, 235, 0.4)',
				borderColor: 'rgba(75,192,192,1)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(36, 121, 177, 0.4)',
				hoverBorderColor: 'rgba(36, 121, 177, 1)',
				data: dates
			}
		]
	};
};

const numberByDatesOptions = {
	scales: {
		yAxes: [
			{
				labelString: 'dasds',
				ticks: {
					autoSkip: false,
					beginAtZero: true,
					callback: function(value) {
						if (value % 1 === 0) {
							return value;
						}
					}
				}
			}
		]
	}
};

const byNumnberOfDays = data => {
	const frequency = {};

	data.map(invoice => invoice.apstradesLaiks).forEach(laiks => {
		frequency[laiks] = laiks in frequency ? frequency[laiks] + 1 : 1;
	});

	const numberOfDates = Object.keys(frequency).sort((a, b) => a - b);

	const numberOfInvoices = numberOfDates.map(number => frequency[number]);

	return {
		labels: numberOfDates,
		datasets: [
			{
				label: 'Rēķinu skaits',
				backgroundColor: 'rgba(54, 162, 235, 0.4)',
				borderColor: 'rgba(75,192,192,1)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(36, 121, 177, 0.4)',
				hoverBorderColor: 'rgba(36, 121, 177, 1)',
				data: numberOfInvoices
			}
		]
	};
};

const Graphs = ({ data, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let {billNr, materials, client} = filters;
	billNr = billNr.toLowerCase();
	materials = materials.toLowerCase();
	client = client.toLowerCase();

	const filterObj = {billNr, materials, client};

	let finalData = data.filter(entry => {
		console.log(entry, entry[key])
		for (var key in filterObj) {
			const whatToSearch = typeof entry[key] === 'object' ? entry[key].join() : entry[key];
			if (whatToSearch === undefined || !whatToSearch.toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;

	})

	return (
		<div>
			<div>
				<h3>Klientu rēķini ar lielāko apstrādes laiku</h3>
				<Bar data={chartDataClientsWithLargestDates(finalData)} width={100} height={30} />
			</div>
			<div>
				<h3>Rēķinu apstrādes laiks dienās</h3>
				<Bar
					data={byNumnberOfDays(finalData)}
					width={100}
					height={30}
					options={numberByDatesOptions}
				/>
			</div>
		</div>
	);
};

export default Graphs;
