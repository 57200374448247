import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import AddressEdit from './forms/addresses';
import { checkPermission } from 'permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import { customTitle } from 'util';
import NoData from 'shared/noDataNotification';
import FieldForm from './filterForm';
import { MILISECONDS_BEFORE_SEARCH } from '../../config';

const actionsButtonFormatter = () => actionButton;

const isUsedFormatter = cell => {
	const data = cell.getRow().getData().isAddressUsed;
	if (data == true) {
		return '';
	}

	return `Var`;
};

class AddressTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			showModal: false,
			data: [],
			id: '',
			currTask: null,
			error: false,
			q: ''
		};
	}
	timeout = null;

	setupData() {
		this.setState({ loading: true, error: false });

		//const uid = this.props.match.params.uid;

		const { mode, uid } = this.props;

		const { q } = this.state;

		if (mode == 'mappost') {
			api.admin.mappost_adr(uid).then(response => {
				this.setState({ loading: false, error: false, data: response });
			});
			return;
		}

		api.search.address({ query: q, clientSearch: false , includeArchived: 1 }).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	columns = [
		{
			title: 'ID',
			field: 'id',
			headerTooltip: 'Adreses ID',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334')
		},
		{
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		},
		{
			title: 'Adrese',
			field: 'name',
			headerTooltip: 'Adrese',
			titleFormatter: column => customTitle(column, 'fas fa-map-marker-alt', '#333334')
		},
		{
			title: 'Piegādes virziens',
			field: 'deliveryDirection',
			headerTooltip: 'Piegādes virziens',
			titleFormatter: column => customTitle(column, 'fas fa-location-arrow', '#333334')
		},
		{
			title: 'Mappost ID',
			field: 'mappostID',
			cssClass: 'rightAligned',
			headerTooltip: 'Objekta Mappost ID',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334')
		}
		/* {
			title: 'Vai var dzēst ārā adresi?',
			field: 'isAddressUsed',
			headerTooltip: 'Vai var dzēst ārā adresi?',
			formatter: (cell) => isUsedFormatter(cell),
			titleFormatter: column => customTitle(column, 'fas fa-trash-alt', '#333334')
		} */
	];

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ [whatToSearch]: e.target.value }, () => {
			this.timeout = setTimeout(() => {
				_self.setupData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleOpenModal(openType, cell) {
		const id = openType == 'new' ? null : cell.getRow().getData().id;

		this.setState({ showModal: true, id });
	}

	render() {
		const { data, error, loading, showModal, currDelivery, id } = this.state;

		const filterItems = [
			{
				visible: true,
				title: 'Adrese',
				inputID: 'address_input',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'q'
			}
		];

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{checkPermission('add', 'addresses') && (
							<div className='rightFloat'>
								<button
									type='button'
									className='btn btn-primary'
									onClick={() => this.handleOpenModal('new')}>
									Pievienot jaunu adresi
								</button>
							</div>
						)}

						<FieldForm items={filterItems} />

						<div className='clearBoth'></div>
						<div style={{ padding: 5 }} />

						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<NoData />
								) : (
									<div className='tableWrapper'>
										<Tabulator
											columns={this.columns}
											options={{ pagination: false }}
											data={data}
										/>
									</div>
								)}
								<CustomModal
									title='Adreses datu labošana'
									showModal={showModal}
									handleCloseModal={this.handleToggleModal}
									content={
										<AddressEdit
											id={id}
											handleCloseModal={this.handleToggleModal}
										/>
									}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default AddressTable;
