//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
//import Card from '@elements/actionButtons/card';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './modal.css';
import api from '@api';
import { Collapse } from 'react-collapse';
import OrderEditModal from 'sections/orders/newModal';
import Loading from 'shared/loading';
import { Radio } from 'shared/form';
import { toast } from 'react-toastify';
import NewTaskModal from 'sections/orders/newTaskModal';
import { checkPermission } from 'permissions';

import OneCard from 'sections/orders/card';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import { reloadPage , getCurrPage } from 'util';
import HistoryModal from 'shared/history';
import setRespectTabs from 'shared/tabs/setRespectTabs';


class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			data: {},
			searching: true,
			historyOpen: false,
			showEditModal: false,
			openedModal: '',
			statusChangeOpen: false,
			orderStatus: null,
			permissions: {}
		};
	}

	setViewed = () => {
		const { orderId: order } = this.state;
		api.orders.setViewed(order).then(response => {
			if (response.status === 'ok') {
				toast.success('Atzīmēts kā apskatīts');
				//this.setup();

				setRespectTabs();
				// setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.info('Radās kļūda, saglabājot datus');
			}
			//this.setState({ searching: false, error: false, data: response, orderStatus:response.orderStatus });
		});
	};

	setToIzpilde() {
		const { orderId: order } = this.state;
		api.orders.setToIzpilde({ order }).then(response => {
			if (response.status === 'ok') {
				toast.success('Nodots izpildei');
				//this.setup();

				setRespectTabs();
				// setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.info('Radās kļūda, saglabājot datus');
			}
			//this.setState({ searching: false, error: false, data: response, orderStatus:response.orderStatus });
		});
	}

	handleOrderDelete = () => {
		const confirm = window.confirm('Vai tiešām vēlaties dzēst pasūtījumu?');
		if (confirm == false) {
			return;
		}
		api.orders.delete(this.state.orderId).then(response => {
			if (response.status === 'ok') {
				toast.success('Pasūtījums veiksmīgi izdzēsts', { autoClose: 2500 });

				setRespectTabs();
				// setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.info('Radās kļūda, dzēšot pasūtījumu');
			}
		});
	};

	setOpenHistory = () => {
		this.setState({ historyOpen: !this.state.historyOpen });
	};

	componentDidMount() {
		const { orderId } = this.props;

		this.setState({ orderId }, () => this.setup());
	}

	//iespējams, vajag uzlabot loģiku
	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId) {
			const { orderId } = this.props;

			this.setState({ orderId }, () => this.setup());
		}
	}

	setup() {
		const { orderId } = this.state;

		if (orderId == null) {
			//šis izpildās, kad aizver modal logu
			this.setState({ data: {} });
			return;
		}
		//this.setState({ searching: true, error: false });

		api.orders.actions(orderId).then(response => {
			this.setState({
				searching: false,
				error: false,
				data: response,
				orderStatus: response.orderStatus
			});
		});
	}

	formatTime(timeStr) {
		if (!timeStr) {
			return '';
		}
		return `${timeStr.substring(6, 8)}.${timeStr.substring(4, 6)}.${timeStr.substring(
			0,
			4
		)}. ${timeStr.substring(8, 10)}:${timeStr.substring(10, 12)}:${timeStr.substring(12, 14)}`;
	}

	parseTaskAdding() {
		//const atlikums = 0;//no props! - jāpadod no client.js
		const { data } = this.state;
		const { remainder } = this.props;

		//console.log(this.props, this.state);

		if (!data.isNextYearOrder && remainder > 0.00009) {
			return { cantForm: false, text: '' };
		} else if (data.isNextYearOrder) {
			return {
				cantForm: true,
				text: `pasūtījuma plānotā izpilde ir sākot no 01.01.${data.plannedCompletionDate}`
			};
		}

		return { cantForm: true, text: 'pasūtījuma atlikums ir 0.000' };
	}

	handleOrderEdit = () => {
		this.setState({ openedModal: 'orderedit' });
	};

	handleOrderCopy = () => {
		this.setState({ openedModal: 'ordercopy' });
	};

	handleCloseEditModal = () => {
		this.setState({ openedModal: '' });
	};

	handleStatusChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		//const toSaveData = Object.assign(this.state.toSaveData,{[name]:inputValue});

		this.setState({ [name]: parseInt(inputValue, 10) });
	};

	handleStatusSave = () => {
		const { orderStatus, orderId: order } = this.state;
		const { orderStatus: origStatus } = this.state.data;

		if (orderStatus === origStatus) {
			toast.info('Statuss netika izmainīts!');
			return;
		}

		api.orders.changeStatus({ order, status: orderStatus }).then(response => {
			if (response.status === 'ok') {
				toast.success('Statuss saglabāts');
				//setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//this.setup();
				this.props.handleCloseModal();
			} else {
				toast.info('Radās kļūda, saglabājot statusu');
			}
			//this.setState({ searching: false, error: false, data: response, orderStatus:response.orderStatus });
		});
	};

	handleNewTask = () => {
		this.setState({ openedModal: 'newTask' });
	};

	handleCloseModal = () => {
		this.setState({ openedModal: '' });
	};

	onSuccSave = () => {
		this.props.onSuccSave();
	};

	toggleHistory = () => {
		this.setState({ openedModal: 'history' });
		//this.setState({ historyOpen: !this.state.historyOpen });
	};

	closeModalWithoutReload = () => {
		this.setState({ openedModal: '' });
	}

	render() {
		//pasūtījuma labošana - iespējama arī izpildītiem pasūtījumiem. Tiešām tā vajag būt?
		const { data, searching, historyOpen, orderStatus, openedModal } = this.state;
		const { orderId, clientRegNr } = this.props;

		const canEdit = 'can_edit' in data ? data.can_edit : checkPermission('edit');
		const canAddNew = 'can_edit' in data ? data.can_edit : checkPermission('add');

		const taskAddition = this.parseTaskAdding();
		const currPage = getCurrPage();

		const modalContent = (
			<div>
				{searching ? (
					Loading()
				) : (
					<div className='row'>
						<div className='ActionCardContainer'>
							{OneCard({
								isDisabled: data.isChecked !== 0 || !canEdit,
								onClick: this.setViewed,
								img: (
									<span>
										<i className='fas fa-check-double fa-2x'></i>
										<i className='fas fa-sticky-note fa-4x'></i>
									</span>
								),
								header: 'Atzīmēt kā apskatītu'
							})}

							{OneCard({
								isDisabled: data.orderStatus !== 1 || !canEdit,
								onClick: this.setToIzpilde,
								img: <i className='fas fa-envelope fa-4x'></i>,
								header: 'Nodot pasūtījumu izpildei'
							})}

							{OneCard({
								isDisabled: taskAddition.cantForm || !canAddNew,
								onClick: this.handleNewTask,
								img: <i className='fas fa-truck-loading fa-4x'></i>,
								header: 'Uzdevuma formēšana',
								text:
									taskAddition.text !== '' ? (
										<span>
											{' '}
											<small>({taskAddition.text})</small>
										</span>
									) : (
										''
									)
							})}

							{OneCard({
								isDisabled: false,
								onClick: this.toggleHistory,
								img: <i className='fas fa-list-alt fa-4x'></i>,
								header: 'Pasūtījuma izmaiņu žurnāls'
								//disabledActionOnText: true,
								/* text: (
									<Collapse isOpened={historyOpen}>
										<div
											dangerouslySetInnerHTML={{
												__html: data.history && data.history.join('<br />')
											}}></div>
									</Collapse>
								) */
							})}

							{OneCard({
								isDisabled: !canEdit,
								onClick: this.handleOrderEdit,
								img: <i className='fas fa-pencil-alt fa-4x'></i>,
								header: 'Labot pasūtījumu'
							})}

							{OneCard({
								isDisabled: !canEdit,
								cssStyle: { cursor: 'auto' },
								onClick: null,
								img: <i className='fas fa-flag fa-4x'></i>,
								header: 'Pasūtījuma statusa maiņa',
								text: (
									<div className='form-group'>
										{/**
          + vēl vajag isDisabled
         */}
										<Radio
											inputClass=''
											name='orderStatus'
											value={2}
											toChecked={orderStatus !== 5}
											handleChange={this.handleStatusChange}
											label='Formēšana'
										/>
										<Radio
											inputClass=''
											name='orderStatus'
											value={5}
											checkAgainst={orderStatus}
											handleChange={this.handleStatusChange}
											label='Izpildīts'
										/>
										<button
											disabled={!canEdit}
											type='button'
											className='btn btn-primary'
											onClick={this.handleStatusSave}>
											Saglabāt
										</button>
									</div>
								)
							})}

							{OneCard({
								isDisabled: !checkPermission('delete'),
								onClick: this.handleOrderDelete,
								img: <i className='fas fa-minus-square fa-4x'></i>,
								header: 'Dzēst pasūtījumu'
							})}

							{OneCard({
								isDisabled: !canAddNew,
								onClick: this.handleOrderCopy,
								img: <i className='fas fa-copy fa-4x'></i>,
								header: 'Kopēt pasūtījumu'
							})}

							{OneCard({
								cssStyle: { cursor: 'auto' },
								isDisabled: false,
								onClick: null,
								img: '',
								text: (
									<div style={{ textAlign: 'right' }}>
										<span>
											Izpildes prioritāte:{' '}
											<b>
												{data.priority === 1 ? (
													'parastā'
												) : (
													<span style={{ color: '#ff0000' }}>
														Steidzami
													</span>
												)}
											</b>
											<br />
											Pasūtījums ievadīts: {this.formatTime(data.date)}
											<br />
											Pasūtījumu ievadīja: {data.user}, {data.department}
										</span>
									</div>
								)
							})}
						</div>
					</div>
				)}
			</div>
		);

		return (
			<div>
				<CustomModal
					title='Pasūtījuma darbības'
					showModal={this.props.showModal}
					handleCloseModal={this.props.closeModalWithoutReload}
					content={modalContent}
				/>
				{openedModal === 'history' && (
					<HistoryModal
						showModal={openedModal === 'history'}
						ID={orderId}
						mode='order'
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'newTask' && (
					<NewTaskModal
						showModal={openedModal === 'newTask'}
						orderId={orderId}
						handleCloseModal={this.handleCloseModal}
						onSuccSave={this.onSuccSave}
					/>
				)}
				{openedModal === 'orderedit' && (
					<OrderEditModal
						showModal={openedModal === 'orderedit'}
						clientRegNr={clientRegNr}
						orderId={orderId}
						handleCloseModal={this.handleCloseModal}
						closeModalWithoutReload={this.closeModalWithoutReload}
					/>
				)}
				{openedModal === 'ordercopy' && (
					<OrderEditModal
						showModal={openedModal === 'ordercopy'}
						clientRegNr={currPage === 'orders' ? null : clientRegNr}
						orderIdCopy={orderId}
						handleCloseModal={this.handleCloseModal}
						closeModalWithoutReload={this.closeModalWithoutReload}
						closeMainModal={(ev) => this.props.handleCloseModal(ev, true)}
					/>
				)}
			</div>
		);
	}
}

export default Modal;
