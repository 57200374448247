/* eslint-disable react/jsx-key */
import React, { useEffect, useState, useMemo } from 'react';
import { useTable, usePagination } from 'react-table';
import { Table as BTable } from 'react-bootstrap';
import {
	formatNumber,
	isNumeric,
	allFieldsHaveValues_new
} from '../../../util';

// Create an editable cell renderer
const EditableCell = ({
	isSelect = false,
	value: initialValue,
	options,
	row: { index },
	column: { id },
	updateMyData // This is a custom function that we supplied to our table instance
}) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(initialValue);

	const onChange = e => {
		setValue(e.target.value);
	};

	// We'll only update the external data when the input is blurred
	const onBlur = () => {
		updateMyData(index, id, value);
	};

	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	if (isSelect) {
		return <select className="form-control">
			{options && Object.keys(options).map(keyInd=> <option value={keyInd}>{options[keyInd]}</option>)}
		</select>;
	}

	return <input className="form-control" value={value} onChange={onChange} onBlur={onBlur} />;
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
	// For this example, we're using pagination to illustrate how to stop
	// the current page from resetting when our data changes
	// Otherwise, nothing is different here.
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns,
			data,
			// use the skipPageReset option to disable page resetting temporarily
			autoResetPage: !skipPageReset,
			// updateMyData isn't part of the API, but
			// anything we put into these options will
			// automatically be available on the instance.
			// That way we can call this function from our
			// cell renderer!
			updateMyData
		},
		usePagination
	);

	// Render the UI for your table
	return (
		<>
			<BTable className='latraps_table' {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => {
								return (
									<th {...column.getHeaderProps([getColWidth(column?.width)])}>
										{column.render('Header')}
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</BTable>
		</>
	);
}

const getValueForSave = (initValue) => {
	if (initValue < 0) {
		return initValue;
	}

	if (initValue.length > 0 && !isNumeric(initValue)) {
		return initValue;
	}
	return formatNumber(initValue, 3);
}

const getColWidth = val =>
	Number.isInteger(val) ? { style: { width: `${val}px` } } : { style: { width: val } };

function TableComp({ orders, cena_par, updateHandler }) {
	const columns = useMemo(
		() => [
			{
				Header: (
					<>
						Pasūt.
						<br />
						nr
					</>
				),
				accessor: 'deliveryId',
				width: 65
			},
			{
				Header: 'Mērv.',
				accessor: 'measurementUnit',
				width: 65
			},
			{
				Header: 'Pasūt. atl.',
				accessor: 'availableAmount',
				width: 100
			},
			{
				Header: <>Pieg. daudzums</>,
				width: 100,
				cssClass: 'deliveredAmount',
				accessor: 'availableAmount_tmp',
				Cell: props => {
					return <EditableCell {...props} />;
				}
			},
			{
				Header: (
					<>
						Pasūt.
						<br />
						cena
					</>
				),
				width: 100,
				cssClass: 'deliveredAmount',
				accessor: 'deliveryPrice',
				Cell: props => {
					return <EditableCell {...props} />;
				}
			},
			{
				Header: 'Cena par',
				width: 100,
				cssClass: 'deliveredAmount',
				accessor: 'priceFor',
				Cell: props => {
					return <EditableCell {...props} isSelect={true} options={cena_par} />;
				}
			},
			{
				Header: 'Pasūtītājs',
				accessor: 'orderer'
			},
			{
				Header: 'Maršruts',
				accessor: 'route',
				Cell: ({ cell }) => {
					const {
						storage: { address: storageAddr, title: storageTitle }
					} = cell.row.original;

					return (
						<span className='preWrap'>
							{storageAddr} -&gt; {storageTitle}
						</span>
					);
				},
				width: 250
			},
			{
				Header: (
					<>
						Materiāls/
						<br />
						piezīmes
					</>
				),
				accessor: 'material',
				width: 250,
				Cell: ({ cell }) => {
					const { material, isIncludedInPrise, materialNotes } = cell.row.original;

					return (
						<span className='preWrap'>
							<b>{material}</b>
							<br />
							{isIncludedInPrise === true && (
								<span style={{ color: 'red' }}>Piegāde iekļauta produkta cenā</span>
							)}
							<br />
							{materialNotes}
						</span>
					);
				}
			}
		],
		[]
	);

	//const [data, setData] = useState([]);
	const [skipPageReset, setSkipPageReset] = useState(false);

	// We need to keep the table from resetting the pageIndex when we
	// Update data. So we can keep track of that flag with a ref.

	// When our cell renderer calls updateMyData, we'll use
	// the rowIndex, columnId and new value to update the
	// original data
	const updateMyData = (rowIndex, columnId, value) => {
		// We also turn on the flag to not reset the page
		setSkipPageReset(true);

		//updateHandler(old =>
		const updatedData = orders.map((row, index) => {
				if (index === rowIndex) {

					return {
						...orders[rowIndex],
						[columnId]: getValueForSave(value)
					};
				}
				return row;
			})
			updateHandler(updatedData);
		//);
	};

	// After data chagnes, we turn the flag back off
	// so that if data actually changes when we're not
	// editing it, the page is reset
	useEffect(() => {
		setSkipPageReset(false);
	}, [orders]);

	return (
		<Table
			columns={columns}
			data={orders}
			updateMyData={updateMyData}
			skipPageReset={skipPageReset}
		/>
	);
}

export default TableComp;
