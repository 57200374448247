export const tabulatorOptions = {
	movableRows: false,
	layout: 'fitDataFill',
	responsiveLayout: 'collapse',
	pagination: 'local',
	paginationSize: 15,
	langs: {
		lv: {
			pagination: {
				first: 'Pirmā',
				first_title: 'Pirmā lapa',
				last: 'Iepr.',
				last_title: 'Iepriekšējā lapa',
				prev: 'Nāk.',
				prev_title: 'Nākamā lapa',
				next: 'Pēdējā',
				next_title: 'Pēdējā lapa'
			},
			headerFilters: {
				default: 'filtrēt...',
				columns: {}
			}
		}
	}
};
//paginationSize:15,
//autoResize:false

console.log('process.env', process.env.NODE_ENV);

const apiEndp = 'api/v1/';

export const kijsKey = process.env.NODE_ENV === 'production' ? process.env.KIJS_KEY : 'JSED';

export const apiEndpoint = apiEndp; //'//latraps.kartes.lv/dev1/app/api/';//'//10.95.26.235/llis/api/';//'//latraps.kartes.lv/dev1/api/';//'//10.95.26.235/llis/api/';//'//latraps.kartes.lv/dev1/api/';//'//10.95.26.235/llis/api/';//`${process.env.REACT_API_URL}`;
//'//latraps.kartes.lv/dev1/api/';//'//latraps.kartes.lv/dev1/api/';//'//10.95.26.235/llis/api/';//'//latraps.kartes.lv/dev1/api/';//'//latraps.kartes.lv/dev1/api/';//'//10.95.26.235/llis/api/';//'//latraps.kartes.lv/dev1/api/';

export const invoicePDFlink = `${apiEndpoint}invoice/pdf/`;
export const invoiceConfirmationPDFlink = `${apiEndpoint}invoice/confirmation/`;
export const bolPDFlink = `${apiEndpoint}bol/pdf/`;
export const deliveryPDFlink = `${apiEndpoint}deliveries/pdf`;

export const MILISECONDS_BEFORE_CLOSE = 700; //pēc cik sekundēm aizvērt modālo logu pēc sekmīgas darbības
export const MILISECONDS_BEFORE_SEARCH = 200;
