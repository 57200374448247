import api from '../../api';
import { toast } from 'react-toastify';
import React from 'react';

import {checkStatusSuccess, loginUser, loginError, checkStatusError, removeLoginErrors, setAppError, request, logoutUser} from './slice';

const WRONG_USER_PASSWORD = 'Nepareizs lietotājs vai parole';

function setSession(workerData) {
	if (Object.keys(workerData).length === 0) {
		//tika padots tukšs objekts - tātad izlogojamies
		localStorage.clear();
		return;
	}
	const {
		data: { vards, uzvards }
	} = workerData;
	localStorage.setItem('user_name', `${vards} ${uzvards}`);
}

export function logout() {
	return dispatch => {
		api.auth.logoutUser().then(response => {
			if (response.status === 'ok') {
				dispatch(logoutUser(true));
				setSession({});
			}
		});
	};
}

const Msg = () => (
	<div onClick={() => window.open(`#/change_password`, '_blank')}>
		Ir vēlams nomainīt paroli! Spiediet uz paziņojuma, lai to izdarītu.
	</div>
);

const setPasswordChangeReminder = data => {
	if ('shouldChangePassword' in data && data.shouldChangePassword) {
		toast.warn(<Msg />, { autoClose: 10000 });
	}
};

export function login({ username, password }) {
	return dispatch => {
		//setTimeout(() => {}, 2500);
		//dispatch(request({ username }));

		dispatch(removeLoginErrors());

		api.auth.loginUser(username, password).then(response => {
			if (response.status === 'ok') {
				//veiksmīga ielogošanās

				//kāpēc bija divas reizes?
				dispatch(loginUser(response));
				//dispatch(loginUser(response));

				setPasswordChangeReminder(response);

				//setAuthorizationToken(response);

				setSession(response);
			} else if (response.status === 'error') {
				dispatch(loginError(WRONG_USER_PASSWORD));
				//unsetAuthorizationToken();
				setSession({});
				//ielogošanās errors
			}
		});
	};
}

export function checkLogin() {
	return dispatch => {
		dispatch(request());

		api.auth
			.checkLogin()
			.then(response => {
				if (response.status === 'ok') {
					dispatch(checkStatusSuccess(response));
					//setAuthorizationToken(response);
				} else if (response.status === 'error') {
					dispatch(checkStatusError());
					//unsetAuthorizationToken();
					setSession({});
				} else {
					dispatch(setAppError());
				}
			})
			.catch(() => {
				dispatch(setAppError());
			});
	};
}
