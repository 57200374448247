/* eslint-disable react/jsx-key */
import React, { useEffect, useState, useMemo } from 'react';
import { useTable, usePagination } from 'react-table';
import { Table as BTable } from 'react-bootstrap';

const getColWidth = val =>
	Number.isInteger(val) ? { style: { width: `${val}px` } } : { style: { width: val } };

const getColBackground = val => val ? { className: val } : {};

function Table({ columns, data }) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data
	});

	// Render the UI for your table
	return (
		<div className='latraps_tableWrap'>
			<BTable className='latraps_table' {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th
									{...column.getHeaderProps([getColWidth(column?.width), {style: {textAlign: 'center'}}])}
									>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									const row = cell?.row;
									const bgColor = cell?.column?.bgColor ? cell?.column?.bgColor(row) : null;
									const bgColorStyle = bgColor ? { style: { backgroundColor: bgColor } } : {};
									const bgClass = getColBackground(cell?.column?.columnClass);


									const cellProps = {...cell.getCellProps([bgClass, bgColorStyle])};
									if (cell?.column?.clickHandler) {
										return <td {...cellProps} onClick={() => cell.column.clickHandler(row.original)}>{cell.render('Cell')}</td>;
									}

									return <td {...cellProps}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</BTable>
		</div>
	);
}

function TableComp({ orders, columns }) {
	const cols = useMemo(() => columns, [columns]);
	const data = useMemo(() => orders, [orders]);

	return <Table columns={cols} data={data} />;
}

export default TableComp;
