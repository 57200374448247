import React, { Component } from 'react';
import api from '@api';
import { toast } from 'react-toastify';
import { Input, Label } from 'shared/form';

class PasswordResetForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			sending: false
		};
	}

	handleLogin = () => {
		const { email, sending } = this.state;

		if (email.trim() === '') {
			toast.error('E-pasts nav aizpildīts', {
				autoClose: 7500
			});
			//this.setState({validLogin: false, errorMessage: "Lietotājvārds vai parole nav aizpildīts"});
			return;
		}

		this.setState({ sending: true });
		api.admin.send_email_for_reset_password({ email }).then(response => {
			this.setState({ sending: false });
			if (response.status !== 'ok') {
				toast.error('E-pasts netika nosūtīts', {
					autoClose: 7500
				});
				return;
			}
			toast.success('E-pasts tika nosūtīts');
		});
	};

	componentDidMount() {
		document.onkeypress = function(e) {
			if (!e) e = window.event;
			const keyCode = e.keyCode || e.which;
			if (keyCode == 13) {
				// Enter pressed
				return false;
			}
		};
	}

	handleKeyDown(event) {
		if (event.key === 'Enter' && this.state.email.length > 0) {
			this.handleLogin();
		}
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setState({ email: inputValue });
	};

	render() {
		const { email, sending } = this.state;

		return (
			<React.Fragment>
				<div className='row'>
					<Label labelClass='col-sm-2' title='E-pasts' name='email' />
					<Input
						inputClass='col-sm-4'
						type='text'
						name='email'
						value={email}
						handleChange={this.handleChange}
					/>
				</div>
				<button
					type='button'
					disabled={sending}
					className='btn btn-success'
					onClick={this.handleLogin}>
					Nosūtīt e-pastu
				</button>
			</React.Fragment>
		);
	}
}
//(() => this.props.loginHandler(this))

export default PasswordResetForm;
