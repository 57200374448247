/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import api from '@api';
import { Input, Label } from 'shared/form';
import Loading from 'shared/loading';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PasswordRules from './passwordRules';
import checkIfValid from './checkIfValid';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class Set2FA extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isActive: false,
			qrCode: null
		};
	}

	setData = () => {
		this.setState({ loading: true });

		api.user.check_current_mfa().then(response => {
			const { status } = response;

			this.setState({ loading: false, isActive: status });
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.token !== prevProps.match.params.token) {
			this.setData();
		}
	}

	setDataToState(key, value) {
		const toSaveData = Object.assign(this.state.toSaveData, {
			[key]: value
		});

		this.setState({ toSaveData, [key]: value });
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (name == 'password1') {
			this.checkIfValidPassword(inputValue);
		}

		this.setDataToState(name, inputValue);
	};

	checkIfValidPassword = password => {
		const checkRes = checkIfValid(password, this.state.passwordValid);

		if (checkRes === true) {
			return;
		}

		this.setState({ ...checkRes });
	};

	saveData = e => {
		e.preventDefault();

		const { password1, password2, username, passwordValid } = this.state;

		if (password1 != password2) {
			toast.info('Paroles nesakrīt', { autoClose: 5000 });
			return;
		}

		if (password1.trim() == '' || password2.trim() == '') {
			toast.info('Parole nedrīkst būt tukša', { autoClose: 5000 });
			return;
		}

		if (!passwordValid) {
			toast.info('Parole neatbilst noteikumiem', { autoClose: 5000 });
			return;
		}

		const token = this.props.match.params.token;

		api.admin.activate_user({ password: password1, username, token }).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				window.open('#', '_self');
				//this.setData();
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	init2FA = () => {
		api.user.get_mfa_qr().then(response => {
			const { status, imgURL } = response;

			this.setState({ qrCode: imgURL });
		});
	};

	render() {
		const { loading, isActive, qrCode } = this.state;

		return (
			<div className='bodyWrapper'>
				<h2>Vairāklīmeņu autentifikācijas vadība</h2>
				{loading ? (
					<Loading />
				) : (
					<div>
						{isActive ? (
							'Jums ir uzstādīts'
						) : (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.init2FA}>
								Uzstādīt 2FA
							</button>
						)}
						{qrCode && <img src={qrCode} />}
					</div>
				)}
			</div>
		);
	}
}

export default Set2FA;
