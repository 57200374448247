import api from '../api';

const planning = {
	new_order: () => api.get(`orders_planning/new_order`),

	order_info: id => api.get(`orders_planning/data_for_edit?order=${id}`),

	order_save: ({ id = null, data }) => api.post(`orders_planning/save`, { id, data }),

	order_delete: id => api.post(`orders_planning/delete`, { id }),

	overview: () => api.get(`orders_planning/overview`),

	department_orders: ({ dept, rows, page, section }) =>
		api.get(`orders_planning/department?n=${dept}&section=${section}&rows=${rows}&page=${page}`),

	related_orders: id => api.get(`orders_planning/related_orders?planning_order=${id}`),

	order_tasks: id => api.get(`orders_planning/tasks?planning_order=${id}`),

	order_deliveries: id => api.get(`orders_planning/deliveries?planning_order=${id}`),
};

export default planning;
