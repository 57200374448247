import React, { Component } from 'react';
import Section from './section';
import Loading from 'shared/loading';
import { CustomModal } from 'shared/modal';
import MultiTaskTable from './multitask';
import '@shared/tabs/tabs.css';
import { checkPermission } from 'permissions';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-tabify';
import { MILISECONDS_BEFORE_CLOSE } from 'config';
import { loadData, resetInclusions, setSection, toggleModal } from './slice';
const tabsContainer = {
	display: 'flex'
	//height: "99vh"
};

const oneTab = (tab, key, currentKey) => {
	return (
		<Tab
			label={
				<span>
					<span>{tab.title}</span>
				</span>
			}>
			{currentKey === key && <Section sectionID={tab.id} name={tab.api} />}
		</Tab>
	);
};

class PlanningPage extends Component {
	constructor(props) {
		super(props);
	}

	handleReload = () => {
		setTimeout(() => {
			this.props.toggleModal();
			this.props.resetInclusions();
			this.props.loadData();
		}, MILISECONDS_BEFORE_CLOSE);
	};

	componentDidMount() {
		this.props.loadData();
	}

	handleSelect = key => {
		this.props.setSection(key);
	};

	handleCloseModal = () => {
		this.props.toggleModal();
	};

	handleNewModal = () => {
		this.props.toggleModal();
	};

	render() {
		const {
			deliveriesForInclusion,
			loading,
			planningSections,
			section,
			modal_open
		} = this.props;

		const howManyOrders = deliveriesForInclusion.length;

		if (loading) {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				<>
					{checkPermission('add') && (
						<div style={{ float: 'right' }}>
							<button
								type='button'
								disabled={howManyOrders < 2}
								className='btn btn-primary'
								onClick={this.handleNewModal}>
								Atzīmēti {howManyOrders} pasūtījumi
							</button>
						</div>
					)}
					<div className='clearBoth' />
					<div
						style={{
							width: '100%'
						}}>
						<div style={tabsContainer}>
							<Tabs activeKey={section} onSelect={this.handleSelect} id='order-tabs'>
								{planningSections.length > 0 &&
									planningSections.map((tabData, key) => (
										<Tab
											key={tabData.id}
											label={
												<span style={{ fontSize: '1.5rem' }}>
													{tabData.title}
												</span>
											}>
											{section === key && (
												<div>
													{oneTab(planningSections[key], key, section)}
												</div>
											)}
										</Tab>
									))}
							</Tabs>
						</div>
					</div>
					<CustomModal
						modalClass='fullWidthModal'
						title='Jauns saliktās kravas uzdevums'
						showModal={modal_open}
						onClose={this.handleCloseModal}
						handleCloseModal={this.handleCloseModal}
						content={<MultiTaskTable onSuccess={this.handleReload} />}
					/>
				</>
			</div>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		loadData: () => {
			dispatch(loadData());
		},
		setSection: id => {
			dispatch(setSection(id));
		},
		toggleModal: () => {
			dispatch(toggleModal());
		},
		resetInclusions: () => {
			dispatch(resetInclusions());
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		deliveriesForInclusion: state.planning.orders,
		loading: state.planning.loading,
		planningSections: state.planning.sections,
		section: state.planning.actual_section,
		modal_open: state.planning.modal_open
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(PlanningPage);
