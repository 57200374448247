import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../../shared/form';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';

import {
	formatNumber,
	allFieldsHaveValues,
	formatFullDate,
	allFieldsHaveValues_new,
	reloadPage
} from 'util';
//import MapObject from '@components/map';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import isNumericWrap from 'fast-isnumeric';
import { formatDateForDB, getToday, getYesterday } from 'helpers/time';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class MaterialEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			toSaveData: {},
			data: {}
		};
	}

	formatAsString = event => {
		const number = event.target.value;
		const name = event.target.name;
		//console.log('handleNumberValidation',number,event);

		this.setDataToState(name, formatNumber(number, 2));
	};

	setData = () => {
		this.setState({ loading: true });
		const { id } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		//{
		api.admin.mat_get(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'title',
				'category',
				'alertResponsible',
				'alertClient',
				'categoryOptions',
				'kmPrice',
				'kmPriceUnit',
				'volume',
				'unitOptions',
				'archivedMaterial',
				'activeFrom',
				'kmPriceHistory'
			]);

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			if (!newState.activeFrom || newState.activeFrom === '') {
				newState.activeFrom = getToday().format('DD.MM.YYYY.')
				this.setDataToState('activeFrom', newState.activeFrom)
			}

			this.setState(newState);
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		if (
			inputValue.length > 0 &&
			(name == 'amount' || name == 'carrierPriceAmount') &&
			isNumericWrap(inputValue) == false
		) {
			return false;
		}

		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {};

		let try2Final = {};
		const dataToSendAsDefaults = [
			'title',
			'category',
			'alertResponsible',
			'alertClient',
			'kmPrice',
			'kmPriceUnit',
			'volume',
			'archivedMaterial', 'activeFrom'
		];
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);
		finalToSend.activeFrom = formatDateForDB(finalToSend.activeFrom);
		if (finalToSend.kmPrice == '') {
			finalToSend.kmPrice = null;
			finalToSend.activeFrom = null;
		}

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			title: 'Nosaukums',
			category: 'Kategorija',
			alertResponsible: 'Ziņot atbildīgajam',
			alertClient: 'Ziņot klientam',
			archivedMaterial: 'Arhivēts',
			activeFrom: 'Materiāla kategorijas transporta cenas stāšanās spēkā datums'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['title', 'category', 'alertResponsible', 'alertClient', 'archivedMaterial'],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		/* if (finalToSend.kmPrice !== null && finalToSend.kmPriceUnit === null) {
			toast.warn(`Nav aizpildīts mērvienības lauks`, {
				autoClose: 7500
			});
			return;
		} */

		//return;

		if (id == null) {
			//new user
			api.admin.mat_create(finalToSend).then(response => {
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 4000 }
					);
				}
			});
		} else {
			api.admin.mat_save({ id, data: finalToSend }).then(response => {
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 4000 }
					);
				}
			});
		}
	};

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	handleDateChange = value => {
		const valueToSave = value == '' ? value : value.format('DD.MM.YYYY');
		this.setDataToState('activeFrom', valueToSave);
	};

	render() {
		const {
			loading,
			title,
			category,
			alertResponsible,
			alertClient,
			categoryOptions,
			kmPrice,
			kmPriceUnit, volume,
			kmPriceHistory,
			archivedMaterial, activeFrom
		} = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Nosaukums' name='title' />
							<Input
								inputClass='col-sm-8'
								name='title'
								value={title}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Kategorija' name='category' />
							<Select
								selectClass='col-sm-8'
								name='category'
								handleChange={this.handleChange}
								value={category}
								placeholder='Izvēlies vērtību'
								options={categoryOptions}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Materiāla transporta cena par km'
								name='kmPrice'
							/>
							<Input
								inputClass='col-sm-3'
								blur={this.formatAsString}
								name='kmPrice'
								value={kmPrice}
								handleChange={this.handleChange}
							/>
							<div
								className='col-sm-5'>
								<Datetime
									isValidDate={(curr) => curr.isAfter( getYesterday() )}
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange}
									defaultValue={activeFrom}
								/>
							</div>
						</div>
						{kmPriceHistory.length > 0 && <div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Materiāla transporta cenas turpmākās vērtības'
								name='kmPrice'
							/>
							<div className='col-sm-8'>
								<ul>
									{kmPriceHistory.map(entry => <li key={entry.id}>{entry.price} ({entry.active_from}-{entry.active_till ?? ''}) {entry.is_category && <small>[mantots no kategorijas]</small>}</li>)}
								</ul>
							</div>
						</div>}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Pārvadāšanas apjoms tonnās'
								name='volume'
							/>
							<Input
								inputClass='col-sm-8'
								blur={this.formatAsString}
								name='volume'
								value={volume}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Sūtīt paziņojumu atbildīgajam, kad pabeidz uzdevumu'
								name='alertResponsible'
							/>
							<div className='col-sm-8'>
								<Switch
									name='alertResponsible'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={alertResponsible ? alertResponsible : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'alertResponsible')
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Sūtīt paziņojumu klientam, kad sāk uzdevumu'
								name='alertClient'
							/>
							<div className='col-sm-8'>
								<Switch
									name='alertClient'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={alertClient ? alertClient : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'alertClient')
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Arhivēts'
								name='archivedMaterial'
							/>
							<div className='col-sm-8'>
								<Switch
									name='archivedMaterial'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={archivedMaterial ? archivedMaterial : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'archivedMaterial')
									}
								/>
							</div>
						</div>
						{checkPermission('edit', 'materials') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default MaterialEdit;
