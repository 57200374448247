import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import UserEdit from './forms/users';
import { CustomModal } from 'shared/modal';
import { actionButton } from 'shared/icons';
import { formatFullDate, customTitle } from 'util';
import { checkPermission } from 'permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import FieldForm from './filterForm';
import { MILISECONDS_BEFORE_SEARCH } from 'config';

const actionsButtonFormatter = () => actionButton;

const statusFormatter = cell => {
	const value =
		cell.getValue() == 'active' ? 'Aktīvs' : '<span style="color: red;">Bloķēts</span>';

	return value;
};

const userFormatter = cell => {
	//{"status":"blocked","lastLogin":"20160629110730","avatar":"","id":"56","departments":["AGRO n."],"name":"\u017danete","surname":"Augule","position":"praktikante","login":"zanete","phone":"29158396","email":"augulezanete@gmail.com"}
	const { name, surname, departments } = cell.getRow().getData();
	const deptString = departments.join(', ');

	return `<b>${name} ${surname}</b><br />(${deptString})`;
};

const contactsFormatter = cell => {
	//{"status":"blocked","lastLogin":"20160629110730","avatar":"","id":"56","departments":["AGRO n."],"name":"\u017danete","surname":"Augule","position":"praktikante","login":"zanete","phone":"29158396","email":"augulezanete@gmail.com"}
	const { phone, email: email_temp } = cell.getRow().getData();

	const email = email_temp.includes('@')
		? `<a href="mailto:${email_temp}">${email_temp}</a>`
		: email_temp; //uzlikt labāku pārbaudi

	return `${phone}<br />${email}`;
};

const dateFormatter = cell => {
	const value = cell.getValue();
	let formatted = formatFullDate(value);
	formatted = formatted.includes('plkst')
		? formatted
		: '<span style="color: red;">Nav datu</span>';

	return formatted;
};

class UsersTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			currTask: null,
			error: false,
			client: '',
			driver: '',
			vilcejs: '',
			showModal: false,
			nameFilter: ''
		};
	}
	timeout = null;

	handleOpe11nActions(cell) {
		//const {id} = cell._cell.row.data;
		this.setState({ showModal: true }); //,userId:id
	}

	setupData() {
		this.setState({ loading: true, error: false });

		api.search.users(this.state.nameFilter).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	handleCloseModal = () => {
		this.setState({ showModal: false });
	};

	//{"status":"blocked","lastLogin":"20160629110730","avatar":"","id":"56","departments":["AGRO n."],"name":"\u017danete","surname":"Augule","position":"praktikante","login":"zanete","phone":"29158396","email":"augulezanete@gmail.com"}
	columns = [
		{
			title: 'Darbinieks',
			width: 200,
			field: 'surname',
			formatter: cell => userFormatter(cell),
			headerTooltip: 'Darbinieks',
			titleFormatter: column => customTitle(column, 'fas fa-user', '#333334')
		},
		{
			title: 'Darbības',
			headerSort: false,
			field: 'id',
			formatter: () => actionsButtonFormatter(),
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		}, //, cellClick: ((e, cell) => this.handleOpenActions(cell))
		{
			title: 'Amats',
			field: 'position',
			width: 150,
			headerTooltip: 'Amats',
			titleFormatter: column => customTitle(column, 'fas fa-address-card', '#333334')
		},
		{
			title: 'Lietotājvārds',
			field: 'login',
			headerTooltip: 'Lietotājvārds',
			titleFormatter: column => customTitle(column, 'fas fa-sign-in-alt', '#333334')
		},
		{
			title: 'Kontakti',
			field: 'phone',
			formatter: cell => contactsFormatter(cell),
			headerTooltip: 'Darbinieka kontakti',
			titleFormatter: column => customTitle(column, 'fas fa-phone', '#333334')
		},
		{
			title: 'Pēd.<br>pieslēdzies',
			width: 125,
			field: 'lastLogin',
			formatter: cell => dateFormatter(cell),
			headerTooltip: 'Pēdējā pieslēgšanās reize',
			titleFormatter: column => customTitle(column, 'fas fa-clock', '#333334')
		},
		{
			title: 'Statuss',
			field: 'status',
			formatter: cell => statusFormatter(cell),
			headerTooltip: 'Statuss',
			titleFormatter: column => customTitle(column, 'fas fa-signal', '#333334')
		}
	];

	handleOpenModal = (openType, cell) => {
		//e.stopPropagation();
		event.preventDefault();
		//._cell.row.data.orderId
		//debugger;
		//const cellData = cell.getRow().getData();

		const userID = openType == 'new' ? 0 : cell.getRow().getData().id;

		this.setState({ showModal: true, userIdCurrent: userID });
	};

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ [whatToSearch]: e.target.value }, () => {
			this.timeout = setTimeout(() => {
				_self.setupData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	render() {
		const { data, error, loading, showModal, userIdCurrent } = this.state;

		const filterItems = [
			{
				visible: true,
				title: 'Darbinieks',
				inputID: 'address_input',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'nameFilter'
			}
		];

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{checkPermission('add', 'users') && (
							<div style={{ float: 'right' }}>
								<button
									type='button'
									className='btn btn-primary'
									onClick={() => this.handleOpenModal('new')}>
									Pievienot jaunu lietotāju
								</button>
							</div>
						)}
						<FieldForm items={filterItems} />

						<div className='clearBoth'></div>
						<div style={{ padding: 5 }} />

						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<div>Nav pieejami dati</div>
								) : (
									<div className='tableWrapper'>
										<Tabulator
											columns={this.columns}
											options={{ pagination: false }}
											data={data}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				)}
				<CustomModal
					title={
						userIdCurrent == 0 ? 'Lietotāja pievienošana' : 'Lietotāja datu labošana'
					}
					showModal={showModal}
					handleCloseModal={this.handleCloseModal}
					content={
						<UserEdit id={userIdCurrent} handleCloseModal={this.handleCloseModal} />
					}
				/>
			</div>
		);
	}
}

export default UsersTable;
