import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from 'shared/form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import { toast } from 'react-toastify';

import { allFieldsHaveValues, formatFullDate, allFieldsHaveValues_new, reloadPage } from 'util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from 'config';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class NewRequestAddition extends Component {
	constructor(props) {
		super(props);
		this.state = {
            destination: '',
			loading: false,
			isLoading: false,
            options: [],
            toSaveData: {}
		};
	}

	setDataToState(key, value) {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	}

	mainStyle = {
		padding: '3px',
		borderLeft: '3px solid #ff9933',
		marginTop: '3px',
		fontSize: '75%'
	};
	subStyle = {
		padding: '3px',
		borderLeft: '3px solid #00CC66',
		marginTop: '3px',
		fontSize: '75%'
	};

	addrFormat(option, idx, field) {
		const mainTitle =
			field == 'storage' ? (
				<span>
					{option.title} - {option.nosaukums}
				</span>
			) : (
				<span>{option.name}</span>
			);

		return (
			<div>
				<span style={this.mainStyle} key={idx}>
					{mainTitle}
				</span>
				<br />
				{option.clients.map((cl, currKey) => (
					<span style={this.subStyle} key={currKey}>
						&nbsp;&nbsp;&nbsp;&nbsp;{cl}
					</span>
				))}
			</div>
		);
	}

	onTypeAheadSelect = (result, whatWasSearched) => {
		if (result != '') {
			this.setDataToState([whatWasSearched], result);
		}
	};

	getDefaultSelected(value) {
		if (value == '' || typeof value == 'undefined') {
			return [];
		}
		return [value];
	}

	_handleSearchMeta(field, query, searchCB) {
		this.setState({ isLoading: true });

		query = encodeURIComponent(query);

		let searcbParams = null;

		const { clientRegNr } = this.props;

		let key = null;

		if (field == 'storage') {
			key = 'storage';
			const { clientSearch_storage } = this.state;
			searcbParams = {
				clientId: clientRegNr,
				totaly: query,
				clientSearch: clientSearch_storage
			};
		} else if (field == 'destination') {
			key = 'destination';
			const { clientSearch_destination } = this.state;
			searcbParams = { query, clientSearch: clientSearch_destination };
		} else if (field == 'payer') {
			key = 'payer';
			searcbParams = query;
		} else if (field == 'material') {
			key = 'material';
			searcbParams = query;
		}
		//tikai noliktavai jāpadod clientID, pārējiem nav
		searchCB(searcbParams) //todo: clientid jāpadod no props
			.then(response => {
				this.setState({
					options: response
					,
					isLoading: false
				});
			});
    }
    

	saveData = e => {
        console.log(this.state)

        const objectID = this.state.toSaveData.destination.mappostID;

        api.transportation.addNewJob({object: objectID, notify: 0})
            .then(res => {
                toast.success('Pieprasījums nosūtīts');
                setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
            })
    }

	render() {
		const { loading, destination } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Adrese' name='title' />
							<div className='col-sm-8'>
								<AsyncTypeahead
									isLoading={this.state.isLoading}
									options={this.state.options}
									onChange={selected =>
										this.onTypeAheadSelect(selected[0], 'destination')
									}
									minLength={2}
									selected={this.getDefaultSelected(destination)}
									labelKey='name'
									filterBy={(option, props) => {
										return option;
									}}
									id='destinationSearch'
									clearButton={true}
									onSearch={query =>
										this._handleSearchMeta(
											'destination',
											query,
											api.search.address
										)
									}
									renderMenuItemChildren={(option, idx) =>
										this.addrFormat(option, idx, 'destination')
									}
								/>
							</div>
						</div>
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
					</div>
				)}
			</div>
		);
	}
}

export default NewRequestAddition;
