//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { CustomModal } from 'shared/modal';
import InfoModal from 'sections/admin/infoModal';
import api from '../../api';

const Link = ({link, link_text}) => {
	return <a href={link} target="_blank">{link_text}</a>
}

class Notifications extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			notifications: []
		};
	}

	componentDidMount() {
		api.admin.notifications().then(response => {
			const showModal = Array.isArray(response) && response.length > 0;
			this.setState({ notifications: response, showModal });
		});
	}

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		const { showModal, notifications } = this.state;

		if (!showModal) {
			return <></>;
		}
		return (
			<CustomModal
				title='Paziņojumi'
				showModal={showModal}
				handleCloseModal={this.handleToggleModal}
				content={
					<div className='notifications'>
						{notifications.map((notif, idx) => {
							const {message_text: text, attributes} = notif;

							if (attributes && Object.keys(attributes).length > 0 && text.includes('LINK_PLACEHOLDER')) {
								const replaced = text.replace('{LINK_PLACEHOLDER}', '___{LINK_PLACEHOLDER}___').split('___').map(element => {
									if (element === '{LINK_PLACEHOLDER}') {
										return <Link {...attributes} />
									}

									return element;
								})

								return <div key={idx} className='notification_element'>{replaced}</div>
							}

							if (text.includes('↑') || text.includes('↓')) {
								const replaced = text.split(/([↑↓])/).map(element => {
									if (element === '↑') {
										return <i className="fas fa-solid fa-arrow-up fa-lg"  style={{
											color: 'red',
										}}></i>
									}
									if (element === '↓') {
										return <i className="fas fa-solid fa-arrow-up fa-rotate-180 fa-lg"  style={{
											color: 'green',
										}}></i>
									}

									return element;
								})

								return <div key={idx} className='notification_element'>{replaced}</div>
							}

							if (text.includes('editor-paragraph')) {
								return <div key={idx} className='planning_notification_element' dangerouslySetInnerHTML={{__html: text}}></div>
							}

							return <div key={idx} className='notification_element'>{text}</div>
						})}
					</div>
				}
			/>
		);
	}
}

export default Notifications;
