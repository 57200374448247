import React from 'react';
/**
 * http://howardahenry.com/blog/how-to-capture-and-log-uncaught-exceptions-client-side-with-stacktrace-js
 * https://github.com/stacktracejs/stacktrace.js/issues/133
 * https://www.stacktracejs.com/#!/docs/stacktrace-js
 */

//https://medium.com/@leonardobrunolima/react-tips-error-handling-d6ca2171dd46
//https://stackoverflow.com/questions/50223991/how-to-make-react-create-app-production-error-boundary-map-to-source-code
export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo
		});
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div className='bodyWrapper'>
					<h2>Notika kļūda, ielādējot lapu!</h2>
					<div style={{ fontSize: '125%', fontWeight: '400' }}>
						Atkārtotas kļūdas gadījumā sazinieties ar{' '}
						<a href='mailto:web@latraps.lv'>administrāciju</a>
					</div>
					<details style={{ whiteSpace: 'pre-wrap' }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</div>
			);
		}
		return this.props.children;
	}
}
