import api from '../api';

const transportation = {
	list: () => api.get(`transportation_prices/list`),

	getMappostIdForDriver: () => api.get(`mappost/drivers`),

	job: (id) =>
		api.get(`transportation_prices/job?id=${id}`),

	addNewJob: ({ object, notify }) =>
		api.post(`transportation_prices/add_job`, {object, notify}),

};

export default transportation;
