import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../shared/form';
import api from '@api';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';
import {
	allFieldsHaveValues,
	formatFullDate,
	allFieldsHaveValues_new,
	reloadPage
} from '../../util';
import Loading from 'shared/loading';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import { checkPermission, horizonCheck } from '../../permissions';
import setRespectTabs from 'shared/tabs/setRespectTabs';

const formatAddress = addressData => {
	return addressData.title;
	//{"id":1,"short_name":"LATRAPS LPKS - ELEJA","address":"LATRAPS LPKS - ELEJA","title":"LATRAPS LPKS - LIETUVAS IELA 16A, ELEJA, ELEJAS PAG., JELGAVAS NOV., LV-3023","phone":"","mappostId":31416}
	//{"address":2304,"title":"PUTRAS, UKRU PAG., AUCES NOV., LV-3729","phone":"29479324,26302305","contacts":"PUTRAS Z.S., VĪTOLS ULDIS","mappostId":34458}
};

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class ClientEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: [],
			horizonSync: false
		};
	}

	horizonSync = () => {
		const { regNr } = this.state;
		this.setState({ horizonSync: true });

		api.clients
			.horizon_sync(regNr)
			.then(response => {
				this.setState({ horizonSync: false });

				if (response.status == 'success') {
					toast.info('Dati sinhronizēti');
					setRespectTabs();
					setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn('Ir problēmas ar datu sinhronizāciju', { autoClose: 4000 });
				}
			})
			.catch(err => {
				this.setState({ horizonSync: false });
				toast.warn('Ir problēmas ar datu sinhronizāciju', { autoClose: 4000 });
			});
	};

	setData = () => {
		this.setState({ loading: true, error: false });
		const { id } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		api.clients.editInfo(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'name',
				'clientType',
				'regNr',
				'clientNR',
				'bankAccounts',
				'contactPerson',
				'phone',
				'fax',
				'isInfoPhoneEnabled',
				'infoPhoneSMS',
				'smsAlertClient',
				'smsAlertSupervisor',
				'email',
				'notes',
				'isIncludedInPayerList',
				'isCarrier'
			]);

			const attrTransformation = {
				isIncludedInPayerList: moreAttrs.isIncludedInPayerList == true ? 'Jā' : 'Nē'
			};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id: clientID } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {}; /*{
      'addressFrom':data.storage.title,
      'addressTo':data.delivery.title,
      'material':data.material,
      'cargoCount':1,//tā vismaz spriežu pēc vecā koda - 'uzd_cargo_count'
      'typeID':9
    }*/
		let try2Final = {};
		const dataToSendAsDefaults = [
			'contactPerson',
			'phone',
			'fax',
			'isInfoPhoneEnabled',
			'infoPhoneSMS',
			'smsAlertClient',
			'smsAlertSupervisor',
			'email',
			'notes',
			'isCarrier'
		]; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			contactPerson: 'Kontaktpersona',
			phone: 'Tālrunis',
			fax: 'Fakss',
			isInfoPhoneEnabled: 'SMS pieslēgts',
			smsAlertClient: 'SMS teksts klientam',
			smsAlertSupervisor: 'SMS teksts atbildīgajam',
			email: 'E-pasts',
			notes: 'Piezīmes',
			isCarrier: 'Vai ir pārvadātājs'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			[
				'phone',
				'fax',
				'isInfoPhoneEnabled',
				'infoPhoneSMS',
				//'smsAlertClient',
				//'smsAlertSupervisor',
				'email',
				'notes',
				'isCarrier'
			],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		api.clients.save({ client: clientID, data: finalToSend }).then(response => {
			if (response.status == 'ok') {
				toast.success('Dati saglabāti');
				setRespectTabs();
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn('Ir problēmas ar datu saglabāšanu', { autoClose: 4000 });
			}
		});
	};

	onDriverSelect(driver) {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	}

	onDeptSelect = departments => {
		const deptForSave = departments.map(dept => dept.value);
		this.setDataToState('departments', deptForSave);
	};

	handleSwitch(elem, state, name) {
		const inputValue = state;
		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	render() {
		const {
			loading,
			name,
			regNr,
			clientNR,
			clientType,
			bankAccounts,
			contactPerson,
			phone,
			fax,
			isInfoPhoneEnabled,
			infoPhoneSMS,
			smsAlertClient,
			smsAlertSupervisor,
			email,
			notes,
			isIncludedInPayerList,
			isCarrier,
			horizonSync
		} = this.state;

		const regNrTitle = clientType == 'J' ? 'Reģ. nr.' : 'Pers. kods';

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Nosaukums' name='name' />
							<Input
								disabled={true}
								inputClass='col-sm-8'
								name='name'
								value={name}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title={regNrTitle} name='regNr' />
							<Input
								disabled={true}
								inputClass='col-sm-8'
								name='regNr'
								value={regNr}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Vai ir iekļauts maksātāju sarakstā'
								name='isIncludedInPayerList'
							/>
							<Input
								disabled={true}
								inputClass='col-sm-8'
								name='isIncludedInPayerList'
								value={isIncludedInPayerList}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Klienta nr.' name='clientNR' />
							<Input
								disabled={true}
								inputClass='col-sm-8'
								name='clientNR'
								value={clientNR}
								handleChange={this.handleChange}
							/>
						</div>

						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Norēķinu konti'
								name='bankAccounts'
							/>
							<Select
								selectClass='col-sm-8'
								name='bankAccounts'
								handleChange={this.handleChange}
								value={bankAccounts}
								placeholder='Izvēlies vērtību'
								options={
									bankAccounts && bankAccounts.length > 0
										? bankAccounts.map((account, ind) => {
												return { value: account, label: account };
										  })
										: []
								}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Kontaktpersona' name='contactPerson' />
							<Input
								inputClass='col-sm-8'
								name='contactPerson'
								value={contactPerson}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Tālrunis' name='phone' />
							<Input
								inputClass='col-sm-8'
								name='phone'
								value={phone}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Fakss' name='fax' />
							<Input
								inputClass='col-sm-8'
								name='fax'
								value={fax}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='SMS serviss' name='infoPhoneSMS' />
							<Select
								selectClass='col-sm-4'
								name='isInfoPhoneEnabled'
								handleChange={this.handleChange}
								value={isInfoPhoneEnabled}
								placeholder='Izvēlies vērtību'
								options={[
									{ value: '1', label: 'Atslēgts' },
									{ value: '2', label: 'Pieslēgts' }
								]}
							/>
							<Input
								inputClass='col-sm-4'
								name='infoPhoneSMS'
								value={infoPhoneSMS}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title={
									<span>
										SMS ziņas teksts klientam{' '}
										<OverlayTrigger
											placement='top'
											overlay={
												<Tooltip id='tooltip-right'>
													Pieejamie mainīgie: {'{'}AMOUNT{'}'}, {'{'}
													AMOUNTUNITS{'}'}, {'{'}MATERIAL{'}'}, {'{'}
													ADDRESS{'}'}, {'{'}VILCEJS{'}'}, {'{'}DRIVERNAME
													{'}'}, {'{'}DRIVERPHONE{'}'}
												</Tooltip>
											}>
											<i className='fas fa-question-circle'></i>
										</OverlayTrigger>
									</span>
								}
								name='smsAlertClient'
							/>
							<Textarea
								inputClass='col-sm-8'
								name='smsAlertClient'
								value={smsAlertClient}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title={
									<span>
										SMS ziņas teksts atbildīgajam{' '}
										<OverlayTrigger
											placement='top'
											overlay={
												<Tooltip id='tooltip-right'>
													Pieejamie mainīgie: {'{'}AMOUNT{'}'}, {'{'}
													AMOUNTUNITS{'}'}, {'{'}RECEIVERNAME{'}'}, {'{'}
													MATERIAL{'}'}, {'{'}ADDRESS{'}'}, {'{'}VILCEJS
													{'}'}, {'{'}DRIVERNAME{'}'}, {'{'}DRIVERPHONE
													{'}'}
												</Tooltip>
											}>
											<i className='fas fa-question-circle'></i>
										</OverlayTrigger>
									</span>
								}
								name='smsAlertSupervisor'
							/>
							<Textarea
								inputClass='col-sm-8'
								name='smsAlertSupervisor'
								value={smsAlertSupervisor}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='E-pasts' name='email' />
							<Input
								inputClass='col-sm-8'
								name='email'
								value={email}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Klienta piezimes' name='notes' />
							<Textarea
								inputClass='col-sm-8'
								name='notes'
								value={notes}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Iekļauts piegādātāju sarakstā'
								name='isCarrier'
							/>
							<Select
								selectClass='col-sm-8'
								name='isCarrier'
								handleChange={this.handleChange}
								value={isCarrier}
								placeholder='Izvēlies vērtību'
								options={[
									{ value: '1', label: 'Jā' },
									{ value: '2', label: 'Nē' }
								]}
							/>
						</div>
						<div className='btn-group' role='group'>
							<button
								type='button'
								disabled={!checkPermission('edit', 'clients')}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt
							</button>
							<button
								type='button'
								disabled={horizonSync || !horizonCheck()}
								className='btn btn-success'
								onClick={this.horizonSync}>
								Sinhronizēt ar Horizon
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default ClientEdit;
