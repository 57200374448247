import React from 'react';
import moment from 'moment';

import filterStyles from './filter.module.css';

import './styles.css';

import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl } from 'react-bootstrap';
import clsx from 'clsx';
import { Input } from 'shared/form';

import { ranges, timespanLabel } from '../helpers';
import {getCurrentYear} from '../../../helpers/time';

const levels = [
	{ title: 'Pārvadātājs', val: 'transporter' },
	{ title: 'Auto', val: 'driver' }
];

const Filter = props => {
	const { start, end, levelChange, applyCallback: dateSet, level, exportData, filters, filterCB } = props;

	const filterItems = [
		{
			visible: true,
			title: level === 'transporter' ? 'Pārvadātājs' : 'Auto',
			inputID: 'transporterInfo',
			value: filters.transporterInfo,
			cb: filterCB,
			cbToSearch: 'transporterInfo'
		},
		{
			visible: true,
			title: 'Nodaļa',
			inputID: 'department',
			value: filters.department,
			cb: filterCB,
			cbToSearch: 'department'
		}
	];

	return (
		<div className={filterStyles.container}>
			<div className={clsx(filterStyles.item, filterStyles.daterange)}>
				<b>Laika posms</b>
				<br />
				<DateTimeRangeContainer
					ranges={ranges}
					start={start}
					end={end}
					smartMode={true}
					pastSearchFriendly={true}
					local={{
						format: 'DD-MM-YYYY',
						sundayFirst: false
					}}
					applyCallback={dateSet}
					years={[2015, getCurrentYear()]}>
					<FormControl
						id='formControlsTextB'
						type='text'
						label='Text'
						placeholder='Enter text'
						value={timespanLabel(start, end)}
					/>
				</DateTimeRangeContainer>
			</div>
			{filterItems.length>0 && <>
				{filterItems.map(item => {
					const {inputID, title, value, cb, cbToSearch} = item;

					return <div key={inputID} className={clsx(filterStyles.item, filterStyles.daterange)}>
					<b>{title}</b>
					<br />
					<Input
						/* inputClass='col-sm-8' */
						name={inputID}
						value={value}
						handleChange={ev => cb(ev, cbToSearch)}
					/>
				</div>
				})}
			</>}
			<div className={clsx(filterStyles.item, 'btn-group')} role='group'>
				<b>Attēlojuma līmenis</b>
				<br />
				{levels.map(thisLevel => {
					const { val, title } = thisLevel;

					return (
						<button
							key={val}
							onClick={() => levelChange(val)}
							type='button'
							className={clsx(
								'btn',
								level === val ? 'btn-primary' : 'btn-outline-primary'
							)}>
							{title}
						</button>
					);
				})}
			</div>
			<div
				className={clsx(filterStyles.item, 'btn-group')}
				role='group'
				aria-label='Basic example'>
				<b>Eksports</b>
				<br />
				<button onClick={exportData} type='button' className={clsx('btn', 'btn-primary')}>
					XLSX
				</button>
			</div>
		</div>
	);
};

export default Filter;
