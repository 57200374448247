/* eslint-disable react/jsx-max-depth */
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import styles from './passwordRules.module.css';

const PasswordRules = () => {
	const [open, setOpen] = useState(true);

	return (
		<div>
			<div className={styles.header} onClick={() => setOpen(!open)}>
				Paroles nosacījumi
			</div>
			<Collapse isOpened={open}>
				<div className={styles.text}>
					Parolei jāsatur:
					<ul>
						<li>vismaz viens mazais burts</li>
						<li>vismaz viens lielais burts</li>
						<li>vismaz viens cipars</li>
						<li>
							vismaz viens īpašais simbols
							(!&quot;#$%&amp;&apos;()*+,-.:;&lt;=&gt;?@[\]^_|~)
						</li>
					</ul>
					Tā nedrīkst saturēt:
					<ul>
						<li>lietotāja vārdu un uzvārdu</li>
						<li>lietotājvārdu (loginu)</li>
						<li>uzņēmuma nosaukumu</li>
						<li>lietotāja e-pasta adresi vai tās daļu</li>
						<li>lietotāja telefona numuru</li>
					</ul>
				</div>
			</Collapse>
		</div>
	);
};

export default PasswordRules;
