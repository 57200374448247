import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../shared/form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import Switch from 'react-bootstrap-switch';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/lv';
import Datetime from 'react-datetime';
import {
	formatNumber,
	isNumeric,
	allFieldsHaveValues,
	allFieldsHaveValues_new,
	reloadPage
} from '../../util';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import clsx from 'clsx';
import setRespectTabs from 'shared/tabs/setRespectTabs';

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class TaskEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			wrongData: false,
			data: {},
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			initialData: {},
			priority: '0',
			name: '',
			icClientDeliveryAddress: false //poga "Piegādes adrese"
		};
	}

	saveData = e => {
		const { task } = this.props;
		const { toSaveData, data } = this.state;
		/*
				  {
		"date": "20190516",
		"notes": "30.11.2018 līdz 16:001!",
		"description": "Rapšu rauši, [Pasūt. KGM, SIA], LATRAPS LPKS - ELEJA ->  TĪŠAS, TĒRVETES PAG.,1TĒRVETES NOV., tel. 67339047",
		"amount": "501",
		"measurementID": "5"
  }
  */

		e.preventDefault();

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Nav ko saglabāt');
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] != data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		}

		const dataToSendAsDefaults = ['date', 'notes', 'description', 'amount', 'measurementID'];
		//orderStatusForSave - šo vajadzētu pārsaukt pirms sūtīšanas, bet pašlaik vienalga
		//'storageID','ordererAddressID', - šie ir no asynctypeahead, kur ir cita struktūra

		let try2Final = {};

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else if (theKey in data) {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = try2Final; //Object.assign({},try2Final);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			date: 'Datums',
			notes: 'Piezīmes',
			description: 'Apraksts',
			amount: 'Daudzums',
			measurementID: 'Mērvienība'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['date', 'notes', 'description', 'amount', 'measurementID'],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		api.tasks.edit({ data: finalToSend, task }).then(response => {
			if (response.status == 'error') {
				toast.warn(response.message, { autoClose: 10000 });
			} else if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				setRespectTabs();
				// setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn('Ir problēmas!', { autoClose: 7000 });
			}
		});
	};

	setData = () => {
		const { task } = this.props;

		this.setState({ loading: true });

		api.tasks.dataForEdit(task).then(response => {
			this.setDataToInitState(response);
		});
	};

	setDataToInitState = response => {
		const { measurementOptions, ...taskData } = response;

		const moreAttrs = getDataFromAPIResponse(response, [
			'date',
			'notes',
			'description',
			'amount',
			'measurementID'
		]);

		const attrTransformation = { date1: moment(moreAttrs.date).format('DD.MM.YYYY.') };

		const newState = Object.assign(
			{},
			moreAttrs,
			{ loading: false, error: false, measurementOptions, data: taskData },
			attrTransformation
		);

		this.setState(newState);
	};

	componentDidMount() {
		this.setData();
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (inputValue.trim().length > 0 && name == 'amount' && isNumeric(inputValue) == false) {
			return false;
		}

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	};

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleDateChange = value => {
		//console.log('handleDateChange',event,event.format('YYYYMMDD'));

		if (typeof value == 'string' && value != '') {
			this.setState({ wrongData: true });
		} else {
			const valueToSave = value == '' ? value : value.format('YYYYMMDD');
			this.setDataToState('date1', valueToSave);
			this.setState({ wrongData: false });
		}
	};

	render() {
		const {
			date1,
			wrongData,
			notes,
			description,
			amount,
			measurementID,
			measurementOptions,
			loading
		} = this.state;

		return (
			<div className='bodyWrapper'>
				{/*<h3>Pasūtījuma labošana/reģistrēšana</h3>*/}
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Uzdevuma izpildes datums'
								name='date1'
							/>
							<div
								className={clsx({
									'col-sm-8': !wrongData,
									'col-sm-7': wrongData
								})}>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange}
									defaultValue={date1}
								/>
							</div>
							{wrongData && (
								<div className='col-sm-1'>
									<OverlayTrigger
										placement='right'
										overlay={
											<Tooltip id='tooltip-right'>
												Datums ir jāapstiprina kalendāra izvēlnē
											</Tooltip>
										}>
										<i className='fas fa-exclamation-triangle'></i>
									</OverlayTrigger>
								</div>
							)}
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Uzdevuma piezīmes' name='notes' />
							<Input
								inputClass='col-sm-8'
								name='notes'
								value={notes}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Uzdevuma apraksts'
								name='description'
							/>
							<Textarea
								inputClass='col-sm-8'
								name='description'
								value={description}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Daudzums' name='amount' />
							<Input
								inputClass='col-sm-5'
								name='amount'
								value={amount}
								handleChange={this.handleChange}
							/>
							<Select
								selectClass='col-sm-3'
								handleChange={this.handleChange}
								name='measurementID'
								value={measurementID}
								placeholder='Izvēlies vērtību'
								options={
									measurementOptions
										? measurementOptions.map(meas => ({
												label: meas.title,
												value: meas.id
										  }))
										: []
								}
							/>
						</div>
						<button type='button' className='btn btn-primary' onClick={this.saveData}>
							Saglabāt!
						</button>
						{/*<pre>{JSON.stringify(this.state, null, "\t")}</pre>*/}
					</div>
				)}
			</div>
		);
	}
}

export default TaskEdit;
