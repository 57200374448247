import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from 'shared/pagination/pagination';
import { changePage, setMetadata, setPageCount } from '../slice';
import OrderTable from './orderTable';

const ROWS_IN_ONE_PAGE = 20;

class TableWithPagination extends Component {
	constructor(props) {
		super(props);
	}

	setData = () => {
		const { dept, client, year, rows } = this.props;
		const rowsForCeil = typeof rows == 'undefined' ? 1 : rows;
		const pages = Math.ceil(rowsForCeil / ROWS_IN_ONE_PAGE);

		this.props.setMetadata({
			department: dept, client, year, pages
		})
	};

	componentDidMount() {
		this.setData();
	}

	handlePageChange = currentPage => {
		this.props.changePage(currentPage)
	};

	render() {
		const {loading, currentPage, totalPages} = this.props;

		return (
			<div>
				{totalPages == 1 || loading &&
					<div>
						<Pagination
							page={currentPage}
							total={totalPages}
							handlePageChange={this.handlePageChange}
						/>
					</div>
				}
				<OrderTable />
				{totalPages == 1 || loading &&
					<div>
						<Pagination
							page={currentPage}
							total={totalPages}
							handlePageChange={this.handlePageChange}
						/>
					</div>
				}
			</div>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		setMetadata: ({department, year, client, pages}) => {
			dispatch(setMetadata({department, year, client}));
			dispatch(setPageCount(pages));
			dispatch(changePage(1));
		},
		changePage: (page) => {
			dispatch(changePage(page));
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		loading: state.planningOrders.loading,
		currentPage: state.planningOrders.current_page,
		totalPages: state.planningOrders.pages
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(TableWithPagination);
