import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import auth from './sections/auth/slice';
import planning from './sections/planning/slice';
import orders from './sections/orders/slice';
import orderTabs from './sections/orders/tabs/slice';
import planningOrders from './sections/ordersPlanning/slice';
import planningOrderTabs from './sections/ordersPlanning/tabs/slice';
import client from './sections/client/slice';

export default configureStore({
	reducer: {
		auth, planning, orders, orderTabs, client, planningOrders, planningOrderTabs
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(logger),
	devTools: process.env.NODE_ENV !== 'production',
});
