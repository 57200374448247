import React, { Component } from 'react';
import api from '@api';
import Loading from 'shared/loading';

class InfoModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			departments: [],
			services: {}
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		api.admin.system_info().then(response => {
			const newState = Object.assign({}, { loading: false, error: false }, response);
			this.setState(newState);
		});
	};

	componentDidMount() {
		this.setData();
	}

	render() {
		const { loading, departments, services, authorization } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
						<div>
							<h3>Manas nodaļas</h3>
							{departments.join(', ')}
							<h3>Piekļuves tiesību grupa</h3>
							{authorization}
							<h3>Lietotnes versija</h3>
							{process.env.REACT_APP_VERSION}
						</div>
						<div>
							<h3>Ārējo servisu statuss</h3>
							<ul>
								{Object.keys(services).map(serv => (
									<li key={serv}>
										{formatter[serv]}:{' '}
										{services[serv] ? (
											<span style={{ color: 'green' }}>ieslēgts</span>
										) : (
											<span style={{ color: 'red' }}>atslēgts</span>
										)}
									</li>
								))}
							</ul>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const formatter = {
	mappost: 'Mappost',
	horizon: 'Horizon sinhronizācija',
	email: 'E-pastu sūtīšana',
	sms: 'SMS sūtīšana',
	db: 'Datubāze'
};

export default InfoModal;
