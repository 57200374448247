import React, { Component } from 'react';

import api from '@api';
import Loading from 'shared/loading';
import moment from 'moment';

import Filter from './filter';
import Table from './table';
import FieldForm  from '@sections/admin/filterForm';
import { apiEndpoint, MILISECONDS_BEFORE_SEARCH } from '../../../config';
import { Check } from 'shared/form';
import { sumByKey } from '../helpers';

class MonthlySummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			status: 'init',
			level: 'overview',
			format: 'table',
			payer: '',
			carrier: '',
			invoiceNr: '',
			date_from: '',
			date_till: '',
			delivery_from: '',
			delivery_till: '',
			start: moment().startOf('month'),
			end: moment(),
			storned: false,
			completed: true,
			horizon_booked: true,
			horizon_template: false,
			logistics_template: false,
			filters: {
				auto: '',
				department: '',
				driver: '',
				material: ''
			}
		};
	}

	setup(searching = false) {
		const { payer, carrier, invoiceNr, date_from, date_till, delivery_from, delivery_till, start, end, level, storned, completed, horizon_booked, horizon_template, logistics_template } = this.state;
		this.setState({ status: 'search'});

		let searchParams = {
			maks: payer,
			carrier: carrier,
			nr: invoiceNr,
			start: delivery_from == '' ? start.format('YYYYMMDD') : delivery_from,
			end: end.format('YYYYMMDD'),
			client: '',
			date_from,
			date_till: date_from == '' ? end.format('YYYYMMDD') : date_till,
			delivery_from,
			delivery_till
		}

		if(searching) {
			const checkboxes = ['storned', 'completed', 'horizon_booked', 'horizon_template', 'logistics_template'];
			checkboxes.forEach(item => {
				if (this.state[item] === true) {
					searchParams = {...searchParams, [item]: true};
				}
			})

			api.reports
			.invoicesComplex2(searchParams)
			.then(response => {
				this.setState({ status: 'loadedData', data: response });
			});
			return
		}

		api.reports
			.invoicesComplex({start: start.format('YYYYMMDD'), end: end.format('YYYYMMDD'), completed, horizon_booked})
			.then(response => {
				this.setState({ status: 'loadedData', data: response });
			});
	}

	exportData = () => {
		const { start, end, level } = this.state;

		const url = `${apiEndpoint}reports/invoice_deep?start=${start.format('YYYYMMDD')}&end=${end.format('YYYYMMDD')}&mode=${level}&export`;
		window.open(url, '_blank');
	};

	componentDidMount() {
		this.setup();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.start !== this.state.start ||
			prevState.end !== this.state.end ||
			prevState.level !== this.state.level
		) {
			this.setup();
		}
	}

	handleLevelChange = newFormat => {
		this.setState({ level: newFormat });
	};

	setDates = (start, end) => {
		this.setState({
			start,
			end
		});
	};

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		const value = ['date_from', 'date_till', 'delivery_from', 'delivery_till'].includes(whatToSearch)
			? e.format('YYYYMMDD')
			: e.target.value;

		this.setState({ [whatToSearch]: value, currentPage: 1, totalPages: 1 }, () => {
			this.timeout = setTimeout(() => {
				_self.setup(true);
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	filterItems = (date_from, date_till, delivery_from, delivery_till) => ([
		{
			visible: true,
			title: 'Rēķina nr.',
			inputID: 'invoiceNr',
			value: '',
			cb: this.handleSearch,
			cbToSearch: 'invoiceNr'
		},
		{
			visible: true,
			title: 'Maksātājs',
			inputID: 'payer',
			value: '',
			cb: this.handleSearch,
			cbToSearch: 'payer'
		},
		{
			visible: true,
			title: 'Pārvadātājs',
			inputID: 'carrier',
			value: '',
			cb: this.handleSearch,
			cbToSearch: 'carrier'
		},
		{
			visible: true,
			field_type: 'date',
			title: 'Izrakstīts no',
			inputID: 'date_from',
			value: date_from,
			cb: this.handleSearch,
			cbToSearch: 'date_from'
		},
		{
			visible: true,
			field_type: 'date',
			title: 'Izrakstīts līdz',
			inputID: 'date_till',
			value: date_till,
			cb: this.handleSearch,
			cbToSearch: 'date_till'
		},
		{
			visible: true,
			field_type: 'date',
			title: 'Piegāde no',
			inputID: 'delivery_from',
			value: delivery_from,
			cb: this.handleSearch,
			cbToSearch: 'delivery_from'
		},
		{
			visible: true,
			field_type: 'date',
			title: 'Piegāde līdz',
			inputID: 'delivery_till',
			value: delivery_till,
			cb: this.handleSearch,
			cbToSearch: 'delivery_till'
		},
	]);

	handleFilterChange = (ev, type = 'input_field') => {

		if(type === 'checkbox') {
			const name = ev;
			this.setState(prevState => ({
				[name]: !prevState[name],
			}), () => {
				this.setup(true);
			});
			return;
		}

		ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
		const name = ev.target.name;

		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				[name]: !prevState[name]
			}
		}))
	}

	render() {
		const {
			status, data, format, start,
			end, level, filters, date_from, date_till,
			storned, completed, horizon_booked, horizon_template, logistics_template,
			delivery_from, delivery_till} = this.state;

		return (
			<div className='bodyWrapper'>
				<div style={{display: 'flex'}}>
					<div style={{minWidth: 500}}>
						<FieldForm fullWidth={true} items={this.filterItems(date_from, date_till, delivery_from, delivery_till)} />
					</div>
					<div style={{marginLeft: 25}}>
						<Check name='completed' value={completed} handleChange={() => this.handleFilterChange('completed', 'checkbox')} label='Pabeigtie loģistikas rēķini' />
						<Check name='logistics_template' value={logistics_template} handleChange={() => this.handleFilterChange('logistics_template', 'checkbox')} label='Loģistikas sagataves' />
						<Check name='horizon_template' value={horizon_template} handleChange={() => this.handleFilterChange('horizon_template', 'checkbox')} label='Horizon sagataves' />
						<Check name='horizon_booked' value={horizon_booked} handleChange={() => this.handleFilterChange('horizon_booked', 'checkbox')} label='Horizon grāmatotie rēķini' />
						<Check name='storned' value={storned} handleChange={() => this.handleFilterChange('storned', 'checkbox')} label='Horizon stornētie rēķini' />
						<div style={{marginTop: 28}}></div>
						<Filter
							start={start}
							end={end}
							level={level}
							levelChange={this.handleLevelChange}
							applyCallback={this.setDates}
							exportData={this.exportData}
							filters={filters}
							filterCB={this.handleFilterChange}
						/>
					</div>
				</div>
				{status === 'search' ? (
					<Loading />
				) : (
					<>
						<hr className='divider' />
						{format === 'table' && <Table data={data} level={level} filters={filters} />}
						<div className='report_overview'>
							<h4>Kopsavilkums</h4>
							<div>Ierakstu skaits: {data.length}</div>
						</div>
					</>
				) }
			</div>
		);
	}
}

export default MonthlySummary;
