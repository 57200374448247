/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import api from '@api';
import { Input, Label } from 'shared/form';
import Loading from 'shared/loading';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PasswordRules from './passwordRules';
import checkIfValid from './checkIfValid';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			message: '',
			name: '',
			password1: '',
			password2: '',
			data: {},
			options: [],
			passwordValid: true,
			passwordValidText: ''
		};
	}

	setData = () => {
		const token = this.props.match.params.token;
		this.setState({ loading: true, error: false });

		api.user.check_reset_token(token).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, ['message']);

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, message: response.message || null },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	checkIfValidPassword = password1 => {
		const checkRes = checkIfValid(password1, this.state.passwordValid);

		if (checkRes === true) {
			return;
		}

		this.setState({ ...checkRes });
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.token !== prevProps.match.params.token) {
			this.setData();
		}
	}

	setDataToState(key, value) {
		const toSaveData = Object.assign(this.state.toSaveData, {
			[key]: value
		});

		this.setState({ toSaveData, [key]: value });
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (name == 'password1') {
			this.checkIfValidPassword(inputValue);
		}
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		e.preventDefault();

		const { password1, password2, passwordValid } = this.state;

		if (password1 != password2) {
			toast.info('Paroles nesakrīt', { autoClose: 5000 });
			return;
		}

		if (password1.trim() == '' || password2.trim() == '') {
			toast.info('Parole nedrīkst būt tukša', { autoClose: 5000 });
			return;
		}

		if (!passwordValid) {
			toast.info('Parole neatbilst noteikumiem', { autoClose: 5000 });
			return;
		}

		const token = this.props.match.params.token;

		api.admin.reset_password({ password: password1, token }).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				window.open('#', '_self');
				//this.setData();
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	render() {
		//"client","responsible"
		const {
			loading,
			message,
			password1,
			password2,
			passwordValid,
			passwordValidText
		} = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<h2>Jaunas paroles izveide</h2>
						{message ? (
							<div>Notika kļūda: {message}</div>
						) : (
							<div>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div style={{ minWidth: '50%' }}>
										<div className='row'>
											<Label
												labelClass='col-sm-2'
												title='Parole'
												name='password1'
											/>
											<Input
												inputClass='col-sm-8'
												type='password'
												name='password1'
												value={password1}
												handleChange={this.handleChange}
											/>
											{password1.length > 0 && passwordValid == false && (
												<OverlayTrigger
													placement='right'
													overlay={
														<Tooltip id='tooltip-right'>
															Parole neatbilst nosacījumiem:{' '}
															{passwordValidText}
														</Tooltip>
													}>
													<i className='fas fa-exclamation-triangle'></i>
												</OverlayTrigger>
											)}
										</div>
										<div className='row'>
											<Label
												labelClass='col-sm-2'
												title='Parole atkārtoti'
												name='password2'
											/>
											<Input
												inputClass='col-sm-8'
												type='password'
												name='password2'
												value={password2}
												handleChange={this.handleChange}
											/>
										</div>
										<button
											type='button'
											disabled={false}
											className='btn btn-primary'
											onClick={this.saveData}>
											Saglabāt!
										</button>
									</div>
									<PasswordRules />
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default ResetPassword;
