import React from 'react';
/*
https://blog.stvmlbrn.com/2017/10/14/display-loading-indicator-in-react-during-ajax-requests.html

https://github.com/Lemoncode/react-promise-tracker
*/
const Loading = () => (
	<span>
		<i className='fa fa-spinner fa-pulse fa-fw'></i> Dati tiek ielādēti
	</span>
);

export default Loading;
