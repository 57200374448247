import React, { Component } from 'react';
import api from '@api';
import Deliveries from './table';
import Header from './header';
import Loading from 'shared/loading';
import Pagination from '../../shared/pagination/pagination';
import FilterText from '../../shared/filter/format';
import { handleDataUpdate } from '../../util';
import ErrorOnLoading from 'shared/errorOnLoad';
import MassDeliveryEdit from './MassDeliveryEdit';
import { connect } from 'react-redux';

const ROWS_IN_ONE_PAGE = 20;

class DeliveriesSection extends Component {
	intervalID;
	constructor(props) {
		super(props);

		this.state = {
			key: 0,
			loading: false,
			error: false,
			data: [],
			currPage: 1,
			pages: 1,
			showMassDeliveryEditModal: false,
			isSelected: [],
			isMassEditButtonsActive: false,
		};
	}

	oneDelivery(delivery, key) {
		return (
			<div key={key}>
				<Header data={delivery} mode='archive' resetData={this.setup} />
				<br />
				<Deliveries
					mode='archive'
					data={delivery.taskDeliveries.deliveries}
					isSelected={this.state.isSelected}
					onCheckboxChange={this.handleCheckboxChange}
					selectAllDeliveries={this.selectAllDeliveries}
					isMassEditButtonsActive={this.state.isMassEditButtonsActive}
				/>
			</div>
		);
	}

	setup() {
		this.setState({ loading: true, error: false, isSelected: [] });

		const { currPage } = this.state;
		const { regNr = '' } = this.props;

		api.deliveries
			.main({ page: currPage, rows: ROWS_IN_ONE_PAGE, client: regNr })
			.then(response => {
				let deliveries = [];
				let pages = 1;
				if ('status' in response && response.status == 'error') {
					deliveries = [];
				} else {
					deliveries = response.deliveries;
					pages = Math.ceil(response.numberOfResults / ROWS_IN_ONE_PAGE);
				}
				this.setState({ loading: false, error: false, data: deliveries, pages });
				this.intervalID = handleDataUpdate(this.setup.bind(this));
			});
	}

	componentWillUnmount() {
		clearTimeout(this.intervalID);
	}

	componentDidMount() {
		this.setup();
	}

	handleSelect(key) {
		this.setState({ key, data: null });
	}

	handlePageChange = currPage => {
		this.setState({ currPage }, () => this.setup());
	};

	handleOpenMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: true });
	};

	handleCloseMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: false });
	};

	handleCheckboxChange = (deliveryId, isSelected) => {
		this.setState(prevState => {
			const newIsSelected = [...prevState.isSelected];
			if (isSelected) {
				if (!newIsSelected.includes(deliveryId)) {
					newIsSelected.push(deliveryId);
				}
			} else {
				const index = newIsSelected.indexOf(deliveryId);
				if (index > -1) {
					newIsSelected.splice(index, 1);
				}
			}
			return { isSelected: newIsSelected };
		});
	};

	selectAllDeliveries = () => {
		this.setState(prevState => {
			const deliveryIds = prevState.data.flatMap(delivery =>
				delivery.taskDeliveries.deliveries.map(d => d.deliveryId)
			);
			const isSelected = prevState.isSelected.length ? [] : deliveryIds;
			return { isSelected };
		});
	};

	activateMassEdit = () => {
		this.setState(prevState => ({ isMassEditButtonsActive: !prevState.isMassEditButtonsActive }));
	};

	render() {
		const { data, error, loading, currPage, pages, showMassDeliveryEditModal, isSelected, isMassEditButtonsActive } = this.state;
		const { separatePage = true } = this.props;

		console.log('Current State:', this.state);

		return (
			<div className={separatePage ? 'bodyWrapper' : ''} style={{ position: 'relative' }}>
				{separatePage && <FilterText />}
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px' }}>
									{pages !== 1 && (
										<Pagination
											page={currPage}
											total={pages}
											handlePageChange={this.handlePageChange}
										/>
									)}
									<div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-end' }}>

										{this.props.canUpdateDeliveriesInBatch && (
											<div>
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.activateMassEdit}
													style={{ display: 'block', width: '220px' }}>
													{isMassEditButtonsActive ? 'Deaktivizēt kopīgo labošanu' : 'Aktivizēt kopīgo labošanu'}
												</button>
											</div>
										)}
										<div style={{ display: 'flex', gap: '10px' }}>
											{isSelected.length > 0 && isMassEditButtonsActive && (
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.handleOpenMassDeliveryEditModal}
													style={{ width: '220px' }}>
													Labot atzīmētās piegādes ({isSelected.length})
												</button>
											)}
											{isMassEditButtonsActive && (
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.selectAllDeliveries}
													style={{ width: '220px' }}>
													{isSelected.length > 0 ? 'Noņemt atzīmes no piegādēm' : 'Atzīmēt visas piegādes'}
												</button>
											)}
										</div>
									</div>
								</div>
								{data.length > 0 ? (
									data.map((delivery, ind) => this.oneDelivery(delivery, ind))
								) : (
									<div>0 piegādes</div>
								)}
								{pages !== 1 && (
									<Pagination
										page={currPage}
										total={pages}
										handlePageChange={this.handlePageChange}
									/>
								)}
							</div>
						)}
					</div>
				)}

				{showMassDeliveryEditModal && (
					<MassDeliveryEdit
						showModal={showMassDeliveryEditModal}
						handleCloseModal={this.handleCloseMassDeliveryEditModal}
						isSelected={this.state.isSelected}
						data={this.state.data.flatMap(delivery => delivery.taskDeliveries.deliveries)}
					/>
				)}

			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	canUpdateDeliveriesInBatch: state.auth.canUpdateDeliveriesInBatch,
})

export default connect(mapStateToProps)(DeliveriesSection);
