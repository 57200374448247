import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Input, Label, Select, Textarea, Radio, Check } from '../../../shared/form';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';
import setRespectTabs from 'shared/tabs/setRespectTabs';

import { allFieldsHaveValues, formatFullDate, allFieldsHaveValues_new, reloadPage } from 'util';
//import MapObject from '@components/map';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import MappostCheck from './mappost';
import { CustomModal } from 'shared/modal';

const MapObject = React.lazy(() => import('shared/map'));

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class AddressEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: []
		};
	}

	getUserFavorites = () => {
		return api.favorites.userFavorites()
			.then(response => {
				this.setState({ userFavorites: response })
			})
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { id } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		//{
		api.admin.address_get(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'title',
				'direction',
				'directionOptions',
				'mappostObjectID',
				'changeHistory',
				'lastUpdate',
				'coordinates',
				'canBeDeleted',
				'archivedAddress',
				'isUsedInStorage'
			]);

			const forNewAddress = [
				'home',
				'street',
				'city',
				'parish',
				'novads',
				'postal',
				'country'
			];

			let ppp = [];

			for (let i = 0; i < forNewAddress.length; i++) {
				ppp[forNewAddress[i]] = '';
			}

			const attrTransformation = { lastUpdate: formatFullDate(moreAttrs.lastUpdate) };

			const responseAppended = Object.assign(response, ppp);
			//

			const newState = Object.assign(
				{ loading: false, error: false, data: responseAppended },
				moreAttrs,
				ppp,
				attrTransformation
			);

			this.setState(newState, () => {
				this.getUserFavorites();
			});
		});
	};

	deleteAddress = () => {};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	handleFavorite(type, id) {
		this.setState({ favIsLoading: true });
		api.favorites.add({type, id})
			.then(() => {
				this.getUserFavorites();
				this.setState({ favIsLoading: false })
			});
	}

	doSave = (id, finalToSend) => {
		if (id == '') {
			api.admin.address_new(finalToSend).then(response => {
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setRespectTabs();
					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 4000 }
					);
				}
			});
		} else {
			api.admin.address_save({ id, data: finalToSend }).then(response => {
				if (response.status == 'ok') {
					setRespectTabs();
					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//toast.success('Dati saglabāti');
					setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 4000 }
					);
				}
			});
		}
	}

	saveData = e => {
		e.preventDefault();

		const { id } = this.props;
		const arrOfValues =
			id == null
				? [
						'home',
						'street',
						'city',
						'parish',
						'novads',
						'postal',
						'country',
						'direction',
						'mappostObjectID'
				]
				: ['title', 'direction', 'mappostObjectID', 'archivedAddress'];

		const { toSaveData, data } = this.state;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {};
		let try2Final = {};
		const dataToSendAsDefaults = arrOfValues; //["title","direction","mappostObjectID"];//+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			home: 'Ēka',
			street: 'Iela',
			city: 'Pilsēta',
			parish: 'Pagasts',
			novads: 'Novads',
			postal: 'Pasta indekss',
			direction: 'Piegādes virziens',
			mappostObjectID: 'Mappost objekta ID',
			title: 'Nosaukums'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			arrOfValues,
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		if (this.state.isUsedInStorage && reallyChanged.archivedAddress === true) {
			const confirm = window.confirm('Šī adrese ir piesaistīta noliktavām. Vai tiešām arhivēt?');
			if (confirm == false) {
				return;
			}
		}
		if (finalToSend.mappostObjectID === '') {
			this.doSave(id, finalToSend)
			return;
		}

		//return;
		api.admin.get_mappost_address_data({object:id, mappost:reallyChanged.mappostObjectID}).then(response => {
			if (response.mappostStatus) {

				this.doSave(id, finalToSend)
			}
			else {
				toast.warn(
					`Ir problēmas ar datu saglabāšanu. Paziņojums: Šāda ID nav Mappost datubāzē!`,
					{ autoClose: 4000 }
				);

			}
		});

	};

	onDriverSelect(driver) {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	}

	onDeptSelect = departments => {
		// if (departments != "") {
		const deptForSave = departments.map(dept => dept.value);
		this.setDataToState('departments', deptForSave);
		// }
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	render() {
		const {
			loading,
			title,
			direction,
			directionOptions,
			mappostObjectID,
			changeHistory,
			lastUpdate,
			coordinates,
			home,
			street,
			city,
			parish,
			novads,
			postal,
			canBeDeleted,
			country,
			showModal,
			archivedAddress,
			userFavorites
		} = this.state;
		const { id } = this.props;

		const destinationFav = userFavorites?.destination.includes(parseInt(id));

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						{id == null ? (
							<div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Māja' name='home' />
									<Input
										inputClass='col-sm-8'
										name='home'
										value={home}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Iela' name='street' />
									<Input
										inputClass='col-sm-8'
										name='street'
										value={street}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Pilsēta' name='city' />
									<Input
										inputClass='col-sm-8'
										name='city'
										value={city}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Pagasts' name='parish' />
									<Input
										inputClass='col-sm-8'
										name='parish'
										value={parish}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Novads' name='novads' />
									<Input
										inputClass='col-sm-8'
										name='novads'
										value={novads}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Pasta indekss'
										name='postal'
									/>
									<Input
										inputClass='col-sm-8'
										name='postal'
										value={postal}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Valsts (norādīt, ja nav Latvija)'
										name='country'
									/>
									<Input
										inputClass='col-sm-8'
										name='country'
										value={country}
										handleChange={this.handleChange}
									/>
								</div>
							</div>
						) : (
							<div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Adrese' name='title' />
									<Input
										inputClass='col-sm-8'
										name='title'
										value={title}
										handleChange={this.handleChange}
									/>
								</div>
							</div>
						)}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Piegādes virziens'
								name='direction'
							/>
							<Select
								selectClass='col-sm-8'
								name='direction'
								handleChange={this.handleChange}
								value={direction}
								placeholder='Izvēlies vērtību'
								options={directionOptions}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Mappost objekta ID'
								name='mappostObjectID'
							/>
							<div>
								<Input
									inputClass='col-sm-4'
									name='mappostObjectID'
									value={mappostObjectID}
									handleChange={this.handleChange}
								/>
								{mappostObjectID && mappostObjectID.length > 0 && (
									<div className='col-sm-5'>
										<button
											type='button'
											disabled={false}
											className='btn btn-primary'
											onClick={this.handleToggleModal}>
											Pārbaudīt
										</button>
									</div>
								)}
							</div>
						</div>
					{id !== null &&
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Adrese arhivēta'
								name='archivedAddress'
							/>
							<div className='col-sm-8'>
								<Switch
									name='archivedAddress'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={
										archivedAddress ? archivedAddress : false
									}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'archivedAddress')
									}
								/>
							</div>
						</div>
					}
					{id !== null &&
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Noliktava pievienota favorītiem'
								name='destinationFav'
							/>
							<div className='col-sm-8'>
								<Switch
									name='destinationFav'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									value={destinationFav}
									onChange={(el, state) =>
										this.handleFavorite('destination', id)
									}
								/>
								&nbsp;
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-right'>
											Mainot pogas stāvokli, adrese tiks pievienota/noņemta favorītiem
											un tas automātiski tiks saglabāts datubāzē.
										</Tooltip>
									}>
									<i className='fas fa-question-circle'></i>
								</OverlayTrigger>
							</div>
						</div>
					}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Izmaiņu vēsture'
								name='changeHistory'
							/>
							<Textarea
								disabled={true}
								inputClass='col-sm-8'
								name='changeHistory'
								value={changeHistory}
								handleChange={this.handleChange}
							/>
							{/*
              <div className="col-sm-8 historyBox" style={{paddingRight:'unset',paddingLeft:'unset'}} dangerouslySetInnerHTML={{__html: changeHistory}}></div>
            */}
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Pēdējās izmaiņas'
								name='lastUpdate'
							/>
							<Input
								disabled={true}
								inputClass='col-sm-8'
								name='lastUpdate'
								value={lastUpdate}
								handleChange={this.handleChange}
							/>
						</div>
						{coordinates !== null && (
							<div className='col-sm-11'>
								<Suspense fallback={null}>
									<MapObject coordinates={coordinates} />
								</Suspense>
							</div>
						)}
						<div className='clearBoth'></div>

						<div className='btn-group' role='group'>
							{checkPermission('edit', 'addresses') && (
								<button
									type='button'
									disabled={false}
									className='btn btn-primary'
									onClick={this.saveData}>
									Saglabāt!
								</button>
							)}
							{/* checkPermission('delete', 'addresses') && canBeDeleted && (
								<button
									type='button'
									disabled={false}
									className='btn btn-primary'
									onClick={this.deleteAddress}>
									Izdzēst ārā
								</button>
							) */}
						</div>
						<CustomModal
							title='Mappost pārbaude'
							showModal={showModal}
							handleCloseModal={this.handleToggleModal}
							content={
								<MappostCheck
									mode='address'
									object={id}
									mappost={mappostObjectID}
									handleCloseModal={this.handleToggleModal}
								/>
							}
						/>
					</div>
				)}
			</div>
		);
	}
}

AddressEdit.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]).isRequired,
	handleCloseModal: PropTypes.func.isRequired
};

export default AddressEdit;
