import React, { Component } from 'react';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';

import { allFieldsHaveValues, allFieldsHaveValues_new } from 'util';
import api from '@api';
import { Input, Label, Select, Textarea } from 'shared/form';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class Services extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: []
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });

		api.admin.services_get().then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'mappost',
				'horizon',
				'email',
				'sms',
				'db'
			]);

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		this.setState({ reallyChanged });

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let try2Final = {};
		const dataToSendAsDefaults = ['mappost', 'horizon', 'email', 'sms', 'db'];
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey]; //atšķirībā no citām lapām
			}
		}

		const finalToSend = try2Final;

		console.log('to save', JSON.stringify(finalToSend));

		this.setState({ finalToSend });
		const nameMap = {
			mappost: 'Mappost',
			horizon: 'Horizon',
			email: 'E-pasts',
			sms: 'SMS',
			db: 'Datubāze'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['mappost', 'horizon', 'email', 'sms', 'db'],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		//return;

		api.admin.services_save(finalToSend).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				//this.setData();
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	render() {
		const { loading, mappost, horizon, email, sms, db } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<h2>Servisu statuss</h2>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Datubāze' name='db' />
							<div className='col-sm-8'>
								<Switch
									name='db'
									offColor='warning'
									onColor='success'
									onText='Ieslēgts'
									offText='Atslēgts'
									defaultValue={db ? db : false}
									onChange={(el, state) => this.handleSwitch(el, state, 'db')}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Mappost' name='mappost' />
							<div className='col-sm-8'>
								<Switch
									name='mappost'
									offColor='warning'
									onColor='success'
									onText='Ieslēgts'
									offText='Atslēgts'
									defaultValue={mappost ? mappost : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'mappost')
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Horizon' name='horizon' />
							<div className='col-sm-8'>
								<Switch
									name='horizon'
									offColor='warning'
									onColor='success'
									onText='Ieslēgts'
									offText='Atslēgts'
									defaultValue={horizon ? horizon : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'horizon')
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='E-pasts' name='email' />
							<div className='col-sm-8'>
								<Switch
									name='email'
									offColor='warning'
									onColor='success'
									onText='Ieslēgts'
									offText='Atslēgts'
									defaultValue={email ? email : false}
									onChange={(el, state) => this.handleSwitch(el, state, 'email')}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='SMS' name='sms' />
							<div className='col-sm-8'>
								<Switch
									name='sms'
									offColor='warning'
									onColor='success'
									onText='Ieslēgts'
									offText='Atslēgts'
									defaultValue={sms ? sms : false}
									onChange={(el, state) => this.handleSwitch(el, state, 'sms')}
								/>
							</div>
						</div>
						<button
							type='button'
							disabled={false}
							className='btn btn-primary'
							onClick={this.saveData}>
							Saglabāt!
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default Services;
