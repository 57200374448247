import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	return parseFloat(val).toFixed(digits);
};

const columns = [
	{
		title: 'Mēnesis',
		field: 'month'
	},
	{
		title: 'Klients',
		field: 'client',
		//headerFilter: 'input',
		width: 200
	},
	{
		title: 'Nodaļa',
		field: 'department',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Produkts',
		field: 'material',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'No',
		field: 'storage',
		//headerFilter: 'input',
		width: 200
	},
	{
		title: 'Uz',
		field: 'destination',
		//headerFilter: 'input',
		width: 200
	},
	{
		title: 'Pārvadātājs',
		field: 'carrier'
	},
	{
		title: 'Apjoms,<br />kopējais',
		field: 'amount',
		align:"right",
		formatter: cell => amountFormatter(cell, 'amount', 1)
	},
	{
		title: 'Vidējā pasūtītāja<br />cena',
		field: 'ordererPrice',
		align:"right",
		formatter: cell => amountFormatter(cell, 'ordererPrice', 2)
	},
	{
		title: 'Vidējā pārvadātāja<br />cena',
		field: 'transporterPrice',
		align:"right",
		formatter: cell => amountFormatter(cell, 'transporterPrice', 2)
	},
	{
		title: 'Izmaksas',
		field: 'expenses',
		align:"right",
		formatter: cell => amountFormatter(cell, 'expenses', 2)
	}
];

const levelMap = {
	month: ['client', 'department', 'material', 'storage', 'destination'],
	client: ['department', 'material', 'storage', 'destination'],
	department: ['material', 'storage', 'destination'],
	material: ['storage', 'destination']
};

const Table = ({ data, level, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let {client, department, material, storage, destination} = filters;
	client = client.toLowerCase();
	department = department.toLowerCase();
	material = material.toLowerCase();
	storage = storage.toLowerCase();
	destination = destination.toLowerCase();

	const filterObj = {client, department, material, storage, destination};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === null) {
				return true;
			}
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;

	})

	const columnsNotToDisplay = levelMap[level] || [];

	const cols = columns.filter(col => !columnsNotToDisplay.includes(col.field));

	return (
		<Tabulator
			columns={cols}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
