import React, { Component, useEffect } from 'react';
import { Tabs, Tab, Badge } from 'react-bootstrap';
import TableWithPagination from '../table/orderTableWithPagination';
import api from '../../../api';
import '@shared/tabs/tabs.css';
import TabHandler from 'shared/tabs/tabSelect';
import { connect } from 'react-redux';
import { setCurrentTab } from './slice';
import setRespectTabs from 'shared/tabs/setRespectTabs';

const oneTab = (tab, key, currKey, client, isClientActive, additionalData) => {
	const currTime = new Date();
	const thisYear = currTime.getFullYear();

	const numberOfResults = tab.orderCount > 0 ? tab.orderCount : '';
	const whatToOpen = tab.orderCount > 0 ? (
		<TableWithPagination
			dept={tab.id}
			rows={tab.orderCount}
			client={client}
			year={thisYear}
		/>
	) : null;

	return (
		<Tab
			eventKey={key}
			key={tab.id}
			title={
				<span>
					<span>{tab.title}</span>{' '}
					{numberOfResults != '' && <small>({numberOfResults})</small>}{' '}
				</span>
			}>
			{currKey === key && whatToOpen}
		</Tab>
	);
};

let handler = new TabHandler();

const OrderTabs = ({
	setCurrentTab,
	orderData,
	isClientActive,
	clientId,
	currentTabIdx,
	meta
}) => {
	useEffect(() => {
		setRespectTabs();
		const curTab = handler.getSettings('OrderTabs');
		if (curTab > -1) {
			handleSelect(curTab);
		}
	}, []);
	const handleSelect = key => {
		setCurrentTab(key);
		handler.saveSettings('OrderTabs', key);
	};

	if (meta.length < 1) {
		return 'Nav datu';
	}

	return (
		<div>
			<Tabs
				className='tabLayout'
				activeKey={currentTabIdx}
				onSelect={handleSelect}
				id='order-tabs'>
				<div style={{ paddingBottom: 20 }}></div>
				{orderData &&
					orderData.map((tab, key) =>
						oneTab(tab, key, currentTabIdx, clientId, isClientActive)
					)}
			</Tabs>
		</div>
	);
};

const mapDispatchToPropsRes = dispatch => {
	return {
		setCurrentTab: key => {
			dispatch(setCurrentTab({ key }));
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		orderData: state.planningOrderTabs.tabs,
		meta: state.planningOrderTabs.meta,
		isClientActive: state.client.active_client,
		clientId: state.client.id,
		currentTabIdx: state.planningOrderTabs.current_key
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(OrderTabs);
