import moment from 'moment';

const getFilterStartPeriod = () => {
	const currentDate = moment();

	const getFinanceYearStart = year => moment(`${year}-07-01`, "YYYY-MM-DD");

	getFinanceYearStart((new Date()).getFullYear())

	if (currentDate.isAfter(getFinanceYearStart((new Date()).getFullYear()), "day")) {
		return getFinanceYearStart((new Date()).getFullYear()).format('YYYYMMDD');
	}

	return getFinanceYearStart((new Date()).getFullYear()-1).format('YYYYMMDD');
}

export default getFilterStartPeriod;
