import React from 'react';
import BillTable from './table';

export default function() {
	return (
		<div className='bodyWrapper'>
			<BillTable mode='separate' />
		</div>
	);
}
