import api from '../api';

const user = {
	check_token: token => api.get(`user/check_token?token=${token}`),
	check_reset_token: token => api.get(`user/check_reset_token?token=${token}`),
	check_current_mfa: () => api.get(`user/check_mfa`),
	get_mfa_qr: () => api.get(`user/get_qr`)
};

export default user;
