import moment from 'moment';
import 'moment/locale/lv';

const empty = require('is-empty');
const isNumericWrap = require('fast-isnumeric');

export function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	} else {
		return response.json().then(Promise.reject.bind(Promise));
	}
}

const mapper = {
	orders: 'orders',
	client: 'clients',
	izpilde_soferi: 'izpilde',
	izpilde_saraksts: 'tasks',
	planosana: 'planning',
	calendar: 'calendar',
	aizvests: 'aizvests',
	drivertasks: 'drivertasks',
	//deliverylist: 'deliverylist',
	planning: 'planning',
	delivered: 'delivered',
	archive: 'archive',
	invoices: 'bills',
	users: 'users',
	roles: 'roles',
	addresses: 'addresses',
	storage: 'storage',
	materials: 'material',
	material_cats: 'materialcats',
	departments: 'departments',
	transport: 'transport',
	smtp: 'smtp',
	responsible: 'responsible',
	sms: 'sms',
	horizon: 'horizon'
};

export const getCurrPage = () => {
	//todo: vēl produkcijas links
	//10.95.26.235/llis/api

	//console.log('window.location',window.location.hash);

	const currentFullPage = window.location.hash.toString();

	//(window.location).toString().replace('http://latraps.kartes.lv/dev1/app/#/','')
	let currPage = currentFullPage.replace('#/', ''); //(window.location).toString().includes('localhost') ?  (window.location).toString().replace('http://localhost:3000/#/','') : (window.location).toString().replace('http://10.95.26.235/llis/#/','');
	currPage = currPage.replace('admin/', '');

	currPage = currPage.includes('client_regnr') ? 'client' : currPage;
	currPage = currPage.includes('client') ? 'client' : currPage;

	if (currPage in mapper) {
		//console.log('curr page is',mapper[currPage])
		return mapper[currPage];
	}

	return currPage;
};

export function reloadPage() {
	return location.reload(); //true;//response.json();
}

export function parseJSON(response) {
	return response.json();
}

export function encodeParams(params) {
	let encodedParams = [];
	for (const key of Object.keys(params)) {
		if (params[key]) {
			encodedParams.push(`${key}=${encodeURIComponent(params[key])}`);
		}
	}
	return encodedParams.join('&');
}

export const formatNumber = (inputValue, decimalPlacesToReturn = 0) => {
	if (isNumericWrap(inputValue)) {
		return parseFloat(inputValue).toFixed(decimalPlacesToReturn);
	}

	return inputValue;
};

export const isNumeric = val => isNumericWrap(val);

export const allFieldsHaveValues_new = (fields, values, nameMap, strict = false) => {
	for (let i = 0; i < fields.length; i++) {
		const theField = fields[i];
		const theVal = theField in values ? values[theField] : null;

		if ((theVal == '' || theVal == 0) && !strict) {
			continue; //šos case 'is-empty' pieņem kā true; mums šādas vērtības der
		}

		if (empty(theVal)) {
			//vēl var izmēģināt https://www.npmjs.com/package/has-value
			return {
				status: false,
				field: theField,
				name: theField in nameMap ? nameMap[theField] : theField
			};
		}
	}

	return { status: true };
};

export const allFieldsHaveValues = (fields, values) => {
	for (let i = 0; i < fields.length; i++) {
		const theField = fields[i];
		const theVal = theField in values ? values[theField] : null;

		if (theVal == '' || theVal == 0) {
			continue; //šos case 'is-empty' pieņem kā true; mums šādas vērtības der
		}

		if (empty(theVal)) {
			//vēl var izmēģināt https://www.npmjs.com/package/has-value
			return false;
		}
	}

	return true;
};

export const formatFullDate = dateStr => {
	if (!dateStr || dateStr.length < 8) {
		return dateStr || '';
	}
	return `${dateStr.substring(6, 8)}.${dateStr.substring(4, 6)}.${dateStr.substring(
		0,
		4
	)}. plkst. ${dateStr.substring(8, 10)}.${dateStr.substring(10, 12)}.${dateStr.substring(
		12,
		14
	)}`;
};

/*
https://www.npmjs.com/package/react-timeout
https://blog.stvmlbrn.com/2019/02/20/automatically-refreshing-data-in-react.html (p.s. labs blogs)
*/
export const handleDataUpdate = (cb, action = 'updates', miliseconds = 2000000) => {
	if (action == 'updates') {
		//setTimeout(cb, miliseconds);
	} else if (action == 'check') {
		//wip
	} // else if (action == 'clear') {

	//}
};

export const customTitle = (title, icon, color, additionalStyle = '') =>
	`<i class="${icon} fa-lg" style="color:${color}; ${additionalStyle}"></i>`;

export const calculateOffset = (currentPage, rowsInOnePage) => {
	return currentPage == 1 ? 0 : (currentPage - 1) * rowsInOnePage;
};

export const calculatePages = (itemCount, rowsInOnePage) => {
	let pages = itemCount < 1 ? 1 : Math.ceil(itemCount / rowsInOnePage);

	return pages;
};

export const formatDateForDB = inputData => {
	if (inputData === '' || inputData === null || typeof inputData === 'undefined') {
		return null;
	}

	const dateInst = moment(inputData, 'DD.MM.YYYY');

	return moment(dateInst).format('YYYYMMDD');
};
