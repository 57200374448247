import React from 'react';
import Modal from 'react-responsive-modal';
import './custom-styling.css';

const CustomModal = props => {
	return (
		<Modal
			open={props.showModal || false}
			onClose={props.handleCloseModal || (() => '')}
			center
			focusTrapped={false}
			classNames={{
				overlay: 'customOverlay',
				modal: `customModal ${props.modalClass || ''}`
			}}>
			<div className='modal-content'>
				<div className='modal-header'>
					<div
						className='modal-title'
						style={{ lineHeight: '1.02857143', fontSize: '125%' }}>
						{props.title}
					</div>
				</div>
				<div className='modal-body'>{props.content}</div>
				<div className='modal-footer'>
					<button
						type='button'
						className='btn btn-secondary btn-lg'
						style={{
							display: 'block',
							marginLeft: 'auto',
							marginRight: 'auto',
							borderColor: '#e7e7e7'
						}}
						onClick={props.handleCloseModal}>
						Aizvērt
					</button>
				</div>
			</div>
		</Modal>
	);
};

export { CustomModal };
