import React, { Component } from 'react';
import * as formatter from '../../orders/table/formatters';
import { connect } from 'react-redux';
import MyCustomTable from '../../../latraps_table';

const orderTypes = {
	1: 'Izvešana',
	2: 'Piegāde',
};

const formatSalesPrice = (rowData) => {
	return rowData.orderType === 1 ? rowData.salesPrice : '';
}

class Table extends Component {
	openActionsModal = row => {
		this.props.modalOpeningHandler(row, 'actions');
	}
	columns = [
		{
			Header: () => (
				<i title='N.p.k.' className='fas fa-list-ol' style={{ color: '#333334' }}></i>
			),
			accessor: 'npk',
			width: 45
		},
		{
			Header: () => (
				<i
					title='Atvērt darbību sarakstu'
					className='fas fa-edit'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 41,
			align: 'center',
			clickHandler: this.openActionsModal,
			accessor: 'orderId',
			Cell: ({ row }) => (
				<div style={{ paddingLeft: 5 }}>
					<i
						className='fas fa-ellipsis-v'
						style={{ fontSize: '1.6em', textAlign: 'center', color: 'green' }}></i>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Pas. nr.' className='fas fa-edit' style={{ color: '#333334' }}></i>
			),
			width: 80,
			accessor: 'orderNr',
			Cell: ({ row }) => (
				<div>
					<b>#{row.original.orderId} <small>{orderTypes[row.original.orderType]}</small></b>
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Piesaistīto pasūtījumu skaits'
					className='fas fa-tasks'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			columnClass: 'orderedAmount',
			accessor: 'numberOfRelatedOrders',
			Cell: ({ row }) => (
				<div
					onClick={() => this.props.modalOpeningHandler(row.original, 'orders')}>
					{row.original.numberOfRelatedOrders}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Šoferiem atdoto uzdevumu skaits'
					className='fas fa-tasks'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			columnClass: 'orderedAmount',
			accessor: 'numberOfTasks',
			Cell: ({ row }) => (
				<div
					onClick={() => this.props.modalOpeningHandler(row.original, 'tasks')}>
					{row.original.numberOfTasks}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Braucienu skaits' className='fas fa-tasks' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			columnClass: 'deliveredAmount',
			accessor: 'numberOfDeliveries',
			Cell: ({ row }) => (
				<div
					onClick={() => this.props.modalOpeningHandler(row.original, 'vedumi')}>
					{row.original.numberOfDeliveries}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Noliktava' className='fas fa-warehouse' style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'storage',
			Cell: ({ row }) => (
				<div className='shrinkLines'>
					<b>{row.original.storage}</b>
					<br />
					{row.original.storageAddress}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Piegādātājs' className='fas fa-shipping-fast' style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'supplier',
			Cell: ({ row }) => (
				<div className='shrinkLines'>
					<b>{row.original.supplier}</b>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Kuģis' className='fas fa-ship' style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'ship',
			Cell: ({ row }) => (
				<div className='shrinkLines'>
					<b>{row.original.ship}</b>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Materiāls' className='fas fa-clipboard' style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'material',
			Cell: ({ row }) => (
				<div className='shrinkLines'>{formatter.materialFormatter(row.original)}</div>
			)
		},
		{
			Header: () => (
				<i title='Plānotais daudzums' className='fas fa-truck-loading' style={{ color: '#333334', }}></i>
			),
			accessor: 'amount',
			columnClass: 'amount',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{row.original.amount} {row.original.measurementUnit} <br></br>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Iepakojumu skaits' className='fas fa-shopping-bag' style={{ color: '#333334', }}></i>
			),
			accessor: 'packagingCount',
			columnClass: 'packagingCount',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{row.original.packagingCount} <br></br>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Piegādes tolerance (-%)' className='fas fa-balance-scale' style={{ color: '#333334', }}></i>
			),
			accessor: 'toleranceMin',
			columnClass: 'toleranceMin',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{row.original.toleranceMin} {row.original.measurementUnit} <br></br>
					{formatter.toleranceFormatter(row.original.toleranceReachMin)}<br></br>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Piegādes tolerance (+%)' className='fas fa-balance-scale' style={{ color: '#333334', }}></i>
			),
			accessor: 'toleranceMax',
			columnClass: 'toleranceMax',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{row.original.toleranceMax} {row.original.measurementUnit} <br></br>
					{formatter.toleranceFormatter(row.original.toleranceReachMax)}<br></br>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Pārdošanas cena, EUR' className='fas fa-euro-sign' style={{ color: '#333334' }}></i>
			),
			accessor: 'salesPrice',
			Cell: ({ row }) => <div className='centerAligned'>{formatSalesPrice(row.original)}</div>
		},
		{
			Header: () => (
				<i
					title='Plānotās atkraušanas'
					className='fas fa-shipping-fast'
					style={{
						color: '#e8e8e8',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			accessor: 'orderedAmount',
			columnClass: 'orderedAmount',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{formatter.amountFormatter(row.original, 'orderedAmount')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Rezervācijas pasūtījumu daudzuma summa'
					className='fas fa-shipping-fast'
					style={{
						color: '#e8e8e8',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			accessor: 'reservationsAmount',
			columnClass: 'reservationsAmount',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{formatter.amountFormatter(row.original, 'inReservations')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Atlikums, kas jāizved'
					className='fas fa-shipping-fast'
					style={{
						color: '#FFCCCC',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			accessor: 'remainder',
			columnClass: 'remainingAmount',
			Cell: ({ row }) => (
				<div>
					{formatter.amountFormatter(row.original, 'remainder')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Atkrautais/ceļā'
					className='fas fa-shipping-fast'
					style={{
						color: '#FFCC66',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			accessor: 'inTasks',
			columnClass: 'amountInTasks',
			Cell: ({ row }) => (
				<div>
					{formatter.amountFormatter(row.original, 'inTasks')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Piegādātais apjoms'
					className='fas fa-shipping-fast'
					style={{
						color: '#CCFF99',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			columnClass: 'deliveredAmount',
			accessor: 'deliveredByFact',
			Cell: ({ row }) => (
				<div>
					{formatter.amountFormatter(row.original, 'deliveredByFact')}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Atkraušana no' className='fas fa-clock' style={{ color: '#333334' }}></i>
			),
			accessor: 'loading_started_at',
			Cell: ({ row }) => <div className='centerAligned'>{row.original.loading_started_at}</div>
		},
		{
			Header: () => (
				<i title='Atkraušana līdz' className='fas fa-clock' style={{ color: '#333334' }}></i>
			),
			accessor: 'loading_ended_at',
			Cell: ({ row }) => <div className='centerAligned'>{row.original.loading_ended_at}</div>
		},
		{
			Header: () => (
				<i title='Piezīmes' className='fas fa-sticky-note' style={{ color: '#333334'}}></i>
			),
			accessor: 'notes',
			Cell: ({ row }) => <div>{row.original.notes}</div>
		}
	];

	render() {
		const { orders, loading } = this.props;

		if (loading) {
			return '...';
		}

		if (!orders || orders.length == 0) {
			return 'Nav datu';
		}

		return (
			<div className='tableWrapper'>
				<MyCustomTable orders={orders} columns={this.columns} />
			</div>
		);
	}
}

const mapStateToPropsRes = state => {
	return {
		orders: state.planningOrders.orders,
		loading: state.planningOrders.loading
	};
};

export default connect(mapStateToPropsRes)(Table);
