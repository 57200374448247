import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import DepartmentEdit from './forms/department';
import { checkPermission } from 'permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import { customTitle } from 'util';
import NoData from 'shared/noDataNotification';

const actionsButtonFormatter = () => actionButton;

const activityFormatter = cell => {
	const data = cell.getRow().getData();

	if (data.active == false) {
		return '<span style="color: red;">Neaktīva</span>';
	}

	return 'Aktīva';
};

class DepartmentsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			currTask: null,
			error: false,
			title: '',
			id: null
		};
	}

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleOpenModal = (openType, cell) => {
		const id = openType == 'new' ? null : cell.getRow().getData().id;

		this.setState({ showModal: true, id });
	};

	setupData() {
		this.setState({ loading: true, error: false });

		const { title } = this.state;

		api.search.departments(title).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	columns = [
		{
			title: 'ID',
			field: 'id',
			headerTooltip: 'Nodaļas ID',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334')
		},
		{
			title: 'Nosaukums',
			field: 'title',
			width: 250,
			headerTooltip: 'Nosaukums',
			titleFormatter: column => customTitle(column, 'fas fa-clipboard', '#333334')
		},
		{
			title: 'Statuss',
			field: 'active',
			formatter: cell => activityFormatter(cell),
			headerTooltip: 'Statuss',
			titleFormatter: column => customTitle(column, 'fas fa-signal', '#333334')
		},
		{
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		}
	];

	render() {
		const { data, error, loading, showModal, id } = this.state;

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{checkPermission('add', 'departments') && (
							<div className='rightFloat'>
								<button
									type='button'
									className='btn btn-primary'
									onClick={() => this.handleOpenModal('new')}>
									Pievienot jaunu nodaļu
								</button>
							</div>
						)}
						<div className='clearBoth'></div>
						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<NoData />
								) : (
									<div className='tableWrapper'>
										<Tabulator
											columns={this.columns}
											options={{ pagination: false }}
											data={data}
										/>
									</div>
								)}
								<CustomModal
									title={
										id == null
											? 'Nodaļas pievienošana'
											: 'Nodaļas datu labošana'
									}
									showModal={showModal}
									handleCloseModal={this.handleToggleModal}
									content={
										<DepartmentEdit
											id={id}
											handleCloseModal={this.handleToggleModal}
										/>
									}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default DepartmentsTable;
