import api from '../api';

const search = {
	//totaly - search string; clientId - vecajā versijā reģ nr. - jāpāriet uz ID//
	storage: ({ clientId, totaly, clientSearch, includeArchived }) =>
		api.get(
			`storage/search?clientId=${clientId}&totaly=${totaly}&clientSearch=${clientSearch}&include_archived=${includeArchived}`
		),

	address: ({ query, clientSearch, includeArchived }) =>
		api.get(`address/search?q=${query}&clientSearch=${clientSearch}&include_archived=${includeArchived}`),

	address_admin: query => api.get(`address/search?q=${query}`),

	storage2: ({ title, address, includeArchived }) => api.get(`storage/search?address=${address}&storage=${title}&include_archived=${includeArchived}`),

	storage_filter: ({ query, includeArchived }) => api.get(`storage/search?storage=${query}&include_archived=${includeArchived}`),

	material: title => api.get(`materials/search?q=${title}`),

	payer: title => api.get(`client/payers?q=${title}`),

	material_cats: title => api.get(`materials/search_categories?q=${title}`),

	departmentsFilter: title => api.get(`department/search?q=${title}`),

	departments: title => api.get(`department/list?q=${title}`),

	atbildigie: name => api.get(`responsible/list?name=${name}`),

	users: (name = '') => api.get(`users/list?filter=${name}`),

	payerDiffPayer: (name = '') => api.get(`client/different_payer/clients?client=${name}`),

	payerAccount: (name = '') => api.get(`client/different_payer/accounts?client=${name}`),

	transport: ({ client, driver, vilcejs, clientID }) =>
		api.get(
			`transport/search?client=${client}&driver=${driver}&vilcejs=${vilcejs}&clientID=${clientID}`
		),

	transport_simple: query => api.get(`transport/simple_search?q=${query}`),

	drivers: ({ query, mappost }) => api.get(`drivers/search?q=${query}&mappost=${mappost}`), //mappost varētu nepadod, bet ar defaulto vērtību true

	drivers_filter: query => api.get(`drivers/search?q=${query}`),

	drivers_simple: ({ query, clientID }) => api.get(`drivers/simple_search?q=${query}&clientID=${clientID}`),

	car_filter: query => api.get(`transport/search_car?q=${query}`),

	carriers: query => api.get(`carriers/search?q=${query}`),

	pvz: query => api.get(`invoice/search_pvz?q=${query}`),

	order: query => api.get(`order/search_by_nr?q=${query}`),

	orderer: query => api.get(`orderer/search?q=${query}`),

	ship: query => api.get(`ships/search?q=${query}`),

	suppliers: query => api.get(`client/search_suppliers?q=${query}`),
};

export default search;
