import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from 'shared/form';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import { toast } from 'react-toastify';

import { allFieldsHaveValues_new, reloadPage } from 'util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';

class OtherBillPayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			toSaveData: {},
			data: { editing: false, data: {} },
			options: { payer: [], account: [] },
			payer: {},
			account: {}
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { id } = this.props;

		api.bills.diffPayerInfo(id).then(response => {
			const payerData = response.data;
			let payer = {};
			let account = {};

			if (Object.keys(payerData).length > 0) {
				payer = {
					name: payerData.name,
					linkToAcc: payerData.linkToAcc,
					linkToAcc2: payerData.linkToAcc2
				};
				account = payerData.pk_rekins;
			} else {
				payer = {
					name: '',
					linkToAcc: '',
					linkToAcc2: ''
				};
				account = '';
			}
			this.setState({ loading: false, error: false, data: response, payer, account }, () =>
				this._handleSearchMeta('account', payer.linkToAcc, api.search.payerAccount)
			);

			//
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	saveData = e => {
		e.preventDefault();

		/* var selectedClient = $('#payerName').find(":selected").attr("value");
		var selectedClientId2 = $('#payerName').find(":selected").attr("data-id");
        var selectedClientBankAcc = $('#payerBankAcc').find(":selected").attr("value");
        var invoiceId = $(this).attr("data-billid");


        if(validateInputs([selectedClient, selectedClientBankAcc, invoiceId]))
        {
			postData("../code/GetClients.php", {invoiceId: invoiceId, clientId: selectedClient, clientId2: selectedClientId2, bankAccId: selectedClientBankAcc, req: "insert"});
        } */

		const { toSaveData, data } = this.state;
		const { id: invoiceId } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		/* for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
        } */

		if ('payer' in toSaveData && 'linkToAcc' in toSaveData.payer) {
			var selectedClient = toSaveData.payer.linkToAcc;
		} else {
			var selectedClient = data.data.linkToAcc;
		}
		if ('payer' in toSaveData && 'linkToAcc2' in toSaveData.payer) {
			var selectedClientId2 = toSaveData.payer.linkToAcc2;
		} else {
			var selectedClientId2 = data.data.linkToAcc2;
		}
		if ('account' in toSaveData) {
			var selectedClientBankAcc = toSaveData.account;
		} else {
			var selectedClientBankAcc = data.data.pk_rekins;
		}

		const finalToSend = {
			invoiceId: invoiceId,
			clientId: selectedClient,
			clientId2: selectedClientId2,
			bankAccId: selectedClientBankAcc
		};

		if (
			typeof finalToSend.clientId == 'undefined' ||
			typeof finalToSend.clientId2 == 'undefined'
		) {
			toast.warn(`Nav aizpildīts jaunā maksātāja lauks`, {
				autoClose: 7500
			});
			return;
		}

		if (typeof finalToSend.bankAccId == 'undefined' || parseInt(finalToSend.bankAccId) < 1) {
			toast.warn(`Nav aizpildīts bankas konta lauks`, {
				autoClose: 7500
			});
			return;
		}

		console.log('to save', JSON.stringify(finalToSend));

		//return;

		api.bills.diffPayerSave(finalToSend).then(response => {
			if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				//setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	remove = () => {
		const { id: invoiceId } = this.props;

		api.bills.diffPayerRemove({ invoiceId }).then(response => {
			if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				//setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	onTypeAheadSelect = (result, whatWasSearched) => {
		console.log(result, whatWasSearched);
		if (typeof result !== 'undefined') {
			this.setDataToState(whatWasSearched, result);
			this._handleSearchMeta('account', result.linkToAcc, api.search.payerAccount);
		} else {
			this.setDataToState(whatWasSearched, []);
		}
	};

	getDefaultSelected(value) {
		if (value == '' || typeof value == 'undefined') {
			return [];
		}
		return [value];
	}

	_handleSearchMeta(field, query, searchCB) {
		console.log(field, query, searchCB);

		this.setState({ isLoading: true });

		searchCB(query).then(response => {
			this.setState({
				options: { ...this.state.options, [field]: response },
				isLoading: false
			});
		});
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	};

	onAccSelect = val => {
		if (typeof val !== 'undefined' && Object.entries(val).length > 0) {
			this.setDataToState('acount', val.value);
		}
	};

	formatPayer(option, idx, field) {
		return <span>{option.name || ''}</span>;
	}

	render() {
		const {
			loading,
			data: { data, editing },
			payer,
			account
		} = this.state;

		const accOptions = this.state.options.account;
		const payerOptions = this.state.options.payer;

		const { options, ...rest } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Maksātājs' name='payer' />
							<div className='col-sm-8'>
								<AsyncTypeahead
									isLoading={this.state.isLoading}
									options={payerOptions}
									onChange={selected =>
										this.onTypeAheadSelect(selected[0], 'payer')
									}
									filterBy={(option, props) => {
										return option;
									}}
									minLength={0}
									selected={this.getDefaultSelected(payer)}
									labelKey='name'
									id='payerSearch'
									//filterBy={['name']}
									clearButton={true}
									onSearch={query =>
										this._handleSearchMeta(
											'payer',
											query,
											api.search.payerDiffPayer
										)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Klienta konts' name='account' />

							<Select
								selectClass='col-sm-8'
								handleChange={this.handleChange}
								name='account'
								value={account}
								placeholder='Izvēlies vērtību'
								options={accOptions}
							/>
							<div className='col-sm-8'>
								{/* <Typeahead
                                    clearButton
									id='accountSearch'
									defaultSelected={
										accOptions && accOptions.length > 0
											? accOptions.filter(opt =>
                                                this.state.account.value == opt.value
											  )
											: []
									}
									labelKey="label"
									onChange={selected => this.onAccSelect(selected[0])}
									options={
										accOptions && accOptions.length > 0
											? accOptions
											: []
									}
									placeholder='Izvēlies konta numuru'
                                /> */}
								{/* <AsyncTypeahead
									isLoading={this.state.isLoading}
									options={this.state.options.account}
									onChange={selected =>
										this.onTypeAheadSelect(selected[0], 'account')
									}
									filterBy={(option, props) => {
										return option;
									}}
									minLength={0}
									selected={this.getDefaultSelected(account)}
									labelKey="label"
									id='accountSearch'
									//filterBy={['name']}
									clearButton={true}
									onSearch={query =>
										this._handleSearchMeta('account', query, api.search.payerAccount)
									}
									renderMenuItemChildren={(option, idx) =>
										this.formatPayer(option, idx, 'account')
									}
								/> */}
							</div>
						</div>

						<div className='btn-group' role='group'>
							{checkPermission('edit', 'bills') && (
								<button
									type='button'
									disabled={false}
									className='btn btn-primary'
									onClick={this.saveData}>
									Saglabāt!
								</button>
							)}
							{editing && checkPermission('delete', 'bills') && (
								<button
									type='button'
									disabled={false}
									className='btn btn-danger'
									onClick={this.remove}>
									Noņemt maksātāju
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default OtherBillPayer;
