import api from '../api';

const admin = {
	new_url: ({ url }) => api.post(`system/save_url`, { url }),

	user_get: id => api.get(`admin/user/get?user=${id}`),

	user_save: ({ id, data }) => api.post(`admin/user/save`, { id, data }),

	user_create: data => api.post(`admin/user/save`, { data }),

	address_get: id => api.get(`address/data_new?id=${id}`),

	address_save: ({ id, data }) => api.post(`address/edit`, { id, data }),

	address_new: data => api.post(`address/edit`, { data }),

	material_list: title => api.get(`materials/list?q=${title}`),

	system_info: () => api.get(`system/info`),
	db_status: () => api.get(`system/db_status`),

	mappost_adr: id => api.get(`address/mappost_report?uid=${id}`),
	mappost_transp: id => api.get(`transport/mappost_report?uid=${id}`),

	mat_get: id => api.get(`materials/new_data?id=${id}`),

	mat_save: ({ id, data }) => api.post(`materials/edit`, { id, data }),

	user_send_set_token: id => api.post(`user/resend_set_token`, { id }),

	mat_create: data => api.post(`materials/edit`, { data }),

	mat_cat_get: id => api.get(`materials/new_category_data?id=${id}`),

	mat_cat_save: ({ id, data }) => api.post(`materials/edit_category`, { id, data }),

	mat_cat_create: data => api.post(`materials/edit_category`, { data }),

	get_email_templates: code => api.get(`email_templates?code=${code}`),

	get_specific_template: id => api.get(`email_templates/${id}`),

	email_template_save: ({ id, data }) => api.post(`email_templates`, { id, data }),

	dept_get: id => api.get(`department/data_new?id=${id}`),

	dept_save: ({ id, data }) => api.post(`department/edit`, { id, data }),

	dept_create: data => api.post(`department/edit`, { data }),

	responsible_get: ({ phone, name }) => api.get(`responsible/info?phone=${phone}&name=${name}`),

	responsible_save: data => api.post(`responsible/edit`, { data }),

	transport_get: id => api.get(`transport/data_new?id=${id}`),

	transport_save: ({ id, data }) => api.post(`transport/edit`, { id, data }),

	transport_create: data => api.post(`transport/edit`, { data }),

	driver_create: data => api.post(`drivers/save`, { data }),

	carrier_create: data => api.post(`transport/save_carrier`, { data }),

	ship_save: ({ id, data }) => api.post(`ships/save`, { id, data }),

	ship_get: id => api.get(`ships/edit?id=${id}`),

	change_password: ({ old, newP }) => api.post(`admin/user/change_password`, { old, newP }),

	storage_get: id => api.get(`storage/data_new?id=${id}`),

	storage_save: ({ id, data }) => api.post(`storage/edit`, { id, data }),

	storage_create: data => api.post(`storage/edit`, { data }),

	smtp_get: () => api.get(`smtp/get_data`),

	smtp_save: data => api.post(`smtp/save`, { data }),

	user_get_smtp: user => api.get(`smtp/user/get?user=${user}`),

	get_mappost_address_data: ({ mappost, object }) =>
		api.get(`mappost/objectData?mappost=${mappost}&id=${object}`),

	get_mappost_user_data: mappost => api.get(`mappost/check_driver?mappost=${mappost}`),

	user_save_smtp: ({ id, data }) => api.post(`smtp/user/save`, { id, data }),

	user_delete_smtp: id => api.post(`smtp/user/delete`, { id }),

	sms_get: () => api.get(`sms/get`),

	sms_save: data => api.post(`sms/save`, { data }),

	horizon_sync: () => api.post(`horizon/sync`),

	directions_get: () => api.get(`directions/list`),

	directions_save: data => api.post(`directions/edit`, { data }),

	services_get: () => api.get(`admin/services/get`),

	services_save: data => api.post(`admin/services/save`, { data }),

	send_error: data => api.post(`send_error`, { data }),

	monitoring_users: () => api.get(`monitoring/users`),

	monitoring_actions: user => api.get(`monitoring/actions?user=${user}`),

	activate_user: data => api.post(`user/activate`, { data }),
	reset_password: data => api.post(`user/reset_password`, { data }),

	send_email_for_reset_password: data => api.post(`user/forgot_password`, { data }),

	history: ({ mode, ID }) => api.get(`system/history_item?table=${mode}&id=${ID}`),

	notifications: () => api.get('notifications/user'),

	sendPriceListEmail: ({template}) => api.post('transportation_prices/send_email', {template}),

	//address_create: (data) => api.post(`admin/user/save`,{data}),
};

export default admin;
