import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import { actionButton } from 'shared/icons';
import './style.css';
import { CustomModal } from 'shared/modal';
import TransportEdit from './forms/transport';
import { checkPermission } from 'permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import { customTitle } from 'util';
import NoData from 'shared/noDataNotification';

import FieldForm from './filterForm';
import { MILISECONDS_BEFORE_SEARCH, apiEndpoint } from '../../config';

const actionsButtonFormatter = () => actionButton;

const mappostFormatter = cell => {
	const data = cell.getRow().getData();
	if (data.mappostUserId == '' || data.mappostUserId == null) {
		return '';
	}

	return `${data.mappostUserId}`;
};

const activityFormatter = cell => {
	const data = cell.getRow().getData();

	if (data.active == false) {
		return '<span style="color:#ff0f0f;">Neaktīvs</span>';
	}

	return '<span style="color:#3a9c3a">Aktīvs</span>';
};

const isClientFormatter = cell => {
	const data = cell.getRow().getData();

	if (data.inClientList == null) {
		return '<span style="color:#ff0f0f;">Nē</span>';
	}
	return '<span style="color:#3a9c3a">Jā</span>';
}

const haveManipulator = cell => {
	return cell.getRow().getData().manipulator ? '<span style="color:#3a9c3a">Jā</span>' : '<span style="color:#ff0f0f;">Nē</span>';
}

class TransportTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			currTask: null,
			error: false,
			client: '',
			driver: '',
			vilcejs: ''
		};
	}
	timeout = null;

	setupData() {
		this.setState({ loading: true, error: false });

		const { client, driver, vilcejs } = this.state;
		let { clientID } = this.props;
		if (typeof clientID == 'undefined') {
			clientID = 0;
		}

		const { mode, uid } = this.props;

		if (mode == 'mappost') {
			api.admin.mappost_transp(uid).then(response => {
				this.setState({ loading: false, error: false, data: response });
			});
			return;
		}

		api.search.transport({ client, driver, vilcejs, clientID }).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	//{"startpId":"750","active":false,"mappostUserId":null,"clientRegNr":"40003620080","client":"RITLOGISTIKA SIA","vilcejs":"JA8860 \/ N3026","driver":"AIGARS PAB\u0112RZS","driverPhone":"","piekabe":"","notes":""},
	columns = [
		{
			name: 'client',
			title: 'Klients',
			field: 'client',
			headerTooltip: 'Klients',
			titleFormatter: column => customTitle(column, 'fas fa-building', '#333334')
		},
		{
			name: 'driver',
			title: 'Šoferis',
			field: 'driver',
			headerTooltip: 'Šoferis',
			titleFormatter: column => customTitle(column, 'fas fa-user', '#333334')
		},
		{
			name: 'actions',
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		},
		{
			name: 'phone',
			title: 'Telefons',
			field: 'driverPhone',
			headerTooltip: 'Šofera tālrunis',
			titleFormatter: column => customTitle(column, 'fas fa-phone', '#333334')
		},
		{
			name: 'vilcejs',
			title: 'Vilcējs',
			field: 'vilcejs',
			headerTooltip: 'Vilcēja numurs',
			titleFormatter: column => customTitle(column, 'fas fa-truck-moving', '#333334')
		},
		{
			name: 'piekabe',
			title: 'Piekabe',
			field: 'piekabe',
			headerTooltip: 'Piekabes numurs',
			titleFormatter: column => customTitle(column, 'fas fa-truck-moving', '#333334')
		},
		{
			name: 'manipulator',
			title: 'Manipulators',
			field: 'manipulator',
			formatter: cell => haveManipulator(cell),
			headerTooltip: 'Manipulators',
			titleFormatter: column => customTitle(column, 'fas fa-weight-hanging', '#333334')
		},
		{
			name: 'notes',
			title: 'Piezīmes',
			field: 'notes',
			headerTooltip: 'Piezīmes',
			titleFormatter: column => customTitle(column, 'fas fa-sticky-note', '#333334')
		},
		{
			name: 'mappost',
			title: 'Mappost ID',
			cssClass: 'rightAligned',
			field: 'notes',
			formatter: cell => mappostFormatter(cell),
			headerTooltip: 'Šofera Mappost ID',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334')
		},
		{
			name: 'status',
			title: 'Statuss',
			field: 'active',
			formatter: cell => activityFormatter(cell),
			headerTooltip: 'Statuss',
			titleFormatter: column => customTitle(column, 'fas fa-signal', '#333334')
		},
		{
			name: 'inClientList',
			title: 'Klientu sarakstā',
			field: 'inClientList',
			formatter: cell => isClientFormatter(cell),
			headerTooltip: 'Klientu sarakstā',
			titleFormatter: column => customTitle(column, 'fas fa-list', '#333334')
		}
	];

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ [whatToSearch]: e.target.value }, () => {
			this.timeout = setTimeout(() => {
				_self.setupData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleOpenModal(openType, cell) {
		const id = openType == 'new' ? null : cell.getRow().getData().startpId;

		this.setState({ showModal: true, id });
	}

	exportData = () => {
		const { client, driver, vilcejs } = this.state;
		const url = `${apiEndpoint}transport/search?client=${client}&driver=${driver}&vilcejs=${vilcejs}&export`;
		window.open(url, '_blank');
	};

	render() {
		const { data, error, loading, showModal, id } = this.state;
		const { clientView } = this.props;

		const columns = clientView
			? this.columns.filter(column => column.name !== 'client')
			: this.columns;

		const filterItems = [
			{
				visible: !(clientView == true),
				title: 'Klients',
				inputID: 'client',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'client'
			},
			{
				visible: true,
				title: 'Šoferis',
				inputID: 'driver',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'driver'
			},
			{
				visible: true,
				title: 'Vilcējs',
				inputID: 'vilcejs',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'vilcejs'
			}
		];

		if (error) {
			return (
				<div className='bodyWrapper'>
					<ErrorOnLoading />
				</div>
			)
		}

		return (
			<div className='bodyWrapper'>
				<div>
					<div className='rightFloat'>
						{(clientView || checkPermission('add', 'transport')) &&
							<button
								type='button'
								className='btn btn-primary'
								onClick={() => this.handleOpenModal('new')}>
								Pievienot jaunu transportlīdzekli
							</button>
						}
					</div>

					<FieldForm items={filterItems} exportData={this.exportData} />

					<div className='clearBoth'></div>
					<div style={{ padding: 5 }} />

					{loading ? (
						<Loading />
					) : (
						<div>
							{data.length < 1 ? (
								<NoData />
							) : (
								<div className='tableWrapper'>
									<Tabulator
										columns={columns}
										options={{ pagination: false }}
										data={data}
									/>
								</div>
							)}
							<CustomModal
								title={
									id == null
										? 'Transporta pievienošana'
										: 'Transporta datu labošana'
								}
								showModal={showModal}
								handleCloseModal={this.handleToggleModal}
								content={
									<TransportEdit
										id={id}
										handleCloseModal={this.handleToggleModal}
									/>
								}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default TransportTable;
