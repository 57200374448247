import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import MaterialEdit from './forms/material';
import { checkPermission } from 'permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import { Collapse } from 'react-collapse';
import FieldForm from './filterForm';

// @ts-ignore
import styles from './Material.module.css';
import MaterialCategoriesEdit from './forms/mat_categories';
import { customTitle } from 'util';
import NoData from 'shared/noDataNotification';
import { MILISECONDS_BEFORE_SEARCH } from '../../config';

const actionsButtonFormatter = () => actionButton;

class MaterialTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			error: false,
			id: null,
			title: '',
			collapsed: []
		};
	}

	timeout = null;

	handleOpenActions(cell) {
		const { id } = cell._cell.row.data;
	}

	setupData() {
		this.setState({ loading: true, error: false });

		const { title } = this.state;

		api.admin.material_list(title).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	columns = [
		{
			title: 'ID',
			field: 'id',
			headerTooltip: 'Materiāla ID',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334')
		},
		{
			title: 'Nosaukums',
			field: 'title',
			width: 250,
			headerTooltip: 'Nosaukums',
			titleFormatter: column => customTitle(column, 'fas fa-clipboard', '#333334')
		},
		{
			title: 'Transporta cena',
			field: 'price',
			width: 50,
			headerTooltip: 'Transporta cena par km',
			titleFormatter: column => customTitle(column, 'fas fa-euro-sign', '#333334')
		},
		{
			title: 'Arhivēts',
			field: 'archived',
			headerTooltip: 'Arhivēts',
			titleFormatter: column => customTitle(column, 'fas fa-file-archive', '#333334')
		},
		//{ title: 'Kategorija', field: 'category' },
		{
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) =>
				this.handleOpenModal2('edit', 'materials', cell.getRow().getData().id),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		}
	];

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ [whatToSearch]: e.target.value }, () => {
			this.timeout = setTimeout(() => {
				_self.setupData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handleCloseModal = () => {
		this.setState({ showModal: '' });
	};

	handleOpenModal2 = (openType, whatToOpen, idPassed) => {
		const id = openType == 'new' ? null : idPassed;

		this.setState({ showModal: whatToOpen, id });
	};

	handleOpenModal = (openType, whatToOpen, idPassed) => event => {
		event.stopPropagation();
		const id = openType == 'new' ? null : idPassed;

		this.setState({ showModal: whatToOpen, id });
	};

	handleCollapse = id => event => {
		const currState = this.state.collapsed;
		let newState = this.state.collapsed;
		if (currState.includes(id)) {
			newState = newState.filter(item => item !== id);
		} else {
			newState = [...newState, id];
		}

		this.setState({ collapsed: newState });
	};

	hideAll = () => {
		const { collapsed, data } = this.state;

		let allKeys = [];

		if (collapsed.length > 0) {
			allKeys = [];
		} else {
			for (let i = 0; i < data.length; i++) {
				allKeys.push(i);
			}
		}

		this.setState({ collapsed: allKeys });
	};

	render() {
		const { data, error, loading, showModal, id, collapsed } = this.state;

		const itemPerPage = 20;

		const filterItems = [
			{
				visible: true,
				title: 'Materiāls',
				inputID: 'material_input',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'title'
			}
		];

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						<div className='rightFloat'>
							<div className='btn-group' role='group'>
								<button
									type='button'
									className='btn btn-secondary'
									onClick={this.hideAll}>
									Mainīt kategoriju redzamību
								</button>
								{checkPermission('add', 'materials') && (
									<button
										type='button'
										className='btn btn-primary'
										onClick={this.handleOpenModal('new', 'materials')}>
										Pievienot jaunu materiālu
									</button>
								)}
								{checkPermission('add', 'materialcats') && (
									<button
										type='button'
										className='btn btn-primary'
										onClick={this.handleOpenModal('new', 'category')}>
										Pievienot jaunu kategoriju
									</button>
								)}
							</div>
						</div>

						<FieldForm items={filterItems} />
						<div className='clearBoth' style={{ padding: 10 }} />

						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<div>Nekas netika atrasts</div>
								) : (
									<div>
										{data.map((entry, key) => {
											const { id, title, price, materials } = entry;

											const isOpen = !collapsed.includes(key);
											const toggleIcon = isOpen ? (
												<i className='fas fa-chevron-up' />
											) : (
												<i className='fas fa-chevron-down' />
											);

											const tableOptions =
												materials.length > itemPerPage
													? { paginationSize: itemPerPage }
													: { pagination: false }; //{pagination:'off'};

											return (
												<div key={key}>
													<div
														className={styles.box}
														style={{ cursor: 'pointer' }}
														onClick={this.handleCollapse(key)}>
														<span>{toggleIcon}</span>{' '}
														<span className={styles.title}>
															{title}
														</span>{' '}
														{price > 0 && <><span>
															(transporta cena par km: {price})
														</span>{' '}</>}
														<span
															style={{ cursor: 'pointer' }}
															onClick={this.handleOpenModal(
																'edit',
																'category',
																id
															)}>
															<i className='fas fa-edit' />
														</span>
													</div>
													<Collapse isOpened={isOpen}>
														<div className={styles.table}>
															{materials.length > 0 ? (
																<div className='tableWrapper'>
																	<Tabulator
																		columns={this.columns}
																		options={tableOptions}
																		data={materials}
																	/>
																</div>
															) : (
																<div className={styles.empty}>
																	<NoData />
																</div>
															)}
														</div>
													</Collapse>
												</div>
											);
										})}
									</div>
								)}
								<CustomModal
									title={
										id == null
											? 'Materiāla pievienošana'
											: 'Materiāla datu labošana'
									}
									showModal={showModal == 'materials'}
									handleCloseModal={this.handleCloseModal}
									content={
										<MaterialEdit
											id={id}
											handleCloseModal={this.handleCloseModal}
										/>
									}
								/>
								<CustomModal
									title={
										id == null
											? 'Materiāla kategorijas pievienošana'
											: 'Materiāla kategorijas datu labošana'
									}
									showModal={showModal == 'category'}
									handleCloseModal={this.handleCloseModal}
									content={
										<MaterialCategoriesEdit
											id={id}
											handleCloseModal={this.handleCloseModal}
										/>
									}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default MaterialTable;
