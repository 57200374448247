import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import NewTasks from './newTasks';

class NewTaskModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			orderNr: '',
			data: {},
			searching: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null
		};
	}

	componentDidMount() {
		const { orderId, orderNr } = this.props;

		this.setState({ orderId, orderNr }, () => this.setup());
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId) {
			const { orderId, orderNr } = this.props;

			this.setState({ orderId, orderNr }, () => this.setup());
		}
	}

	setup() {
		/*
      šis škiet nav vajadzīgs?
      
      const {orderId} = this.state;



      if (orderId == null) {//šis izpildās, kad aizver modal logu
        this.setState({ data: {} });
        return;
      }
      //this.setState({ searching: true, error: false });
        
      api.deliveries.deliveryOrder(orderId)
          .then((response) => {
              this.setState({ searching: false, error: false, data: response });
          }
      );
      */
	}

	onSuccSave = () => {
		this.props.onSuccSave();
	};

	render() {
		const { data, searching, orderNr } = this.state;
		const { orderId } = this.props;

		return (
			<div>
				<CustomModal
					title='Vienkārša uzdevuma izveide'
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						<NewTasks
							orderId={orderId}
							onSuccSave={this.onSuccSave}
							handleCloseModal={this.props.handleCloseModal}
						/>
					}
				/>
			</div>
		);
	}
}
//

export default NewTaskModal;
