import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../../shared/form';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import Datetime from 'react-datetime';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';

import { allFieldsHaveValues, formatFullDate, allFieldsHaveValues_new, reloadPage } from 'util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import MappostCheck from './mappost';
import { CustomModal } from 'shared/modal';

const resultStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' };

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class TransportEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: [],
			searchResults: [],
			isSearchModalOpen: false,
			isMainModalOpen: false,
		};
	}

	toggleSearchModal = () => {
		this.setState(prevState => ({ isSearchModalOpen: !prevState.isSearchModalOpen }));
	};

	toggleMainModal = () => {
		this.setState(prevState => ({ isMainModalOpen: !prevState.isMainModalOpen }));
	};

	setData = () => {
		this.setState({ loading: true, error: false });
		const { id } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		//{
		api.admin.transport_get(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'carrierID',
				'driverID',
				'vilcejs',
				'piekabe',
				'manipulator',
				'notes',
				'driver',
				'phone',
				'mappostUserID',
				'isActive',
				'carrierOptions'
			]);

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {}; /*{
      'addressFrom':data.storage.title,
      'addressTo':data.delivery.title,
      'material':data.material,
      'cargoCount':1,//tā vismaz spriežu pēc vecā koda - 'uzd_cargo_count'
      'typeID':9
    }*/
		let try2Final = {};
		const dataToSendAsDefaults = [
			'carrierID',
			'vilcejs',
			'piekabe',
			'manipulator',
			'notes',
			'driver',
			'phone',
			'mappostUserID',
			'isActive',
			'driverID'
		]; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			carrierID: 'Pārvadātājs',
			vilcejs: 'Vilcējs',
			piekabe: 'Piekabe',
			manipulator: 'Manipulators',
			notes: 'Piezīmes',
			driver: 'Šoferis',
			phone: 'Tālrunis',
			mappostUserID: 'Mappost lietotāja ID',
			isActive: 'Vai ir aktīvs'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			[
				'carrierID',
				'vilcejs',
				//'piekabe',
				//'notes',
				'driver'
				//'phone',
				//'mappostUserID',
				//'isActive'
			],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		//return;

		if (id == null) {
			//new user
			api.admin.transport_create(finalToSend).then(response => {
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 7500 }
					);
				}
			});
		} else {
			api.admin.transport_save({ id, data: finalToSend }).then(response => {
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 7500 }
					);
				}
			});
		}
	};

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}
	/*

        $returnArray['title'] = $deptData['nodala_nos'];
		$returnArray['isActive'] = $deptData['nodala_akt'];

        $returnArray['alertResponsible'] = $deptData['alert_responsible'];
		$returnArray['alertClient'] = $deptData['alert_client'];

    */
	onTypeAheadSelect(result, whatWasSearched) {
		if (
			whatWasSearched == 'carrierID' &&
			typeof result !== 'undefined' &&
			Object.entries(result).length > 0
		) {
			this.setDataToState([whatWasSearched], result.value);
		} else if (result != '') {
			this.setDataToState([whatWasSearched], result);
		}
	}

	fetchDriverAndMappostData = () => {
		this.setState({ isLoading: true });

		return api.transportation.getMappostIdForDriver().then(response => {
			const allResults = Object.entries(response).map(([id, driver]) => {
				const driverName = driver.split(' (')[0];
				return { id, driverName };
			});
			this.setState({ allResults: allResults, isLoading: false });
		});
	};

	triggerSearch = async () => {
		this.setState({ isSearchModalOpen: true });
	  
		if (this.state.driverName) {
		  if (!this.state.allResults) {
			await this.fetchDriverAndMappostData();
		  }
	  
		  const options = {
			keys: ['driverName'],
			includeScore: true,
			threshold: 0.3
		  };
	  
		  const fuse = new Fuse(this.state.allResults, options);
		  const searchResults = fuse.search(this.state.driverName).map(({ item }) => item);
	  
		  this.setState({ mappostId: null, searchResults: searchResults });
		}
	  };

	  handleMappostIdAdd = (id) => {
		this.setState(prevState => ({
		  ...prevState,
		  toSaveData: {
			...prevState.toSaveData,
			mappostUserID: id,
		  },
		  mappostUserID: id,
		  isSearchModalOpen: false
		}));
	  };
	  
	  handleClick = (id) => () => {
		this.handleMappostIdAdd(id);
	  };

	render() {
		const {
			loading,
			carrierID,
			driverID,
			vilcejs,
			piekabe,
			notes,
			driver,
			driverName,
			phone,
			mappostUserID,
			isActive,
			isMainModalOpen,
			isSearchModalOpen,
			carrierOptions,
			options,
			manipulator,
		} = this.state;

		const isNew = this.props.id == null ? true : false;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						{isNew && (
							<div className='row'>
								<Label
									labelClass='col-sm-3'
									title={
										<span>
											Pārvadātājs{' '}
											<OverlayTrigger
												placement='top'
												overlay={
													<Tooltip id='tooltip-right'>
														Lai iekļautu jaunu klientu pārvadātāju
														sarakstā, ir jāatver klients labošanas
														režīmā un jānorāda &quot;Iekļauts
														pārvadātāju sarakstā&quot; &gt;
														&quot;Jā&quot;
													</Tooltip>
												}>
												<i className='fas fa-question-circle'></i>
											</OverlayTrigger>
										</span>
									}
									name='carrierID'
									required={true}
								/>
								{/* <Select
									selectClass="col-sm-8"
									name="carrierID"
									handleChange={this.handleChange}
									value={carrierID}
									placeholder="Izvēlies vērtību"
									options={carrierOptions}
								/> */}

								<div className='col-sm-8'>
									<Typeahead
										clearButton
										id='carrierID'
										defaultSelected={
											carrierOptions && carrierOptions.length > 0
												? carrierOptions.filter(
													opt => carrierID == opt.value
												)
												: []
										}
										onChange={selected =>
											this.onTypeAheadSelect(selected[0], 'carrierID')
										}
										options={
											carrierOptions && carrierOptions.length > 0
												? carrierOptions
												: []
										}
										placeholder='Izvēlies pārzini'
									/>
								</div>
							</div>
						)}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Šoferis'
								name='driver'
								required={true}
							/>
							<Input
								inputClass='col-sm-8'
								name='driver'
								value={driver}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Šofera tālrunis' name='phone' />
							<Input
								inputClass='col-sm-8'
								name='phone'
								value={phone}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Šofera Mappost ID'
								name='mappostUserID'
							/>
							<Input
								inputClass='col-sm-2'
								name='mappostUserID'
								value={mappostUserID}
								handleChange={this.handleChange}
							/>
							<button
								type='button'
								disabled={false}
								className='btn btn-primary mr-2'
								onClick={this.triggerSearch}>
								Meklēt Mappost ID
							</button>
							{mappostUserID && mappostUserID.length > 0 && (
								<button
									type='button'
									disabled={false}
									className='btn btn-primary'
									onClick={this.toggleMainModal}>
									Pārbaudīt
								</button>
							)}
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Vilcējs'
								name='vilcejs'
								required={true}
							/>
							<Input
								inputClass='col-sm-8'
								name='vilcejs'
								value={vilcejs}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Piekabe' name='piekabe' />
							<Input
								inputClass='col-sm-8'
								name='piekabe'
								value={piekabe}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Manipulators' name='manipulator' />
							<div className='col-sm-8'>
								<Switch
									name='manipulator'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={manipulator == true ? true : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'manipulator')
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Piezīmes' name='notes' />
							<Input
								inputClass='col-sm-8'
								name='notes'
								value={notes}
								handleChange={this.handleChange}
							/>
						</div>
						{isNew == false && (
							<div className='row'>
								<Label labelClass='col-sm-3' title='Aktīvs' name='isActive' />
								<div className='col-sm-8'>
									<Switch
										name='isActive'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={isActive ? isActive : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'isActive')
										}
									/>
								</div>
							</div>
						)}
						{checkPermission('edit', 'transport') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
						)}
						<CustomModal
							title='Mappost pārbaude'
							showModal={isMainModalOpen}
							handleCloseModal={this.toggleMainModal}
							content={
								<MappostCheck
									mode='user'
									mappost={mappostUserID}
									handleCloseModal={this.toggleMainModal}
								/>
							}
						/>
							<CustomModal
								title='Mappost ID meklēšanas rezultāti'
								showModal={isSearchModalOpen}
								handleCloseModal={this.toggleSearchModal}
								content={
									<div>
										<div className='row'>
											<Label labelClass='col-sm-2' title='Šoferis' name='driver' />
											<Input
												inputClass='col-sm-6'
												name='driverName'
												value={driverName}
												handleChange={this.handleChange}
											/>
											<button
												type='button'
												disabled={false}
												className='btn btn-primary'
												onClick={this.triggerSearch}>
												Meklēt Mappost ID
											</button>
										</div>
										{this.state.isLoading ? <Loading /> :
											this.state.searchResults?.length > 0 ? this.state.searchResults.map(result => (
												<div key={result.id} style={resultStyle}>
													<div><strong>Šoferis:</strong> {result.driverName}</div>
													<div><strong>Mappost ID:</strong> {result.id}</div>
													<button
														type='button'
														disabled={false}
														className='btn btn-primary'
														onClick={this.handleClick(result.id)}>
														Pievienot Mappost ID
													</button>
												</div>
											)) :
												<div>Neviens Mappost lietotājs ar šādu vārdu netika atrasts</div>
										}
									</div>
								}
							/>
					</div>
				)}
			</div>
		);
	}
}

export default TransportEdit;
