import Bills from './sections/bills';
import EditBill from './sections/bills/edit';
import OrderPage from './sections/orders/orderPage';

import ClientProfile from './sections/client';

import AuthContainer from './sections/auth/authContainer';
import Search from './sections/client/search';
//import Client from './orders/client';
//import Edit from './orders/edit';
import DeliveredKrava from './sections/deliveries/aizvestais';
//import Deliveries from './deliveries/table';
import DeliveryDrivers from './sections/deliveries/drivers';
import MainDeliveriesSection from './sections/deliveries';
//import DeliveryList from '../sections/deliveries/deliveryList';
import DeliveredArchive from './sections/deliveries/archive';
import Planning from './sections/planning';
import OrdersPlanningPage from './sections/ordersPlanning';
//import Filter from './filter';
import TestingThings from './testingThings';

//import NewTasks from './orders/newTasks';
//import DeliveryEdit from './deliveries/edit';
import AddressTable from '@sections/admin/addresses';
import StorageTable from '@sections/admin/storages';
import MaterialTable from '@sections/admin/material';
//import MaterialCatsTable from './admin/materialCats';
import DepartmentsTable from '@sections/admin/depts';
import TransportTable from '@sections/admin/transport';
import ShipsTable from '@sections/admin/ships';
import UsersTable from '@sections/admin/users';
import CalendarMainView from './sections/deliveries/calendarView';
import RoleAdministration from '@sections/admin/roles/admin';
import ResponsibleTable from '@sections/admin/atbildigie';
import SMTPEdit from '@sections/admin/smtp';
import SMSEdit from '@sections/admin/sms';
import HorizonSync from '@sections/admin/horizon';
import EmailTemplates from '@sections/admin/email_templates';
import PasswordChange from 'sections/passwords/passwordChange';
import DirectionsEdit from '@sections/admin/directions';
import Services from '@sections/admin/services';
import AuditTable from '@sections/admin/audit';
import ActivateUser from 'sections/passwords/userSet';
import ResetPassword from 'sections/passwords/resetPassword';
import Set2FA from 'sections/passwords/mfa';
import MappostAddress from 'sections/reports/mappost/address';
import MappostTransport from 'sections/reports/mappost/transport';
import Invoices from 'sections/reports/invoices';
import MonthlySummary from 'sections/reports/monthly_summary';
import Orders from 'sections/reports/orderList';
import RegisteredOrders from 'sections/reports/registeredOrders';
import OrdererPrices from 'sections/reports/ordererPrices';
import Transporters from 'sections/reports/transporters';
import OrdersDeliveries from 'sections/reports/orders_deliveries';
import AgroDepartment from 'sections/reports/agro_dept';
import AutoReports from 'sections/reports/drivers';
import TimeLength from 'sections/reports/time_length';
import TransportationPrices from 'sections/transportation';
import TransportationPriceRequest from 'sections/transportation/request';
import InvoicesComplex from 'sections/reports/invoices_complex';
import PriceSummary from 'sections/admin/prices';
import OauthError from 'sections/auth/oauthError';

export const routes = [
	{
		component: MappostAddress,
		path: '/mappost/address/:uid',
		checkLogin: true,
		readPermissions: '',
		title: 'Mappost adrešu pārbaude'
	},
	{
		component: MappostTransport,
		path: '/mappost/transport/:uid',
		checkLogin: true,
		readPermissions: '',
		title: 'Mappost šoferu pārbaude'
	},
	{
		component: PasswordChange,
		path: '/change_password',
		checkLogin: true,
		readPermissions: '',
		title: 'Paroles maiņa'
	},
	{
		component: Set2FA,
		path: '/mfa',
		checkLogin: true,
		readPermissions: '',
		title: 'Vairāklīmeņu autentifikācija'
	},
	{
		component: TestingThings,
		path: '/testing',
		checkLogin: false,
		readPermissions: '',
		title: ''
	},
	{
		component: Search,
		path: '/',
		checkLogin: true,
		readPermissions: '',
		title: 'Klientu meklēšana'
	},
	{
		component: AuthContainer,
		path: '/login',
		checkLogin: false,
		readPermissions: '',
		title: 'Autentifikācija'
	},
	{
		component: OauthError,
		path: '/auth_error',
		checkLogin: false,
		readPermissions: '',
		title: 'Autentifikācija'
	},
	{
		component: Bills,
		path: '/invoices',
		checkLogin: true,
		readPermissions: 'bills',
		title: 'Rēķini'
	},
	//{'component':EditBill, path: '/rekini/edit', checkLogin: true, readPermissions: ''},
	{
		component: OrderPage,
		path: '/orders',
		checkLogin: true,
		readPermissions: 'orders',
		title: 'Pasūtījumi'
	},
	{
		component: ClientProfile,
		path: '/client/:regnr',
		checkLogin: true,
		readPermissions: 'clients',
		title: 'Klienta profils'
	},
	//{'component':ClientProfile, path: '/client_regnr/:regnr', checkLogin: true, readPermissions: 'clients'},
	//{'component':ClientProfile, path: '/client/:id', checkLogin: true, readPermissions: 'clients'},
	//{'component':ClientProfile, path: '/details/:id', checkLogin: true, readPermissions: ''},
	//{'component':Edit, path: '/order', checkLogin: true, readPermissions: ''},
	{
		component: DeliveredKrava,
		path: '/delivered',
		checkLogin: true,
		readPermissions: 'delivered',
		title: 'Aizvestās kravas'
	},
	{
		component: MainDeliveriesSection,
		path: '/deliveries',
		checkLogin: true,
		readPermissions: 'delivered',
		title: 'Braucieni'
	},
	//{'component':Deliveries, path: '/deliveries', izpilde: true, readPermissions: ''},
	{
		component: DeliveryDrivers,
		path: '/drivertasks',
		checkLogin: true,
		readPermissions: 'drivertasks',
		title: 'Šoferi/uzdevumi'
	},
	//{'component':DeliveryList, path: '/deliverylist', checkLogin: true, readPermissions: 'deliverylist', title: ''},
	{
		component: DeliveredArchive,
		path: '/archive',
		checkLogin: true,
		readPermissions: 'archive',
		title: 'Arhīvs'
	},
	{
		component: Planning,
		path: '/planning',
		checkLogin: true,
		readPermissions: 'planning',
		title: 'Saliktās kravas'
	},
	{
		component: OrdersPlanningPage,
		path: '/orders_planning',
		checkLogin: true,
		readPermissions: 'planning',
		title: 'Plānošana'
	},
	//{'component':NewTasks, path: '/new_task', checkLogin: true, readPermissions: ''},
	//{'component':DeliveryEdit, path: '/delivery_edit', checkLogin: true, readPermissions: ''},
	//{'component':Filter, path: '/filter', checkLogin: true, readPermissions: ''},
	{
		component: RoleAdministration,
		path: '/admin/roles',
		checkLogin: true,
		readPermissions: 'roles',
		title: 'Grupu tiesības'
	},
	{
		component: AddressTable,
		path: '/admin/addresses',
		checkLogin: true,
		readPermissions: 'addresses',
		title: 'Adreses'
	},
	{
		component: StorageTable,
		path: '/admin/storage',
		checkLogin: true,
		readPermissions: 'storage',
		title: 'Noliktavas'
	},
	{
		component: MaterialTable,
		path: '/admin/material',
		checkLogin: true,
		readPermissions: 'materials',
		title: 'Nomenklatūra un grupas'
	},
	//{'component':MaterialCatsTable, path: '/admin/material_cats', checkLogin: true, readPermissions: 'materialcats', title: ''},
	{
		component: ResponsibleTable,
		path: '/admin/responsible',
		checkLogin: true,
		readPermissions: 'responsible',
		title: 'Atbildīgās personas'
	},
	{
		component: DepartmentsTable,
		path: '/admin/departments',
		checkLogin: true,
		readPermissions: 'departments',
		title: 'Nodaļas'
	},
	{
		component: TransportTable,
		path: '/admin/transport',
		checkLogin: true,
		readPermissions: 'transport',
		title: 'Transports'
	},
	{
		component: ShipsTable,
		path: '/admin/ships',
		checkLogin: true,
		readPermissions: 'transport',
		title: 'Kuģi'
	},
	{
		component: UsersTable,
		path: '/admin/users',
		checkLogin: true,
		readPermissions: 'users',
		title: 'Lietotāji'
	},
	{
		component: SMTPEdit,
		path: '/admin/smtp',
		checkLogin: true,
		readPermissions: 'smtp',
		title: 'SMTP'
	},
	{
		component: SMSEdit,
		path: '/admin/sms',
		checkLogin: true,
		readPermissions: 'sms',
		title: 'Noklusētie SMS teksti'
	},
	{
		component: CalendarMainView,
		path: '/calendar',
		checkLogin: true,
		readPermissions: 'calendar',
		title: 'Kalendārs'
	},
	{
		component: HorizonSync,
		path: '/admin/horizon',
		checkLogin: true,
		readPermissions: 'horizon',
		title: 'Horizon sinhronizācija'
	}, //horizon
	{
		component: DirectionsEdit,
		path: '/admin/directions',
		checkLogin: true,
		readPermissions: 'directions',
		title: 'Piegādes virzieni'
	},
	{
		component: Services,
		path: '/admin/services',
		checkLogin: true,
		readPermissions: '',
		title: 'Servisu statuss'
	},
	{
		component: EmailTemplates,
		path: '/admin/text_templates',
		checkLogin: true,
		readPermissions: '',
		title: 'Teksta veidnes'
	},
	{
		component: AuditTable,
		path: '/admin/audit',
		checkLogin: true,
		readPermissions: '',
		title: 'Auditācija'
	},
	{
		component: PriceSummary,
		path: '/admin/price_summary',
		checkLogin: true,
		readPermissions: '',
		title: 'Cenu tabula'
	},
	{
		component: ActivateUser,
		path: '/confirm_user/:token',
		checkLogin: false,
		readPermissions: '',
		title: 'Jauna lietotāja aktivizācija'
	},
	{
		component: ResetPassword,
		path: '/reset_password/:token',
		checkLogin: false,
		readPermissions: '',
		title: 'Jaunas paroles izveide'
	},
	{
		component: Invoices,
		path: '/reports/invoices',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Rēķinu apstrādes laiks'
	},
	{
		component: InvoicesComplex,
		path: '/reports/invoice_rows',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Rēķinu rindas'
	},
	{
		component: MonthlySummary,
		path: '/reports/monthly_summary',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Mēnešu kopsavilkums'
	},
	{
		component: Orders,
		path: '/reports/orders',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Pasūtījumi'
	},
	{
		component: RegisteredOrders,
		path: '/reports/registered_orders',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Ievadītie pasūtījumi'
	},
	{
		component: OrdererPrices,
		path: '/reports/orderer_prices',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Vēsturiskās pasūtītāja cenas'
	},
	{
		component: Transporters,
		path: '/reports/transporters',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Pārvadātāji'
	},
	{
		component: OrdersDeliveries,
		path: '/reports/orders_deliveries',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Pasūtījumu braucieni'
	},
	{
		component: AgroDepartment,
		path: '/reports/agro',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Agro nodaļa'
	},
	{
		component: AutoReports,
		path: '/reports/auto',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Auto'
	},
	{
		component: TimeLength,
		path: '/reports/time_length',
		checkLogin: true,
		readPermissions: 'main_reports',
		title: 'Veiktā ceļa garuma / ilguma neatbilstības'
	},
	{
		component: TransportationPrices,
		path: '/transportation_prices',
		checkLogin: true,
		readPermissions: '',
		title: 'Transporta cenu aprēķināšana'
	},
	{
		component: TransportationPriceRequest,
		path: '/transportation_prices/:id',
		checkLogin: true,
		readPermissions: '',
		title: 'Transporta cenu aprēķināšana'
	}
];
