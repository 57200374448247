// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import clsx from 'clsx';
import 'react-datetime/css/react-datetime.css';

const OneField = props => {
	const { visible, title, inputID, field_type, cb, cbToSearch, fullWidth, value } = props;

	if (!visible) {
		return '';
	}

	return (
		<div className={clsx('input-group form-row', fullWidth ? '' : 'col-md-5')}>
			<label className='input-group-addon form-label' htmlFor={inputID}>
				{title}:{' '}
			</label>
			{field_type && field_type === 'date' ? (
				<Datetime
					locale='lv'
					timeFormat={false}
					closeOnSelect={true}
					onChange={event => cb(event, cbToSearch)}
					defaultValue=''
				/>
			) : (
				<input
					id={inputID}
					type='text'
					className='form-control'
					onChange={event => cb(event, cbToSearch)}
					//onChange={ev => setTimeout(() => cb(ev, cbToSearch), 250)}
				/>
			)}
		</div>
	);
};

OneField.propTypes = {
	field_type: PropTypes.oneOf(['date', 'string']),
	visible: PropTypes.bool,
	fullWidth: PropTypes.bool,
	title: PropTypes.string.isRequired,
	inputID: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	cb: PropTypes.func,
	cbToSearch: PropTypes.string
};

const fieldForm = props => {
	const { items, fullWidth, exportData } = props;

	return (
		<fieldset className='form-fieldset' style={{ flex: 1 }}>
			{items.map((item, key) => (
				<div key={key} style={{ display: 'flex', alignItems: 'center' }}>
					<OneField {...item} fullWidth={fullWidth} />
					{(key === 0 && exportData) && (
						<button onClick={exportData} type='button' className={clsx('btn', 'btn-primary')} style={{ marginLeft: 10 }}>
							Eksportēt XLSX
						</button>
					)}
				</div>
			))}
		</fieldset>
	);
};

fieldForm.propTypes = {
	fullWidth: PropTypes.bool,
	exportData: PropTypes.func,
	items: PropTypes.array.isRequired
};

export default fieldForm;
