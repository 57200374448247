import { apiEndpoint } from '../config';
import queryString from 'query-string';

export default ({ url, options }) => {
	if (apiEndpoint !== url.substr(0, apiEndpoint.length)) {
		url = apiEndpoint + url;
	}

	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};
	/*
      let authToken = getAuthorizationToken();
  
      console.log(authToken);
  
      if (authToken) {
        headers['Authorization'] = authToken;
      }
      */
	options = {
		method: 'get',
		headers,
		...options
	};

	options.method = options.method.toLowerCase();
	/*
      if (options.method === 'get' && typeof options.params !== 'undefined') {
        url = `${url}?`+queryString.stringify(options.params);
      }
      */

	if ((options.method === 'post' || options.method === 'put') && options.body !== undefined) {
		options.body = JSON.stringify(options.body);
	}

	return {
		url,
		options
	};
};
