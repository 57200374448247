//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import Card from 'shared/actionButtons/card';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '@api';
import { Collapse } from 'react-collapse';
import OrderEditModal from './newModal';
import Loading from 'shared/loading';
import { Radio } from '../../shared/form';
import { toast } from 'react-toastify';
import { object } from 'prop-types';
import Deliveries from '../deliveries/table';
import Header from '../deliveries/header';
import NoData from 'shared/noDataNotification';
import MassDeliveryEdit from 'sections/deliveries/MassDeliveryEdit';
import { connect } from 'react-redux';

class VedumiModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			orderNr: '',
			data: {},
			searching: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null,
			showMassDeliveryEditModal: false,
			isSelected: [],
			isMassEditButtonsActive: false,
		};
	}

	componentDidMount() {
		const { orderId, orderNr, billId, planningOrderId } = this.props;

		this.setState({ orderId, orderNr, billId, planningOrderId }, () => this.setup());
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId ||
			this.props.billId !== prevProps.billId ||
			this.props.planningOrderId !== prevProps.planningOrderId)
			{
			const { orderId, orderNr, billId, planningOrderId } = this.props;

			this.setState({ orderId, orderNr, billId, planningOrderId }, () => this.setup());
		}
	}

	getByOrder = (id) => {
		this.setState({ searching: true, error: false });

		api.deliveries.orderDeliveries(id).then(response => {
			console.log('VedumiModal', id, response);
			this.setState({ searching: false, error: false, data: response });
		});
	}

	getByBill = id => {
		this.setState({ searching: true, error: false });

		api.bills.deliveries(id).then(response => {
			console.log('VedumiModal', id, response);
			this.setState({ searching: false, error: false, data: response });
		});
	}

	handleCloseEditModal = () => {
		this.props.handleCloseEditModal();
	}

	setup() {
		const { orderId, billId, planningOrderId } = this.props;

		if (orderId === null && billId === null && planningOrderId === null) {
			//šis izpildās, kad aizver modal logu
			this.setState({ data: {} });
			return;
		}

		if (planningOrderId) {
			api.planning.order_deliveries(planningOrderId).then(response => {
				this.setState({ searching: false, error: false, data: response });
			});
			return;
		}

		if (orderId) {
			this.getByOrder(orderId);
		}

		if (billId) {
			this.getByBill(billId);
		}
	}

	closeModalWithoutReload = () => {
		this.setState({
			showEditModal: false
		});
	}

	handleOpenMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: true });
	};

	handleCloseMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: false });
	};

	handleCheckboxChange = (deliveryId, isSelected) => {
		this.setState(prevState => {
			const newIsSelected = [...prevState.isSelected];
			if (isSelected) {
				if (!newIsSelected.includes(deliveryId)) {
					newIsSelected.push(deliveryId);
				}
			} else {
				const index = newIsSelected.indexOf(deliveryId);
				if (index > -1) {
					newIsSelected.splice(index, 1);
				}
			}
			return { isSelected: newIsSelected };
		});
	};

	selectAllDeliveries = () => {
		this.setState(prevState => {
			console.log('aaaa', prevState.data)
			const deliveryIds = prevState.data.deliveries.flatMap(delivery => delivery.deliveryId);
			const isSelected = prevState.isSelected.length ? [] : deliveryIds;
			return { isSelected };
		});
	};

	activateMassEdit = () => {
		this.setState(prevState => ({ isMassEditButtonsActive: !prevState.isMassEditButtonsActive }));
	};

	render() {
		const { data, searching, orderNr, planningOrderId, isSelected, isMassEditButtonsActive, showMassDeliveryEditModal } = this.state;
		const {billTitle} = this.props;

		const title = planningOrderId ?
			`Plānošanas pasūtījuma #${planningOrderId} reģistrētās piegādes` :
			`Pasūtījuma #${orderNr} reģistrētās piegādes`;

		return (
			<div>
				<CustomModal
					modalClass='fullWidthModal'
					title={billTitle ? `Rēķinā #${billTitle} iekļautās piegādes` : title}
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						searching ? (
							Loading()
						) : (
							<div>

								<div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-end' }}>
										{this.props.canUpdateDeliveriesInBatch && (
											<div>
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.activateMassEdit}
													style={{ display: 'block', width: '220px' }}>
													{isMassEditButtonsActive ? 'Deaktivizēt kopīgo labošanu' : 'Aktivizēt kopīgo labošanu'}
												</button>
											</div>
										)}
										<div style={{ display: 'flex', gap: '10px' }}>
											{isSelected.length > 0 && isMassEditButtonsActive && (
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.handleOpenMassDeliveryEditModal}
													style={{ width: '220px' }}>
													Labot atzīmētās piegādes ({isSelected.length})
												</button>
											)}
											{isMassEditButtonsActive && (
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.selectAllDeliveries}
													style={{ width: '220px' }}>
													{isSelected.length > 0 ? 'Noņemt atzīmes no piegādēm' : 'Atzīmēt visas piegādes'}
												</button>
											)}
										</div>
									</div>
								{typeof data.deliveries !== 'undefined' &&
								data.deliveries.length > 0 ? (
									<Deliveries data={data.deliveries} exclude={[]} reload_data={this.setup}
									isSelected={this.state.isSelected}
									onCheckboxChange={this.handleCheckboxChange}
									selectAllDeliveries={this.selectAllDeliveries}
									isMassEditButtonsActive={this.state.isMassEditButtonsActive} />
								) : (
									<NoData />
								)}
							</div>
						)
					}
				/>
				{showMassDeliveryEditModal && (
					<MassDeliveryEdit
						showModal={showMassDeliveryEditModal}
						handleCloseModal={this.handleCloseMassDeliveryEditModal}
						isSelected={this.state.isSelected}
						data={this.state.data.deliveries}
					/>
				)}
				<OrderEditModal
					showModal={this.state.showEditModal}
					handleCloseModal={this.handleCloseEditModal}
					closeModalWithoutReload={this.closeModalWithoutReload}
				/>
			</div>
		);
	}
}
//
const mapStateToProps = (state, ownProps) => ({
	canUpdateDeliveriesInBatch: state.auth.canUpdateDeliveriesInBatch,
})

export default connect(mapStateToProps)(VedumiModal);
