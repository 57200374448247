import React, { Component, createRef } from 'react';
import Modal from '@sections/orders/actions/modal';
import api from '@api';
import Loading from 'shared/loading';
import OrderEditModal from './newModal';
import { checkPermission } from '../../permissions';
import { handleDataUpdate } from '../../util';
import NoData from 'shared/noDataNotification';
import LoadComponent from 'load_component';

class NewOrders extends Component {
	intervalID;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			currentlyEditingUser: null,
			showModal: false,
			today: [],
			last: [],
			clientId: '',
			currOrder: null,
			isActive: true,
			showNewOrderModal: false
		};
	}

	setup() {
		this.setState({ searching: true, error: false });

		api.clients.blankOrders(this.state.clientId).then(response => {
			//console.log('...',response);
			this.setState({
				searching: false,
				error: false,
				today: response.today,
				last: response.last
			});
			this.intervalID = handleDataUpdate(this.setup.bind(this));
		});
	}

	componentDidMount() {
		const clientId = this.props.client;
		const isActive = this.props.isActive;

		this.setState({ clientId, isActive }, () => this.setup());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.client !== prevProps.client || this.props.isActive !== prevProps.isActive) {
			const clientId = this.props.client;
			const isActive = this.props.isActive;
			this.setState({ clientId, isActive }, () => this.setup());
		}
	}
	componentWillUnmount() {
		clearTimeout(this.intervalID);
	}

	oneCard(data, ind) {
		const { measureUnit, orderStatus } = data;

		let status = <span></span>;

		if (orderStatus < 5) {
			status = (
				<span>
					<span className='glyphicon glyphicon-refresh' aria-hidden='true'></span>{' '}
					Formēšanā
				</span>
			);
		} else if (orderStatus === 5) {
			status = (
				<span>
					<span className='glyphicon glyphicon-ok' aria-hidden='true'></span> Izpildīts
				</span>
			);
		}

		const zeroAmount = '0.00'; //parseFloat('0').toFixed(2);

		const bgColor = orderStatus === 5 ? 'green' : '#FF6600';

		const route = () => {
			const routeFrom_1 = data.storageDetails.name !== '' ? data.storageDetails.name : '';
			const routeFrom_2 =
				data.storageDetails.contacts !== '' ? `, tel: ${data.storageDetails.contacts}` : '';
			const routeTo = data.payerDetails.name !== '' ? data.payerDetails.name : '';

			return (
				<span>
					{routeFrom_1}
					{routeFrom_2 == '' ? '' : ' '} {routeFrom_2} -&gt; {routeTo}
				</span>
			);
		};

		return (
			<div className='row bordered' key={ind} style={{ fontSize: '85%' }}>
				<div className='col-sm-1' style={{ minWidth: '100px' }}>
					<div className='box' style={{ backgroundColor: bgColor }}>
						#{data.orderNr !== '' ? data.orderNr : ''}
					</div>
					<br />
					{status}
				</div>
				<div className='col-sm-7'>
					Materiāls: <b>{data.material !== '' ? data.material : ''}</b>
					<br />
					Maršruts: {route()}
					<br />
					Klienta rēķina cena:{' '}
					<b>
						{data.pas_parv_cena !== '' ? data.pas_parv_cena : ''} EUR/
						{data.par !== '' ? data.par : ''}
					</b>
					<br />
					Piezīmes: {data.materialNotes !== '' ? data.materialNotes : ''}
					<br />
					Pasūtījumu ievadīja:{' '}
					<b>
						{data.orderAdded !== '' ? data.orderAdded : ''}{' '}
						{data.workerInfo !== '' ? data.workerInfo : ''}
					</b>
					<br />
				</div>
				<div className='col-sm-3'>
					Pasūtīts daudzums:{' '}
					<b>
						{data.orderedAmount !== '' &&
						!isNaN(data.orderedAmount) &&
						data.orderedAmount !== null
							? parseFloat(data.orderedAmount).toFixed(2)
							: zeroAmount}{' '}
						{measureUnit}
					</b>
					<br />
					Pasūtījuma atlikums:{' '}
					{data.remainer !== '' && !isNaN(data.remainer) && data.remainer !== null
						? parseFloat(data.remainer).toFixed(2)
						: zeroAmount}{' '}
					{measureUnit}
					<br />
					Uzdevumos atdots:{' '}
					{data.amountInTasks !== '' &&
					!isNaN(data.amountInTasks) &&
					data.amountInTasks !== null
						? parseFloat(data.amountInTasks).toFixed(2)
						: zeroAmount}{' '}
					{measureUnit}
					<br />
					Aizvests pēc fakta:{' '}
					{data.plannedAmount !== '' &&
					!isNaN(data.plannedAmount) &&
					data.plannedAmount !== null
						? parseFloat(data.plannedAmount).toFixed(2)
						: zeroAmount}{' '}
					{measureUnit}
					<br />
				</div>
				<div className='col-sm-1'>
					<button
						type='button'
						className='btn btn-default btn-lg'
						onClick={() => this.handleOpenModal(data)}>
						<span className='glyphicon glyphicon-edit' aria-hidden='true'></span>
					</button>
				</div>
			</div>
		);
	}

	closeModalWithoutReload = () => {
		this.setState({
			showModal: false,
			showNewOrderModal: false,
			currOrder: null
		});
	}

	handleOpenModal = cellData => {
		this.setState({
			showModal: true,
			currOrder: cellData.orderId,
			remainder: cellData.remainer
		});
	};

	handleCloseModal = () => {
		this.setup();
		this.setState({
			showModal: false,
			showNewOrderModal: false,
			currOrder: null
		});
	};

	handleOrderAdding = () => {
		this.setState({ showNewOrderModal: true });
	};

	render() {
		const { searching, error, remainder, today, last, currOrder, isActive } = this.state;
		const { clientRegNr } = this.props;

		return (
			<div>
				<div>
						{
							searching && <LoadComponent />
						}
						{checkPermission('add', 'orders') && (
							<div>
								{isActive && (
									<div style={{ float: 'right' }}>
										<button
											type='button'
											className='btn btn-primary'
											onClick={this.handleOrderAdding}>
											Pievienot jaunu pasūtījumu
										</button>
									</div>
								)}
							</div>
						)}
						{isActive == false && (
							<div
								className='createOrder'
								style={{
									color: 'red',
									cursor: 'not-allowed'
								}}>
								Šis klients ir atzīmēts par neaktīvu. Jauna pasūtījuma izveide ir
								atspējota
							</div>
						)}

						<div>
							<h3>Šodien ievadītie pasūtījumi</h3>
							{today.length == 0 ? (
								<NoData message='Nav šodien ievadītu pasūtījumu' />
							) : (
								<div>{today.map((item, ind) => this.oneCard(item, ind))}</div>
							)}
							<h3>Pēdējie pasūtījumi</h3>
							{last.length == 0 ? (
								<NoData message='Klientam nav pasūtījumu' />
							) : (
								<div>{last.map((item, ind) => this.oneCard(item, ind))}</div>
							)}
						</div>
						<Modal
							showModal={this.state.showModal}
							orderId={currOrder}
							clientRegNr={clientRegNr}
							remainder={remainder}
							handleCloseModal={this.handleCloseModal}
							closeModalWithoutReload={this.closeModalWithoutReload}
						/>
						<OrderEditModal
							showModal={this.state.showNewOrderModal}
							clientRegNr={clientRegNr}
							handleCloseModal={this.handleCloseModal}
							closeModalWithoutReload={this.closeModalWithoutReload}
						/>
					</div>
			</div>
		);
	}
}

export default NewOrders;
