import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import OrderEditModal from './newModal';
import Loading from 'shared/loading';
import RelatedOrders from '../orders/table/orderTable';
import NoData from 'shared/noDataNotification';
import { loadData } from '../orders/slice';
import { connect } from 'react-redux';

class RelatedOrdersModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			planningOrderId: '',
			data: {},
			searching: true,
			showEditModal: false
		};
	}

	componentDidMount() {
		const { planningOrderId } = this.props;

		this.setState({ planningOrderId }, () => this.setup());
	}

	handleCloseEditModal = () => {
		this.props.handleCloseEditModal();
	}

	componentDidUpdate(prevProps) {
		if (this.props.planningOrderId !== prevProps.planningOrderId) {
			const { planningOrderId } = this.props;

			this.setState({ planningOrderId }, () => this.setup());
		}
	}

	setup = (loader = true) => {
		const { planningOrderId } = this.state;

		if (planningOrderId === null) {
			this.setState({ data: {} });
			return;
		}

		if (loader) {
			this.setState({ loading: true, error: false });
		}

		api.planning.related_orders(planningOrderId).then(response => {
			this.setState({ searching: false, error: false, data: response });
			this.props.loadData(this.state.data);
		});
	}

	closeModalWithoutReload = () => {
		this.setState({
			showEditModal: false
		});
	}

	render() {
		const { data, searching, planningOrderId } = this.state;

		const title = `Plānošanas pasūtījuma #${planningOrderId} saistītie pasūtījumi`;

		return (
			<div>
				<CustomModal
					modalClass='fullWidthModal'
					title={title}
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						searching ? (
							Loading()
						) : (
							<div>
								{data.length > 0 ? (
									<RelatedOrders />
								) : (
									<NoData />
								)}
							</div>
						)
					}
				/>
				<OrderEditModal
					showModal={this.state.showEditModal}
					handleCloseModal={this.handleCloseEditModal}
					closeModalWithoutReload={this.closeModalWithoutReload}
				/>
			</div>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		loadData: (data) => {
			dispatch(loadData(data));
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		orders: state.orders.orders
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(RelatedOrdersModal);

