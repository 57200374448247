import React, { Component } from 'react';

class OauthError extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: ''
		};
	}

	componentDidMount() {
		this.setState({ error: 'Lietotājs nepastāv' });
	}

	goBack = () => {
		window.location.replace('');
	};

	render() {
		const { error } = this.state;

		return (
			<div>
				<div className='row'>
					<div className='col-md-4'></div>
					<div className='col-md-4'>
						<div className='panel panel-default'>
							<div className='panel-heading'>LATRAPS Loģistikas IS</div>
							<div className='panel-body'>
								<form>
									{error ? <div className='alert alert-danger'>{error}</div> : ''}
								</form>
								<div className='btn-group pull-right' role='group'>
									<button
										type='button'
										className='btn btn-primary'
										onClick={this.goBack}>
										Atgriezties
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-4'></div>
				</div>
			</div>
		);
	}
}

export default OauthError;
