import Login from './index';
import { login, logout } from './actions';
import { connect } from 'react-redux';

const mapDispatchToPropsRes = dispatch => {
	return {
		loginHandler: ({ username, password }) => {
			dispatch(login({ username, password }));
		},
		logoutHandler: () => {
			dispatch(logout());
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		error: state.auth.loginError,
		read: state.auth.readPerms,
		isAuth: state.auth.loggedIn,
		username: state.auth.username,
		horizonSync: state.auth.horizonSync,
		reports: state.auth.reports
	};
};

const AuthContainer = connect(mapStateToPropsRes, mapDispatchToPropsRes)(Login);

export default AuthContainer;
