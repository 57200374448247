import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	if (section == 'amount') {
		return parseFloat(val).toFixed(digits) + ' ' + data.unit;
	}

	if (section == 'carrierPrice') {
		return parseFloat(val).toFixed(digits) + ' ' + data.carrierPriceUnit;
	}

	return parseFloat(val).toFixed(digits);
};

const diffFormatter = (cell, type) => {
	const data = cell.getRow().getData();

	let diff = 0;
	if (type == 'sum') {
		diff = data.money - data.carrierExpenses;
	}

	if (type == 'price') {
		diff = data.ordererPrice - data.carrierPrice;
	}

	return diff < 0 ? `<span style="color: red; font-weight: bold;">${diff.toFixed(2)}</span>` : diff.toFixed(2);
};

const columns = [
	{
		title: 'Nedēļa',
		field: 'week',
	},
	{
		title: 'Klients',
		field: 'client',
		width: 180
	},
	{
		title: 'Pasūtījumi',
		field: 'orders',
		formatter: cell => cell.getRow().getData().orders.length,
	},
	{
		title: 'Uzdevumi',
		field: 'tasks',
		formatter: cell => cell.getRow().getData().tasks.length,
	},
	{
		title: 'Braucieni',
		field: 'deliveries',
		formatter: cell => cell.getRow().getData().deliveries.length,
	},
	{
		title: 'Nodaļa',
		field: 'department',
	},
	{
		title: 'Materiāls',
		field: 'material'
	},
	// {
	// 	title: 'Mērvienība',
	// 	field: 'unit'
	// },
	{
		title: 'Aizvestais daudzums',
		field: 'amount',
		align: 'right',
		formatter: cell => amountFormatter(cell, 'amount', 1)
	},
	{
		title: 'Klienta rēķina cena',
		field: 'ordererPrice',
		align: 'right',
		formatter: cell => amountFormatter(cell, 'ordererPrice', 2)
	},
	{
		title: 'Summa apmaksai klientam',
		field: 'money',
		align: 'right',
		formatter: cell => amountFormatter(cell, 'money', 2)
	},
	{
		title: 'Pārvadātāja cena',
		field: 'carrierPrice',
		align: 'right',
		formatter: cell => amountFormatter(cell, 'carrierPrice', 2)
	},
	{
		title: 'Pārvadātāja izmaksas',
		field: 'carrierExpenses',
		align: 'right',
		formatter: cell => amountFormatter(cell, 'carrierExpenses', 2)
	},
	{
		title: 'Starpība cenai',
		field: 'priceDifference',
		align: 'right',
		formatter: cell => diffFormatter(cell, 'price')
	},
	{
		title: 'Starpība summai',
		field: 'sumDifference',
		align: 'right',
		formatter: cell => diffFormatter(cell, 'sum')
	}
];

const Table = ({ data, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let { client, department, material } = filters;

	const filterObj = {
		client: client.toLowerCase(),
		department: department.toLowerCase(),
		material: material.toLowerCase(),
	};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;
	});

	return (
		<Tabulator
			columns={columns}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
