import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { clear } from '../slice';
import TabHandler from 'shared/tabs/tabSelect';

const initialState = {
	tabs: [],
	current_tab: null,
	current_key: null,
	loading: false,
	loading_error: false,
	meta: {}
};

const orderTabsSlice = createSlice({
	name: 'order_tabs',
	initialState,
	reducers: {
		startRequest: (state, action) => {
			state.loading = true;
		},
		loadTabs: (state, action) => {
			state.loading = false;
			state.tabs = action.payload;
		},
		loadMeta: (state, action) => {
			state.meta = action.payload;
		},
		setCurrentTab: (state, action) => {
			const {id, key} = action.payload;

			state.current_tab = id;
			state.current_key = key;
		}
	},
});

const {actions, reducer} = orderTabsSlice;

const formatTabs = ({orders, newOrders, transport, invoices, problematic, invoiceReady, archivedTasks}) => {
	const {normal, urgent, nextYear, departments} = orders;

	/* urgentOrdersTab={orders.urgent}
	nextYearTab={orders.nextYear}
	deptOrders={orders.normal} */

	let allTabs = [];

	if (normal && normal.length>0) {
		allTabs = [...normal];
	}

	if (departments && departments.length>0) {
		allTabs = [...departments];
	}

	if (urgent) {
		allTabs.splice(0, 0, { title: 'STEIDZAMI', id: -1 });
	}

	if (newOrders) {
		allTabs.splice(0, 0, { title: 'Visi pasūtījumi', id: -2 });
	}

	if (nextYear) {
		const nextYear = new Date().getFullYear() + 1;
		allTabs.push({ title: `${nextYear}. gads`, id: -3 });
	}

	if (problematic > 0) {
		allTabs.push({ title: 'Problemātiskās piegādes', id: -6 });
	}

	if (invoiceReady > 0) {
		allTabs.push({ title: 'Rēķiniem gatavās piegādes', id: -7 });
	}

	if (archivedTasks > 0) {
		allTabs.push({ title: 'Arhivētie braucieni', id: -8 });
	}

	if (invoices) {
		allTabs.push({ title: 'Rēķini', id: -4 });
	}

	if (transport) {
		allTabs.push({ title: 'Transports', id: -5 });
	}

	return allTabs;
}

export const loadData = ({include: {newOrders, transport, invoices, problematic, invoiceReady, archivedTasks}}) => (dispatch) => {

	dispatch(actions.startRequest())


	api.orders.overview().then(response => {
		const format = formatTabs({
			orders: response,
			newOrders, transport, invoices, problematic, invoiceReady, archivedTasks
		});
		dispatch(actions.loadMeta(response));
		dispatch(actions.loadTabs(format));
		const wantedTab = (new TabHandler()).getSettings('OrderTabs');
		dispatch(actions.setCurrentTab({key:wantedTab}));
	});
}

export const loadFromClient = ({meta, orders, include: {newOrders, transport, invoices, problematic, invoiceReady, archivedTasks}}) => (dispatch) => {

	const format = formatTabs({
		orders,
		newOrders, transport, invoices, problematic, invoiceReady, archivedTasks
	});
	dispatch(actions.loadTabs(format));
	// dispatch(actions.setCurrentTab({key:0}));
	dispatch(actions.loadMeta(meta));
}

export const setCurrentTab = ({key, id}) => (dispatch, getState) => {
	const currData = getState().orderTabs;
	if (currData.current_key === key && currData.current_tab === id) {
		return;
	}
	dispatch(actions.setCurrentTab({key, id}));
	dispatch(clear())
}

// export const {setCurrentTab} = actions;

export default reducer;
