import api from '../api';

const clients = {
	searchClient: term => api.get(`client/search?q=${term}`),

	details: ({ identifier, parameter }) => api.get(`client/details?${parameter}=${identifier}`),

	blankOrders: clientId => api.get(`order/all?client=${clientId}`),

	editInfo: regnr => api.get(`client/info?regnr=${regnr}`),

	save: ({ client, data }) => api.post(`client/update`, { client, data }),

	horizon_sync: regNr => api.post(`horizon/sync/client`, { client: regNr }),

	horizon_search: regNr => api.get(`horizon/search?regnr=${regNr}`),

	save_new: data => api.post(`horizon/save_new`, { data }),
};

export default clients;
