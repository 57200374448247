import React from 'react';
import PropTypes from 'prop-types';
import './card.css';
import clsx from 'clsx';

const OneCard = props => {
	const { isDisabled, onClick, img, text, header, disabledActionOnText, cssStyle = {} } = props;

	//isDisabled -

	return (
		<div
			className={clsx(
				{ disabledAction: isDisabled, enabledAction: !isDisabled },
				'ActionCard'
			)}
			style={cssStyle}>
			<div className='icon' onClick={!isDisabled ? onClick : undefined}>
				{img}
			</div>
			<div className='header' onClick={!isDisabled ? onClick : undefined}>
				{header || ''}
			</div>
			<div
				className='text'
				onClick={!isDisabled && !disabledActionOnText ? onClick : undefined}>
				{text || ''}
			</div>
		</div>
	);
};

OneCard.propTypes = {
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	img: PropTypes.any,
	text: PropTypes.string,
	header: PropTypes.string,
	disabledActionOnText: PropTypes.bool,
	cssStyle: PropTypes.object
};

export default OneCard;
