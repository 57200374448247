import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css';
import { tabulatorOptions } from '../../config';
import './Tabulator.css';

const Tabulator = props => {
	const { options, ...other } = props;
	const newOptions = Object.assign({}, tabulatorOptions, options);

	//console.log('Tabulator',tabulatorOptions, newOptions, options);

	//console.log(props);
	return <ReactTabulator options={newOptions} {...other} />;
};
/*
locale={true} localized={(local, lang) => {
		console.log('HERE!!!!',local, lang, navigator.language)
	}}
*/

export default Tabulator;

/*
http://tabulator.info/docs/4.4/layout
 responsiveLayoutCollapseFormatter={data=> nrFormatter(data)}
const nrFormatter = data => {
	//data - an array of objects containing the column title and value for each cell
	var list = document.createElement("ul");

	data.forEach(function(col){
		let item = document.createElement("li");
		item.innerHTML = "<strong>" + col.title + "</strong> - " + col.value;
		list.appendChild(item);
	});

	return Object.keys(data).length ? list : "";
} */
