import moment from 'moment';

const ranges = {
	'Šis gads': [moment().startOf('year'), moment().endOf('year')],
	'Iepriekšējais gads': [
		moment()
			.subtract(1, 'years')
			.startOf('year'),
		moment()
			.subtract(1, 'years')
			.endOf('year')
	],
	'Šis ceturksnis': [moment().startOf('quarter'), moment().endOf('quarter')],
	'Iepriekšējais ceturksnis': [
		moment()
			.subtract(1, 'quarter')
			.startOf('quarter'),
		moment()
			.subtract(1, 'quarter')
			.endOf('quarter')
	]
};

export const sumByKey = (rows, key) => {
	let sum = 0;
	for(const row of rows) {
		if (row[key]) {
			sum += parseFloat(row[key]);
		}
	}

	return sum;
}

export const sumAmounts = (rows) => {
	let amounts = [];
	for(const row of rows) {
		if (!amounts[row['unit']]) {
			amounts[row['unit']] = parseFloat(row['amount']);
		}

		amounts[row['unit']] += parseFloat(row['amount']);
	}

	const amountsText = Object.entries(amounts)
		.map(([unit, value]) => unit === 't' ? `${value.toFixed(1)} ${unit}` : `${value} ${unit}`)
		.join(', ');

	return amountsText;
}

export const sumDiff = (rows, type) => {
	let diff = 0;

	for(const row of rows) {
		if (type == 'sum') {
			diff += row.money - row.carrierExpenses;
		}

		if (type == 'price') {
			diff += row.ordererPrice - row.carrierPrice;
		}
	}

	return diff.toFixed(2);
}

export const countByKey = (rows, key) => {
	let count = 0;
	for(const row of rows) {
		if (row[key]) {
			count += row[key].length;
		}
	}

	return count;
}

const timespanLabel = (start, end) => {
	if (start && end) {
		return `${start.format('DD.MM.YYYY.')} - ${end.format('DD.MM.YYYY.')}`;
	}

	return '';
};

export { ranges, timespanLabel };
