import React, { Component } from 'react';
import './table.css';

class RoleTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			headers: [
				'Sadaļa',
				'Apskate',
				'Labot',
				'Pievienot',
				'Dzēst',
				'Eksportēt',
				'Sinhronizēt',
				'Sūtīt'
			]
		};
	}

	handleChange(event, roleIndex, section) {
		const { groupIndex, roles } = this.props;
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		//console.log(roleInd, section,inputValue);
		//const roleInd = 1, section = 1,score = 10;

		let newRoles = roles;
		newRoles[roleIndex].scores[section] = inputValue;

		//this.setState({headers, roles: newRoles});
		this.props.onDataChange(groupIndex, newRoles);
	}

	render() {
		const { headers } = this.state;
		const { roles, group } = this.props;

		return (
			<div>
				<table className='table'>
					<thead>
						<tr>
							{headers.map((cell, ind) => (
								<th key={ind}>{cell}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{roles &&
							roles.length > 0 &&
							roles.map((role, ind1) => (
								<tr key={ind1}>
									{/* LATR-31 - Esošo sadaļu Plānošana pārsaucam par Saliktās kravas */}
									<td>{group === 'Uzdevumi/braucieni' && role.title === 'Plānošana' ? 'Saliktās kravas' : role.title}</td>
									{role.scores.map((score, ind2) => (
										<td key={ind2}>
											<input
												type='checkbox'
												checked={score}
												onChange={e => this.handleChange(e, ind1, ind2)}
											/>
										</td>
									))}
								</tr>
							))}
						<tr />
					</tbody>
				</table>
			</div>
		);
	}
	//(e) => this.handleChange(e,ind1,ind2)
}

export default RoleTable;
