//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '@api';
import { Collapse } from 'react-collapse';
import Loading from 'shared/loading';
import { Input, Radio, Select, Label } from '../../../shared/form';
import Switch from 'react-bootstrap-switch';
import DeliveryEditModal from './../DeliveryEditModal';
import { toast } from 'react-toastify';
import Card from 'shared/actionButtons/card';
import { checkPermission } from '../../../permissions';
import OneCard from '../../orders/card';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import { reloadPage, isNumeric } from 'util';
import HistoryModal from 'shared/history';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import Invoice from './invoice';
import Lading from './lading';
import BraucienaForma from '../braucienaForma';

class DeliveryActions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			data: {},
			searching: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null,
			toSaveData: {},
			ladingTemplate: '',
			emailTo: '',
			emailToOther: '',
			emailTemplate: '',
			deliveryInvoiceNr: '',
			paymentDueDate: '',
			unloadDate: '',
		};
	}

	setOpenHistory = () => {
		this.setState({ historyOpen: !this.state.historyOpen });
	};

	setup() {
		const { deliveryId, taskId } = this.props;

		api.deliveries.actions({ delivery: deliveryId, task: taskId }).then(response => {
			const invoiceTotal = !response.invoiceTotal ? response.deliveryExpenses : response.invoiceTotal;

			this.setState({
				searching: false,
				error: false,
				data: response,
				deliveryStatus: response.deliveryStatusId,
				incomingInvoice: response.incomingInvoice,
				invoiceNr: response.invoiceNr,
				invoiceDate: response.invoiceDate,
				invoiceTotal: parseFloat(invoiceTotal).toFixed(2),
				deliveryExpenses: response.deliveryExpenses,
			});
		});
	}

	resetDeliveryFormProps() {
		this.setState({
			ladingTemplate: '',
			emailTo: '',
			emailToOther: '',
			emailTemplate: '',
			deliveryInvoiceNr: '',
			paymentDueDate: '',
			unloadDate: '',
		});
	}

	componentDidMount() {
		this.setup();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.deliveryId !== prevProps.deliveryId ||
			this.props.taskId !== prevProps.taskId
		) {
			this.setup();
		}
	}

	formatTime(timeStr) {
		if (!timeStr) {
			return '';
		}
		return `${timeStr.substring(6, 8)}.${timeStr.substring(4, 6)}.${timeStr.substring(
			0,
			4
		)}. ${timeStr.substring(8, 10)}:${timeStr.substring(10, 12)}:${timeStr.substring(12, 14)}`;
	}

	handleStatusChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setState({ [name]: parseInt(inputValue, 10) });
	}

	handleChange = (event, mode = null) => {
		let inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (inputValue.length > 0 && ['invoiceTotal', 'invoiceNr'].includes(name)) {
			if (isNumeric(inputValue) == false && inputValue == 'invoiceTotal') {
				return false;
			}
			inputValue = inputValue.trim();
		}

		this.setDataToState(name, inputValue);
	};

	handleStatusSave = event => {
		const { deliveryStatusId: origStatus } = this.state.data;
		const { deliveryStatus } = this.state;
		const { deliveryId: delivery } = this.props;

		if (origStatus != null && deliveryStatus == origStatus) {
			toast.info('Statuss netika izmainīts!');
			return;
		}

		api.deliveries.updateStatus({ status: deliveryStatus, delivery }).then(response => {
			if (response.status == 'ok') {
				toast.success('Statuss nomainīts!');
				setRespectTabs();
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Statuss netika nomainīts!: ${deliveryStatus}`, { autoClose: 7500 });
			}
		});
	};

	handleCloseModal = () => {
		if(this.state.openedModal === 'deliveryForm') {
			this.resetDeliveryFormProps();
		}
		this.setState({ openedModal: '' });
	};

	handleDelete = () => {
		const { deliveryId } = this.props;
		const confirm = window.confirm('Vai tiešām vēlaties dzēst piegādi?');
		if (confirm == false) {
			return;
		}
		api.deliveries.delete({ delivery: deliveryId }).then(response => {
			if (response.status == 'ok') {
				toast.success('Piegāde izdzēsta!');

				setRespectTabs();
				//setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn('Piegāde netika izdzēsta!', { autoClose: 7500 });
			}
		});
	};

	openModal = (modalType) => {
		this.setState({ openedModal: modalType });
	};

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });
		this.setState({ toSaveData, [key]: value });
	};

	handleDateChange = val => event => {
		try {
			this.setDataToState(val, event.format('YYYYMMDD'));
		} catch (err) {
			return;
		}
	};

	render() {
		//pasūtījuma labošana - iespējama arī izpildītiem pasūtījumiem. Tiešām tā vajag būt?
		const { data, searching, historyOpen,
			deliveryStatus, openedModal, invoiceNr, invoiceDate,
			invoiceTotal, emailTo, emailToOther, emailTemplate,
			deliveryInvoiceNr, paymentDueDate, unloadDate,
		} = this.state;
		const { deliveryId, taskId } = this.props;

		const invoiceReceived = invoiceNr && (invoiceDate && invoiceDate !== 'Invalid date') && invoiceTotal;

		const modalContent = <div className='row'>
						<div className='ActionCardContainer'>
							{OneCard({
								isDisabled: false,
								onClick: () =>
									this.setState({ historyOpen: !this.state.historyOpen }),
								img: <i className='fas fa-list-alt fa-4x'></i>,
								header: 'Pasūtījuma piegādes izmaiņu žurnāls'
							})}

							{OneCard({
								isDisabled: !checkPermission('edit'),
								onClick: () => this.openModal('deliveryEdit'),
								img: <i className='fas fa-pencil-alt fa-4x'></i>,
								header: 'Labot pasūtījuma piegādi'
							})}

							{OneCard({
								isDisabled:
									data.hasBeenImportedInHorizon || !checkPermission('delete'),
								onClick: this.handleDelete,
								img: <i className='fas fa-minus-square fa-4x'></i>,
								header: 'Dzēst pasūtījuma piegādi'
							})}

							{OneCard({
								isDisabled: !checkPermission('edit'),
								cssStyle: { cursor: 'auto' },
								onClick: null,
								img: <i className='fas fa-flag fa-4x'></i>,
								header: 'Pasūtījuma piegādes statusa maiņa',
								text: (
									<div className='form-group'>
										<Radio
											disabled={data.hasBeenImportedInHorizon}
											inputClass=''
											name='deliveryStatus'
											value={1}
											checkAgainst={deliveryStatus}
											handleChange={this.handleStatusChange}
											label='piegādes formēšana'
										/>
										<Radio
											disabled={data.hasBeenImportedInHorizon}
											inputClass=''
											name='deliveryStatus'
											value={2}
											checkAgainst={deliveryStatus}
											handleChange={this.handleStatusChange}
											label='atdots izpildē'
										/>
										<Radio
											disabled={data.hasBeenImportedInHorizon}
											inputClass=''
											name='deliveryStatus'
											value={3}
											checkAgainst={deliveryStatus}
											handleChange={this.handleStatusChange}
											label='aizvests'
										/>
										<Radio
											disabled={true}
											inputClass=''
											name='deliveryStatus'
											value={5}
											checkAgainst={deliveryStatus}
											handleChange={this.handleStatusChange}
											label='iekļauts rēķinā'
										/>
										<Radio
											disabled={true}
											inputClass=''
											name='deliveryStatus'
											value={6}
											checkAgainst={deliveryStatus}
											handleChange={this.handleStatusChange}
											label='arhīvā'
										/>
										<button
											disabled={!checkPermission('edit')}
											type='button'
											className='btn btn-primary'
											onClick={this.handleStatusSave}>
											Saglabāt
										</button>
									</div>
								)
							})}

							{OneCard({
								isDisabled: false,
								cssStyle: { cursor: 'auto' },
								header: 'Izdrukas formas',
								text: <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 10 }}>
										{OneCard({
											isDisabled: false,
											onClick: () => this.openModal('bol'),
											img: <i className='fas fa-clipboard fa-4x'></i>,
											header: 'Mazā pavadzīme',
											cssStyle: { border: 'none' }
										})}
										{OneCard({
											isDisabled: false,
											onClick: () => this.openModal('deliveryForm'),
											img: <i className='fas fa-clipboard fa-4x'></i>,
											header: 'Brauciena izdrukas forma',
											cssStyle: { border: 'none' }
										})}
									</div>
							})}

							{OneCard({
								isDisabled: !checkPermission('edit'),
								cssStyle: { cursor: 'auto' },
								onClick: null,
								img: <i className="fas fa-file-invoice-dollar fa-4x"></i>,
								header: 'Saņemtais rēķins',
								text: <Invoice invoiceNr={invoiceNr} handleChange={e => this.handleChange(e)} handleDateChange={this.handleDateChange} invoiceDate={invoiceDate} invoiceTotal={invoiceTotal} invoiceReceived={invoiceReceived} toSaveData={this.state.toSaveData} data={this.state.data} deliveryId={this.props.deliveryId} />
							})}
						</div>
					</div>;

		return (
			<div>
				<CustomModal
					title='Piegādes darbības'
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={searching ? <Loading /> : modalContent}
				/>
				{this.state.historyOpen && (
					<HistoryModal
						showModal={this.state.historyOpen}
						ID={deliveryId}
						mode='delivery'
						handleCloseModal={() =>
							this.setState({ historyOpen: !this.state.historyOpen })
						}
					/>
				)}
				<DeliveryEditModal
					showModal={openedModal === 'deliveryEdit'}
					handleCloseModal={() => {
						this.handleCloseModal();
						this.props.handleCloseModal();
					}}
					delivery={deliveryId}
					task={taskId}
					readOnlyMode={data.readOnly}
				/>
				{this.state.openedModal === 'deliveryForm' && (
					<CustomModal
						title='Brauciena izdrukas forma'
						showModal={this.state.openedModal === 'deliveryForm'}
						handleCloseModal={() => this.setState({ openedModal: '' })}
						content={
								<Lading emailTo={emailTo}
									handleChange={(e, mode) => this.handleChange(e, mode)}
									handleDateChange={this.handleDateChange}
									handleCloseModal={this.handleCloseModal}
									deliveryId={deliveryId}
									emailToOther={emailToOther}
									data={this.state.data}
									emailTemplate={emailTemplate}
									ladingTemplate={this.state.ladingTemplate}
									deliveryInvoiceNr={deliveryInvoiceNr}
									paymentDueDate={paymentDueDate}
									unloadDate={unloadDate} />
						}
					/>
				)}
				{this.state.openedModal === 'bol' && (
					<CustomModal
						title='Mazā pavadzīme'
						showModal={this.state.openedModal === 'bol'}
						handleCloseModal={() => this.setState({ openedModal: '' })}
						content={
							<div>
								<BraucienaForma delivery={deliveryId} />
							</div>
						}
					/>
				)}
			</div>
		);
	}
}
//

export default DeliveryActions;
