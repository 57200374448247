import React, { Component } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Loading from 'shared/loading';

class MappostCheck extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			mappostStatus: false,
			mappostAddress: '',
			mappostName: '',
			dbStatus: null
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { object, mappost, mode } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;
		if (mode == 'address') {
			api.admin.get_mappost_address_data({ object, mappost }).then(response => {
				const newState = Object.assign({}, { loading: false, error: false }, response);

				//loading: true, error: false
				this.setState(newState);
			});
		} else {
			api.admin.get_mappost_user_data(mappost).then(response => {
				const newState = Object.assign({}, { loading: false, error: false }, response);

				//loading: true, error: false
				this.setState(newState);
			});
		}
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	render() {
		const {
			loading,
			mappostStatus,
			mappostAddress,
			mappostName,
			dbStatus,
			isMappostUser,
			user
		} = this.state;
		//{"mappostStatus":true,"mappostAddress":"Daugavgr\u012bvas \u0161oseja 6, R\u012bga, Latvija","mappostName":"NordBrik AS","dbStatus":null}
		const { mode } = this.props;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						{mappostStatus || isMappostUser ? (
							<div>
								{mode == 'user' ? (
									<React.Fragment>
										<b>Mappost lietotājs</b>: {user}
									</React.Fragment>
								) : (
									<React.Fragment>
										<b>Mappost objekts</b>: {mappostName}{' '}
										<small>({mappostAddress})</small>
									</React.Fragment>
								)}
								<br />
								{mode !== 'user' &&
									(dbStatus ? (
										<span>Uzmanību! {dbStatus}</span>
									) : (
										<span>
											Šis Mappost ID nav piesaistīts nevienam objektam
										</span>
									))}
							</div>
						) : (
							<div>
								<div className='alert alert-danger' role='alert'>
									Šāda ID nav Mappost datubāzē!
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default MappostCheck;
