import api from '../api';

const auth = {
	loginUser: (username, password) => api.post('auth/login', { username, password }),

	logoutUser: () => api.post('auth/logout'),

	checkLogin: () => api.get('auth/check'),

	getPermissions: () => api.get('roles/get_user_permissions')
};

export default auth;
