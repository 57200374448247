//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import '@sections/orders/actions/modal.css';
import api from '@api';
import DeliveryEdit from './edit';

class DeliveryEditModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			orderNr: '',
			data: {},
			searching: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null
		};
	}

	componentDidMount() {
		const { orderId, orderNr } = this.props;

		//this.setState({orderId, orderNr}, () => this.setup());
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId) {
			const { orderId, orderNr } = this.props;

			//this.setState({orderId, orderNr}, () => this.setup());
		}
	}

	setup() {
		const { orderId } = this.state;

		if (orderId == null) {
			//šis izpildās, kad aizver modal logu
			this.setState({ data: {} });
			return;
		}
		//this.setState({ searching: true, error: false });

		api.deliveries.deliveryOrder(orderId).then(response => {
			this.setState({ searching: false, error: false, data: response });
		});
	}

	render() {
		const { delivery, task, readOnlyMode } = this.props;

		const title = readOnlyMode
			? 'Piegādes datu labošana (sadaļa ir lasīšanas režīmā)'
			: 'Piegādes datu labošana';
		return (
			<div>
				<CustomModal
					title={title}
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						<DeliveryEdit
							readOnlyMode={readOnlyMode}
							delivery={delivery}
							task={task}
							handleCloseModal={this.props.handleCloseModal}
						/>
					}
				/>
			</div>
		);
	}
}
//

export default DeliveryEditModal;
