import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	return parseFloat(val).toFixed(digits);
};

const columns = [
	{
		title: 'Piegādes ID',
		field: 'deliveryID'
	},
	{
		title: 'Datums',
		field: 'date'
	},
	{
		title: 'No',
		field: 'storage',
		//headerFilter: 'input',
		width: 250
	},
	{
		title: 'Uz',
		field: 'destination',
		//headerFilter: 'input',
		width: 250
	},
	{
		title: 'Faktiskā vērtība',
		field: 'real',
		formatter: cell => amountFormatter(cell, 'real', 2)
	},
	{
		title: 'Prognozētā vērtība',
		field: 'prognosed',
		formatter: cell => amountFormatter(cell, 'prognosed', 2)
	},
	{
		title: 'Attiecība pret prognozēto',
		field: 'attieciba',
		formatter: cell => amountFormatter(cell, 'attieciba', 2)
	}
];

const Table = ({ data, level, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let {storage, destination} = filters;
	storage = storage.toLowerCase();
	destination = destination.toLowerCase();

	const filterObj = {storage, destination};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;
  
	})

	return (
		<Tabulator
			columns={columns}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
