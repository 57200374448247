import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	return parseFloat(val).toFixed(digits);
};

const columns = [
	{
		title: 'Nodaļa',
		field: 'department',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Kilometri',
		field: 'distance',
	},
	{
		title: 'Mērvienība',
		field: 'unit'
	},
	{
		title: 'Kopējais daudzums',
		align:"right", 
		field: 'amount',
		formatter: cell => amountFormatter(cell, 'amount', 1)
	},
	{
		title: 'Daudzums<br />(iekļ. prod. cenā)',
		align:"right", 
		field: 'amountIncludedInDeliveryPrice',
		formatter: cell => amountFormatter(cell, 'amountIncludedInDeliveryPrice', 1)
	},
	{
		title: 'Daudzums<br />(rēķinā)',
		align:"right", 
		field: 'amountWithInvoice',
		formatter: cell => amountFormatter(cell, 'amountWithInvoice', 1)
	},
	{
		title: 'Daudzums<br />(LATRAPS)',
		align:"right", 
		field: 'amountWithLatraps',
		formatter: cell => amountFormatter(cell, 'amountWithLatraps', 1)
	},
	{
		title: 'Daudzums<br />(ārpakalpojums)',
		align:"right", 
		field: 'amountWithoutLatraps',
		formatter: cell => amountFormatter(cell, 'amountWithoutLatraps', 1)
	},
	{
		title: 'Nauda',
		align:"right", 
		field: 'money',
		formatter: cell => amountFormatter(cell, 'money', 2)
	},
	{
		title: 'Nauda<br />(LATRAPS)',
		align:"right", 
		field: 'moneyWithLatraps',
		formatter: cell => amountFormatter(cell, 'moneyWithLatraps', 2)
	},
];

const Table = ({ data, level, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let {department} = filters;
	department = department.toLowerCase();

	const filterObj = {department};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;
  
	})

	return (
		<Tabulator
			columns={columns}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
