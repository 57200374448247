import React, { Component } from 'react';
import Modal from 'sections/ordersPlanning/actions';
import api from '../../../api';
import { formatNumber , customTitle } from 'util';
import { CustomModal } from 'shared/modal';
import Loading from 'shared/loading';
import setRespectTabs from 'shared/tabs/setRespectTabs';

import Table from './tableComponent';
import { connect } from 'react-redux';
import { loadData } from '../slice';
import UzdevumiModal from 'sections/orders/uzdevumiModal';
import VedumiModal from 'sections/orders/vedumiModal';
import RelatedOrdersModal from '../relatedOrdersModal';

class OrderTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			loading: true,
			currentlyEditingUser: null,
			openedModal: '',
		};
	}

	handleOpenModal = (cellData, modalToOpen) => {
		this.setState({
			showModal: true,
			openedModal: modalToOpen,
			orderId: cellData.orderId,
			remainder: cellData.remainder,
			storageID: cellData.storage.id,
			relatedData: {
				relatedOrders: cellData.numberOfRelatedOrders,
				deliveries: cellData.numberOfDeliveries,
				tasks: cellData.numberOfTasks,
			},
		});
	};

	handleCloseModal = (ev, update = false) => {
		this.setState({ openedModal: '' });

		if (update) {
			this.props.loadData();
		}
	};

	render() {
		const {
			orderId,
			remainder,
			relatedData,
			openedModal,
			searching,
			storageID,
		} = this.state;
		const {orders} = this.props;

		if (searching) {
			return <Loading />;
		}

		return (
			<>
				<div>
					<div style={{ padding: 3 }} />
					<Table
						modalOpeningHandler={this.handleOpenModal}
					/>
				</div>

				{openedModal === 'actions' && (
					<Modal
						showModal={openedModal === 'actions'}
						orderId={orderId}
						remainder={remainder}
						relatedData={relatedData}
						closeModalWithoutReload={this.handleCloseModal}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'orders' && (
					<RelatedOrdersModal
						showModal={openedModal === 'orders'}
						planningOrderId={orderId}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'tasks' && (
					<UzdevumiModal
						showModal={openedModal === 'tasks'}
						planningOrderId={orderId}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'vedumi' && (
					<VedumiModal
						showModal={openedModal === 'vedumi'}
						planningOrderId={orderId}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
			</>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		loadData: () => {
			dispatch(loadData());
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		orders: state.planningOrders.orders
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(OrderTable);
