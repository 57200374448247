import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../shared/form';
import api from '@api';
import { toast } from 'react-toastify';
import { allFieldsHaveValues_new, isNumeric } from '../../util';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';

const personalNumberIsRequired = (bussinessForm) => ['33', '41'].includes(bussinessForm)

class CreateClient extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			showModal: false,
			toSaveData: {},
			horizonWorking: false,
			horizonCheck: false,
			horizonFound: false,
			options: []
		};
	}

	setData = () => {
		const businessFormOptions = [
			{ title: 'AS - Akciju sabiedrība', val: 31 },
			{ title: 'B - Biedrība', val: 39 },
			{ title: 'BI - Budžeta iestāde', val: 36 },
			{ title: 'CITI - Citi', val: 38 },
			{ title: 'IK - Individuālais komersants', val: 34 },
			{ title: 'IU - Individuālais uzņēmums', val: 32 },
			{ title: 'KS - Kooperatīvā sabiedrība', val: 37 },
			{ title: 'LPKS - Lauksaimniecības pakalpojumu kooperatīvā sabiedrība', val: 42 },
			{ title: 'PP - Privātpersona', val: 33 },
			{ title: 'SDV - Saimnieciskās darbības veicējs', val: 41 },
			{ title: 'SIA - Sabiedrība ar ierobežotu atbildību', val: 30 },
			{ title: 'VAS - Valsts akciju sabiedrība', val: 40 },
			{ title: 'ZS - Zemnieku saimniecība', val: 35 },
		];

		this.setState({ options: businessFormOptions });
		this.setState({ loading: false, error: false });
	};

	componentDidMount() {
		this.setData();
	}

	// componentDidUpdate(prevProps) {
	// 	//
	// }

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (inputValue.length > 0 && name == 'horizonRegNr' && this.state.horizonCheck) {
			this.setState({ horizonCheck: false, horizonFound: false });
		}

		if (inputValue.length > 0 &&
			(name == 'regNr' || name == 'horizonRegNr') &&
			isNumeric(inputValue) == false
		) {
			return false;
		}

		this.setDataToState(name, inputValue);
	};

	horizonCheck = () => {
		const { horizonRegNr } = this.state;
		this.setState({ horizonCheck: false, horizonFound: false, horizonWorking: true });

		api.clients.horizon_search(horizonRegNr).then(response => {
			if (response.status == 'success') {
				toast.success('Klients atrasts un dati importēti', { autoClose: 7500 });
				this.setState({ horizonCheck: true, horizonFound: true, horizonWorking: false });
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn('Klients nav atrasts Horizon', { autoClose: 7500 });
				this.setState({ horizonCheck: true, horizonFound: false, horizonWorking: false });
			}
		});
	};

	saveData = e => {
		const { toSaveData } = this.state;
		e.preventDefault();

		const toSaveDataKeys = Object.keys(toSaveData);
		if (toSaveDataKeys.length === 0 || (toSaveDataKeys.length === 1 && toSaveDataKeys[0] === 'horizonRegNr')) {
			toast.info('Dati nav ievadīti');
			return;
		}

		if (!('notes' in toSaveData)) {
			Object.assign(toSaveData, {notes: 'Klients pievienots no Loģistikas IS'})
		}

		const defaultFields = {
			paymentType: 'Debitors',
			bankAccount: '2314',
		}

		const finalToSend = Object.assign({}, toSaveData, defaultFields)

		const nameMap = {
			businessForm: 'Uzņēmējdarbības forma',
			regNr: 'Reģ. nr.',
			title: 'Nosaukums',
			name: 'Vārds',
			surname: 'Uzvārds',
			pvnNr: 'PVN nr.',
			street: 'Iela/Māja',
			city: 'Pilsēta/Novads',
			region: 'Pagasts',
			postalCode: 'Pasta indekss',
			country: 'Valsts',
			paymentType: 'Norēķinu veids',
			bankAccount: 'Norēķinu konts',
			notes: 'Klienta piezīmes',
			personalCode: 'Personas kods',
		};

		const fieldsToCheck = [
			'businessForm',
			'regNr',
			// 'pvnNr',
			'street',
			'city',
			// 'region',
			'postalCode',
			'country',
			'paymentType',
			'bankAccount',
			'notes'
		];

		if (personalNumberIsRequired(finalToSend.businessForm)) {
			fieldsToCheck.push('personalCode');
			fieldsToCheck.push('name');
			fieldsToCheck.push('surname');
			finalToSend['regNr'] = finalToSend['personalCode'].replace(/-/gi,'');
		} else {
			fieldsToCheck.push('title');
			// if not personal number required by businessForm value, then simply delete it (it may get filled in previous send)
			delete finalToSend['personalCode'];
			delete finalToSend['name'];
			delete finalToSend['surname'];
		}

		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			fieldsToCheck,
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		console.log('to save', JSON.stringify(finalToSend));

		this.setState({ horizonWorking: true });
		api.clients.save_new(finalToSend).then(response => {
			if (response.status == 'success') {
				toast.success('Klients izveidots', { autoClose: 7500 });
				this.setState({ horizonWorking: false });
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Klienta datu saglabāšana neveiksmīga! ${response.message}`, { autoClose: 7500 });
				this.setState({ horizonWorking: false });
			}
		});
	}

	render() {
		const {
			loading,
			horizonRegNr,
			businessForm,
			regNr,
			title,
			name,
			surname,
			pvnNr,
			personalCode,
			street,
			city,
			region,
			postalCode,
			country,
			phone,
			email,
			horizonCheck,
			horizonFound,
			horizonWorking,
			notes,
			options
		} = this.state;

		let { paymentType, bankAccount } = this.state;
		paymentType = 'Debitors'
		bankAccount = '2314';

		const needPersonalCode = personalNumberIsRequired(businessForm);

		const noResultInHorizon = horizonCheck && !horizonFound;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						{!noResultInHorizon &&
							<div className='row'>
							<Label labelClass='col-sm-3' title='Reģ. nr.' name='horizonRegNr' />
							<Input
								inputClass='col-sm-6'
								name='horizonRegNr'
								value={horizonRegNr}
								handleChange={this.handleChange}
							/>
							<button
								type='button'
								disabled={horizonCheck || horizonWorking || !horizonRegNr}
								className='btn btn-primary'
								onClick={this.horizonCheck}>
								{horizonWorking ? 'Meklē...' : 'Pārbaudīt HORIZON'}
							</button>
						</div>
						}

						{(horizonCheck && !horizonFound) &&
							<div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Uzņēmējdarbības forma' name='businessForm' required={true}/>
									<Select
										selectClass='col-sm-8'
										name='businessForm'
										handleChange={this.handleChange}
										value={businessForm}
										placeholder='Izvēlies vērtību'
										options={
											options
												? options.map(bsForm => ({
														label: bsForm.title,
														value: bsForm.val
												}))
												: []
										}
									/>
								</div>
								{!needPersonalCode && <div className='row'>
									<Label labelClass='col-sm-3' title='Reģ. nr.' name='regNr' required={true}/>
									<Input
										inputClass='col-sm-8'
										name='regNr'
										value={regNr}
										handleChange={this.handleChange}
									/>
								</div>}
								{needPersonalCode && <div className='row'>
									<Label labelClass='col-sm-3' title='Personas kods' name='personalCode' required={true} />
									<Input
										inputClass='col-sm-8'
										name='personalCode'
										value={personalCode}
										handleChange={this.handleChange}
									/>
								</div>}
								{!needPersonalCode && <div className='row'>
									<Label labelClass='col-sm-3' title='Nosaukums' name='title' required={true}/>
									<Input
										inputClass='col-sm-8'
										name='title'
										value={title}
										handleChange={this.handleChange}
									/>
								</div>}
								{needPersonalCode && <div className='row'>
									<Label labelClass='col-sm-3' title='Vārds' name='name' required={true} />
									<Input
										inputClass='col-sm-8'
										name='name'
										value={name}
										handleChange={this.handleChange}
									/>
								</div>}
								{needPersonalCode && <div className='row'>
									<Label labelClass='col-sm-3' title='Uzvārds' name='surname' required={true} />
									<Input
										inputClass='col-sm-8'
										name='surname'
										value={surname}
										handleChange={this.handleChange}
									/>
								</div>}
								<div className='row'>
									<Label labelClass='col-sm-3' title='PVN nr.' name='pvnNr' />
									<Input
										inputClass='col-sm-8'
										name='pvnNr'
										value={pvnNr}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Iela/Māja' name='street' required={true}/>
									<Input
										inputClass='col-sm-8'
										name='street'
										value={street}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Pilsēta/Novads' name='city' required={true}/>
									<Input
										inputClass='col-sm-8'
										name='city'
										value={city}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Pagasts' name='region' />
									<Input
										inputClass='col-sm-8'
										name='region'
										value={region}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Pasta indekss' name='postalCode' required={true}/>
									<Input
										inputClass='col-sm-8'
										name='postalCode'
										value={postalCode}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Valsts' name='country' required={true}/>
									<Input
										inputClass='col-sm-8'
										name='country'
										value={country}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Tālrunis' name='phone' />
									<Input
										inputClass='col-sm-8'
										name='phone'
										value={phone}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='E-pasts' name='email' />
									<Input
										inputClass='col-sm-8'
										name='email'
										value={email}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Norēķinu veids' name='paymentType'
									/>
									<Input
										disabled={true}
										inputClass='col-sm-8'
										name='paymentType'
										value={paymentType}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Norēķinu konts' name='bankAccount'
									/>
									<Input
										disabled={true}
										inputClass='col-sm-8'
										name='bankAccount'
										value={bankAccount}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label labelClass='col-sm-3' title='Klienta piezīmes' name='notes' />
									<Textarea
										inputClass='col-sm-8'
										name='notes'
										value={notes == undefined ? 'Klients pievienots no Loģistikas IS' : notes}
										handleChange={this.handleChange}
									/>
								</div>
								<button
									type='button'
									className='btn btn-success'
									disabled={horizonWorking}
									onClick={this.saveData}>
									{horizonWorking ? 'Saglabā...' : 'Izveidot klientu HORIZON'}
								</button>
							</div>
						}
					</div>
				)}
			</div>
		);
	}
}

export default CreateClient;
