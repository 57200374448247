//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Tabs } from 'react-bootstrap';
import api from '@api';
import Loading from 'shared/loading';
import 'shared/tabs/tabs.css';
import ErrorOnLoading from 'shared/errorOnLoad';
import NoData from 'shared/noDataNotification';
import TabHandler from 'shared/tabs/tabSelect';
import oneTab from 'shared/tabs/oneTab';

let handler = new TabHandler();

class CalendarOneDay extends Component {
	constructor(props) {
		super(props);

		this.state = {
			clientKey: 0,
			key: 0,
			date: '',
			loading: false,
			driverData: [],
			clientsList: [],
			filteredDrivers: [],
			error: false
		};
	}

	setup() {
		this.setState({ loading: true, error: false });
		const { dateToShow } = this.props;

		const month = (dateToShow.getMonth() + 1).toString();
		const year = dateToShow.getFullYear();
		const day = dateToShow.getDate().toString();

		const formattedDate = `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`;

		api.deliveries.calendarDay(formattedDate).then(response => {
			let sortedDrivers= [];
			if (Array.isArray(response) && response.length > 0) {
				sortedDrivers = response.sort((a, b) => a.driverName.localeCompare(b.driverName));
			}

			this.setState({ loading: false, error: false, driverData: sortedDrivers, date: formattedDate, unformattedDay: dateToShow});
		}).then(() => {
			this.getCLientList();
		});
	}

	getCLientList = () => {
		const { driverData } = this.state;

		let clients = [];
		driverData.forEach((driver, ind) => {
			if (!clients.some(client => client.name === driver.clientName)) {
				clients.push({ id: ind + 1, name: driver.clientName });
			}
		});

		const finalClientList = [
			{ id: 0, name: 'VISI PĀRVADĀTĀJI' },
			...clients.sort((a, b) => a.name.localeCompare(b.name))
		];

		this.setState({ clientsList: finalClientList, clientKey: 0, filteredDrivers: [] });
	}

	componentDidMount() {
		this.setup();
		const curTab = handler.getSettings('OrderTabs');
		if (curTab) {
			this.handleSelect(curTab);
		}
	}

	componentDidUpdate(prevState) {
		if (+this.props.dateToShow !== +prevState.dateToShow) {
			this.setup();
			handler.setUnRespecting();
		}
	}

	handleSelect = key => {
		this.setState({ key });
		handler.saveSettings('OrderTabs', key);
	};

	clientOption = (client) => {
		return (
			<button
				type='button'
				key={client.id}
				className={this.state.clientKey === client.id ? 'btn-custom active' : 'btn-custom'}
				onClick={() => this.filterDrivers(client)}
				>
				{client.name}
			</button>
		);
	};

	filterDrivers = (client) => {
		const { driverData } = this.state;
		const filteredDrivers = client.id === 0 ? driverData : driverData.filter(driver => driver.clientName === client.name);

		this.setState({ filteredDrivers, clientKey: client.id, key: 0 });
	}

	render() {
		const { driverData, clientsList, filteredDrivers, date, error, loading } = this.state;
		const drivers = filteredDrivers.length > 0 ? filteredDrivers : driverData;

		const renderContent = () => {
			if (error) return <ErrorOnLoading />;
			if (loading) return <Loading />;
			if (drivers.length === 0) return <NoData />;

			return (
				<>
					<br />
					<div className='clientBtnLayout'>
						{clientsList.map((client, ind) =>
							this.clientOption(client)
						)}
					</div>
					<br />
					<Tabs
						className='tabLayout'
						activeKey={this.state.key}
						onSelect={this.handleSelect}
						id='driver-tabs'>
						{drivers.map((tab, ind) =>
							oneTab(tab, ind, this.state.key, date)
						)}
					</Tabs>
				</>
			)
		}

		return (
			<div className='bodyWrapper'>
				{renderContent()}
			</div>
		);
	}
}

export default CalendarOneDay;
