import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from 'shared/form';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';

import { allFieldsHaveValues, formatFullDate, allFieldsHaveValues_new, reloadPage } from 'util';
//import MapObject from '@components/map';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class ResponsibleEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: []
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { name, phone } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		//{
		api.admin.responsible_get({ name, phone }).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'name',
				'phone',
				'alertResponsible'
			]);

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {};

		let try2Final = {};
		const dataToSendAsDefaults = ['name', 'phone', 'alertResponsible']; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			name: 'Vārds',
			phone: 'Telefons',
			alertResponsible: 'Vai paziņot'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['name', 'phone', 'alertResponsible'],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		//return;

		api.admin.responsible_save(finalToSend).then(response => {
			if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	render() {
		const { loading, name, phone, alertResponsible } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Vārds' name='name' />
							<Input
								disabled={true}
								inputClass='col-sm-8'
								name='name'
								value={name}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Telefons' name='phone' />
							<Input
								disabled={true}
								inputClass='col-sm-8'
								name='phone'
								value={phone}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Sūtīt paziņojumu atbildīgajam, kad pabeidz uzdevumu'
								name='alertResponsible'
							/>
							<div className='col-sm-8'>
								<Switch
									name='alertResponsible'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={alertResponsible ? alertResponsible : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'alertResponsible')
									}
								/>
							</div>
						</div>
						{checkPermission('edit') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default ResponsibleEdit;
