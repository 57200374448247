import React, { Component } from 'react';
import reactUltimatePaginationBootstrap3 from 'react-ultimate-pagination-bootstrap-3';

const UltimatePagination = reactUltimatePaginationBootstrap3;

class Pagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			total: 10
		};
	}

	onPageChange = page => {
		this.props.handlePageChange(page);
		//this.setState({page});
	};

	render() {
		const { page, total } = this.props;

		return (
			<div>
				<UltimatePagination
					currentPage={page}
					totalPages={total}
					onChange={this.onPageChange}
				/>
			</div>
		);
	}
}

export default Pagination;
