import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	return parseFloat(val).toFixed(digits);
};

const paymentTypeFormatter = (cell) => {
	const data = cell.getRow().getData();

	return data.delivery_included ? 'Iekļauts cenā' : 'Apmaksā klients';
};

const diffFormatter = (cell) => {
	const data = cell.getRow().getData();

	const diff = data.money - data.carrier_expenses;

	return diff < 0 ? `<span style="color: red; font-weight: bold;">${diff.toFixed(2)}</span>` : diff.toFixed(2);
};

const columns = transporterTitle => ([
	{
		title: transporterTitle,
		field: 'transporterInfo',
		//headerFilter: 'input',
		width: 250
	},
	{
		title: 'Nodaļa',
		field: 'department',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Mērvienība',
		field: 'unit'
	},
	{
		title: 'Apmaksas nosacījumi',
		field: 'delivery_included',
		formatter: cell => paymentTypeFormatter(cell)
	},
	{
		title: 'Kopējais daudzums',
		field: 'amountSum',
		align:"right",
		formatter: cell => amountFormatter(cell, 'amountSum', 1)
	},
	{
		title: 'Summa apmaksai klientam',
		field: 'money',
		align:"right",
		formatter: cell => amountFormatter(cell, 'money', 2)
	},
	{
		title: 'Pārvadātāja izmaksas',
		field: 'carrier_expenses',
		align:"right",
		formatter: cell => amountFormatter(cell, 'carrier_expenses', 2)
	},
	{
		title: 'Starpība',
		field: 'diff',
		align:"right",
		formatter: cell => diffFormatter(cell)
	}
]);

const Table = ({ data, level, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let {transporterInfo, department} = filters;
	transporterInfo = transporterInfo.toLowerCase();
	department = department.toLowerCase();

	const filterObj = {transporterInfo, department};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;

	})

	const transporterTitle = level === 'transporter' ? 'Pārvadātājs' : 'Auto';

	const cols = columns(transporterTitle);

	return (
		<Tabulator
			columns={cols}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
