/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../shared/form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import { toast } from 'react-toastify';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { checkPermission } from '../../permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE, invoicePDFlink, bolPDFlink } from '../../config';
import { reloadPage, isNumeric } from 'util';
import { nb } from 'date-fns/locale';

//import './edit.module.scss';

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key) && key in initialData) {
			dataForReturn[key] =
				initialData[key] == null
					? ''
					: typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class BraucienaForma extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			toSaveData: {},
			data: {},
			pdfTemplate: '',
			pdfTemplates: [],
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { delivery } = this.props;
		/*
    const delivery = '8178';//šis jāpadod no this.props;
    const task = '3446';//šis jāpadod no this.props;
    */
		api.deliveries.forma(delivery).then(response => {
			//ko darīt ar driverData? + pg_parbaudita_gatava_iekl_rekina
			const moreAttrs = getDataFromAPIResponse(response, [
				'delivery',
				'create_date',
				'deliveryAddress',
				'storageAddress',
				'deliveryAddress_MOD',
				'storageAddress_MOD',
				'receiver',
				'transport',
				'transporter',
				'driver',
				'deliveryFormNr',
				'bolID'
			]);

			const attrTransformation = {
				create_date: moment(moreAttrs['create_date']).format('DD.MM.YYYY.')
			};
			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});

		api.admin.get_email_templates('EMAIL_PDF_LADING_SMALL').then(response => {
			this.setState({pdfTemplates: response});
		})
	};

	getDefaultSelected(value) {
		if (value == '' || typeof value == 'undefined') {
			return [];
		}
		return [value];
	}
	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleDateChange = val => event => {
		try {
			this.setDataToState(val, event.format('YYYYMMDD'));
		} catch (err) {
			return;
		}
	};

	handleChange = event => {
		let inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (inputValue.length > 0 && name == 'deliveryFormNr') {
			if (isNumeric(inputValue) == false) {
				//  inputValue = parseFloat(inputValue).toFixed(3);
				//} else {
				return false;
			}

			inputValue = inputValue.trim();
		}

		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//{"piekabe":"AAA","uzkrauts":"20181129","izkrauts":"20190403","amountToDeliver":25,"deliveredInReality":"3","dikstave_h":"5","overworkHours":"5","overworkPrice":"50","dikstavePrice":"50","ordererPrice":"3","price":"6","crm":"gfgdfgfgd","deliveryNotes":"29.11.2018 \nNo rīta uzkraujamies... Cenas iepriekšējās !","delivery":"123","task":"456","driver":{"mappostUserID":12598,"carrierDriverID":376,"driverName":"AIGARS BUTKEVIČS","driverId":281,"vilcejs":"JJ1570","client":"OZOLIŅI JO, SIA","carrierId":200}}
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { delivery, task } = this.props;
		const { toSaveData, data } = this.state;
		const pdfTemplate = toSaveData.pdfTemplate;

		if (!pdfTemplate) {
			toast.info('Nav izvēlēta PDF veidne');
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		/* if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		} */

		//{"piekabe":"sdfffs","uzkrauts":"20181129","izkrauts":"20190411","amountToDeliver":25,"deliveredInReality":0,"dikstave_h":0,"overworkHours":0,"overworkPrice":0,"dikstavePrice":0,"ordererPrice":0,"price":0,"crm":"lkjljklkjk","deliveryNotes":"29.11.2018 \nNo rīta uzkraujamies... Cenas iepriekšējās !","priceFor":1,"madeLength":0,"ordererPriceFor":1,"workingHours":0,"deliveryCheckedForBill":false,"deliveryIncludedInPrice":true,"delivery":64091,"task":21750,"driver":{"mappostUserID":13158,"carrierDriverID":688,"driverName":"AIGARS GARKALNS","driverId":79,"vilcejs":"KR983","client":"A M UNA, SIA","carrierId":562}}
		const dataToSendAsDefaults = [
			'delivery',
			'create_date',
			'deliveryFormNr',
			'deliveryAddress_MOD',
			'storageAddress_MOD',
			'pdfTemplate',
		]; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga

		//todo: šo laikam varētyu apvienot ar reallyChanged masīva izveidi
		let try2Final = {};

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}
		//console.log(try2Final);

		//const try2Final = dataToSendAsDefaults.map(arrKey => reallyChanged.includes(arrKey) ? reallyChanged[arrKey] : data[arrKey]);

		const finalToSend = Object.assign({}, try2Final);

		console.log('to save', JSON.stringify(finalToSend));

		//vēl skatīt datu validāciju (validateDeliveryCostFields) - saveNewTaskSingle funkcija
		//return;
		api.deliveries.forma_upd({ data: finalToSend }).then(response => {
			if (response.status == 'error') {
				toast.warn(response.message, { autoClose: 10000 });
			} else if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				this.setState({ bolID: response.id });

				this.handleOpenBOL();

				//setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas! ${response.message}`, { autoClose: 7000 });
			}
		});
	};

	handleCopy = (orig, target) => {
		this.setState({
			[target]: this.state[orig]
		});
	};

	handleOpenBOL = () => {
		const { bolID } = this.state;

		window.open(`${bolPDFlink}${bolID}`, '_blank');
	};

	render() {
		const {
			deliveryFormNr,
			create_date,
			deliveryAddress,
			storageAddress,
			deliveryAddress_MOD,
			storageAddress_MOD,
			receiver,
			transport,
			transporter,
			driver,
			loading,
			pdfTemplates,
		} = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Pavadzīmes numurs'
								name='deliveryFormNr'
							/>
							<Input
								inputClass='col-sm-9'
								name='deliveryFormNr'
								value={deliveryFormNr}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Izveides datums'
								name='create_date'
							/>
							<div className='col-sm-9'>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange('create_date')}
									defaultValue={create_date !== 'Invalid date' ? create_date : ''}
								/>
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title={
									<span>
										Izsniegšanas adrese{' '}
										<span
											onClick={() =>
												this.handleCopy(
													'storageAddress',
													'storageAddress_MOD'
												)
											}>
											<i className='fas fa-copy'></i>
										</span>
									</span>
								}
								name='storageAddress_MOD'
							/>
							<Input
								inputClass='col-sm-9'
								name='storageAddress_MOD'
								value={storageAddress_MOD}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Oriģinālā izsniegšanas adrese'
								name='storageAddress'
							/>
							<Input
								disabled={true}
								inputClass='col-sm-9'
								name='storageAddress'
								value={storageAddress}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title={
									<span>
										Saņemšanas adrese{' '}
										<span
											onClick={() =>
												this.handleCopy(
													'deliveryAddress',
													'deliveryAddress_MOD'
												)
											}>
											<i className='fas fa-copy'></i>
										</span>
									</span>
								}
								name='deliveryAddress_MOD'
							/>
							<Input
								inputClass='col-sm-9'
								name='deliveryAddress_MOD'
								value={deliveryAddress_MOD}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Oriģinālā saņemšanas adrese'
								name='deliveryAddress'
							/>
							<Input
								disabled={true}
								inputClass='col-sm-9'
								name='deliveryAddress'
								value={deliveryAddress}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Saņēmējs' name='receiver' />
							<Input
								disabled={true}
								inputClass='col-sm-9'
								name='receiver'
								value={receiver}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Transporta līdzeklis'
								name='transport'
							/>
							<Input
								disabled={true}
								inputClass='col-sm-9'
								name='transport'
								value={transport}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Pārvadātājs' name='transporter' />
							<Input
								disabled={true}
								inputClass='col-sm-9'
								name='transporter'
								value={transporter}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Šoferis' name='driver' />
							<Input
								disabled={true}
								inputClass='col-sm-9'
								name='driver'
								value={driver}
								handleChange={this.handleChange}
							/>
						</div>
						{this.state.pdfTemplates.length > 0 &&
							<div className='row'>
								<Label
									labelClass='col-sm-3'
									title='Pavadzīmes veidne:'
									name='pdfTemplate'
									required={true}
								/>
								<Select
									selectClass='col-sm-8'
									handleChange={this.handleChange}
									name='pdfTemplate'
									value={this.state.pdfTemplate}
									options={pdfTemplates.map(tpl => ({value: tpl.id, label: tpl.name}))}
								/>
							</div>
						}
						<div className='btn-group' role='group' style={{ marginTop: 5 }}>
							{this.state.bolID !== '' && (
								<button
									type='button'
									className='btn btn-default'
									onClick={this.handleOpenBOL}>
									<i className='fas fa-file'></i> Skatīt pavadzīmi
								</button>
							)}
							<button
								type='button'
								disabled={
									false //readOnlyMode || !checkPermission('add') || !checkPermission('edit')
								}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt un atvērt pavadzīmi!
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default BraucienaForma;
