import React, { Component } from 'react';
import TableComp from './table';
import api from '@api';
import {
	formatNumber,
	isNumeric,
	allFieldsHaveValues_new
} from '../../../util';
import { Label, Select } from '../../../shared/form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from 'moment';
import 'moment/locale/lv';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import '../table.css';
import Loading from 'shared/loading';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import clsx from 'clsx';
import { connect } from 'react-redux';

const formatTableResponseForSave = data => {
	const dataForReturn = data.map(row => {
		const { orderId: order, availableAmount_tmp: amount, deliveryPrice, priceFor } = row;

		return { order, amount, deliveryPrice, priceFor };
	});

	return dataForReturn;
};

const materialFormatter = cell => {
	//const allRow = cell.getRow().getData();
	//console.log(allRow);
	const data = cell.getRow().getData();
	//console.log(data);

	let result = [];

	result.push(`<b>${data.material}</b>`);

	if (data.isIncludedInPrise === true) {
		result.push('<span style="color: red;">Piegāde iekļauta produkta cenā</span>');
	}

	result.push(data.materialNotes);

	return `<span class="preWrap">${result.join('<br>')}</span>`;
};

const routeFormatter = cell => {
	const data = cell.getRow().getData();
	//{"storage":{"id":2041,"address":"LATRAPS LPKS  - SALDUS","title":"LATRAPS-SALDUS, GR\u012a\u0160\u013bI, SALDUS PAG., SALDUS NOV.","phone":""},"payerDelivery":{"title":"","address":"LATRAPS LPKS - LIETUVAS IELA 16A, ELEJA, ELEJAS PAG., JELGAVAS NOV., LV-3023"},"plannedTasks":4,"availableAmount":0,"measurementUnit":"Fpll","deliveryId":21552,"orderNr":21442,"title":"LATRAPS, LPKS","material":"AAL","notes":"","deliveryIncludedInPrice":"on","orderId":21552,"deliveryPrice":0,"priceFor":1}
	const {
		storage: { address: storageAddr, title: storageTitle }
	} = data; //cell._cell.row.data;

	//console.log(section1, measurementUnit);

	return `<span class="preWrap">${storageAddr} -> ${storageTitle}</span>`;
};

const priceForFormatter = (cell, optionMap) => {
	const currValue = cell.getValue();

	return optionMap[currValue];
};

const driverFormat = (option, props, idx) => {
	return (
		<span style={{ fontSize: '75%' }} key={idx}>
			{option.driverName}{' '}
			{option.mappostUserID > 0 ? (
				<span style={{ color: 'green' }}>(MAPPOST lietotājs)</span>
			) : (
				<span style={{ color: '#ff0000' }}>(Nav reģistrēts MAPPOST sistēmā)</span>
			)}{' '}
			- {option.client} - {option.vilcejs}
		</span>
	);
};

class MultiTaskTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wrongData: false,
			sending: false,
			orders: [],
			loading: true,
			currentlyEditingUser: null,
			searching: false,
			priceForOptions: [],
			toSaveData: {},
			date: moment(new Date()).format('DD.MM.YYYY.'),
			gadget: '1',
			data: {
				gadget: '1',
				date: moment(new Date()).format('YYYYMMDD'),
				driver: {}
			}
		};
	}

	setup() {
		//dept - padots no props
		//dept={tab.id} rows={tab.orderCount} client={client} year={thisYear}
		this.setState({ searching: true, error: false });

		const { orders } = this.props;
		const ordersForSearch = typeof orders == 'string' ? orders : orders.join(',');

		api.tasks.dataForMultitask(ordersForSearch).then(response => {
			const { orders, priceForOptions, isMappostEnabled } = response;
			//console.log('...',response);

			const gadgetVal = isMappostEnabled ? '1' : '2';

			this.setState({
				searching: false,
				error: false,
				orders,
				priceForOptions,
				isMappostEnabled,
				gadget: gadgetVal,
				data: {
					...this.state.data,
					gadget: gadgetVal
				}
			});
		});
	}

	updateStateAfterEdit = (row, field, newValue) => {
		const { orders } = this.state;

		orders[row][field] = newValue;

		const newState = Object.assign({}, this.state, { orders });
		this.setState(newState);
	};

	componentDidMount() {
		this.setup();
	}

	//customSelect

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });

		//šis jāsalabo - jāmaina this.state.options, kad nomainās gadget vērtība
		if (key == 'gadget') {
			this._handleSearch('');
			//mainās 'ar mappost'/'bez mappost'
			//this.typeahead.getInstance().clear();
		}
	};

	_handleSearch = query => {
		this.setState({ isLoading: true });
		const mappost = this.state.gadget == '1' ? true : null;
		api.search.drivers({ query, mappost }).then(response => {
			this.setState({ options: response, isLoading: false });
		});
	};

	onDriverSelect = driver => {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	};

	setFieldError = field => {
		this.setState({
			dataerrors: {
				[field]: true
			}
		});
	};

	saveData = e => {
		this.setState({ dataerrors: {} });
		const { task } = this.props;
		const { toSaveData, data, orders } = this.state;

		e.preventDefault();

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] != data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		}

		const dataToSendAsDefaults = ['date', 'gadget', 'driver'];
		//orderStatusForSave - šo vajadzētu pārsaukt pirms sūtīšanas, bet pašlaik vienalga
		//'storageID','ordererAddressID', - šie ir no asynctypeahead, kur ir cita struktūra

		let try2Final = {};

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else if (theKey in data) {
				try2Final[theKey] = data[theKey];
			}
		}

		const tableData = formatTableResponseForSave(orders);

		for (let i = 0; i < orders.length; i++) {
			const orderNr = i + 1;
			const { availableAmount_tmp: amount, deliveryPrice, priceFor } = orders[i];

			if (parseFloat(amount) == 0 || amount.toString().trim() == '') {
				toast.warn(`${orderNr}. pasūtījumam nav norādīts daudzums`, { autoClose: 3500 });
				return;
			}

			if (parseFloat(deliveryPrice) == 0 || deliveryPrice.toString().trim() == '') {
				toast.warn(`${orderNr}. pasūtījumam nav norādīta pasūtītāja cena`, {
					autoClose: 3500
				});
				return;
			}

			if (parseFloat(deliveryPrice) > 0 && priceFor == 1) {
				toast.warn(`${orderNr}. pasūtījumam nav norādīta pasūtītāja cenas mērvienība`, {
					autoClose: 3500
				});
				return;
			}
		}

		const bigSumAmount = tableData.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

		const finalToSend = Object.assign(
			{},
			try2Final,
			{ amount: bigSumAmount },
			{ orders: tableData }
		);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			date: 'Datums',
			gadget: 'Izpildes veids',
			driver: 'Šoferis',
			orders: 'Pasūtījumi'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['date', 'gadget', 'driver', 'orders'],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			this.setFieldError(confirmAllFieldsHasValues.field);
			return;
		}

		//return;
		this.setState({ sending: true });
		api.tasks.saveMultitask(finalToSend).then(response => {
			this.setState({ sending: false });
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				this.props.onSuccess();
				//setRespectTabs();
				//setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn('Dati netika saglabāti', { autoClose: 7500 });
			}
		});

		//for sending: {"date":"20190503","gadget":"1","driver":{"mappostUserID":10670,"carrierDriverID":322,"driverName":"JĀNIS BAKSTĪTĀJS","driverId":237,"vilcejs":"BAIGI VELK","client":"DAUKSTS MĀRTIŅŠ","carrierId":282},"orders":[{"order":21552,"amount":0,"deliveryPrice":0,"priceFor":"3"},{"order":21567,"amount":"6.76","deliveryPrice":"6","priceFor":"4"}]}
	};

	myCustomSum(data, fieldVal) {
		let sums = {};

		data.map(tableRow => {
			const { measurementUnit, [fieldVal]: field } = tableRow;

			if (measurementUnit in sums) {
				sums[measurementUnit] += parseFloat(field);
			} else {
				sums[measurementUnit] = parseFloat(field);
			}
		});

		const res = Object.keys(sums).map(meas => `${sums[meas]} ${meas}`);

		return res.join(', ');
	}

	handleDateChange = value => {
		//console.log('handleDateChange',event,event.format('YYYYMMDD'));

		if (typeof value == 'string' && value != '') {
			this.setState({ wrongData: true });
		} else {
			const valueToSave = value == '' ? value : value.format('YYYYMMDD');
			this.setDataToState('date', valueToSave);
			this.setState({ wrongData: false });
		}

		//this.setDataToState('date',event.format('YYYYMMDD'));
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value; //isNaN(event.target.name) ? event.target.value : parseFloat(event.target.name);
		const name = event.target.name;

		if (
			inputValue.length > 0 &&
			(name == 'amount' || name == 'amountKavas' || name == 'taskPrice') &&
			isNumeric(inputValue) == false
		) {
			return false;
		}

		this.setDataToState(name, inputValue.trim());
	};

	updateHandler = (orders) => {
		console.log('updateHandler', orders)
		this.setState({orders})
	}

	render() {
		const { orders, searching, gadget, date, sending, wrongData } = this.state;

		//const mySums = this.myCustomSum(orders,'availableAmount_tmp');
		const { orders: ord, priceForOptions, options, isMappostEnabled, ...rest } = this.state;

		const optionMap = priceForOptions.reduce((acc, currVal) => {
			acc[currVal.value] = currVal.label;
			return acc;
		}, {});
		return (
			<div>
				{searching ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Izpildes veids'
								name='gadget'
								required={true}
							/>
							<Select
								selectClass='col-sm-8'
								handleChange={this.handleChange}
								name='gadget'
								value={gadget}
								placeholder='Izvēlies vērtību'
								options={
									isMappostEnabled
										? [
												{ label: 'Ar Mappost', value: 1 },
												{ label: 'Bez Mappost', value: 2 }
										  ]
										: [{ label: 'Bez Mappost', value: 2 }]
								}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Šoferis'
								name='driver'
								required={true}
							/>
							<div className='col-sm-8'>
								{/*
								//@ts-ignore
								*/}
								<AsyncTypeahead
									id='driverSearch'
									isLoading={this.state.isLoading}
									options={this.state.options}
									labelKey='driverName'
									filterBy={(option, props) => {
										return option;
									}}
									//filterBy={['driverName', 'client']}
									ref={typeahead => (this.typeahead = typeahead)}
									onChange={selected => this.onDriverSelect(selected[0])}
									minLength={0}
									clearButton={true}
									onSearch={this._handleSearch}
									placeholder='Meklēt šoferi'
									renderMenuItemChildren={(option, props, idx) =>
										driverFormat(option, props, idx)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Piegādi sākt'
								name='date'
								required={true}
							/>
							<div
								className={clsx({
									'col-sm-8': !wrongData,
									'col-sm-7': wrongData
								})}>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange}
									defaultValue={date}
								/>
							</div>
							{wrongData && (
								<div className='col-sm-1'>
									<OverlayTrigger
										placement='right'
										overlay={
											<Tooltip id='tooltip-right'>
												Datums ir jāapstiprina kalendāra izvēlnē
											</Tooltip>
										}>
										<i className='fas fa-exclamation-triangle'></i>
									</OverlayTrigger>
								</div>
							)}
						</div>
						<div>
							{orders && orders.length > 0 && (
								<div>
									<TableComp
										updateHandler={this.updateHandler}
										cena_par={optionMap}
										//columns={this.columns()}
										orders={orders}
									/>
									<span style={{ alignContent: 'center' }}>
										Kopā: {this.myCustomSum(orders, 'availableAmount_tmp')}
									</span>
								</div>
							)}
						</div>
						<div>
							<button
								type='button'
								className='btn btn-primary'
								onClick={this.saveData}
								disabled={sending}>
								Izveidot saliktās kravas uzdevumu
							</button>
						</div>
						{/*<pre>{JSON.stringify(rest, null, 2)}</pre>*/}
					</div>
				)}
			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		orders: state.planning.orders
	}
};

export default connect(mapStateToProps)(MultiTaskTable);
