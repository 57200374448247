import api from '../api';

const favorites = {
	add: ({ type, id }) => api.post(`favorites/add`, { type, id }),

	remove: ({ type, id }) => api.post(`favorites/remove`, { type, id }),

	userFavorites: () => api.get(`favorites/get_for_user`),
};

export default favorites;
