import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';
import { createSelector } from 'reselect'

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	return parseFloat(val).toFixed(digits);
};

const columns = [
	{
		title: 'Rēķina numurs',
		field: 'bill_nr',
		width: 150
	},
	{
		title: 'Rēķina datums',
		field: 'bill_date',
		width: 150
	},
	{
		title: 'Brauciena datums',
		field: 'delivery_date',
		width: 150
	},
	{
		title: 'Maksātājs',
		field: 'payer',
		width: 150
	},
	{
		title: 'No',
		field: 'storage',
		width: 150
	},
	{
		title: 'Uz',
		field: 'delivery_endpoint',
		width: 150
	},
	{
		title: 'Materiāls',
		field: 'material',
		width: 150
	},
	{
		title: 'Pārvadātājs',
		field: 'carrier',
		width: 150
	},
	{
		title: 'Mašīna',
		field: 'car',
		width: 150
	},
	{
		title: 'Šoferis',
		field: 'driver',
		width: 150
	},
	{
		title: 'Saņemtais daudzums',
		field: 'received_amount',
		width: 150,
		formatter: cell => amountFormatter(cell, 'received_amount', 2)
	},
	{
		title: 'Mērvienība',
		field: 'unit',
		width: 150
	},
	{
		title: 'Klienta rēķina cena',
		field: 'orderer_price',
		width: 150,
		formatter: cell => amountFormatter(cell, 'orderer_price', 2)
	},
	{
		title: 'Pārvadātāja cena',
		field: 'carrier_price',
		width: 150,
		formatter: cell => amountFormatter(cell, 'carrier_price', 2)
	},
	{
		title: 'Summa bez PVN',
		field: 'bill_sum_wo_pvn',
		width: 150,
		formatter: cell => amountFormatter(cell, 'bill_sum_wo_pvn', 2)
	},
	{
		title: 'Pārvadātāja izmaksas',
		field: 'carrier_expenses',
		width: 150,
		formatter: cell => amountFormatter(cell, 'carrier_expenses', 2)
	},
	{
		title: 'Starpība',
		field: 'diff',
		width: 150,
		formatter: cell => amountFormatter(cell, 'diff', 2)
	}
];

const Table = ({ data, level, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}
	let {auto, department, driver, material} = filters;
	auto = auto.toLowerCase();
	department = department.toLowerCase();
	driver = driver.toLowerCase();
	material = material.toLowerCase();

	const filterObj = {auto, department, driver, material};

	/* let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;

	}) */

	const cols = columns;

	return (
		<Tabulator
			columns={cols}
			options={data.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={data}
		/>
	);
};

export default Table;
