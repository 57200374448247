import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
	loggedIn: false,
	loginError: false,
	horizonSync: false,
	ignoreMaterialTransportationPrice: false,
	canEditCarrierPrice: false,
	canApproveOrdererPrice: false,
	canUpdateDeliveriesInBatch: false,
	reports: false,
	username: '',
	loggingIn: true,
	roles: {},
	readPerms: [],
	services: {},
	appError: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logoutUser: (state, action) => {
			return {
				...initialState,
				loggingIn: false
			};
		},
		request: (state, action) => {
			return {
				...initialState,
				loggingIn: true, loginError: false
			};
		},
		setAppError: (state, action) => {
			return {
				...initialState,
				appError: true
			};
		},
		removeLoginErrors: (state, action) => {
			state.loginError = false;
		},
		checkStatusError: (state, action) => {
			state.loggingIn = false;
		},
		loginError: (state, action) => {
			state.loginError = action.payload;
			state.loggingIn = false;
		},
		loginUser: (state, action) => {
			const {
				data: { vards, uzvards },
				roles,
				read,
				serviceToggle,
				sync_horizon: horizonSync,
				services,
				reports,
				ignore_material_transportation_price: ignoreMaterialTransportationPrice,
				can_approve_orderer_price: canApproveOrdererPrice,
				can_update_deliveries_in_batch: canUpdateDeliveriesInBatch,
				can_edit_carrier_price: canEditCarrierPrice,
			} = action.payload;

			return {
				loggedIn: true,
				loginError: false,
				loggingIn: false,
				username: `${vards} ${uzvards}`,
				readPerms: read,
				reports,
				roles,
				horizonSync,
				serviceToggle,
				services,
				ignoreMaterialTransportationPrice,
				canApproveOrdererPrice,
				canUpdateDeliveriesInBatch,
				canEditCarrierPrice,
				appError: null
			};
		},
		checkStatusSuccess: (state, action) => {
			const {
				roles, read, sync_horizon: horizonSync, services, serviceToggle, reports, ignore_material_transportation_price: ignoreMaterialTransportationPrice, can_approve_orderer_price: canApproveOrdererPrice, can_update_deliveries_in_batch: canUpdateDeliveriesInBatch, can_edit_carrier_price: canEditCarrierPrice, vards, uzvards,
			} = action.payload;

			var username = `${vards} ${uzvards}`;

			return {
				loggedIn: true,
				loginError: false,
				loggingIn: false,
				username,
				roles,
				readPerms: read,
				horizonSync,
				serviceToggle,
				services,
				reports,
				ignoreMaterialTransportationPrice,
				canUpdateDeliveriesInBatch,
				canApproveOrdererPrice,
				canEditCarrierPrice,
				appError: null
			};
		}
	},
});

export const {checkStatusSuccess, loginUser, loginError, checkStatusError, removeLoginErrors, setAppError, request, logoutUser } = authSlice.actions;

export default authSlice.reducer;
