/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import api from '@api';
import OrderTabs from '@sections/orders/tabs/orderTabs';
import { Select } from '../../shared/form';
import Loading from 'shared/loading';
import { CustomModal } from 'shared/modal';
import ClientEdit from './edit';
import FilterText from '../../shared/filter/format';
import { checkPermission } from '../../permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { loadFromClient } from 'sections/orders/tabs/slice';
import { setClient, removeClient } from './slice';

//
const infoCard = data => (
	<div
		className={clsx({
			clientInfoCard: true,
			redBg: data.isError
		})}>
		{data.value}
		<br />
		<span style={{ float: 'right', alignSelf: 'flex-end' }}>
			{data.icon} <span style={{ fontWeight: 'bold' }}>{data.text}</span>
		</span>
	</div>
);

class ClientDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		const { showModal } = this.state;
		const { clientInfo: data, regNr } = this.props;

		return (
			<div>
				{checkPermission('edit', 'clients') && (
					<div style={{ float: 'right' }}>
						<button
							type='button'
							className='btn btn-primary'
							onClick={this.handleToggleModal}>
							Labot datus
						</button>
					</div>
				)}
				<h3>{data.clientName}</h3>
				{data.is_archived &&
					<p
						style={{ color: '#ff0000' }}>
						(arhivēts klients)
					</p>
				}
				<div className='row'>
					<div className='clientInfoCards'>
						{infoCard({
							value: data.address,
							icon: <i className='fas fa-address-card' title='Adrese'></i>,
							text: 'Adrese'
						})}
						{data.bankAccounts.length > 0
							? infoCard({
									value: data.bankAccounts.join('; '),
									icon: (
										<i
											className='fas fa-money-check-alt'
											title='Bankas konta numurs'></i>
									),
									text: 'Bankas konta numurs'
							  })
							: infoCard({
									value: 'Klientam nav pieejams neviens maksājumu konts!',
									icon: <i className='fas fa-exclamation-circle'></i>,
									text: 'Bankas konta numurs',
									isError: true
							  })}
						{data.phone[0] == '' && data.eMail[0] == '' && data.fax == '' ? (
							infoCard({
								value:
									'Klientam nav pieejama kontaktinformācija (tālrunis, faksa nr. vai e-pasts)!',
								icon: <i className='fas fa-exclamation-circle'></i>,
								text: 'Kontaktinformācija',
								isError: true
							})
						) : (
							<React.Fragment>
								{/* vajadzētu vienkāršot back-end pusē - ja nav telefona, tad null etc. */}
								{data.phone[0] !== '' &&
									infoCard({
										value: data.phone.join('; '),
										icon: (
											<i className='fas fa-phone' title='Telefona numurs'></i>
										),
										text: 'Telefona numurs'
									})}
								{data.fax !== '' &&
									infoCard({
										value: data.fax,
										icon: <i className='fas fa-phone' title='Faksa numurs'></i>,
										text: 'Faksa numurs'
									})}
								{data.smsInfoPhone !== '' &&
									infoCard({
										value: (
											<span>
												{data.smsInfoPhone}{' '}
												{data.infoPhoneEnabled === 1 ? (
													<i
														className='fas fa-check-circle'
														style={{ color: 'green' }}
														title='aktīvs'></i>
												) : (
													<i
														className='fas fa-minus-circle'
														style={{ color: 'red' }}
														title='atslēgts'></i>
												)}
											</span>
										),
										icon: (
											<i
												className='fas fa-sms'
												title='Telefona numurs SMS sūtīšanai'></i>
										),
										text: 'Telefons SMS sūtīšanai'
									})}
								{data.contactPerson &&
									infoCard({
										value: data.contactPerson,
										icon: (
											<i
												className='fas fa-user fa-1x'
												title='Kontaktpersona'></i>
										),
										text: 'Kontaktpersona'
								})}
								{data.eMail[0] !== '' &&
									infoCard({
										value: data.eMail.map((email, i) => (
											<span key={email}>
												<a key={email} href={`mailto:${email}`}>
													{email}
												</a>
												{i < data.eMail.length - 1 && ', '}
											</span>
										)),
										icon: (
											<i
												className='fas fa-envelope-open fa-1x'
												title='E-pasts'></i>
										),
										text: 'E-pasts'
									})}
							</React.Fragment>
						)}
						{data.notes !== '' &&
							infoCard({
								value: data.notes,
								icon: <i className='fas fa-sticky-note 1x' title='Piezīmes'></i>,
								text: 'Piezīmes'
							})}
					</div>
				</div>
				<CustomModal
					title='Klienta datu labošana'
					showModal={showModal}
					handleCloseModal={this.handleToggleModal}
					content={<ClientEdit id={regNr} handleCloseModal={this.handleToggleModal} />}
				/>
			</div>
		);
	}
}

class ClientProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			currentlyEditingUser: null,
			today: [],
			last: [],
			clientId: '',
			currOrder: null,
			clientInfo: {},
			orders: {}
		};
	}

	setup() {
		const { id, regnr } = this.props.match.params;

		const parameter = regnr == null || typeof regnr == 'undefined' ? 'client' : 'reg_nr';
		const identifier = regnr == null || typeof regnr == 'undefined' ? id : regnr;

		this.setState({ loading: true, error: false });

		api.clients.details({ identifier, parameter }).then(response => {
			this.setState({
				loading: false,
				error: false,
				clientInfo: response.clientInfo,
				regNr: response.regNr,
				clientId: response.clientID,
				orders: response.orderInfo
			});

			this.props.loadData({
				meta: response.orderInfo,
				isActive: response.clientInfo.activity,
				orders: response.orderInfo,
				include: {
					newOrders: true,
					transport:
						response.clientInfo.clientParvadatajs &&
						checkPermission('read', 'transport'),
					invoices:
						response.clientInfo.numberOfBills > 0 && checkPermission('read', 'bills'),
					problematic: response.orderInfo.problematic,
					invoiceReady: response.orderInfo.invoiceReady,
					archivedTasks: response.orderInfo.archived
				},
				id: response.clientID,
				reg_nr: response.regNr
			});
		});
	}

	componentDidMount() {
		this.setup();
	}

	componentWillUnmount() {
		this.props.cleanup();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.match.params.id !== prevProps.match.params.id ||
			this.props.match.params.regnr !== prevProps.match.params.regnr
		) {
			this.setup();
		}
	}

	render() {
		const { loading, error, clientInfo, regNr } = this.state;

		if (error) {
			return (
				<div className='bodyWrapper'>
					<ErrorOnLoading />
				</div>
			);
		}

		if (loading) {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				<FilterText />
				<ClientDetails clientInfo={clientInfo} regNr={regNr} />
				<div style={{ paddingBottom: 10 }}></div>
				<OrderTabs />
			</div>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		loadData: ({ meta, orders, include, id, reg_nr, isActive }) => {
			dispatch(
				setClient({
					active_client: isActive,
					id,
					reg_nr
				})
			);
			dispatch(
				loadFromClient({
					meta, orders,
					include
				})
			);
		},
		cleanup: () => {
			dispatch(removeClient());
		}
	};
};

export default connect(null, mapDispatchToPropsRes)(ClientProfile);
