import { getCurrPage } from 'util';

export const setUIPosition = (
	specificPosition = document.body.scrollTop || document.documentElement.scrollTop
) => {
	const currPage = getCurrPage();
	const currLocalStorage = localStorage.getItem('ui-position');

	if (currLocalStorage !== null) {
		//šis jānočeko, vai šādi strādā
		let position = JSON.parse(currLocalStorage);

		//console.log('scrollTop',document.body.scrollTop, document.documentElement.scrollTop);
		//if (currPage in position) {
		position[currPage] = specificPosition; //document.body.scrollTop;//'XXXXXXXXXXXXXXXXXXXXXpx';//todo: fixme!
		//}

		localStorage.setItem('ui-position', JSON.stringify(position));

		//console.log('bad permissions',section, action,permissions[section][action]);

		return false;
	}
};

const getCurrentPosition = () => {
	/*
  const currLocalStorage = localStorage.getItem('ui-position');
  
if (currLocalStorage !== null) {//šis jānočeko, vai šādi strādā
  const currPage = getCurrPage();
  let position = JSON.parse(currLocalStorage);

  if (currPage in position) {
    return position[currPage];
  } else {
    return 0;
  }
}

return 0;
*/
};

export const setPageOnPosition = () => {
	/*
  const currPos = getCurrentPosition();
  console.log('currPos',currPos);

  if (currPos > 0) {
    console.log('scrolling',currPos);
    window.scrollTo(0,currPos);
  }
  */
};

export const handleUIPositionChange = () => {
	//const currPage = getCurrPage();
	//const currLocalStorage = localStorage.getItem('ui-position');

	//console.log(currLocalStorage);
	setUIPosition();

	//setTimeout(setUIPosition,1000);
};
