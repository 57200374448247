import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import Datetime from 'react-datetime';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import { getCurrPage, reloadPage } from '../../util';
import { toast } from 'react-toastify';
import Loading from 'shared/loading';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import setRespectTabs from 'shared/tabs/setRespectTabs';

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes, includeIfNotFound = false) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] = initialData[key];
		} else {
			dataForReturn[key] = '';
		}
	}

	return dataForReturn;
};

const formatDate = dateStr => {
	if (!dateStr) {
		return '';
	}
	return `${dateStr.substring(6, 8)}.${dateStr.substring(4, 6)}.${dateStr.substring(0, 4)}.`;
};

const CustomTypeAhead = props => {
	//onChange, toRender uzlabot
	const {
		isLoading,
		options,
		labelKey,
		selected,
		onChange,
		onSearch,
		placeholder,
		toRender,
		multiple,
		id
	} = props;

	return (
		<AsyncTypeahead
			isLoading={isLoading}
			id={id}
			options={options}
			labelKey={labelKey}
			filterBy={(option, props) => {
				return option;
			}}
			onChange={onChange}
			selected={selected}
			minLength={0}
			clearButton={true}
			onSearch={onSearch}
			placeholder={placeholder}
			renderMenuItemChildren={toRender}
			multiple={multiple || false}
		/>
	);
};

class Filter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			toSaveData: {},
			data: {},
			options: {
				departments: [],
				orderer: [],
				storage: [],
				destination: [],
				material: [],
				orderNr: [],
				pvz: [],
				carrier: [],
				driver: []
			}
		};
	}

	setData = () => {
		let currPage = getCurrPage();
		this.setState({ loading: true, error: false });

		api.filter.getState(currPage).then(response => {
			const newAttrs = getDataFromAPIResponse(
				response,
				[
					'orderer',
					'storage',
					'destination',
					'material',
					'orderNr',
					'pvz',
					'carrier',
					'car',
					'paymentType',
					'driver',
					'departments',
					'deliveryType',
					'orderType',
					'dateFrom',
					'dateTill',
					'taskType'
				],
				true
			);

			const attrTransformation = {
				dateFrom: formatDate(newAttrs.dateFrom),
				dateTill: formatDate(newAttrs.dateTill)
			};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				response,
				newAttrs,
				attrTransformation
			);
			this.setState(newState);
		});
	};

	componentDidMount() {
		this.setData();
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleDateChange = val => event => {
		try {
			this.setDataToState([val], event.format('YYYYMMDD'));
		} catch (err) {
			return;
		}
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	deleteFilter = () => {
		this.setState({ toSaveData: {} }, () => this.handleSave({}));
	};

	handleSave = finalToSend => {
		const currPage = getCurrPage();

		api.filter.save({ section: currPage, data: finalToSend }).then(response => {
			if (response.status == 'success') {
				toast.success('Filtrs saglabāts');
				setRespectTabs();
				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn('Filtrs netika saglabāts', { autoClose: 7500 });
			}
		});
	};

	saveData = e => {
		e.preventDefault();

		const { toSaveData, data } = this.state;

		if (Object.keys(toSaveData).length === 0) {
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			return;
		}

		const defaultFields = {};

		const dateFrom =
			'dateFrom' in reallyChanged
				? reallyChanged.dateFrom
				: 'dateFrom' in data
				? data.dateFrom
				: null;
		const dateTill =
			'dateTill' in reallyChanged
				? reallyChanged.dateTill
				: 'dateTill' in data
				? data.dateTill
				: null;

		reallyChanged = Object.assign({}, reallyChanged, {
			dates: { from: dateFrom, till: dateTill }
		});

		const dataToSendAsDefaults = [
			'orderer',
			'storage',
			'destination',
			'material',
			'orderNr',
			'pvz',
			'carrier',
			'driver',
			'car',
			'paymentType',
			'departments',
			'dates',
			'deliveryType',
			'orderType',
			'taskType'
		];
		//orderStatusForSave - šo vajadzētu pārsaukt pirms sūtīšanas, bet pašlaik vienalga
		//'storageID','ordererAddressID', - šie ir no asynctypeahead, kur ir cita struktūra

		let try2Final = {};

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				const theValue = reallyChanged[theKey];
				if (
					(theValue == null || theValue == '' || Object.keys(theValue).length == 0) ==
					false
				) {
					try2Final[theKey] = reallyChanged[theKey];
				}
			} else if (theKey in data) {
				const theValue = data[theKey];
				if (
					(theValue == null || theValue == '' || Object.keys(theValue).length == 0) ==
					false
				) {
					try2Final[theKey] = data[theKey];
				}
			}
		}

		let finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		this.handleSave(finalToSend);

		//vēl skatīt datu validāciju (validateDeliveryCostFields) - saveNewTaskSingle funkcija
	};

	parseForSave(data) {
		let { departments, startDate, endDate } = data;

		return { departments: departments.id, startDate, endDate };
	}

	handleRemoveDeliveryType = () => {
		this.setDataToState('deliveryType', null);
	};

	handleRemoveOrder = () => {
		this.setDataToState('orderType', null);
	};

	handleRemovePaymentType = () => {
		this.setDataToState('paymentType', null);
	}

	_handleSearch = (query, event) => {
		this.setState({ isLoading: true });
		const mappost = this.state.gadget == 1 ? true : null;
		api.search.drivers({ query, mappost }).then(response => {
			this.setState({ options: response, isLoading: false });
		});
	};

	_handleSearchMeta(field, query, searchCB) {
		this.setState({ isLoading: true });
		let searcbParams = null;

		const { clientRegNr } = this.props;

		if (field == 'destination') {
			searcbParams = { clientSearch: false, query, includeArchived: 1 };
		} else {
			searcbParams = query;
		}

		if (field == 'storage'){
			searcbParams = { query, includeArchived: 1 };
		}

		searchCB(searcbParams) //todo: clientid jāpadod no props
			.then(response => {
				this.setState({
					options: {
						...this.state.options,
						[field]: response
					},
					isLoading: false
				});
			});
	}

	onDataSelect = (value, section) => {
		if ((typeof value == 'undefined' || value == '' || value == null) == false) {
			this.setDataToState(section, value);
		} else {
			this.setDataToState(section, []);
		}
	};

	getDefaultSelected(value) {
		if (typeof value == 'undefined' || value == '' || value == null) {
			return [];
		}
		return value;
	}
	handleSwitch(elem, state, name) {
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	render() {
		const {
			orderer,
			storage,
			destination,
			material,
			orderNr,
			pvz,
			car,
			paymentType,
			carrier,
			driver,
			departments,
			dateFrom,
			dateTill,
			deliveryType,
			orderType,
			taskType
		} = this.state;
		const { loading, isLoading, options } = this.state;

		const { options: op2, ...rest } = this.state;
		const currPage = getCurrPage();
		const haveInvoiceFilter = ['drivertasks', 'delivered', 'archive', 'deliveries']

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Sākuma datums' name='dateFrom' />
							<div className='col-sm-8'>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange('dateFrom')}
									defaultValue={dateFrom}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Beigu datums' name='dateTill' />
							<div className='col-sm-8'>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange('dateTill')}
									defaultValue={dateTill}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Nodaļa' name='departments' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='departments'
									multiple={true}
									isLoading={isLoading}
									options={options.departments}
									selected={this.getDefaultSelected(departments)}
									labelKey='title'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.title}
										</span>
									)}
									onChange={selected =>
										this.onDataSelect(selected, 'departments')
									}
									onSearch={query =>
										this._handleSearchMeta(
											'departments',
											query,
											api.search.departmentsFilter
										)
									}
								/>
							</div>
						</div>

						<div className='row'>
							<Label labelClass='col-sm-3' title='Pasūtītājs' name='orderer' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='orderer'
									isLoading={isLoading}
									options={options.orderer}
									selected={this.getDefaultSelected(orderer)}
									labelKey='title'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.title}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'orderer')}
									onSearch={query =>
										this._handleSearchMeta('orderer', query, api.search.orderer)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Noliktava' name='storage' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='storage'
									multiple={true}
									isLoading={isLoading}
									options={options.storage}
									selected={this.getDefaultSelected(storage)}
									labelKey='nosaukums'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.nosaukums}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'storage')}
									onSearch={query =>
										this._handleSearchMeta(
											'storage',
											query,
											api.search.storage_filter
										)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Piegādes adrese'
								name='destination'
							/>
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='destination'
									multiple={true}
									isLoading={isLoading}
									options={options.destination}
									selected={this.getDefaultSelected(destination)}
									labelKey='name'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.name}
										</span>
									)}
									onChange={selected =>
										this.onDataSelect(selected, 'destination')
									}
									onSearch={query =>
										this._handleSearchMeta(
											'destination',
											query,
											api.search.address
										)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Materiāls' name='material' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='material'
									multiple={true}
									isLoading={isLoading}
									options={options.material}
									selected={this.getDefaultSelected(material)}
									labelKey='title'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.title}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'material')}
									onSearch={query =>
										this._handleSearchMeta(
											'material',
											query,
											api.search.material
										)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Pasūtījuma nr.' name='orderNr' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='orderNr'
									isLoading={isLoading}
									options={options.orderNr}
									selected={this.getDefaultSelected(orderNr)}
									labelKey='title'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.title}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'orderNr')}
									onSearch={query =>
										this._handleSearchMeta('orderNr', query, api.search.order)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='PVZ' name='pvz' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='pvz'
									isLoading={isLoading}
									options={options.pvz}
									selected={this.getDefaultSelected(pvz)}
									labelKey='title'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.title}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'pvz')}
									onSearch={query =>
										this._handleSearchMeta('pvz', query, api.search.pvz)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Pārvadātājs' name='carrier' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='carrier'
									isLoading={isLoading}
									options={options.carrier}
									selected={this.getDefaultSelected(carrier)}
									labelKey='title'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.title}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'carrier')}
									onSearch={query =>
										this._handleSearchMeta(
											'carrier',
											query,
											api.search.carriers
										)
									}
								/>
							</div>
						</div>

						<div className='row'>
							<Label labelClass='col-sm-3' title='Šoferis' name='driver' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='driver'
									multiple={true}
									isLoading={isLoading}
									options={options.driver}
									selected={this.getDefaultSelected(driver)}
									labelKey='driverName'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.driverName}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'driver')}
									onSearch={query =>
										this._handleSearchMeta(
											'driver',
											query,
											api.search.drivers_filter
										)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Auto numurs' name='car' />
							<div className='col-sm-8'>
								<CustomTypeAhead
									self={this}
									id='car'
									multiple={true}
									isLoading={isLoading}
									options={options.car}
									selected={this.getDefaultSelected(car)}
									labelKey='car'
									toRender={(option, idx) => (
										<span style={{ fontSize: '75%' }} key={idx}>
											{option.car}
										</span>
									)}
									onChange={selected => this.onDataSelect(selected, 'car')}
									onSearch={query =>
										this._handleSearchMeta(
											'car',
											query,
											api.search.car_filter
										)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Uzdevumu izpildes veids'
								name='taskType'
							/>
							<Select
								selectClass='col-sm-8'
								handleChange={this.handleChange}
								name='taskType'
								value={taskType}
								placeholder='Izvēlies vērtību'
								options={[
									{ value: '', label: '-' },
									{ value: 'with', label: 'Ar Mappost' },
									{ value: 'without', label: 'Bez Mappost' }
								]}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Apmaksas veids' name='paymentType' />

							<Select
								selectClass='col-sm-8'
								handleChange={this.handleChange}
								name='paymentType'
								value={paymentType}
								placeholder='Izvēlies vērtību'
								options={[
									{ value: '', label: '-' },
									{ value: 'included', label: 'Piegāde iekļauta produkta cenā' },
									{ value: 'client', label: 'Maksā klients' }
								]}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Pasūtījumi' name='orderType' />
							<div className='col-sm-8'>
								<Radio
									name='orderType'
									value='woOrdererPriceOrder'
									checkAgainst={orderType}
									handleChange={this.handleChange}
									label='Visi pasūtījumi bez apstiprinātas pasūtītāja cenas'
								/>
								<button
									type='button'
									className='btn btn-warning btn-sm'
									onClick={this.handleRemoveOrder}>
									Nodzēst pasūtījuma tipu
								</button>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Piegādes' name='deliveryType' />
							<div className='col-sm-8'>
								<Radio
									name='deliveryType'
									value='unfinished'
									checkAgainst={deliveryType}
									handleChange={this.handleChange}
									label='Visas nepabeigtās piegādes'
								/>
								<Radio
									name='deliveryType'
									value='bill'
									checkAgainst={deliveryType}
									handleChange={this.handleChange}
									label='Visas piegādes, kas gatavas apmaksai'
								/>
								<Radio
									name='deliveryType'
									value='archive'
									checkAgainst={deliveryType}
									handleChange={this.handleChange}
									label='Visas piegādes, kuras var arhivēt'
								/>
								<Radio
									name='deliveryType'
									value='woOrdererPrice'
									checkAgainst={deliveryType}
									handleChange={this.handleChange}
									label='Visas piegādes bez apstiprinātas pasūtītāja cenas'
								/>
								{haveInvoiceFilter.includes(currPage) &&
									<Radio
										name='deliveryType'
										value='woInvoice'
										checkAgainst={deliveryType}
										handleChange={this.handleChange}
										label='Visas piegādes, kam nav ienākošā rēķina'
									/>
								}
								<button
									type='button'
									className='btn btn-warning btn-sm'
									onClick={this.handleRemoveDeliveryType}>
									Nodzēst piegāžu tipu
								</button>
							</div>
						</div>
						<div className='btn-group' role='group'>
							<button
								type='button'
								className='btn btn-success'
								onClick={this.saveData}>
								Filtrēt
							</button>
							<button
								type='button'
								className='btn btn-danger'
								onClick={this.deleteFilter}>
								Nodzēst filtru
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default Filter;
