import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import MaterialEdit from './forms/material';
import ResponsibleEdit from './forms/atbildigie';
import ErrorOnLoading from 'shared/errorOnLoad';
import { customTitle } from 'util';
import FieldForm from './filterForm';
import { MILISECONDS_BEFORE_SEARCH } from '../../config';

const actionsButtonFormatter = () => actionButton;

const statusFormatter = cell => {
	const { status } = cell.getRow().getData();
	const formatted = status == '1' ? 'Jā' : '<span style="color: red;">Nē</span>';

	return formatted;
};

class ResponsibleTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			currTask: null,
			error: false,
			id: null,
			name: '',
			phone: ''
		};
	}

	setupData() {
		this.setState({ loading: true, error: false });

		const { name } = this.state;

		api.search.atbildigie(name).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	columns = [
		{
			title: 'Vārds',
			field: 'name',
			width: 250,
			headerTooltip: 'Atbildīgās personas vārds',
			titleFormatter: column => customTitle(column, 'fas fa-user', '#333334')
		},
		{
			title: 'Telefons',
			field: 'phone',
			headerTooltip: 'Atbildīgās personas tālruņa numurs',
			titleFormatter: column => customTitle(column, 'fas fa-phone', '#333334')
		},
		{
			title: 'Vai sūtīt SMS?',
			field: 'status',
			formatter: cell => statusFormatter(cell),
			headerTooltip: 'Vai šai personai sūtīt SMS?',
			titleFormatter: column => customTitle(column, 'fas fa-sms', '#333334')
		},
		{
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		}
	];

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ [whatToSearch]: e.target.value }, () => {
			this.timeout = setTimeout(() => {
				_self.setupData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleOpenModal(openType, cell) {
		const { name, phone } = cell.getRow().getData();

		this.setState({ showModal: true, name, phone });
	}

	render() {
		const { data, error, loading, showModal, name, phone } = this.state;

		const filterItems = [
			{
				visible: true,
				title: 'Vārds',
				inputID: 'username',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'name'
			}
		];

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						<FieldForm items={filterItems} />
						<div className='clearBoth' />
						<div style={{ padding: 5 }} />
						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<div>Nekas netika atrasts</div>
								) : (
									<div className='tableWrapper'>
										<Tabulator
											columns={this.columns}
											options={{ pagination: false }}
											data={data}
										/>
									</div>
								)}
								<CustomModal
									title='Atbildīgās personas datu labošana'
									showModal={showModal}
									handleCloseModal={this.handleToggleModal}
									content={
										<ResponsibleEdit
											name={name}
											phone={phone}
											handleCloseModal={this.handleToggleModal}
										/>
									}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default ResponsibleTable;
