import React from 'react';

export const materialFormatter = data => {
	console.log('mat', data)
	return <div className="preWrap">
		<b>{data.material}</b>
		<br />
		{(data.isIncludedInPrise === 'on' || data.isIncludedInPrise === true) && <span style={{color: 'red'}}>Piegāde iekļauta produkta cenā</span>}
		<br />
		{data.materialNotes}
	</div>;
};

export const deliverTillFormatter = ({
	deliverTill: deliveryDate,
	deliverTillMandantory: mandantory
}) => {
	if (deliveryDate == null) {
		return '';
	}
	if (mandantory) {
		return <span style={{fontWeight: 'bold', color: 'red'}}>{deliveryDate}</span>;
	}
	return deliveryDate;
};

export const deliverTillMandantory = (mandantory) => {
	if (mandantory) {
		return <span style={{fontWeight: 'bold', color: 'red'}}>Obligāti</span>;
	}
	return 'Vēlams';
};

export const npkColumnBackground = row => {
	const data = row.original;

	let bgColor_cell = '';

	if (data.priority === 2 && data.orderStatusId !== 5) {
		bgColor_cell = '#FF3333';
	}

	if (data.isChecked == 1) {
		if (parseInt(data.remainder, 10) < 1 && parseInt(data.deliveredByFact, 10) > 1) {
			//uz int vēl pārvērst
			bgColor_cell = '#CCFF99';
		} else {
			bgColor_cell = '#FFCC66';
		}
	} else {
		bgColor_cell = '#FFCCCC';
	}

	return bgColor_cell;
}

export const npkFormatter = (row, clickHandler) => {
	const data = row.original;

	let bgColor = '';
	let orderStatus;

	let bgColor_cell = '';

	if (data.priority === 1) {
		bgColor = ''; //"#fefefe"; - šis laikam nozīmē, ka tas ir steidzamais pasūtījums?
	} else if (data.priority === 2 && data.orderStatusId !== 5) {
		bgColor = '#FF3333';
		bgColor_cell = '#FF3333';
	}

	let isViewed = true;
	if (data.isChecked == 1) {
		if (parseInt(data.remainder, 10) < 1 && parseInt(data.deliveredByFact, 10) > 1) {
			//uz int vēl pārvērst
			bgColor_cell = '#CCFF99';
			orderStatus = (
				<span title='Pasūtījums ir izpildīts'>
					<span className='glyphicon glyphicon-check' aria-hidden='true'></span>
				</span>
			);
		} else {
			bgColor_cell = '#FFCC66';
			orderStatus = (
				<span title='Pasūtījums ir apskatīts'>
					<span className='glyphicon glyphicon-ok' aria-hidden='true'></span>
				</span>
			);
		}
	} else {
		isViewed = false;
		bgColor_cell = '#FFCCCC';
		orderStatus = (
			<span title='Pasūtījums nav apskatīts'>
				<span
					style={{ color: 'red', fontSize: '1.5em' }}
					className='glyphicon glyphicon-exclamation-sign'
					aria-hidden='true'></span>
			</span>
		); //"Pasūtījums nav apskatīts";
	}

	const { npk: newNPK } = data;

	let npkV = isViewed ? newNPK : <span style={{ fontWeight: 'bold' }}>{newNPK}</span>;

	return (
		<div style={{ backgroundColor: bgColor_cell }} onClick={() => clickHandler(row.original)}>
			<span style={{ color: bgColor }}>{npkV}</span>
			<br />
			{orderStatus}
		</div>
	);
};

export const statusFormatter = data => {
	const { department, worker, orderEntered } = data;

	const dateFormat = `${orderEntered.substring(6, 8)}.${orderEntered.substring(
		4,
		6
	)}.${orderEntered.substring(0, 4)}.`;

	return <span className="preWrap">{department}<br />{worker}<br />{dateFormat}</span>;
};

export const amountFormatter = (data, section) => {
	const { [section]: section1, measurementUnit } = data;

	return <>{section1} {measurementUnit}</>;
};

export const addressFormatter = props => {
	const { data, type } = props;

	if (type === 'orderer') {
		return (
			<div className='preWrap'>
				<b>{data.orderer.title}</b>
				<br />
				{data.orderer.address}
				{typeof data.orderer.phone !== 'undefined' &&
					data.orderer.phone !== '' &&
					', tel.: ' + data.orderer.phone}
				<span
					className='glyphicon glyphicon-share-alt'
					aria-hidden='true'
					onClick={() => window.open(`#/client/${data.orderer.regNr}`, '_self')}></span>
			</div>
		);
	} else if (type === 'storage') {
		if ([1, 2, 6, 43, 48, 114].indexOf(data.storage.id) > -1) {
			return <b>{data.storage.name}</b>;
		}

		return (
			<span className='preWrap'>
				<b>{data.storage.name}</b>
				<br />
				{data.storage.address}
				{typeof data.storage.contacts !== 'undefined' &&
					data.storage.contacts !== '' &&
					', tel.: ' + data.storage.contacts}
			</span>
		);
	} else if (type === 'delivery') {
		return (
			<span className='preWrap'>
				<b>{data.delivery.title}</b>
				{typeof data.delivery.contacts !== 'undefined' &&
					data.delivery.contacts.length > 0 &&
					', tel.: ' + data.delivery.contacts.join(', ')}
			</span>
		);
	}
};

export const actionsButtonFormatter = () => (
	<i
		className='fas fa-ellipsis-v'
		style={{ fontSize: '1.6em', textAlign: 'center', color: 'green' }}></i>
);

export const toleranceFormatter = amount => {
	const color = amount < 0 ? 'red' : 'green';

	return <span style={{ color }}>{amount}</span>;
}
