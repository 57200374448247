import React, { Component } from 'react';
import api from '@api';
import Deliveries from './table';
import Header from './header';
import Loading from 'shared/loading';
import Pagination from '../../shared/pagination/pagination';
import FilterText from '../../shared/filter/format';
import { setUIPosition } from '../../uiPosition';
import { handleDataUpdate } from '../../util';
import ErrorOnLoading from 'shared/errorOnLoad';

import TabHandler from 'shared/tabs/tabSelect';
import Summary from './archiveSummary';
import MassDeliveryEdit from './MassDeliveryEdit';
import { connect } from 'react-redux';
//{"description":"Rap\u0161u rau\u0161i, ","notes":"29.11.2018 \nNo r\u012bta uzkraujamies... Cenas iepriek\u0161\u0113j\u0101s !","carrierOrg":"ZIEDI\u0145I Z.S., RAMANIS ANDRIS","taskId":27866,"taskIzpilde":"MAPPOST","isMappostTask":true,"mappostTaskId":8374584,"mappostTaskState":1,"taskStartDate":"20181129","driver":"ALDIS LATAITIS","taskDescription":"Rap\u0161u rau\u0161i, [Pas\u016bt. PALSA, SIA], ","taskDaudz":25,"taskDaudzUnit":"t","worker":"L. Sni\u0137ers","mappostDriverId":10407,"orderedAmount":"25.000 t","givenAmount":"25.000 t","deliveredAmount":"0.000 t"}

const ROWS_IN_ONE_PAGE = 20;

let handler = new TabHandler();

class DeliveredKrava extends Component {
	intervalID;
	constructor(props) {
		super(props);

		this.state = {
			key: 0,
			loading: false,
			error: false,
			data: [],
			currPage: 1,
			pages: 1,
			summary: {},
			showMassDeliveryEditModal: false,
			isSelected: [],
			isMassEditButtonsActive: false,
		};
	}
	componentWillUnmount() {
		clearTimeout(this.intervalID);
	}

	oneDelivery(delivery, key) {
		return (
			<div key={key}>
				<Header data={delivery} mode='aizvestais' resetData={this.setup} />
				<br />
				<Deliveries
					mode='aizvestais'
					data={delivery.taskDeliveries.deliveries}
					reload_data={this.setup}
					isSelected={this.state.isSelected}
					onCheckboxChange={this.handleCheckboxChange}
					selectAllDeliveries={this.selectAllDeliveries}
					isMassEditButtonsActive={this.state.isMassEditButtonsActive}
				/>
			</div>
		);
	}

	handleCheckboxChange = (deliveryId, isSelected) => {
		this.setState(prevState => {
			const newIsSelected = [...prevState.isSelected];
			if (isSelected) {
				if (!newIsSelected.includes(deliveryId)) {
					newIsSelected.push(deliveryId);
				}
			} else {
				const index = newIsSelected.indexOf(deliveryId);
				if (index > -1) {
					newIsSelected.splice(index, 1);
				}
			}
			return { isSelected: newIsSelected };
		});
	};

	setup = (loader = true) => {
		if (loader) {
			this.setState({ loading: true, error: false, isSelected: [] });
		}

		const { currPage } = this.state;

		api.deliveries.kravas({ page: currPage, rows: ROWS_IN_ONE_PAGE }).then(response => {
			let deliveries = [];
			let pages = 1;
			if ('status' in response && response.status == 'error') {
				deliveries = [];
			} else {
				deliveries = response.deliveries;
				pages = Math.ceil(response.numberOfResults / ROWS_IN_ONE_PAGE);
			}

			this.setState({
				loading: false,
				error: false,
				data: deliveries,
				pages,
				summary: response.summary
			});
			this.intervalID = handleDataUpdate(this.setup.bind(this));
		});
	};

	componentDidMount() {
		this.setup();
		const curTab = handler.getSettings('OrderTabs');
		if (curTab) {
			this.handleSelect(curTab);
		}
	}

	handleSelect(key) {
		console.log('SET KEY', key);
		this.setState({ key, data: null });
		handler.saveSettings('OrderTabs', key);
	}

	handlePageChange = currPage => {
		this.setState({ currPage }, () => this.setup());
	};

	handleOpenMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: true });
	};

	handleCloseMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: false });
	};

	selectAllDeliveries = () => {
		this.setState(prevState => {
			const deliveryIds = prevState.data.flatMap(delivery =>
				delivery.taskDeliveries.deliveries.map(d => d.deliveryId)
			);
			const isSelected = prevState.isSelected.length ? [] : deliveryIds;
			return { isSelected };
		});
	};

	activateMassEdit = () => {
		this.setState(prevState => ({
			isMassEditButtonsActive: !prevState.isMassEditButtonsActive
		}));
	};

	render() {
		const {
			data,
			error,
			loading,
			currPage,
			pages,
			showMassDeliveryEditModal,
			isSelected,
			isMassEditButtonsActive
		} = this.state;

		return (
			<div className='bodyWrapper'>
				<FilterText />
				{setUIPosition()}
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										margin: '10px'
									}}>
									{pages !== 1 && (
										<Pagination
											page={currPage}
											total={pages}
											handlePageChange={this.handlePageChange}
										/>
									)}
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '10px',
											alignItems: 'flex-end'
										}}>
											{this.props.canUpdateDeliveriesInBatch && (
												<div>
													<button
														type='button'
														className='btn btn-primary'
														onClick={this.activateMassEdit}
														style={{ display: 'block', width: '220px' }}>
														{isMassEditButtonsActive
															? 'Deaktivizēt kopīgo labošanu'
															: 'Aktivizēt kopīgo labošanu'}
													</button>
												</div>
											)}
										<div style={{ display: 'flex', gap: '10px' }}>
											{isSelected.length > 0 && isMassEditButtonsActive && (
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.handleOpenMassDeliveryEditModal}
													style={{ width: '220px' }}>
													Labot atzīmētās piegādes ({isSelected.length})
												</button>
											)}
											{isMassEditButtonsActive && (
												<button
													type='button'
													className='btn btn-primary'
													onClick={this.selectAllDeliveries}
													style={{ width: '220px' }}>
													{isSelected.length > 0
														? 'Noņemt atzīmes no piegādēm'
														: 'Atzīmēt visas piegādes'}
												</button>
											)}
										</div>
									</div>
								</div>
								{data.length > 0 ? (
									<div>
										{/* <Summary data={summary} /> */}
										{data.map((delivery, ind) =>
											this.oneDelivery(delivery, ind)
										)}
									</div>
								) : (
									<div>0 piegādes</div>
								)}
								{pages == 1 ? (
									''
								) : (
									<div>
										<Pagination
											page={currPage}
											total={pages}
											handlePageChange={this.handlePageChange}
										/>
									</div>
								)}
							</div>
						)}
						{showMassDeliveryEditModal && (
							<MassDeliveryEdit
								showModal={showMassDeliveryEditModal}
								handleCloseModal={this.handleCloseMassDeliveryEditModal}
								isSelected={this.state.isSelected}
								data={this.state.data.flatMap(
									delivery => delivery.taskDeliveries.deliveries
								)}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	canUpdateDeliveriesInBatch: state.auth.canUpdateDeliveriesInBatch,
})

export default connect(mapStateToProps)(DeliveredKrava);
