//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import { Collapse } from 'react-collapse';
import api from '@api';
import Tabulator from 'shared/table/Tabulator';
import PropTypes from 'prop-types';
import Loading from 'shared/loading';

const changesFormatter = cell => {
	let finalArr = [];

	const allData = cell.getValue();
	finalArr = Object.keys(allData).map(item => {
		const title = item;
		const changes = allData[item]; //[old, curr]
		let finalValue = '';
		if (typeof changes == 'string') {
			finalValue = changes;
		} else if (changes != null && typeof changes[Symbol.iterator] === 'function') {
			const [old, curr] = changes;
			if (old === '') {
				finalValue = curr;
			} else {
				finalValue = `${old} → ${curr}`;
			}
		}

		return `<li><span style="color:gray;">${title}</span>: ${finalValue}</li>`;
	});
	return `<ul style="column-count: 3;">${finalArr.join('')}</ul>`;
};

class HistoryModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			unformatted: []
		};
	}

	setData = () => {
		const { mode, ID } = this.props;

		this.setState({ loading: true, error: false });

		api.admin.history({ mode, ID }).then(response => {
			this.setState({
				loading: false,
				error: false,
				data: response.data,
				unformatted: response.old
			});
		});
	};

	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.mode !== prevProps.mode || this.props.ID !== prevProps.ID) {
			this.setData();
		}
	}
	columns = [
		{
			title: 'Laiks',
			width: 150,
			field: 'timestamp'
		},
		{
			title: 'Veicējs',
			width: 100,
			field: 'user'
		},
		{
			title: 'Izmaiņas',
			field: 'changes',
			//width: 600,
			formatter: cell => changesFormatter(cell)
		}
	];

	render() {
		const { data, loading, unformatted } = this.state;

		const donthaveData = data.length < 1 && unformatted.length < 1;

		return (
			<div>
				<CustomModal
					modalClass={data.length < 1 ? '' : 'fullWidthModal'}
					title='Vēsture'
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						<div>
							{loading ? (
								<Loading />
							) : (
								<>
									{donthaveData ? (
										<div>Nav pieejami dati</div>
									) : (
										<>
											{data.length < 1 ? (
												''
											) : (
												<div className='tableWrapper'>
													<Tabulator
														columns={this.columns}
														options={{ pagination: false }}
														data={data}
													/>
												</div>
											)}
											{unformatted.length > 0 && (
												<>
													{data.length < 1 ? (
														''
													) : (
														<span>Vecās izmaiņas:</span>
													)}
													<br />
													<div
														dangerouslySetInnerHTML={{
															__html: unformatted
														}}
													/>
												</>
											)}
										</>
									)}
								</>
							)}
						</div>
					}
				/>
			</div>
		);
	}
}

HistoryModal.propTypes = {
	ID: PropTypes.string.isRequired,
	handleCloseModal: PropTypes.func,
	mode: PropTypes.oneOf(['order', 'invoice', 'delivery']).isRequired,
	showModal: PropTypes.bool
};
//

export default HistoryModal;
