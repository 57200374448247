import api from '../api';

const filter = {
	reset: () => api.get(`filter/reset`),

	getState: (section = null) => api.get(`filter/get?section=${section}`),
	formatted: (section = null) => api.get(`filter/formatted?section=${section}`),

	save: ({ section, data }) => api.post(`filter/save`, { section, data })
};

export default filter;
