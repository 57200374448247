import React, { Component } from 'react';
import OrderTabs from '@sections/orders/tabs/orderTabs';
import Loading from 'shared/loading';
import FilterText from '../../shared/filter/format';
import OrderEditModal from './newModal';
import { checkPermission } from 'permissions';
import { connect } from 'react-redux';
import { loadData } from './tabs/slice';

class OrderPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orders: {},
			searching: false,
			error: false,
			showNewOrderModal: false
		};
	}

	componentDidMount() {
		this.props.loadData();
	}

	handleOrderAdding = () => {
		this.setState({ showNewOrderModal: true });
	};

	handleCloseModal = () => {
		this.props.loadData();
		this.setState({
			showModal: false,
			showNewOrderModal: false,
			currOrder: null
		});
	};

	closeModalWithoutReload = () => {
		this.setState({
			showModal: false,
			showNewOrderModal: false,
			currOrder: null
		});
	}

	render() {
		const { loading } = this.props;

		if (loading) {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				{checkPermission('add', 'orders') && (
					<div style={{ float: 'right' }}>
						<button
							type='button'
							className='btn btn-primary'
							onClick={this.handleOrderAdding}>
							Pievienot jaunu pasūtījumu
						</button>
					</div>
				)}
				<FilterText />
				<div className='clearBoth'></div>
				<div style={{ padding: 5 }} />

				<OrderTabs />
				<OrderEditModal
					showModal={this.state.showNewOrderModal}
					clientRegNr={null}
					handleCloseModal={this.handleCloseModal}
					closeModalWithoutReload={this.closeModalWithoutReload}
				/>
			</div>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		loadData: () => {
			dispatch(
				loadData({
					include: {
						newOrders: false,
						transport: false,
						invoices: false,
						problematic: false,
						invoiceReady: false,
						archivedTasks: false
					}
				})
			);
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		loading: state.orderTabs.loading
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(OrderPage);
