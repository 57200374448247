import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/*
https://www.codementor.io/blizzerand/building-forms-using-react-everything-you-need-to-know-iz3eyoq4y
*/

const generateStyles = (main, isError) => {
	let errorStyle = {};

	if (isError) {
		errorStyle = { border: '1px solid red' };
	}

	return {
		...main,
		...errorStyle
	};
};

const Input = props => (
	<div className={props.inputClass}>
		<input
			style={generateStyles(props.inputStyle, props.isError)}
			className={clsx(props.divClasses, 'form-control')}
			id={props.name}
			name={props.name}
			onBlur={props.blur}
			type={props.type || 'text'}
			value={props.value || ''}
			onChange={props.handleChange}
			placeholder={props.placeholder}
			disabled={props.disabled ? props.disabled : false}
			onKeyPress={props.onKeyPress}
			onKeyDown={props.onKeyDown}
		/>
	</div>
);

Input.propTypes = {
	isError: PropTypes.bool,
	inputClass: PropTypes.string,
	inputStyle: PropTypes.object,
	divClasses: PropTypes.string,
	name: PropTypes.string,
	blur: PropTypes.func,
	type: PropTypes.oneOf(['text', 'password']),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	handleChange: PropTypes.func,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	onKeyDown: PropTypes.func,
	onKeyPress: PropTypes.func
};

//{props.required ? <span className="required">*</span> : ""}
const Label = props => {
	return (
		<label htmlFor={props.name} className={`control-label ${props.labelClass}`}>
			{props.title} {props.required ? <span className='required'>*</span> : ''}
		</label>
	);
};

const Select = props => {
	return (
		<div className={props.selectClass}>
			{/*defaultValue={props.defaultValue}*/}
			<select
				className={props.innerClass || 'form-control'}
				style={generateStyles(props.inputStyle, props.isError)}
				name={props.name}
				value={props.value ? props.value : ''}
				onChange={props.handleChange}
				disabled={props.disabled ? props.disabled : false}>
				<option value='' disabled>
					{props.placeholder}
				</option>
				{props.options.map(option => {
					return (
						<option
							key={option.value}
							value={option.value}
							label={option.label}
							disabled={option.disabled || false}>
							{option.label}
						</option>
					);
				})}
			</select>
		</div>
	);
};

const Textarea = props => {
	return (
		<div className={props.inputClass}>
			{/*defaultValue={props.defaultValue}*/}
			<textarea
				className='form-control'
				style={generateStyles(props.inputStyle, props.isError)}
				name={props.name}
				rows={props.rows}
				cols={props.cols}
				value={props.value}
				onChange={props.handleChange}
				disabled={props.disabled ? props.disabled : false}
			/>
		</div>
	);
};

//<input type="radio" name={"completionYear"} value="nextyear" checked={completionYear === 'nextyear'} onChange={this.handleChange} /> Nākamā gada pavasarī
const Radio = props => {
	return (
		<div className='radio'>
			<div className={props.inputClass}>
				<label>
					<input
						type='radio'
						name={props.name}
						value={props.value}
						checked={
							props.toChecked ? props.toChecked : props.checkAgainst === props.value
						}
						onChange={props.handleChange}
						disabled={props.disabled ? props.disabled : false}
					/>{' '}
					{props.label}
				</label>
			</div>
		</div>
	);
};
//<Radio inputClass={} name={} value={} checkAgainst={} handleChange={} label={} />

//<input type="radio" name={"completionYear"} value="nextyear" checked={completionYear === 'nextyear'} onChange={this.handleChange} /> Nākamā gada pavasarī
const Check = props => {
	return (
		<div className={props.inputClass}>
			<label className='check-label'>
			<input
				name={props.name}
				type='checkbox'
				checked={props.value}
				disabled={props.disabled ? props.disabled : false}
				onChange={props.handleChange}
			/>{' '}
			{props.label}
			</label>
		</div>
	);
};
//<Check inputClass={} name={} value={} handleChange={} label={} />

const Percentage = props => {
	return (
		<div className={props.inputClass}>
			<input
				style={generateStyles(props.inputStyle, props.isError)}
				className='form-control'
				id={props.name}
				name={props.name}
				type='number'
				min='0'
				max='100'
				value={props.value || '0'}
				onChange={props.handleChange}
				placeholder={props.placeholder}
				disabled={props.disabled ? props.disabled : false}
				onKeyPress={props.onKeyPress}
				onKeyDown={props.onKeyDown}
				onInput={e => {
					e.target.value = Math.max(0, parseInt(e.target.value))
						? Math.min(100, parseInt(e.target.value))
						: '';
				}}
			/>
		</div>
	);
}

export { Input, Label, Select, Textarea, Radio, Check, Percentage };
