import './App.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Redirect } from 'react-router';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorBoundary from './ErrorBoundary';

import AuthContainer from '../auth/authContainer';
import ScrollUpButton from 'react-scroll-up-button';

import Page from './Page';

import { routes } from '../../routes';
import Notifications from './Notifications';

// if (process.env.NODE_ENV !== 'production') {
// 	 const {whyDidYouUpdate} = require('why-did-you-update');
// 	 whyDidYouUpdate(React);
// }

const GeneralRoute = ({ key, path, component, title, ...otherProps }) => (
	<Route
		key={key}
		exact
		path={path}
		render={otherProps => <Page {...otherProps} component={component} title={title} />}
	/>
);

const formatRoutes = (permissionChecker, isAuth) => {
	return routes.map((entry, key) => {
		//{'component':Search, path: '/', checkLogin: true, readPermissions: ''}
		const { component, path, checkLogin, readPermissions, title } = entry;

		//console.log('formatRoutes',entry, isAuth);

		if (checkLogin == false) {
			return <GeneralRoute key={key} exact path={path} component={component} title={title} />;
		}

		if (isAuth == false || typeof isAuth == 'undefined') {
			return <GeneralRoute key={key} exact path={path} component={AuthContainer} title='' />;
		}

		if (readPermissions == '' || permissionChecker.includes(readPermissions)) {
			return <GeneralRoute key={key} exact path={path} component={component} title={title} />;
		}

		if (readPermissions !== '' && permissionChecker.includes(readPermissions) == false) {
			return (
				<GeneralRoute
					key={key}
					exact
					path={path}
					component={NotAuthorizedToAccess}
					title='Nav autorizācijas'
				/>
			);
		}
	});
};

const NotFound = ({ location }) =>
	location.pathname === '/driverdeliveries' ? (
		<Redirect to='/drivertasks' />
	) : (
		<div className='bodyWrapper'>
			<h3>
				Netika atrasta lapa <code>{location.pathname}</code>
			</h3>
		</div>
	);

const NotAuthorizedToAccess = () => (
	<div className='bodyWrapper'>
		<h3>Jums nav tiesību skatīt šo lapu</h3>
	</div>
);

class App extends React.Component {
	componentDidMount() {
		this.props.checkLogin();
	}

	render() {
		const {
			isAuth,
			user,
			logoutHandler,
			read,
			loggingIn,
			serviceToggle,
			appError,
			reports
		} = this.props;

		if (appError) {
			return (
				<div className='bodyWrapper'>
					<h3>Nevarēja ielādēt Loģistikas lietotni</h3>
					Mēģiniet vēlreiz pārlādēt lapu (vēlams arī{' '}
					<a
						href='https://clear-my-cache.com/en/windows.html'
						target='_blank'
						rel='noopener noreferrer'>
						iztīrīt pārlūka kešu
					</a>
					). Ja joprojām redzams šis paziņojums, sazinieties ar{' '}
					<a href='mailto:web@latraps.lv'>administrāciju</a>!
					<br />
					<small>(lietotnes versija: {process.env.REACT_APP_VERSION})</small>
				</div>
			);
		}

		return (
			<Router>
				<div>
					{loggingIn == false && (
						<div>
							<Header
								username={user}
								isLoggedIn={isAuth}
								readPermissions={read}
								handleLogout={logoutHandler}
								serviceToggle={serviceToggle}
								reports={reports}
							/>
							<ErrorBoundary>
								<Notifications />
								<Switch>
									{formatRoutes(read, isAuth)}
									<Route component={NotFound} />
								</Switch>
							</ErrorBoundary>
							<ToastContainer
								position='top-left'
								autoClose={2000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnVisibilityChange={true}
								draggable={false}
								pauseOnHover
							/>
							<ScrollUpButton />
						</div>
					)}
				</div>
			</Router>
		);
	}
}

export default App;
