//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { CustomModal } from 'shared/modal';
import InfoModal from 'sections/admin/infoModal';
import api from '../../api';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			dbIsReadOnly: false
		};
	}

	componentDidMount() {
		api.admin.db_status().then(response => {
			this.setState({ dbIsReadOnly: response.db_read_only });
		});
	}

	handleToggleModal = () => {
		this.setState({ modalOpen: !this.state.modalOpen });
	};

	handleRemoveFilters = () => {
		api.filter.reset().then(response => {
			if (response.status === 'ok') {
				alert('Visi filtri nodzēsti');
				location.reload();
			}
		});
	};

	render() {
		const { modalOpen, dbIsReadOnly } = this.state;
		const {
			isLoggedIn,
			username,
			handleLogout,
			readPermissions,
			serviceToggle,
			reports
		} = this.props;

		//console.log('header js props',this.props)

		const reading = typeof readPermissions == 'undefined' ? '[]' : readPermissions;

		return (
			//condensed
			<div>
				<Navbar inverse fluid collapseOnSelect>
					<Navbar.Header>
						<Navbar.Brand>LLIS</Navbar.Brand>
						<Navbar.Toggle />
					</Navbar.Header>
					<Navbar.Collapse>
						<Nav>
							{reading.includes('clients') && (
								<LinkContainer exact to='/'>
									<NavItem>Klientu meklēšana</NavItem>
								</LinkContainer>
							)}
							{reading.includes('orders') && (
								<LinkContainer to='/orders'>
									<NavItem>Pasūtījumi</NavItem>
								</LinkContainer>
							)}
							{isLoggedIn ? (
								<NavDropdown title='Uzdevumi/braucieni' id='deliveries-dropdown'>
									{reading.includes('drivertasks') && (
										<LinkContainer to='/drivertasks'>
											<NavItem>Šoferi/uzdevumi</NavItem>
										</LinkContainer>
									)}
									{/*reading.includes('deliverylist') && <LinkContainer to="/deliverylist"><NavItem>Uzdevumi</NavItem></LinkContainer>*/}
									{reading.includes('calendar') && (
										<LinkContainer to='/calendar'>
											<NavItem>Kalendārs</NavItem>
										</LinkContainer>
									)}
								</NavDropdown>
							) : (
								''
							)}
							{reading.includes('planning') && (
								<LinkContainer to='/planning'>
									{/* LATR-31 - agrāk "Plānošana" */}
									<NavItem>Saliktās kravas</NavItem>
								</LinkContainer>
							)}
							{reading.includes('orders_planning') && (
								<LinkContainer to='/orders_planning'>
									<NavItem>Plānošana</NavItem>
								</LinkContainer>
							)}
							{isLoggedIn ? (
								<NavDropdown title='Aizvestās kravas' id='delivered-dropdown'>
									{reading.includes('delivered') && (
										<LinkContainer to='/delivered'>
											<NavItem>Aizvestās kravas</NavItem>
										</LinkContainer>
									)}
									{reading.includes('delivered') && (
										<LinkContainer to='/deliveries'>
											<NavItem>Braucieni</NavItem>
										</LinkContainer>
									)}
									{reading.includes('archive') && (
										<LinkContainer to='/archive'>
											<NavItem>Arhīvs</NavItem>
										</LinkContainer>
									)}
								</NavDropdown>
							) : (
								''
							)}
							{reading.includes('bills') && (
								<LinkContainer exact to='/invoices'>
									<NavItem>Rēķini</NavItem>
								</LinkContainer>
							)}
							{isLoggedIn && reading.includes('main_reports') && (
								<NavDropdown title='Atskaites' id='reports-dropdown'>
									<LinkContainer exact to='/reports/invoices'>
										<NavItem>Rēķinu apstrāde</NavItem>
									</LinkContainer>
									<LinkContainer exact to='/reports/invoice_rows'>
										<NavItem>Rēķinu rindas</NavItem>
									</LinkContainer>
									<LinkContainer exact to='/reports/transporters'>
										<NavItem>Pārvadātāji</NavItem>
									</LinkContainer>
									<LinkContainer exact to='/reports/registered_orders'>
										<NavItem>Ievadītie pasūtījumi</NavItem>
									</LinkContainer>
									{/* path: '/reports/orders_deliveries', */}
									<LinkContainer exact to='/reports/orders_deliveries'>
										<NavItem>Pasūtījumu braucieni</NavItem>
									</LinkContainer>
									{/* <LinkContainer exact to='/reports/monthly_summary'>
										<NavItem>Mēnešu kopsavilkums</NavItem>
									</LinkContainer>
									<LinkContainer exact to='/reports/orders'>
										<NavItem>Pasūtījumi</NavItem>
									</LinkContainer>
									<LinkContainer exact to='/reports/orderer_prices'>
										<NavItem>Pasūtītāja cenas</NavItem>
									</LinkContainer>
									<LinkContainer exact to='/reports/auto'>
										<NavItem>Auto</NavItem>
									</LinkContainer>
									<LinkContainer exact to='/reports/agro'>
										<NavItem>Agro nodaļa</NavItem>
									</LinkContainer> */}
								</NavDropdown>
							)}
							{isLoggedIn ? (
								<NavDropdown title='Administrēšana' id='admin-dropdown'>
									{reading.includes('users') && (
										<LinkContainer exact to='/admin/users'>
											<NavItem>Lietotāji</NavItem>
										</LinkContainer>
									)}
									{reading.includes('roles') && (
										<LinkContainer exact to='/admin/roles'>
											<NavItem>Grupu tiesības</NavItem>
										</LinkContainer>
									)}
									{reading.includes('addresses') && (
										<LinkContainer exact to='/admin/addresses'>
											<NavItem>Adreses</NavItem>
										</LinkContainer>
									)}
									{reading.includes('directions') && (
										<LinkContainer exact to='/admin/directions'>
											<NavItem>Piegādes virzieni</NavItem>
										</LinkContainer>
									)}
									{reading.includes('storage') && (
										<LinkContainer exact to='/admin/storage'>
											<NavItem>Noliktavas</NavItem>
										</LinkContainer>
									)}
									{reading.includes('materials') && (
										<LinkContainer exact to='/admin/material'>
											<NavItem>Nomenklatūra un grupas</NavItem>
										</LinkContainer>
									)}
									{reading.includes('materials') && (
										<LinkContainer exact to='/admin/price_summary'>
											<NavItem>Cenu lapas</NavItem>
										</LinkContainer>
									)}
									{reading.includes('departments') && (
										<LinkContainer exact to='/admin/departments'>
											<NavItem>Nodaļas</NavItem>
										</LinkContainer>
									)}
									{reading.includes('transport') && (
										<LinkContainer exact to='/admin/transport'>
											<NavItem>Transports</NavItem>
										</LinkContainer>
									)}
									{reading.includes('transport') && (
										<LinkContainer exact to='/admin/ships'>
											<NavItem>Kuģi</NavItem>
										</LinkContainer>
									)}
									{reading.includes('smtp') && (
										<LinkContainer exact to='/admin/smtp'>
											<NavItem>SMTP</NavItem>
										</LinkContainer>
									)}
									{reading.includes('responsible') && (
										<LinkContainer exact to='/admin/responsible'>
											<NavItem>Atbildīgās personas</NavItem>
										</LinkContainer>
									)}
									{reading.includes('sms') && (
										<LinkContainer exact to='/admin/sms'>
											<NavItem>SMS</NavItem>
										</LinkContainer>
									)}
									<LinkContainer exact to='/admin/text_templates'>
										<NavItem>Teksta veidnes</NavItem>
									</LinkContainer>
									{serviceToggle && (
										<LinkContainer exact to='/admin/services'>
											<NavItem>Ārējie servisi</NavItem>
										</LinkContainer>
									)}
									<LinkContainer exact to='/admin/audit'>
										<NavItem>Auditācija</NavItem>
									</LinkContainer>
									{reading.includes('horizon') && (
										<LinkContainer exact to='/admin/horizon'>
											<NavItem>Horizon sinhronizācija</NavItem>
										</LinkContainer>
									)}
								</NavDropdown>
							) : (
								''
							)}

							{/*
              <NavDropdown title="Saites" id="links-dropdown">
                <LinkContainer to="/login"><NavItem>Login</NavItem></LinkContainer>
                <LinkContainer to="/order"><NavItem>order edit/new</NavItem></LinkContainer>
                <LinkContainer to="/ordertest"><NavItem>test</NavItem></LinkContainer>
                <LinkContainer to="/details/701"><NavItem>detaļas</NavItem></LinkContainer>
                <LinkContainer to="/izpilde"><NavItem>izpilde</NavItem></LinkContainer>
                <LinkContainer to="/izpilde_soferi"><NavItem>DeliveryDrivers</NavItem></LinkContainer>
                <LinkContainer to="/izpilde_soferi_specific"><NavItem>DeliveryDriver One</NavItem></LinkContainer>
              </NavDropdown>

              <NavDropdown title="Administrēšana" id="admin-dropdown">
                <LinkContainer to="/admin/addresses"><NavItem>Adreses</NavItem></LinkContainer>
                <LinkContainer to="/admin/storages"><NavItem>Noliktavas</NavItem></LinkContainer>
                <LinkContainer to="/admin/materials"><NavItem>Materiāli</NavItem></LinkContainer>
                <LinkContainer to="/admin/material_cats"><NavItem>Materiālu kategorijas</NavItem></LinkContainer>
                <LinkContainer to="/admin/deps"><NavItem>Nodaļas</NavItem></LinkContainer>
              </NavDropdown>
              */}
						</Nav>
						{isLoggedIn ? (
							<Nav pullRight>
								{dbIsReadOnly && (
									<NavItem>
										<span style={{ color: 'red' }}>DB: read-only</span>
									</NavItem>
								)}
								<NavDropdown title={username} id='lists-dropdown'>
									<NavItem onClick={this.handleToggleModal}>
										Sistēmas info
									</NavItem>
									<NavItem onClick={this.handleRemoveFilters}>
										Atiestatīt filtrus
									</NavItem>
									<LinkContainer exact to='/change_password'>
										<NavItem>Paroles maiņa</NavItem>
									</LinkContainer>
									<NavItem onClick={handleLogout} target='_parent'>
										Iziet
									</NavItem>
								</NavDropdown>
							</Nav>
						) : (
							''
						)}
					</Navbar.Collapse>
				</Navbar>
				{modalOpen && (
					<CustomModal
						title='Sistēmas info'
						showModal={modalOpen}
						handleCloseModal={this.handleToggleModal}
						content={<InfoModal />}
					/>
				)}
				{/* <FilterModal showModal={modalOpen} handleCloseModal={this.handleToggleModal} /> */}
			</div>
		);
	}
}

export default Header;
