//<OrderTable dept={tab.id} rows={tab.orderCount} client={client} year={thisYear} />
//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import Edit from 'sections/orders/edit/edit';
/* import TaskEdit from './orders/newTasks';

import BillActions from 'sections/bills/actions';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import clsx from 'clsx';
import InfoModal from 'sections/admin/infoModal';
import OtherBillPayer from 'sections/bills/otherPayer';
import HistoryModal from 'shared/history'; */
import BraucienaForma from 'sections/deliveries/braucienaForma';
import FreePiegades from 'sections/bills/allFreePiegades';
/* import DeliveryEdit from './deliveries/edit';
import PageWithPaginate from './pagination';
import CalendarCom from './deliveries/calendarMonth';
import MultiTaskTable from './planning/newMultiTask';
//import TaskEdit from './orders/taskEdit';
import Switch from 'react-bootstrap-switch';
import UserEdit from '../sections/admin/forms/users';
import ClientEdit from './clients/edit';
//import BillActions from './bills/actions';
//import EditBill from './bills/edit';
import Filter from './filter';
import Modal from '../sections/orders/actions/modal';
import Deliveries from './deliveries/table'; */

const driverFormat = (option, props, idx) => {
	return (
		<span style={{ fontSize: '75%' }} key={idx}>
			{option.driverName}{' '}
			{option.mappostUserID > 0 ? (
				<span style={{ color: 'green' }}>(MAPPOST lietotājs)</span>
			) : (
				<span style={{ color: '#ff0000' }}>(Nav reģistrēts MAPPOST sistēmā)</span>
			)}{' '}
			- {option.client} - {option.vilcejs}
		</span>
	);
};

class TestingThings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: [],
			dataerrors: {},
			sending: false
		};
	}

	render() {
		//<HistoryModal mode="order" ID="26444" showModal={true} />
		//const deliveryIncludedInPrice = true;
		//console.log(process.env)
		//<div>{JSON.stringify(process.env)}</div>
		//<ul>{this.state.options.map(driver => <li key={driver.name}>{driverFormat(driver, {}, driver.name)}</li>)}</ul>
		return (
			<React.Fragment>
				{/* <Edit /> */}

				<FreePiegades
					payer={469}
					type='6965'
					pvz='44482e9e-5c73-4600-9d7f-03202c60a43f'
					order={26605}
					stat='1'
				/>
				{/* <BraucienaForma delivery='79338' /> */}
				{/* <OtherBillPayer id='68' /> */}
			</React.Fragment>
		);
	}
}
/*
<Edit
		orderId={null}
		clientRegNr={'58503007191'}

	/>
	*/

export default TestingThings;
