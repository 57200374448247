import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check, Percentage } from '../../shared/form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../api';
import { toast } from 'react-toastify';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { formatNumber, allFieldsHaveValues_new, formatDateForDB } from 'util';
import isNumericWrap from 'fast-isnumeric';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { CustomModal } from 'shared/modal';
import ShipEdit from 'sections/admin/forms/ship';

const packagingOptions = [
	{ id: 1, title: 'maisi' },
];

export const packagingWeightOptions = [
	{ id: 1, title: '500 kg', value: 500 },
	{ id: 2, title: '600 kg', value: 600 },
	{ id: 3, title: '1000 kg', value: 1000 },
];

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class Edit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			dataerrors: {},
			data: {},
			orderType: '1',
			wrongData: {},
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			initialData: {},
			options: {
				storage: [],
				material: []
			},
			clientSearch_storage: true,
			sendingStatus: false,
			userFavorites: {
				storage: [],
				destination: []
			},
		};
		this.saveData = this.saveData.bind(this);
	}

	mainStyle = {
		padding: '3px',
		borderLeft: '3px solid #ff9933',
		marginTop: '3px',
		fontSize: '75%'
	}

	subStyle = {
		padding: '3px',
		borderLeft: '3px solid #00CC66',
		marginTop: '3px',
		fontSize: '75%'
	}

	dashStyle = {
		fontWeight: 'bold',
		fontSize: 20,
		position: 'relative',
		left: 14,
		color: 'grey'
	}

	setFieldError = field => {
		this.setState({
			dataerrors: {
				[field]: true
			}
		});
	}

	saveData = e => {
		const { orderId } = this.props;
		const { toSaveData, data } = this.state;
		const isNewOrder = orderId === null;

		e.preventDefault();
		this.setState({ dataerrors: {} });

		if (Object.keys(toSaveData).length === 0) {
			toast.info(orderId ? 'Dati nav mainījušies' : 'Nav ko saglabāt');
			return;
		}

		let reallyChanged = {};

		if (!isNewOrder) {
			for (let key in toSaveData) {
				if (toSaveData[key] !== data[key]) {
					reallyChanged[key] = toSaveData[key];
				}
			}

			if (Object.keys(reallyChanged).length === 0) {
				toast.info('Dati netika izmainīti');
				return;
			}
		} else {
			reallyChanged = toSaveData;
		}

		const dataToSendAsDefaults = [
			'orderType',
			'storage',
			'material',
			'amount',
			'measurementID',
			'packagingID',
			'packagingWeightID',
			'packagingCount',
			'productPrice',
			'salesPrice',
			'ordererPriceID',
			'ordererPriceAmount',
			'loading_started_at',
			'loading_ended_at',
			'deadline_start',
			'deadline_end',
			'department',
			'ship',
			'supplier',
			'notes',
			'invoice',
			'contract',
			'ship_date',
			'tolerance',
		];

		let try2Final = {};

		const priceKeys = ['productPrice', 'salesPrice', 'ordererPriceAmount'];

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else if (theKey in data) {
				try2Final[theKey] = data[theKey];
			}

			if (priceKeys.includes(theKey) && try2Final[theKey] === '') {
				try2Final[theKey] = 0;
			}

			if (theKey === 'tolerance' && !try2Final[theKey]) {
				try2Final[theKey] = 0;
			}
		}

		const notes = 'notes' in try2Final ? try2Final.notes : this.state.notes;
		const orderTypeFinal = 'orderType' in try2Final ? try2Final.orderType : this.state.orderType;

		const finalToSend = Object.assign({}, try2Final,
			{ orderType: orderTypeFinal },
			{ notes },
			{ loading_started_at: formatDateForDB(try2Final.loading_started_at) },
			{ loading_ended_at: formatDateForDB(try2Final.loading_ended_at) },
			{ ship_date: formatDateForDB(try2Final.ship_date) },
			{ deadline_start: formatDateForDB(try2Final.deadline_start) },
			{ deadline_end: formatDateForDB(try2Final.deadline_end) },
		);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			orderType: 'Pamatveids',
			storage: orderTypeFinal === '1' ? 'Atkraušanas vieta' : 'Piegādes vieta',
			material: 'Materiāls',
			amount: 'Plānotais daudzums',
			measurementID: 'Mērvienība',
			packagingID: 'Iepakojuma veids',
			packagingWeightID: 'Iepakojuma svars',
			packagingCount: 'Iepakojumu skaits',
			productPrice: orderTypeFinal === '1' ? 'Iepirkuma cena' : 'Produkta cena',
			ordererPriceAmount: 'Iekļautā transporta cena',
			ordererPriceID: 'Iekļautā transporta cenas mērvienība',
			notes: 'Piezīmes',
			department: 'Nodaļa',
			loading_started_at: 'Plānotais preces pienākšanas periods no',
			loading_ended_at: 'Plānotais preces pienākšanas periods līdz',
			ship_date: 'Preces pienākšanas datums',
			deadline_start: 'Piegādes periods no',
			deadline_end: 'Piegādes periods līdz',
			supplier: 'Pircējs'
		};

		const requiredFields = [
			'orderType',
			'storage',
			'material',
			'amount',
			'measurementID',
			'productPrice',
			'ordererPriceAmount',
			'ordererPriceID',
			'loading_started_at',
			'loading_ended_at',
			'department',
			'supplier',
			'notes'
		];

		if (finalToSend.ship_date) {
			requiredFields.push('ship_date', 'deadline_start', 'deadline_end');
		}

		const confirmAllFieldsHasValues = allFieldsHaveValues_new(requiredFields, finalToSend, nameMap);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			this.setFieldError(confirmAllFieldsHasValues.field);
			return;
		}

		if (finalToSend.ordererPriceAmount < 0.85) {
			toast.warn(`Iekļautā transporta cenai jābūt lielākai par 0,85`, {
				autoClose: 7500
			});
			this.setFieldError('ordererPriceAmount');
			return;
		}

		this.setState({ sendingStatus: true });

		const params = {data: finalToSend};
		if (orderId) {
			params.id = orderId;
		}

		api.planning.order_save(params).then(response => {
				this.setState({ sendingStatus: false });

				if (response.status == 'error') {
					toast.warn(response.message, { autoClose: 10000 });
				} else if (['ok', 'success'].includes(response.status)) {
					toast.success('Dati saglabāti');
					setRespectTabs();
					setTimeout(() => this.props.handleClose(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn('Ir problēmas!', { autoClose: 7000 });
				}
			})
			.catch(error => this.setState({ sendingStatus: false }));
	}

	setData = () => {
		const { orderId } = this.props;

		this.setState({ loading: true });
		if (orderId == null) {
			api.planning.new_order().then(response => {
				this.setDataToInitState(response);
			});
		} else {
			api.planning.order_info(orderId).then(response => {
				this.setDataToInitState(response);
			});
		}
	}

	getUserFavorites = () => {
		return api.favorites.userFavorites()
			.then(response => {
				this.setState({ userFavorites: response })
			})
	}

	setDataToInitState = response => {
		const { optionData, orderData } = response;

		optionData.packaging = packagingOptions;
		optionData.packagingWeight = packagingWeightOptions;

		const moreAttrs = getDataFromAPIResponse(orderData, [
			'orderType',
			'storageID',
			'material',
			'amount',
			'measurementID',
			'packagingID',
			'packagingWeightID',
			'packagingCount',
			'productPrice',
			'salesPrice',
			'ordererPriceID',
			'ordererPriceAmount',
			'loading_started_at',
			'loading_ended_at',
			'deadline_start',
			'deadline_end',
			'notes',
			'department',
			'storage',
			'ship',
			'supplier',
			'invoice',
			'contract',
			'ship_date',
			'tolerance',
		]);

		if (moreAttrs.ordererPriceID == null) {
			moreAttrs.ordererPriceID = '2';
			const toSaveData = Object.assign(this.state.toSaveData, { ordererPriceID: '2' });
			this.setState({ toSaveData });
		}

		const attrTransformation = {
			amount: formatNumber(moreAttrs.amount, 3),
			ordererPriceAmount: formatNumber(moreAttrs.ordererPriceAmount, 3),
			packagingCount: moreAttrs.packagingCount == null ? '0' : moreAttrs.packagingCount,
			department: moreAttrs.department,
			notes: moreAttrs.notes == null ? '' : moreAttrs.notes,
			loading_started_at: moreAttrs.loading_started_at == null ? '' : moreAttrs.loading_started_at,
			loading_ended_at: moreAttrs.loading_ended_at == null ? '' : moreAttrs.loading_ended_at,
			deadline_start: moreAttrs.deadline_start == null ? '' : moreAttrs.deadline_start,
			deadline_end: moreAttrs.deadline_end == null ? '' : moreAttrs.deadline_end,
			ship_date: moreAttrs.ship_date == null ? '' : moreAttrs.ship_date,
			invoice: moreAttrs.invoice == null ? '' : moreAttrs.invoice,
			contract: moreAttrs.contract == null ? '' : moreAttrs.contract,
			tolerance: moreAttrs.tolerance == null ? '0' : moreAttrs.tolerance,
		};

		const newState = Object.assign(
			{},
			moreAttrs,
			{ loading: false, error: false, optionData, data: orderData },
			attrTransformation
		);

		this.setState(newState, () => {
			this.getUserFavorites();
		});
	}

	componentDidMount() {
		this.setData();
	}

	handleChange = event => {
		const inputValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		const numericFields = ['amount', 'productPrice', 'salesPrice', 'ordererPriceAmount'];

		if (inputValue.length > 0 && numericFields.includes(name) && !this.isnumeric(inputValue)){
			return false;
		}

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue }, () => {
			if (['amount', 'measurementID', 'packagingID', 'packagingWeightID'].includes(name)) {
				this.calculatePackaging();
			}
		});
	}

	addrFormat(option, idx, field) {
		const mainTitle =
			field == 'storage' ? (
				<span>
					{option.title} - {option.nosaukums}
				</span>
			) : (
				<span>{option.name}</span>
			);

		return (
			<div>
				<span style={this.mainStyle} key={idx}>
					{mainTitle}
				</span>
				<br />
				{option.clients.map((cl, currKey) => (
					<span style={this.subStyle} key={currKey}>
						&nbsp;&nbsp;&nbsp;&nbsp;{cl}
					</span>
				))}
			</div>
		);
	}

	setDataToState(key, value) {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });
		this.setState({ toSaveData, [key]: value });
	}

	onTypeAheadSelect = (result, whatWasSearched) => {
		if (result != '') {
			this.setDataToState([whatWasSearched], result);
		}
	}

	formatAsString = event => {
		const {value: number, name} = event.target;
		this.setDataToState(name, formatNumber(number, 3));
	}

	_handleSearchMeta(field, query, searchCB) {
		this.setState({ isLoading: true });

		query = encodeURIComponent(query);

		let searcbParams = null;

		const { clientRegNr } = this.props;

		let key = null;

		if (field == 'storage') {
			key = 'storage';
			const { clientSearch_storage } = this.state;
			searcbParams = {
				clientId: clientRegNr,
				totaly: query,
				clientSearch: clientSearch_storage
			};
		} else if (field == 'ship') {
			key = 'ship';
			searcbParams = query;
		} else if (field == 'material') {
			key = 'material';
			searcbParams = query;
		} else if (field == 'supplier') {
			key = 'supplier';
			searcbParams = query;
		}

		searchCB(searcbParams)
			.then(response => {
				this.setState({
					options: {
						...this.state.options,
						[key]: response
					},
					isLoading: false
				});
			});
	}

	handleDateChange = val => event => {
		try {
			this.setDataToState(val, event.format('DD.MM.YYYY'));
			this.setState((prevState) => ({
				wrongData: {
					...prevState.wrongData,
					[val]: false,
				},
			}));
		} catch (err) {
			this.setState((prevState) => ({
				wrongData: {
					...prevState.wrongData,
					[val]: true,
				},
			}));
			return;
		}
	}

	handleFavorite(type, id) {
		api.favorites.add({type, id})
			.then(() => {
				this.getUserFavorites();
			});
	}

	isnumeric(value) {
		return isNumericWrap(value);
	}

	getDefaultSelected(value) {
		if (value == '' || typeof value == 'undefined') {
			return [];
		}
		return [value];
	}

	requiredField = (field, type = 'async') => {
		if (field == undefined || field == null || field == '') {
			const returnStyle =
				type === 'input' ?
				{border: '1px solid #f10303'} :
				{style: {border: '1px solid #f10303'}}
			return returnStyle
		}
		return {}
	}

	handleOpenModal(openType) {
		this.setState({ showModal: true, modalType: openType });
	}

	handleToggleModal = (ev, data = null, modalType = '') => {
		if (data != null && modalType == 'ship') {
			this.setDataToState(modalType, data);
		}
		this.setState({ showModal: !this.state.showModal });
	}

	calculatePackaging = () => {
		const { amount, measurementID, packagingID, packagingWeightID } = this.state;

		if (!amount || !measurementID || !packagingID || !packagingWeightID) {
			return;
		}

		// accept only 'tonnas'
		if (measurementID !== '1') {
			return;
		}

		const packagingWeight = this.state.optionData.packagingWeight.find(
			weight => weight.id == packagingWeightID
		);

		if (amount && packagingWeight) {
			const amountInKg = amount * 1000;
			const packagingCount = Math.round(amountInKg / packagingWeight.value);
			this.setDataToState('packagingCount', packagingCount);
		}
	}

	render() {
		const {
			orderType,
			storage,
			material,
			ship,
			productPrice,
			salesPrice,
			ordererPriceAmount,
			ordererPriceID,
			amount,
			department,
			notes,
			measurementID,
			packagingID,
			packagingWeightID,
			packagingCount,
			dataerrors,
			loading,
			sendingStatus,
			userFavorites,
			loading_started_at,
			loading_ended_at,
			supplier,
			showModal,
			optionData,
			wrongData,
			invoice,
			contract,
			ship_date,
			deadline_start,
			deadline_end,
			tolerance,
		} = this.state;

		const storageFav = userFavorites.storage.includes(storage?.id) ? 'gold' : '#ECEBEB';
		const orderTotal = formatNumber(productPrice * amount, 3);
		const showPackagingCount = amount && measurementID === '1' && packagingID && packagingWeightID;

		if (loading) {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				<div className='row'>
					<Label labelClass='col-sm-4' title='Pamatveids' name='orderType' />
					<div className='col-md-8'>
						<Radio
							name='orderType'
							value='1'
							checkAgainst={orderType}
							handleChange={this.handleChange}
							label='Izvešana'
						/>
						<Radio
							name='orderType'
							value='2'
							checkAgainst={orderType}
							handleChange={this.handleChange}
							label='Piegāde'
						/>
					</div>
				</div>

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={
							<span>
								{orderType === '1' ? 'Atkraušanas vieta ' : 'Piegādes vieta '}
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-right'>Maršruts no</Tooltip>
									}>
									<span
										className='glyphicon glyphicon-question-sign'
										aria-hidden='true'></span>
								</OverlayTrigger>
							</span>
						}
						name='storage'
					/>
					<div className='col-sm-7'>
						<AsyncTypeahead
							isLoading={this.state.isLoading}
							options={this.state.options.storage}
							onChange={selected =>
								this.onTypeAheadSelect(selected[0], 'storage')
							}
							filterBy={(option, props) => {
								return option;
							}}
							minLength={2}
							selected={this.getDefaultSelected(storage)}
							labelKey='title'
							id='storageSearch'
							clearButton={true}
							onSearch={query =>
								this._handleSearchMeta('storage', query, api.search.storage)
							}
							renderMenuItemChildren={(option, idx) =>
								this.addrFormat(option, idx, 'storage')
							}
							inputProps={this.requiredField(storage)}
						/>
					</div>
					{storage &&
						<span onClick={() => this.handleFavorite('storage', storage.id)} style={{ cursor: 'pointer' }}
								title={storageFav === 'gold' ? 'Pievienots favorītiem' : 'Nav pievienots favorītiem'}>
							<i className='fas fa-star'
								style={{ color: storageFav, fontSize: 22, paddingTop: 6, marginLeft: -10 }}></i>
						</span>
					}
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Materiāls' name='material' />
					<div className='col-sm-8'>
						<AsyncTypeahead
							isLoading={this.state.isLoading}
							options={this.state.options.material}
							onChange={selected =>
								this.onTypeAheadSelect(selected[0], 'material')
							}
							selected={this.getDefaultSelected(material)}
							minLength={2}
							labelKey='title'
							id='materialSearch'
							clearButton={true}
							filterBy={(option, props) => {
								return option;
							}}
							onSearch={query =>
								this._handleSearchMeta('material', query, api.search.material)
							}
							renderMenuItemChildren={(option, idx) => (
								<span style={{ fontSize: '75%' }} key={idx}>
									{option.title}
								</span>
							)}
							inputProps={this.requiredField(material)}
						/>
					</div>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Plānotais daudzums' name='amount' />
					<Input
						inputClass='col-sm-3'
						blur={this.formatAsString}
						name='amount'
						isError={dataerrors.amount}
						value={amount}
						handleChange={this.handleChange}
						inputStyle={this.requiredField(amount, 'input')}
					/>
					<Select
						selectClass='col-sm-5'
						handleChange={this.handleChange}
						name='measurementID'
						isError={dataerrors.measurementID}
						value={measurementID}
						placeholder='Izvēlies mērvienību'
						options={
							optionData
								? optionData.measurement.map(meas => ({
										label: meas.title,
										value: meas.id
								}))
								: []
						}
					/>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Iepakojuma veids' name='packagingID' />
					<Select
						selectClass='col-sm-3'
						handleChange={this.handleChange}
						name='packagingID'
						isError={dataerrors.packagingID}
						value={packagingID}
						placeholder='Izvēlies iepakojumu'
						options={
							optionData
								? optionData.packaging.map(meas => ({
										label: meas.title,
										value: meas.id
								}))
								: []
						}
					/>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Iepakojuma svars' name='packagingWeightID' />
					<Select
						selectClass='col-sm-4'
						handleChange={this.handleChange}
						name='packagingWeightID'
						isError={dataerrors.packagingWeightID}
						value={packagingWeightID}
						placeholder='Izvēlies iepakojuma svaru'
						options={
							optionData
								? optionData.packagingWeight.map(meas => ({
										label: meas.title,
										value: meas.id
								}))
								: []
						}
					/>
				</div>

				{showPackagingCount && (
					<div className='row' style={{marginTop: 5}}>
						<Label
							labelClass='col-sm-4'
							title='Iepakojumu skaits'
							name='packagingCount'
						/>
						<span style={{marginLeft: 27}}>
							{packagingCount}
						</span>
					</div>
				)}

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Piegādes tolerance +/- %'
						name='tolerance'
					/>
					<Percentage
						inputClass='col-sm-3'
						name='tolerance'
						value={tolerance}
						handleChange={this.handleChange}
					/>
				</div>

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={orderType === '1' ? 'Iepirkuma cena' : 'Produkta cena'}
						name='productPrice'
					/>
					<Input
						inputClass='col-sm-8'
						name='productPrice'
						isError={dataerrors.productPrice}
						blur={this.formatAsString}
						value={productPrice}
						handleChange={this.handleChange}
					/>
				</div>

				{(productPrice && amount) && (
				<div className='row' style={{marginTop: 5}}>
					<Label
						labelClass='col-sm-4'
						title='Pasūtījuma summa'
						name='orderTotal'
					/>
					<span style={{marginLeft: 27}}>
						{orderTotal} &euro;
					</span>
				</div>
				)}

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={
							<span>
								Iekļautā transporta cena{' '}
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-right'>
											Šeit jāievada ar klientu atrunātā cena par
											piegādi
										</Tooltip>
									}>
									<span
										className='glyphicon glyphicon-question-sign'
										aria-hidden='true'></span>
								</OverlayTrigger>
							</span>
						}
						name='ordererPriceAmount'
					/>
					<Input
						inputClass='col-sm-3'
						blur={this.formatAsString}
						name='ordererPriceAmount'
						isError={dataerrors.ordererPriceAmount}
						value={ordererPriceAmount}
						handleChange={this.handleChange}
						inputStyle={this.requiredField(ordererPriceAmount, 'input')}
					/>
					<Select
						selectClass='col-sm-5'
						handleChange={this.handleChange}
						name='ordererPriceID'
						value={ordererPriceID}
						placeholder='Izvēlies mērvienību'
						options={
							optionData
								? optionData.price.map(price => ({
									label: price.title,
									value: price.id
								}))
								: []
						}
					/>
				</div>

				{orderType === '1' &&
					<div className='row'>
						<Label
							labelClass='col-sm-4'
							title='Pārdošanas cena'
							name='salesPrice'
						/>
						<Input
							inputClass='col-sm-8'
							name='salesPrice'
							isError={dataerrors.salesPrice}
							blur={this.formatAsString}
							value={salesPrice}
							handleChange={this.handleChange}
						/>
					</div>
				}

				<div className='row'>
					<Label labelClass='col-sm-4'
						title={
							<OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id='tooltip-right'>No - Līdz</Tooltip>
								}>
								<span>Plānotais preces pienākšanas periods</span>
							</OverlayTrigger>
						}
						name='loadingDates' />
					<div className='col-sm-3' style={{display: 'flex'}}>
						<Datetime
							locale='lv'
							timeFormat={false}
							closeOnSelect={true}
							onChange={this.handleDateChange('loading_started_at')}
							defaultValue={loading_started_at !== 'Invalid date' ? loading_started_at : ''}
						/>
						<div style={{
							fontWeight: 'bold',
							fontSize: 20,
							position: 'relative',
							left: 14,
							color: 'grey'
							}}>-</div>
					</div>
					<div className='col-sm-3' >
						<Datetime
							locale='lv'
							timeFormat={false}
							closeOnSelect={true}
							onChange={this.handleDateChange('loading_ended_at')}
							defaultValue={loading_ended_at !== 'Invalid date' ? loading_ended_at : ''}
						/>
					</div>
					{(wrongData.loading_started_at || wrongData.loading_ended_at) && (
						<div className='col-sm-1'>
							<OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id='tooltip-right'>
										Datums ir jāapstiprina kalendāra izvēlnē
									</Tooltip>
								}>
								<i className='fas fa-exclamation-triangle'></i>
							</OverlayTrigger>
						</div>
					)}
				</div>

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Nodaļa, kas veic pasūtījumu'
						name='department'
					/>
					<Select
						selectClass='col-sm-8'
						handleChange={this.handleChange}
						name='department'
						value={department}
						isError={dataerrors.department}
						placeholder='Izvēlies vērtību'
						options={
							optionData
								? optionData.departments.map(dept => ({
										label: dept.title,
										value: dept.id
									}))
								: []
						}
					/>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Kuģis' name='ship' />
					<div className='col-sm-6'>
						<AsyncTypeahead
							isLoading={this.state.isLoading}
							options={this.state.options.ship}
							onChange={selected =>
								this.onTypeAheadSelect(selected[0], 'ship')
							}
							selected={this.getDefaultSelected(ship)}
							minLength={2}
							labelKey='title'
							id='shipSearch'
							clearButton={true}
							filterBy={(option, props) => {
								return option;
							}}
							onSearch={query =>
								this._handleSearchMeta('ship', query, api.search.ship)
							}
							renderMenuItemChildren={(option, idx) => (
								<span style={{ fontSize: '75%' }} key={idx}>
									{option.title}
								</span>
							)}
						/>
					</div>
					<button
						type='button'
						className='btn btn-success'
						style={{borderColor: '#E7E7E7', marginLeft: -14}}
						onClick={() => this.handleOpenModal('newShip')}
						>
						Izveidot
					</button>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4'
						title='Preces pienākšanas datums'
						name='ship_date' />
					<div className='col-sm-3' >
						<Datetime
							locale='lv'
							timeFormat={false}
							closeOnSelect={true}
							onChange={this.handleDateChange('ship_date')}
							defaultValue={ship_date !== 'Invalid date' ? ship_date : ''}
						/>
					</div>
					{wrongData.ship_date && (
						<div className='col-sm-1'>
							<OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id='tooltip-right'>
										Datums ir jāapstiprina kalendāra izvēlnē
									</Tooltip>
								}>
								<i className='fas fa-exclamation-triangle'></i>
							</OverlayTrigger>
						</div>
					)}
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Pircējs' name='supplier' />
					<div className='col-sm-8'>
						<AsyncTypeahead
							isLoading={this.state.isLoading}
							options={this.state.options.supplier}
							onChange={selected =>
								this.onTypeAheadSelect(selected[0], 'supplier')
							}
							selected={this.getDefaultSelected(supplier)}
							minLength={2}
							labelKey='title'
							id='supplierSearch'
							clearButton={true}
							filterBy={(option, props) => {
								return option;
							}}
							onSearch={query =>
								this._handleSearchMeta('supplier', query, api.search.suppliers)
							}
							renderMenuItemChildren={(option, idx) => (
								<span style={{ fontSize: '75%' }} key={idx}>
									{option.title} - {option.reg_nr}
								</span>
							)}
							inputProps={this.requiredField(supplier)}
						/>
					</div>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Līguma nummurs' name='contract' />
					<Input
						inputClass='col-sm-8'
						name='contract'
						value={contract}
						handleChange={this.handleChange}
					/>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Iepirkuma PVZ' name='invoice' />
					<Input
						inputClass='col-sm-8'
						name='invoice'
						value={invoice}
						handleChange={this.handleChange}
					/>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4'
						title={
							<OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id='tooltip-right'>
										{ship_date ? 'No - Līdz': 'Ievadiet "Preces pienākšanas datums" lauku, lai aktivizētu'}
									</Tooltip>
								}>
								<span>Piegādes periods</span>
							</OverlayTrigger>
						}
						name='deadline' />
					<div className='col-sm-3' style={{display: 'flex'}}>
						<Datetime
							locale='lv'
							timeFormat={false}
							closeOnSelect={true}
							onChange={this.handleDateChange('deadline_start')}
							defaultValue={deadline_start !== 'Invalid date' ? deadline_start : ''}
							inputProps={{ disabled: ship_date ? false : true }}
						/>
						<div style={{
							fontWeight: 'bold',
							fontSize: 20,
							position: 'relative',
							left: 14,
							color: 'grey'
							}}>-</div>
					</div>
					<div className='col-sm-3' >
						<Datetime
							locale='lv'
							timeFormat={false}
							closeOnSelect={true}
							onChange={this.handleDateChange('deadline_end')}
							defaultValue={deadline_end !== 'Invalid date' ? deadline_end : ''}
							inputProps={{ disabled: ship_date ? false : true }}
						/>
					</div>
					{(wrongData.deadline_start || wrongData.deadline_end) && (
						<div className='col-sm-1'>
							<OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id='tooltip-right'>
										Datums ir jāapstiprina kalendāra izvēlnē
									</Tooltip>
								}>
								<i className='fas fa-exclamation-triangle'></i>
							</OverlayTrigger>
						</div>
					)}
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Piezīmes' name='notes' />
					<Textarea
						inputClass='col-sm-8'
						name='notes'
						value={notes}
						handleChange={this.handleChange}
					/>
				</div>
				<button
					type='button'
					disabled={sendingStatus}
					className='btn btn-primary'
					onClick={this.saveData}>
					Saglabāt!
				</button>
				<CustomModal
					title='Kuģa pievienošana'
					showModal={showModal}
					handleCloseModal={this.handleToggleModal}
					content={
						<ShipEdit
							handleCloseModal={this.handleToggleModal}
						/>
					}
				/>
			</div>
		);
	}
}

export default Edit;
