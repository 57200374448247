import { getCurrPage } from './util';
import store from './store';

export function checkPermission(action = null, section = null) {
	//return true;
	if (section == null) {
		section = getCurrPage();
	}
	//return true;
	//console.log('getting permissions for: ',section, action);

	const state = store.getState();
	//console.log('state object',state, state.auth.roles);

	const permissionsFromLocalStorage = state.auth.roles; //localStorage.getItem('roles');
	//console.log(JSON.parse(permissionsFromLocalStorage));

	if (permissionsFromLocalStorage !== null) {
		//šis jānočeko, vai šādi strādā
		const permissions = permissionsFromLocalStorage;

		if (
			section in permissions &&
			action in permissions[section] &&
			permissions[section][action] == 1
		) {
			//console.log('good permissions',section, action,permissions[section][action]);
			return true;
		}
		//console.log('bad permissions',section, action,permissions[section][action]);

		return false;
	}
	return false;
}

export function horizonCheck() {
	const state = store.getState();

	return state.auth.horizonSync;
}
