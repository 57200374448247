import React, { Component, Suspense } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../../shared/form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import Switch from 'react-bootstrap-switch';
import { toast } from 'react-toastify';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import {
	formatNumber,
	isNumeric,
	allFieldsHaveValues,
	allFieldsHaveValues_new,
	reloadPage
} from 'util';
import empty from 'is-empty';
import isNumericWrap from 'fast-isnumeric';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import clsx from 'clsx';
import cssStyles from './styles.module.css';
import { connect } from 'react-redux';
import { formatDateForDB } from 'helpers/time';
import { calculatePrice } from './helpers';
import InfoRow from './info';
import { CustomModal } from 'shared/modal';
import { packagingWeightOptions } from '../../ordersPlanning/editModal';
const MapObject = React.lazy(() => import('shared/map'));

//var empty = require('is-empty');
//var isNumericWrap = require('fast-isnumeric');

const calculateFinalPrice = state => {
	const { materialTransportPrice, prognosedDistanceAndTime } = state;
	const { amount, ordererPriceAmount, material } = state;

	if ('len' in prognosedDistanceAndTime == false || prognosedDistanceAndTime.len === null) {
		return null;
	}

	if (materialTransportPrice === null) {
		return null;
	}

	let price = materialTransportPrice.value;

	/* if (prognosedDistanceAndTime && prognosedDistanceAndTime.len && material && (material.category === 'Graudi, Rapsis' || material.category === 'MM')) {
		if (prognosedDistanceAndTime.len > 100) {
			price = 0.8;
		} else if (prognosedDistanceAndTime.len > 40) {
			price = 0.85;
		} else {
			price = 0.9;
		}
	} */

	const val = (prognosedDistanceAndTime.len * price) / materialTransportPrice.volume;

	return Math.round((val + Number.EPSILON) * 100) / 100; //Math.round(val);
};

const handlePayerOrdererLabel = (deliveryIncludedInPrice, clientRegNr) => {
	let shouldShowOrderer = false;
	let showPayer = false;

	if (clientRegNr === null) {
		//pasūtījumu tabs
		shouldShowOrderer = deliveryIncludedInPrice ? true : false;
		showPayer = true;
	} else {
		//klienta kartiņa
		shouldShowOrderer = false;
		showPayer = deliveryIncludedInPrice ? false : true;
	}

	return {
		showField: showPayer,
		ordererAsLabel: shouldShowOrderer
	};
};

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

const getFromData = (path, storage, defaultValue, stringify = true) => {
	const rootElementToCheck = path[0];
	const childElementToCheck = path[1];

	if (typeof storage[rootElementToCheck] == 'undefined' || !(rootElementToCheck in storage)) {
		return defaultValue;
	}

	if (childElementToCheck === null) {
		return stringify ? storage[rootElementToCheck].toString() : storage[rootElementToCheck];
	}

	if (storage[rootElementToCheck][childElementToCheck] === null) {
		return '';
	}

	if (stringify) {
		return storage[rootElementToCheck][childElementToCheck].toString();
	}

	return storage[rootElementToCheck][childElementToCheck];
}

class Edit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			dataerrors: {},
			data: {},
			wrongData: false,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			initialData: {},
			prognosedDistanceAndTime: {
				len: null,
				time: null
			},
			materialTransportPrice: null,
			options: {
				storage: [],
				destination: [],
				payer: [],
				material: []
			},
			priority: '1',
			responsibility: '1',
			name: '',
			finalPrice: 0,
			historicPrices: [],
			historicPricesLoaded: false,
			showNotesInBill: false,
			deliveryIncludedInPrice: true,
			clientSearch_storage: true,
			clientSearch_destination: true,
			sendingStatus: false,
			icClientDeliveryAddress: false, //poga "Piegādes adrese"
			userFavorites: {
				storage: [],
				destination: []
			},
			favIsLoading: false,
			manipulator: false,
			paymentQuarter: '',
			packagingError: false,
			amountOptions: [],
		};
		this.saveData = this.saveData.bind(this);
	}

	setFieldError = field => {
		this.setState({
			dataerrors: {
				[field]: true
			}
		});
	};

	saveData = e => {
		const { orderId, canEditCarrierPrice } = this.props;
		const {
			toSaveData,
			data,
			finalPrice,
			orderStatusForSave,
			prognosedDistanceAndTime,
			ordererPriceID,
			ordererPriceAmount,
			carrierPriceID,
			carrierPriceAmount,
			deliveryIncludedInPrice,
			department,
			showNotesInBill,
			manipulator,
			paymentQuarter,
			planningOrderId,
			packagingError,
			packagingWeightID,
		} = this.state;
		const isNewOrder = orderId == null ? true : false;
		//{"driver":{"mappostUserID":11156,"carrierDriverID":525,"driverName":"AGRIS BRIŅĶIS","driverId":282,"vilcejs":"JG 2969","client":"ZIEDIŅI Z.S., RAMANIS ANDRIS","carrierId":435},"piekabe":"dasdas","uzkrauts":"20190402","izkrauts":"20190326","amountToDeliver":"28.88sad","deliveredInReality":"28.88asd","routeLength":"asd","travelTime":"asd","dikstave_h":"0asd","overworkHours":"0asd","overworkPrice":"0as","dikstavePrice":"0asd","ordererPrice":"9.5asd","price":"9.5asd","crm":"AKTS NR.P15/70069asd","deliveryNotes":"21.08.15asd","[object Object]":false}
		//vēl jābūt obligātajiem laukiem

		if (e) {
			e.preventDefault();
		}

		this.setState({ dataerrors: {} });

		if (packagingError) {
			toast.warn('Norādiet pasūtījuma daudzumu!', { autoClose: 7500 });
			return;
		}

		if (planningOrderId && toSaveData.amount > 0 && packagingWeightID) {
			if (!this.checkPackaging()) {
				toast.warn('Ir problēmas ar iepakojuma apjoma pārbaudi!', { autoClose: 7500 });
				return;
			}
		}

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Nav ko saglabāt');
			return;
		}

		let reallyChanged = {};

		if (isNewOrder == false) {
			for (let key in toSaveData) {
				if (toSaveData[key] !== data[key]) {
					reallyChanged[key] = toSaveData[key];
				}
			}

			if (Object.keys(reallyChanged).length === 0) {
				toast.info('Dati netika izmainīti');
				return;
			}
		} else {
			reallyChanged = toSaveData; //{};
		}

		const defaultFields = {
			orderStatusForSave,
			ordererPriceAmount,
			ordererPriceID,
			carrierPriceAmount,
			carrierPriceID,
			department,
			showNotesInBill,
			manipulator,
			paymentQuarter,
		};

		const dataToSendAsDefaults = [
			'storage',
			'destination',
			'material',
			'payer',
			'deliverTill',
			'deliveryIncludedInPrice',
			'title',
			'salesPrice',
			'amount',
			'measurementID',
			'showNotesInBill',
			'notes',
			'plannedDelivery',
			'priority',
			'responsibility',
			'department',
			'orderStatusForSave',
			'ordererPriceID',
			'ordererPriceAmount',
			'carrierPricePriceID',
			'carrierPriceAmount',
			'carrierPriceApprove',
			'icClientDeliveryAddress',
			'deliverTillMandantory',
			'planningOrderId',
			'manipulator',
			'paymentQuarter',
		];
		//orderStatusForSave - šo vajadzētu pārsaukt pirms sūtīšanas, bet pašlaik vienalga
		//'storageID','ordererAddressID', - šie ir no asynctypeahead, kur ir cita struktūra

		let try2Final = {};

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else if (theKey in data) {
				try2Final[theKey] = data[theKey];
			}
		}

		const delivIncl =
			typeof deliveryIncludedInPrice == 'undefined' ? false : deliveryIncludedInPrice;

		const { clientRegNr } = this.props;
		const payer =
			delivIncl == true && typeof clientRegNr !== 'undefined' && clientRegNr !== null
				? { regNr: clientRegNr }
				: try2Final.payer; //regNr - no this.props

		const priorityFinal = 'priority' in try2Final ? try2Final.priority : this.state.priority;
		const responsibilityFinal = 'responsibility' in try2Final ? try2Final.responsibility : this.state.responsibility;

		const plannedDelivery =
			'plannedDelivery' in try2Final ? try2Final.plannedDelivery : this.state.plannedDelivery;

		const notes = 'notes' in try2Final ? try2Final.notes : this.state.notes;
		const salesPrice = 'salesPrice' in try2Final ? try2Final.salesPrice : this.state.salesPrice;

		const finalToSend = Object.assign(
			{},
			try2Final,
			defaultFields,
			{ notes },
			{ prognosedDistanceAndTime },
			{ priority: priorityFinal },
			{ responsibility: responsibilityFinal },
			{ finalPrice },
			{ salesPrice },
			{ plannedDelivery },
			{ payer },
			{ deliveryIncludedInPrice: delivIncl },
			{ deliverTill: formatDateForDB(try2Final.deliverTill) },
			{
				deliverTillMandantory:
					'deliverTillMandantory' in try2Final ? try2Final.deliverTillMandantory : false
			}
		);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			storage: 'Noliktava',
			destination: 'Piegādes adrese',
			material: 'Materiāls',
			payer: 'Maksātājs',
			deliveryIncludedInPrice: 'Piegāde iekļauta cenā',
			amount: 'Daudzums',
			measurementID: 'Mērvienība',
			deliverTill: 'Piegādāt līdz',
			notes: 'Piezīmes',
			plannedDelivery: 'Izpilde',
			priority: 'Prioritāte',
			responsibility: 'Atbildīgais par piegādi',
			department: 'Nodaļa',
			orderStatusForSave: 'Statuss',
			ordererPriceAmount: 'Klienta rēķina cena',
			ordererPriceID: 'Klienta rēķina cenas mērvienība',
			carrierPriceAmount: 'Pārvadātāja cena',
			carrierPriceID: 'Pārvadātāja cenas mērvienība',
			showNotesInBill: 'Rādīt piezīmes rēķinā',
			manipulator: 'Manipulators',
			paymentQuarter: 'Apmaksa',
		};

		const fieldsToCheck = [
			'storage',
			'destination',
			'material',
			'payer',
			'deliverTill',
			'deliveryIncludedInPrice',
			'amount',
			'measurementID',
			'plannedDelivery',
			'priority',
			'responsibility',
			'department',
			'orderStatusForSave',
			'ordererPriceAmount',
			'ordererPriceID',
		];
		if (canEditCarrierPrice) {
			fieldsToCheck.push('carrierPriceID');
			fieldsToCheck.push('carrierPriceAmount');
		}
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			fieldsToCheck,
			finalToSend,
			nameMap
		); //,"notes" -> šis ģenerē erroru, lai arī piezīmes ir tukšs lauks -> tas ir ok

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			this.setFieldError(confirmAllFieldsHasValues.field);
			return;
		}

		if (finalToSend.planningOrderId && finalToSend.amount <= 0) {
			toast.warn(`Daudzumam jābūt lielākam par 0.000`, {
				autoClose: 7500
			});
			this.setFieldError('amount');
			return;
		}

		if (finalToSend.ordererPriceAmount < 0.85) {
			toast.warn(`Klienta rēķina cenai jābūt lielākai par 0,85`, {
				autoClose: 7500
			});
			this.setFieldError('ordererPriceAmount');
			return;
		}

		if (finalToSend.payer.regNr == '' || finalToSend.payer.regNr == null) {
			toast.warn(`Nav aizpildīts maksātāja lauks`, {
				autoClose: 7500
			});
			this.setFieldError('payer');
			return;
		}

		if (finalToSend.deliverTill == '' || finalToSend.deliverTill == null) {
			toast.warn(`Nav aizpildīts "Piegādāt līdz"`, {
				autoClose: 7500
			});
			this.setFieldError('deliverTill');
			return;
		}

		if (finalToSend.department === '10' && (!finalToSend.hasOwnProperty('paymentQuarter') || finalToSend.paymentQuarter === null || finalToSend.paymentQuarter === 'Nav')) {
			toast.warn('Apmaksas lauks ir obligāts, ja izvēlētā nodaļa, kas veic pasūtījumu ir AGRO n.', {
				autoClose: 7500
			});
			this.setFieldError('paymentQuarter');
			return;
		}

		this.setState({ sendingStatus: true });
		let promise = null;

		if (orderId == null) {
			promise = api.orders.saveNewOrder(finalToSend);
		} else {
			promise = api.orders.updateOrder({ order: orderId, data: finalToSend });
		}

		promise
			.then(response => {
				this.setState({ sendingStatus: false });

				if (response.status == 'error') {
					toast.warn(response.message, { autoClose: 10000 });
				} else if (['ok', 'success'].includes(response.status)) {
					toast.success('Dati saglabāti');
					setRespectTabs();
					setTimeout(() => this.props.handleClose(), MILISECONDS_BEFORE_CLOSE);
					if (this.props.orderIdCopy) {
						setTimeout(() => this.props.handleCloseMainModal(), MILISECONDS_BEFORE_CLOSE);
					}
				} else {
					toast.warn('Ir problēmas!', { autoClose: 7000 });
				}
			})
			.catch(error => this.setState({ sendingStatus: false }));
	};

	toastButtonStyle = (backgroundColor) => ({
		backgroundColor,
		color: 'white',
		margin: '10px 20px',
		padding: '6px 12px',
		borderRadius: 3,
		border: '1px solid #ccc'
	});

	checkRemainder = () => {
		const { amount, remainder } = this.state;
		toast.dismiss();

		if (amount && parseFloat(amount) > parseFloat(remainder)) {
			this.setState({ remainderToastActive: true });
			toast(
				<div style={{ color: 'black', textAlign: 'center', marginTop: 0 }}>
					<br />
					Pieejamais daudzums pārsniedz pieprasīto!
					<br />
					Vai vēlaties nepieejamo apjomu ievietot rezervācijā?
					<br />
					<button style={this.toastButtonStyle('#5cb85c')} onClick={() => this.handleOrderSave(true)}>Jā</button>
					<button style={this.toastButtonStyle('#f0ad4e')} onClick={() => this.handleOrderSave(false)}>Nē</button>
				</div>,
				{
					position: toast.POSITION.TOP_CENTER,
					autoClose: false,
					closeOnClick: false,
					onClose: () => this.setState({ remainderToastActive: false }),
					draggable: false,
				});
			return;
		}

		this.saveData();
	};

	checkPackaging = () => {
		const { amount, packagingWeightID } = this.state;

		const packagingWeight = packagingWeightOptions.find(
			weight => weight.id == packagingWeightID
		);

		if (!amount || !packagingWeight) {
			return false;
		}

		const amountInKg = amount * 1000;
		const packagingCount = amountInKg / packagingWeight.value;

		if (packagingCount % 1 !== 0) {
			this.setState({ packagingCount, packagingWeight, packagingError: true });

			const amounts = [];

			const minPackagingCount = Math.floor(packagingCount);
			if (minPackagingCount > 0) {
				const minAmount = minPackagingCount * packagingWeight.value / 1000;
				const minAmountLabel = minAmount.toFixed(3) + `t (${minPackagingCount} x ${packagingWeight.value}kg)`;
				amounts.push({ value: minAmount, label: minAmountLabel });
			}

			const maxPackagingCount = Math.ceil(packagingCount);
			const maxAmount = maxPackagingCount * packagingWeight.value / 1000;
			const maxAmountLabel = maxAmount.toFixed(3) + `t (${maxPackagingCount} x ${packagingWeight.value}kg)`;
			amounts.push({ value: maxAmount, label: maxAmountLabel });

			this.setState({ amountOptions: amounts });

			toast.warn(`Norādiet pasūtījuma daudzumu! Iepakojumu skaits: ${packagingCount.toFixed(3)}`,
				{ autoClose: 7500 }
			);

			return false;
		}

		this.setState({ packagingError: false });

		return true;
	};

	handleAmountSelect = (selectedAmount) => {
		if (selectedAmount) {
			this.setState({ packagingError: false });
			this.setDataToState('amount', selectedAmount);
		}
	}

	handleOrderSave = (reservationOrder) => {
		toast.dismiss();

		if (reservationOrder) {
			this.setState({ remainderToastActive: false });
			this.saveData();
			return;
		}

		this.setState(prevState => ({
			remainderToastActive: false,
			amount: prevState.remainder,
			toSaveData: { ...prevState.toSaveData, amount: prevState.remainder }
		}), () => {
			this.saveData();
		});
	};

	setData = () => {
		const { orderId, clientRegNr, orderIdCopy, planningOrderId } = this.props;
		let clientRegNr_FINAL = null;
		if (clientRegNr) {
			clientRegNr_FINAL = clientRegNr;
		}

		this.setState({ loading: true });

		if (orderId == null) {
			if (orderIdCopy) {
				// order copy
				return api.orders.copy(orderIdCopy).then(response => {
					this.setDataToInitState(response);
				});
			}
			// new order
			return api.orders.new({ clientRegNr: clientRegNr_FINAL, planningOrderId: planningOrderId }).then(response => {
				this.setDataToInitState(response);
			});
		}

		// existing order
		return api.orders.info(orderId).then(response => {
			this.setDataToInitState(response);
		});
	};

	getHistoricPrices = () => {
		const { storage, material, destination } = this.state;
		const { historicPricesLoaded } = this.state;
		if (historicPricesLoaded) {
			return;
		}

		//http://latraps.kartes.lv/api/v1/order/historic_orderer_price?storage=1&target=880&material=101
		if (
			storage &&
			'objectId' in storage &&
			material &&
			'id' in material &&
			destination &&
			'id' in destination
		) {
			this.setState({ historicPricesLoaded: true });

			//api.orders.historicPrices({storage:1,target:880,material:101})
			api.orders
				.historicPrices({
					storage: storage.objectId,
					target: destination.id,
					material: material.id
				})
				.then(res => {
					this.setState({ historicPrices: res });
				});
		}
	};

	getTransportPrice = () => {
		if (this.props.ignoreMaterialTransportationPrice) {
			return Promise.resolve(0);
		}
		//fixme: nepieprasīt visu laiku
		const { material } = this.state;

		if (!material || (material && 'id' in material === false)) {
			return Promise.resolve(0);
		}

		return api.orders
			.materialTransportPrice(material.id)
			.then(res => {
				const {value, volume} = res;
				if (!volume || !value) {
					return;
				}
				this.setState({ materialTransportPrice: res });
			});
	};

	getUserFavorites = () => {
		return api.favorites.userFavorites()
			.then(response => {
				this.setState({ userFavorites: response })
			})
	}

	setDataToInitState = response => {
		const { orderId, orderIdCopy, planningOrderRemainder } = this.props;
		if (planningOrderRemainder) {
			this.setState({ remainder: planningOrderRemainder });
		}

		const { optionData, orderData } = response;
		this.setState({ optionData, orderData });

		const currYear = new Date().getFullYear();
		const currYearStr = currYear.toString();

		const moreAttrs = getDataFromAPIResponse(orderData, [
			'storageID',
			'ordererPriceApprove',
			'ordererAddressID',
			'deliveryIncludedInPrice',
			'material',
			'title',
			'payer',
			'amount',
			'deliverTill',
			'measurementID',
			'ordererPrice',
			'carrierPrice',
			'showNotesInBill',
			'notes',
			'plannedDelivery',
			'priority',
			'responsibility',
			'department',
			'orderStatus',
			'destination',
			'storage',
			'deliverTillMandantory',
			'planningOrderId',
			'planningOrderType',
			'salesPrice',
			'planningOrderSalesPrice',
			'manipulator',
			'paymentQuarter',
			'packagingWeightID',
		]);

		//moreAttrs.orderStatus - jauniem pasūtījumiem ir null

		const orderSt = getFromData(['orderStatus', 'id'], moreAttrs, 0, false);
		const ordererPriceAmount_tmp = getFromData(['ordererPrice', 'amount'], moreAttrs, null, true);
		const ordererPriceID_tmp = getFromData(['ordererPrice', 'id'], moreAttrs, null, true);

		const carrierPriceAmount_tmp = getFromData(['carrierPrice', 'amount'], moreAttrs, null, true);
		const carrierPriceID_tmp = getFromData(['carrierPrice', 'id'], moreAttrs, null, true);

		const plannedDeliveryDef = getFromData(['plannedDelivery', null], moreAttrs, new Date().getFullYear().toString(), false);

		const isNewOrder = orderId == null ? true : false;

		const attrTransformation = {
			ordererPriceApprove: moreAttrs.ordererPriceApprove ?? 0,
			deliverTill: moreAttrs.deliverTill == null ? '' : moreAttrs.deliverTill,
			amount: formatNumber(moreAttrs.amount, 3),
			salesPrice: isNewOrder ? moreAttrs.planningOrderSalesPrice : moreAttrs.salesPrice,
			orderStatusForSave: orderSt,
			ordererPriceID: ordererPriceID_tmp,
			ordererPriceAmount: formatNumber(ordererPriceAmount_tmp, 3),
			carrierPriceID: carrierPriceID_tmp,
			carrierPriceAmount: formatNumber(carrierPriceAmount_tmp, 3),
			deliveryIncludedInPrice: moreAttrs.deliveryIncludedInPrice,
			department: moreAttrs.department,
			plannedDelivery: plannedDeliveryDef,
			notes: moreAttrs.notes == null ? '' : moreAttrs.notes,
			showNotesInBill: orderIdCopy ? false : moreAttrs.showNotesInBill,
			manipulator: moreAttrs.manipulator,
			paymentQuarter: moreAttrs.paymentQuarter ?? null,
			//payer: payerTMP
		};

		const newState = Object.assign(
			{},
			moreAttrs,
			{ loading: false, error: false, optionData, data: orderData },
			attrTransformation
		);

		console.log(newState);

		this.setState(newState, () => {
			this.getPrognosedDistance();
			this.getTransportPrice();
			// this.handleMinimalPriceInput();
			this.setFinalPrice();
			this.getUserFavorites();
		});
	};

	componentDidMount() {
		this.setData();
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (
			inputValue.length > 0 &&
			(name == 'amount' || name == 'ordererPriceAmount' || name == 'carrierPriceAmount' || name == 'salesPrice') &&
			this.isnumeric(inputValue) == false
		) {
			return false;
		}

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });
		this.setState({ toSaveData, [name]: inputValue });

		// LATR-92:
		// 'Atbildīgais par piegādi' === 'Klients' => 'Piegāde iekļauta produkta cenā' === true
		if (name === 'responsibility' && inputValue === '2' && this.state.planningOrderId) {
			this.setDataToState('deliveryIncludedInPrice', true);
		}

		this.getHistoricPrices();
		this.getTransportPrice();
		this.setFinalPrice();

		if (['amount'].includes(name)) {
			console.log(name, inputValue);
			// this.handleMinimalPriceInput(inputValue);
		}
	};

	handleSwitch(elem, state, name) {
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	mainStyle = {
		padding: '3px',
		borderLeft: '3px solid #ff9933',
		marginTop: '3px',
		fontSize: '75%'
	};
	subStyle = {
		padding: '3px',
		borderLeft: '3px solid #00CC66',
		marginTop: '3px',
		fontSize: '75%'
	};

	addrFormat(option, idx, field) {
		const mainTitle =
			field == 'storage' ? (
				<span>
					{option.title} - {option.nosaukums}
				</span>
			) : (
				<span>{option.name}</span>
			);

		return (
			<div>
				<span style={this.mainStyle} key={idx}>
					{mainTitle}
				</span>
				<br />
				{option.clients.map((cl, currKey) => (
					<span style={this.subStyle} key={currKey}>
						&nbsp;&nbsp;&nbsp;&nbsp;{cl}
					</span>
				))}
			</div>
		);
	}

	componentDidUpdate = (oldProps, oldState) => {
		if (oldState.amount != this.state.amount || oldState.carrierPriceAmount != this.state.carrierPriceAmount || oldState.materialTransportPrice != this.state.materialTransportPrice || oldState.prognosedDistanceAndTime.len != this.state.prognosedDistanceAndTime.len) {
			/* console.log('componentDidUpdate',
				this.state.amount,
				this.state.materialTransportPrice,
				this.state.carrierPriceAmount,
				this.state.prognosedDistanceAndTime, oldState) */
			this.handleMinimalPriceInput();
		}
	}

	setDataToState(key, value) {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value }, () => {
			if (['ordererPriceAmount', 'carrierPriceAmount'].includes(key) && value === '') {
				//deleting amount input
				return;
			}
			// this.handleMinimalPriceInput();
		});
	}

	setFinalPrice = () => {
		this.getTransportPrice()
		.then(() => this.getPrognosedDistance())
		.then(() => {

			console.log('state before set final price2', this.state);
			const val = calculateFinalPrice(this.state);
			this.setState({
				finalPrice: val
			});
		})
	}

	onTypeAheadSelect = (result, whatWasSearched) => {
		if (result != '') {
			this.setDataToState([whatWasSearched], result);
			this.getHistoricPrices();
			this.getPrognosedDistance();
			this.getTransportPrice();
			this.setFinalPrice();
			if (['material'].includes(whatWasSearched)) {
				// this.handleMinimalPriceInput();
			}
		}
	};

	handleMinimalPriceInput = (defaultAmount = null) => {
		const {
			amount,
			materialTransportPrice,
			ordererPriceAmount,
			prognosedDistanceAndTime,
			material
		} = this.state;

		console.log(this.state);

		const finalAmount = defaultAmount ? defaultAmount : amount;

		if (ordererPriceAmount || !finalAmount) {
			return;
		}

		const expectedDistance = prognosedDistanceAndTime && prognosedDistanceAndTime.len;

		if (!expectedDistance) {
			return;
		}

		if (
			ordererPriceAmount ||
			!materialTransportPrice ||
			typeof materialTransportPrice.volume === 'undefined'
		) {
			return;
		}

		const finalPrice = calculatePrice({ distance: expectedDistance, amount: finalAmount, minAmount: materialTransportPrice.volume, price: materialTransportPrice.value, materialCategory: material.category })

		// console.log('klienta rekina cena', {distance: expectedDistance, amount: finalAmount, minAmount: materialTransportPrice.volume, price: materialTransportPrice.value}, finalPrice)

		this.setPrice(formatNumber(finalPrice, 3), 2);

		//if (parseInt(finalAmount) >= vol) {
		//	this.setPrice(vol, 2); //2 = tonnas
		//}
	};

	formatAsString = event => {
		const { value: number, name } = event.target;

		this.setDataToState(name, formatNumber(number, 3));
	};

	_handleSearchMeta(field, query, searchCB) {
		this.setState({ isLoading: true });

		query = encodeURIComponent(query);

		let searcbParams = null;

		const { clientRegNr } = this.props;

		let key = null;

		if (field == 'storage') {
			key = 'storage';
			const { clientSearch_storage } = this.state;
			searcbParams = {
				clientId: clientRegNr,
				totaly: query,
				clientSearch: clientSearch_storage
			};
		} else if (field == 'destination') {
			key = 'destination';
			const { clientSearch_destination } = this.state;
			searcbParams = { query, clientSearch: clientSearch_destination };
		} else if (field == 'payer') {
			key = 'payer';
			searcbParams = query;
		} else if (field == 'material') {
			key = 'material';
			searcbParams = query;
		}
		//tikai noliktavai jāpadod clientID, pārējiem nav
		searchCB(searcbParams) //todo: clientid jāpadod no props
			.then(response => {
				this.setState({
					options: {
						...this.state.options,
						[key]: response
					},
					isLoading: false
				});
			});
	}

	handleDateChange = value => {
		if (typeof value == 'string' && value != '') {
			this.setState({ wrongData: true });
		} else {
			const valueToSave = value == '' ? value : value.format('DD.MM.YYYY');
			this.setDataToState('deliverTill', valueToSave);
			this.setState({ wrongData: false });
		}
	};

	handleFavorite(type, id) {
		// todo: loading spineris
		this.setState({ favIsLoading: true });
		api.favorites.add({ type, id })
			.then(() => {
				this.getUserFavorites();
				this.setState({ favIsLoading: false })
			});
	}

	isnumeric(value) {
		return isNumericWrap(value);
	}

	getDefaultSelected(value) {
		if (value == '' || typeof value == 'undefined') {
			return [];
		}
		return [value];
	}

	setPrice = (value, priceFor) => {
		this.setDataToState('ordererPriceAmount', value);
		this.setDataToState('ordererPriceID', priceFor);
	};

	getPrognosedDistance = () => {
		const { storage, destination } = this.state;
		const { prognosedDistanceAndTime } = this.state;

		//http://latraps.kartes.lv/api/v1/order/historic_orderer_price?storage=1&target=880&material=101
		if (
			storage &&
			'objectId' in storage &&
			destination &&
			'mappostID' in destination
		) {

			if ('objFrom' in prognosedDistanceAndTime && prognosedDistanceAndTime.objFrom == storage.objectId && 'objTo' in prognosedDistanceAndTime && prognosedDistanceAndTime.objTo == destination.mappostID) {
				return Promise.resolve(0);
			}

			return api.orders
				.transportationPrices({
					objFrom: storage.objectId,
					objTo: destination.mappostID
				})
				.then(res => {
					const { distance, duration, route } = res;

					if (distance && duration && route) {
						//vai nav errora?
						this.setState({
							prognosedDistanceAndTime: {
								len: distance,
								time: duration,
								coords: route,
								objFrom: storage.objectId,
								objTo: destination.mappostID
							}
						});
					}
				});
		}

		return Promise.resolve(0);
	}

	requiredField = (field, type = 'async') => {
		if (field == undefined || field == null || field == '') {
			const returnStyle =
				type === 'input' ?
				{ border: '1px solid #f10303' } :
				{ style: { border: '1px solid #f10303' } }
			return returnStyle
		}
		return {}
	}

	getMeasurementIdOptions = () => {
		const { optionData, material } = this.state;

		if (!optionData || !optionData.measurement) {
			return [];
		}

		if (!material || material.category !== 'AAL') {
			return optionData.measurement;
		}

		// for AAL group we enable only these measurements: Fpl, Epl un gab.

		return optionData.measurement.filter(measr => ['3', '4', '5'].includes(measr.id.toString()));
	}

	getPaymentQuarterOptions = () => {
		return [
			{ label: 'Pavasaris', value: 'spring' },
			{ label: 'Rudens', value: 'fall' },
			{ label: 'Nav', value: null }
		];
	}

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		const {
			destination,
			material,
			payer,
			amount,
			historicPrices,
			department,
			notes,
			ordererPriceID,
			carrierPriceID,
			deliveryIncludedInPrice: delivIncl1,
			ordererPriceAmount,
			carrierPriceAmount,
			storage,
			measurementID,
			price,
			salesPrice,
			wrongData,
			priceType,
			plannedDelivery,
			priority,
			responsibility,
			dataerrors,
			deliverTillMandantory,
			deliveryIncluded,
			ordererPriceApprove,
			orderStatus,
			finalPrice,
			showNotesInBill,
			deliverTill,
			orderStatusForSave,
			loading,
			icClientDeliveryAddress,
			clientSearch_destination,
			clientSearch_storage,
			sendingStatus,
			prognosedDistanceAndTime,
			materialTransportPrice,
			userFavorites,
			showModal,
			favIsLoading,
			planningOrderId,
			planningOrderType,
			remainder,
			remainderToastActive,
			manipulator,
			paymentQuarter,
			packagingError,
			amountOptions,
		} = this.state;
		const { optionData, orderData } = this.state;

		const { canEditCarrierPrice } = this.props;
		const measurementIDOptions = this.getMeasurementIdOptions();

		const currYear = new Date().getFullYear();
		const currYearStr = currYear.toString();
		const nextYear = (currYear + 1).toString();

		const isNewOrder = this.props.orderId == null ? true : false;

		const { id, title } =
			orderData && typeof orderData.orderStatus !== 'undefined'
				? orderData.orderStatus
				: { id: '', title: '' };

		const orderStatusOptions = [
			{ label: title, value: id },
			{ label: 'izņemt no izpildes', value: '1' }
		];

		const deliveryIncludedInPrice = delivIncl1 == true ? true : false;

		const {
			showField: showPayer,
			ordererAsLabel: shouldShowOrderer
		} = handlePayerOrdererLabel(deliveryIncludedInPrice, this.props.clientRegNr);

		const destinationFav = userFavorites.destination.includes(destination?.id) ? 'gold' : '#ECEBEB';
		const storageFav = userFavorites.storage.includes(storage?.id) ? 'gold' : '#ECEBEB';

		if (loading) {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				{showPayer && (
					<div className='row'>
						<Label
							labelClass='col-sm-4'
							title={shouldShowOrderer ? 'Pasūtītājs' : 'Maksātājs'}
							name='payer'
						/>
						<div className='col-sm-8'>
							<AsyncTypeahead
								isLoading={this.state.isLoading}
								options={this.state.options.payer}
								onChange={selected =>
									this.onTypeAheadSelect(selected[0], 'payer')
								}
								filterBy={(option, props) => {
									return option;
								}}
								selected={this.getDefaultSelected(payer)}
								minLength={1}
								labelKey='title'
								id='payerSearch'
								clearButton={true}
								onSearch={query =>
									this._handleSearchMeta('payer', query, api.search.payer)
								}
								renderMenuItemChildren={(option, idx) => (
									<span style={{ fontSize: '75%' }} key={idx}>
										{option.title}
									</span>
								)}
								inputProps={this.requiredField(payer)}
							/>
						</div>
					</div>
				)}

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={
							<span>
								Noliktava{' '}
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-right'>Maršruts no</Tooltip>
									}>
									<span
										className='glyphicon glyphicon-question-sign'
										aria-hidden='true'></span>
								</OverlayTrigger>
							</span>
						}
						name='storage'
					/>
					<div className='col-sm-7'>
						<AsyncTypeahead
							isLoading={this.state.isLoading}
							options={this.state.options.storage}
							onChange={selected =>
								this.onTypeAheadSelect(selected[0], 'storage')
							}
							filterBy={(option, props) => {
								return option;
							}}
							minLength={2}
							selected={this.getDefaultSelected(storage)}
							labelKey='title'
							id='storageSearch'
							clearButton={true}
							onSearch={query =>
								this._handleSearchMeta('storage', query, api.search.storage)
							}
							renderMenuItemChildren={(option, idx) =>
								this.addrFormat(option, idx, 'storage')
							}
							inputProps={this.requiredField(storage)}
						/>
					</div>
					{storage &&
						// (favIsLoading ? (<span>
						// 	<i className='fa fa-spinner fa-pulse fa-fw'></i>
						// </span>) : (
						<span onClick={() => this.handleFavorite('storage', storage.id)} style={{ cursor: 'pointer' }}
							title={storageFav === 'gold' ? 'Pievienots favorītiem' : 'Nav pievienots favorītiem'}>
							<i className='fas fa-star'
								style={{ color: storageFav, fontSize: 22, paddingTop: 6, marginLeft: -10 }}></i>
						</span>
					// ))
					}
				</div>
				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={
							<span>
								Piegādes adrese{' '}
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-right'>Maršruts uz</Tooltip>
									}>
									<span
										className='glyphicon glyphicon-question-sign'
										aria-hidden='true'></span>
								</OverlayTrigger>
							</span>
						}
						name='destination'
					/>
					<div className='col-sm-7'>
						<AsyncTypeahead
							isLoading={this.state.isLoading}
							options={this.state.options.destination}
							onChange={selected =>
								this.onTypeAheadSelect(selected[0], 'destination')
							}
							minLength={2}
							selected={this.getDefaultSelected(destination)}
							labelKey='name'
							filterBy={(option, props) => {
								return option;
							}}
							id='destinationSearch'
							clearButton={true}
							onSearch={query =>
								this._handleSearchMeta(
									'destination',
									query,
									api.search.address
								)
							}
							renderMenuItemChildren={(option, idx) =>
								this.addrFormat(option, idx, 'destination')
							}
							inputProps={this.requiredField(destination)}
						/>
					</div>
					{destination &&
						<span onClick={() => this.handleFavorite('destination', destination.id)} style={{ cursor: 'pointer' }}
							title={destinationFav === 'gold' ? 'Pievienots favorītiem' : 'Nav pievienots favorītiem'}>
							<i className='fas fa-star'
								style={{ color: destinationFav, fontSize: 22, paddingTop: 6, marginLeft: -10 }}></i>
						</span>
					}
				</div>
				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Atzīmēt šo kā klienta piegādes adresi'
						name='icClientDeliveryAddress'
					/>
					<div className='col-sm-8'>
						<Switch
							name='icClientDeliveryAddress'
							offColor='warning'
							onColor='success'
							onText='Jā'
							offText='Nē'
							defaultValue={
								icClientDeliveryAddress ? icClientDeliveryAddress : false
							}
							onChange={(el, state) =>
								this.handleSwitch(el, state, 'icClientDeliveryAddress')
							}
						/>
					</div>
				</div>
				<div className='row'>
					<Label labelClass='col-sm-4' title='Materiāls' name='material' />
					<div className='col-sm-8'>
						<AsyncTypeahead
							isLoading={this.state.isLoading}
							options={this.state.options.material}
							onChange={selected =>
								this.onTypeAheadSelect(selected[0], 'material')
							}
							selected={this.getDefaultSelected(material)}
							minLength={2}
							labelKey='title'
							id='materialSearch'
							clearButton={true}
							filterBy={(option, props) => {
								return option;
							}}
							onSearch={query =>
								this._handleSearchMeta(
									'material',
									query,
									api.search.material
								)
							}
							renderMenuItemChildren={(option, idx) => (
								<span style={{ fontSize: '75%' }} key={idx}>
									{option.title}
								</span>
							)}
							inputProps={this.requiredField(material)}
						/>
					</div>
				</div>

				<div className='row'>
					<Label labelClass='col-sm-4' title='Atbildīgais par piegādi' name='responsibility' />
					<div className='col-md-8'>
						<Radio
							name='responsibility'
							value='1'
							checkAgainst={responsibility}
							handleChange={this.handleChange}
							label='LATRAPS'
						/>
						<Radio
							name='responsibility'
							value='2'
							checkAgainst={responsibility}
							handleChange={this.handleChange}
							label='Klients'
						/>
					</div>
				</div>

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Piegāde iekļauta produkta cenā'
						name='deliveryIncludedInPrice'
					/>
					<div className='col-sm-8'>
						<Switch
							name='deliveryIncludedInPrice'
							offColor='warning'
							onColor='success'
							onText='Jā'
							offText='Nē'
							value={deliveryIncludedInPrice == true ? true : false}
							onChange={(el, state) =>
								this.handleSwitch(el, state, 'deliveryIncludedInPrice')
							}
						/>
					</div>
				</div>

				{(planningOrderId && planningOrderType === '1') &&
					<div className='row'>
						<Label
							labelClass='col-sm-4'
							title='Pārdošanas cena'
							name='salesPrice'
						/>
						<Input
							inputClass='col-sm-8'
							name='salesPrice'
							isError={dataerrors.salesPrice}
							blur={this.formatAsString}
							value={salesPrice}
							handleChange={this.handleChange}
						/>
					</div>
				}

				{packagingError &&
					<div className='row' style={{ marginLeft: 17, marginTop: 10 }}>
						<Label
							labelClass='col-sm-4'
							title=''
							name='packagingError'
						/>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<span>
								Aprēķinātais iepakojumu skaits nav vesels skaitlis = <span style={{ color: 'red' }}>{this.state.packagingCount.toFixed(3)}</span>
							</span>
							<small> (Plānošanas pasūtījumā norādītais iepakojums - <strong>{this.state.packagingWeight.title}/maisi)</strong></small>
						</div>
					</div>
				}

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={planningOrderId && remainder ?
							<>Daudzums <small style={{ fontWeight: 'normal' }}>(atlikums {remainder})</small></> :
							'Daudzums'
						}
						name='amount'
					/>

					{!packagingError &&
						<Input
							inputClass='col-sm-3'
							blur={this.formatAsString}
							name='amount'
							isError={dataerrors.amount}
							value={amount}
							handleChange={this.handleChange}
							inputStyle={this.requiredField(amount, 'input')}
						/>
					}

					{packagingError &&
						<div className='col-sm-8' style={{ marginTop: 5, marginBottom: 5 }}>
							{amountOptions.map(option => (
								<><button
									key={option.value}
									type='button'
									disabled={sendingStatus || remainderToastActive}
									className='btn btn-primary'
									onClick={() => this.handleAmountSelect(option.value)}>
									{option.label}
								</button>&nbsp;&nbsp;</>
							))}
						</div>
					}

					{!packagingError &&
						<Select
							selectClass='col-sm-5'
							handleChange={this.handleChange}
							name='measurementID'
							isError={dataerrors.measurementID}
							value={measurementID}
							placeholder='Izvēlies mērvienību'
							disabled={planningOrderId}
							options={measurementIDOptions.map(meas => ({
								label: meas.title,
								value: meas.id
							}))}
						/>
					}

				</div>
				<InfoRow prognosedDistanceAndTime={prognosedDistanceAndTime} material={material} amount={amount} finalPrice={finalPrice} materialTransportPrice={materialTransportPrice} carrierPriceAmount={carrierPriceAmount} handleToggleModal={this.handleToggleModal} />
				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={
							<span>
								Klienta rēķina cena{' '}
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-right'>
											Šeit jāievada ar klientu atrunātā cena par
											piegādi
										</Tooltip>
									}>
									<span
										className='glyphicon glyphicon-question-sign'
										aria-hidden='true'></span>
								</OverlayTrigger>
							</span>
						}
						name='ordererPriceAmount'
					/>
					<Input
						inputClass='col-sm-3'
						blur={this.formatAsString}
						name='ordererPriceAmount'
						isError={dataerrors.ordererPriceAmount}
						value={ordererPriceAmount}
						handleChange={this.handleChange}
						inputStyle={this.requiredField(ordererPriceAmount, 'input')}
					/>
					<Select
						selectClass='col-sm-5'
						handleChange={this.handleChange}
						name='ordererPriceID'
						value={ordererPriceID}
						placeholder='Izvēlies mērvienību'
						options={
							optionData
								? optionData.price.map(price => ({
									label: price.title,
									value: price.id
								}))
								: []
						}
					/>
				</div>
				{this.props.canApproveOrdererPrice && <div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Klienta rēķina cena ir apstiprināta'
						name='ordererPriceApprove'
					/>
					<div className='col-sm-8'>
						<Switch
							name='ordererPriceApprove'
							offColor='warning'
							onColor='success'
							onText='Jā'
							offText='Nē'
							defaultValue={ordererPriceApprove == true ? true : false}
							onChange={(el, state) =>
								this.handleSwitch(el, state, 'ordererPriceApprove')
							}
						/>
					</div>
				</div>}

				{historicPrices.length > 0 && (
					<div className='row'>
						<Label
							labelClass='col-sm-4'
							title={
								<span style={{ fontSize: 'small', fontStyle: 'italic' }}>
									Trīs pēdējās vērtības
								</span>
							}
							name='ordererPriceAmount'
						/>
						<div className='col-sm-8'>
							<div className={cssStyles.ordererPriceContainer}>
								{historicPrices.map(item => (
									<span
										key={`${item.value}-${item.price_id}`}
										className={cssStyles.ordererPriceHistoryItem}
										onClick={() =>
											this.setPrice(item.value, item.price_id)
										}>{`${item.value} ${item.price_title} (${item.order_save})`}</span>
								))}
							</div>
						</div>
					</div>
				)}
				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title={
							<span>Pārvadātāja cena</span>
						}
						name='carrierPriceAmount'
					/>
					<Input
						inputClass='col-sm-3'
						disabled={!canEditCarrierPrice}
						blur={this.formatAsString}
						name='carrierPriceAmount'
						isError={dataerrors.carrierPriceAmount}
						value={carrierPriceAmount}
						handleChange={this.handleChange}
						inputStyle={canEditCarrierPrice ? this.requiredField(carrierPriceAmount, 'input') : {}}
					/>
					<Select
						selectClass='col-sm-5'
						handleChange={this.handleChange}
						name='carrierPriceID'
						disabled={!canEditCarrierPrice}
						value={carrierPriceID}
						placeholder='Izvēlies mērvienību'
						options={
							optionData
								? optionData.price.map(price => ({
									label: price.title,
									value: price.id
								}))
								: []
						}
					/>
				</div>
				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Nodaļa, kas veic pasūtījumu'
						name='department'
					/>
					<Select
						selectClass='col-sm-8'
						handleChange={this.handleChange}
						name='department'
						value={department}
						isError={dataerrors.department}
						placeholder='Izvēlies vērtību'
						options={
							optionData
								? optionData.departments.map(dept => ({
									label: dept.title,
									value: dept.id
								}))
								: []
						}
					/>
				</div>
				<div className='row'>
					<Label labelClass='col-sm-4' title='Piegādāt līdz' name='deliverTill' />
					<div
						className={clsx({
							'col-sm-5': !wrongData,
							'col-sm-4': wrongData
						})}>
						<Datetime
							locale='lv'
							timeFormat={false}
							closeOnSelect={true}
							onChange={this.handleDateChange}
							defaultValue={deliverTill}
							inputProps={this.requiredField(deliverTill)}
						/>
					</div>
					{wrongData && (
						<div className='col-sm-1'>
							<OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id='tooltip-right'>
										Datums ir jāapstiprina kalendāra izvēlnē
									</Tooltip>
								}>
								<i className='fas fa-exclamation-triangle'></i>
							</OverlayTrigger>
						</div>
					)}
					<div className='col-sm-2'>
						<Switch
							name='deliverTillMandantory'
							offColor='warning'
							onColor='success'
							onText='Obligāti'
							offText='Vēlams'
							defaultValue={deliverTillMandantory == true ? true : false}
							onChange={(el, state) =>
								this.handleSwitch(el, state, 'deliverTillMandantory')
							}
						/>
					</div>
				</div>
				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Manipulators'
						name='manipulator'
					/>
					<div className='col-sm-8'>
						<Switch
							name='manipulator'
							offColor='warning'
							onColor='success'
							onText='Jā'
							offText='Nē'
							defaultValue={manipulator == true ? true : false}
							onChange={(el, state) =>
								this.handleSwitch(el, state, 'manipulator')
							}
						/>
					</div>
				</div>
				<div className='row'>
						<Label
							labelClass='col-sm-4'
							title='Apmaksa'
							name='paymentQuarter'
						/>
						<Select
							selectClass='col-sm-8'
							handleChange={this.handleChange}
							name='paymentQuarter'
							value={paymentQuarter === null ? 'Nav' : paymentQuarter}
							isError={dataerrors.paymentQuarter}
							placeholder='Izvēlies vērtību'
							style={this.requiredField(paymentQuarter)}
							options={this.getPaymentQuarterOptions()}
						/>
				</div>
				{isNewOrder == false && (
					<div className='row'>
						<Label
							labelClass='col-sm-4'
							title='Pasūtījuma statuss'
							name='orderStatusForSave'
						/>
						<Select
							selectClass='col-sm-8'
							handleChange={this.handleChange}
							isError={dataerrors.orderStatusForSave}
							name='orderStatusForSave'
							value={orderStatusForSave}
							placeholder='Izvēlies vērtību'
							options={orderStatusOptions}
						/>
					</div>
				)}
				<div className='row'>
					<Label labelClass='col-sm-4' title='Izpilde' name='plannedDelivery' />
					<div className='col-md-8'>
						<Radio
							name='plannedDelivery'
							value={currYearStr}
							checkAgainst={plannedDelivery}
							handleChange={this.handleChange}
							label='Šogad'
						/>
						<Radio
							name='plannedDelivery'
							value={nextYear}
							checkAgainst={plannedDelivery}
							handleChange={this.handleChange}
							label='Nākamā gada pavasarī'
						/>
					</div>
				</div>
				<div className='row'>
					<Label labelClass='col-sm-4' title='Prioritāte' name='priority' />
					<div className='col-md-8'>
						<Radio
							name='priority'
							value='1'
							checkAgainst={priority}
							handleChange={this.handleChange}
							label='Parastā'
						/>
						<Radio
							name='priority'
							value='2'
							checkAgainst={priority}
							handleChange={this.handleChange}
							label='Steidzami'
						/>
					</div>
				</div>

				<div className='row'>
					<Label
						labelClass='col-sm-4'
						title='Rādīt piezīmes rēķinā'
						name='showNotesInBill'
					/>
					<div className='col-sm-8'>
						<Switch
							name='showNotesInBill'
							offColor='warning'
							onColor='success'
							onText='Jā'
							offText='Nē'
							defaultValue={showNotesInBill == true ? true : false}
							onChange={(el, state) =>
								this.handleSwitch(el, state, 'showNotesInBill')
							}
						/>
					</div>
				</div>
				{/* <div><pre>{JSON.stringify(this.state.toSaveData, null, 2)}</pre></div> */}
				<div className='row'>
					<Label labelClass='col-sm-4' title='Piezīmes' name='notes' />
					<Textarea
						inputClass='col-sm-8'
						name='notes'
						value={notes}
						handleChange={this.handleChange}
					/>
				</div>
				<button
					type='button'
					disabled={sendingStatus || remainderToastActive}
					className='btn btn-primary'
					onClick={planningOrderId && remainder ? this.checkRemainder : this.saveData}>
					Saglabāt!
				</button>
				{prognosedDistanceAndTime.coords !== null && (
					<CustomModal
						title='Piegādes maršruts'
						key={Date.now()}
						showModal={showModal}
						handleCloseModal={this.handleToggleModal}
						content={
							<Suspense fallback={null}>
								<MapObject route={true} coordinates={prognosedDistanceAndTime.coords}/>
							</Suspense>
						}
					/>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	ignoreMaterialTransportationPrice: state.auth.ignoreMaterialTransportationPrice,
	canEditCarrierPrice: state.auth.canEditCarrierPrice,
	canApproveOrdererPrice: state.auth.canApproveOrdererPrice
})

export default connect(mapStateToProps)(Edit);
