import React from 'react';
import api from '@api';
import { toast } from 'react-toastify';
import { reloadPage } from '../../../util';
import {
	invoicePDFlink,
	MILISECONDS_BEFORE_CLOSE,
	invoiceConfirmationPDFlink
} from '../../../config';
import { horizonCheck } from '../../../permissions';


export const actionsButtonFormatter = cell => {
	const row_data = cell;

	const wasSent = row_data.variosActions.send.alreadySent;

	return <i className="fas fa-ellipsis-v" style={{fontSize: '1.6em', textAlign: 'center', color: (wasSent ? 'green' : 'red')}}></i>;
};

export const writerFormatter = (cell) => {
	const { billWriteTime, billWritePerson } = cell;

	return <>{billWriteTime}<br />{billWritePerson}</>;
};

export const nrFormatter = cell => {
	const { billNr, year, billNrCript } = cell;

	return <><b>{billNr}/{year}</b><br /><a target="_blank" rel="noopener noreferrer" href={`${invoicePDFlink}${billNrCript}`} style={{color:'red', fontSize:'135%', fontWeight: 'bold'}}>PDF</a></>;
};

export const deadlineFormatter = cell => {
	const { billPaymentDeadline, isBillPaymentInFuture, billStatus } = cell;

	const bgColor = billStatus == 1 ? 'green' : (isBillPaymentInFuture ? 'green' : 'red');

	return <span style={{color:bgColor}}>{billPaymentDeadline}</span>;
};

const horizonIcon = color => {
	return <i className="fas fa-file-import fa-2x" style={{verticalAlign:'middle', color}}></i>;
};

export const horizonStatus = cell => {
	const row_data = cell;

	if (
		typeof row_data.variosActions.horizon.status !== 'undefined' &&
		row_data.variosActions.horizon.status == 'error'
	) {
		return `Kļūda! ${row_data.variosActions.horizon.message}`;
	}
	const {
		horizon: {
			import: { billStat, billId, pvzdoc, imageStatus },
			lastCheck
		},
		importStatus
	} = row_data.variosActions;

	let horizIcon = null;
	if (imageStatus == './images/export-not-done.png') {
		horizIcon = horizonIcon('red');
	} else if (imageStatus == './images/export.png') {
		horizIcon = horizonIcon('green');
	} else if (imageStatus == './images/export-cant-create.png') {
		horizIcon = horizonIcon('gray');
	}

	const updText =
		lastCheck == '' ? '' : <><br />(atj. {lastCheck})</>;
	//<img  src="${imageStatus}" style="vertical-align:middle; width:27px; height:27px; margin-left:20px; cursor: pointer">
	return <>{horizIcon}{updText}</>;
};

export const horizon2Status = cell => {
	const row_data = cell;

	if (
		typeof row_data.variosActions.horizon.status !== 'undefined' &&
		row_data.variosActions.horizon.status == 'error'
	) {
		return `Kļūda! ${cell.variosActions.horizon.message}`;
	}
	const {
		horizon: {
			importCreditor: { billStat, billId, pvzdoc, imageStatus },
			lastCheck
		},
		importStatus
	} = row_data.variosActions;

	//<img data-bill-stat="1" class="horizon-import-imported horizon-import-0" data-type="regular" data-bill-id="5858" data-hor-check-class="horizon-import-0" data-pvzdoc="0b0f7dae5528caea8ed90525b6f3fceacc9419d4" src="./images/export.png" alt="pic" style="vertical-align:middle; width:27px; height:27px; margin-left:20px; cursor: pointer" border="0">

	let horizIcon = null;
	if (imageStatus == './images/export-not-done.png') {
		horizIcon = horizonIcon('red');
	} else if (imageStatus == './images/export.png') {
		horizIcon = horizonIcon('green');
	} else if (imageStatus == './images/export-cant-create.png') {
		horizIcon = horizonIcon('gray');
	}

	return horizIcon;
};

const horizonInvoiceStatusses = {
	1: 'sagatave',
	2: 'izpildīts',
	3: 'grāmatots',
	4: 'dzēsts'
};

export const billStatus = cell => {
	let valueLOG = cell.billStatus;
	let valueHOR = cell.horizonInvoiceStatus;
	let hasBeenChangedAccount = cell.hasBeenChangedAccount;
	let colorLOG;
	let colorHOR;

	if (valueLOG === '1' || valueLOG === 1) {
		colorLOG = 'green';
		valueLOG = 'pabeigts';
	} else if (valueLOG === '2' || valueLOG === '0' || valueLOG === 2 || valueLOG === 0) {
		// color = "red";
		valueLOG = 'atvērts';
	} else {
		valueLOG = 'NEZINĀMS STATUSS';
	}

	if (valueHOR === 3) {
		colorHOR = 'green';
	}

	hasBeenChangedAccount = hasBeenChangedAccount ? <><br /><span style={{color:'red'}}>Horizonā mainījies bankas konta numurs!</span></> : '';

	valueHOR = horizonInvoiceStatusses[valueHOR] || 'NEZINĀMS STATUSS'; //todo: check if numeric (izņemot 0), then explicitly convert to number

	return <>LOG: <span style={{color:colorLOG}}>{valueLOG}</span><br />HOR: <span style={{color:colorHOR}}>{valueHOR}</span>{hasBeenChangedAccount}</>;
};

export const billSum = cell => {
	let value = cell.billSumm;

	return <><b>{value[0]}</b><br />{value[1]}<br />{value[2]}</>;
};

export const deliveryColumn = cell => {
	const { deliveries, billNrCript, delivery_files } = cell;

	return <>{deliveries}&nbsp;
		{delivery_files != deliveries && delivery_files > 0 ?
		<span title={'Pieejamie piegādes apstiprinājuma dokumenti: ' + delivery_files}>
		<span style={{ color: 'red', fontSize: '1.2em' }} className='glyphicon glyphicon-exclamation-sign' aria-hidden='true'></span>
		</span> : ''}
		{delivery_files > 0 ?
			<><br /><a target="_blank" rel="noopener noreferrer" href={`${invoiceConfirmationPDFlink}${billNrCript}`} style={{color:'red', fontSize:'135%', fontWeight: 'bold'}}>PDF</a></> : ''}</>;
};

export const horizonImportClickHandler = (cell, billType, isHorizonEnabled, cb, isHorizonImporting) => {
	if (!horizonCheck()) {
		toast.warn(`Nav tiesību importēt Horizon`, { autoClose: 5000 });
		return;
	}
	if (!isHorizonEnabled) {
		toast.warn(`Horizon pašlaik ir atslēgts`, { autoClose: 5000 });
		return;
	}

	if (isHorizonImporting) {
		toast.warn(`Jau ir aktīvs Horizon imports`, { autoClose: 5000 });
		return;
	}

	const row_data = cell;

	const {
		variosActions: {
			importStatus,
			horizon: { status, message }
		},
		billNrCript
	} = row_data;

	if (typeof status !== 'undefined' && status == 'error') {
		toast.warn(`Kļūda! ${message}`, { autoClose: 5000 });
		return;
	}

	if (importStatus == true) {
		toast.warn(`Rēķins ir jau ir importēts Horizon`, { autoClose: 5000 });
		return;
	}

	const isCreditor = billType == 'creditor' ? true : false;

	const billStatus = isCreditor
		? row_data.variosActions.horizon.importCreditor.billStat
		: row_data.variosActions.horizon.import.billStat;

	if (billStatus != '1') {
		toast.warn(`Rēķina statusam ir jābūt pabeigtam, lai to varētu importēt Horizon datubāzē`, {
			autoClose: 5000
		});
		return;
	}

	//šis jāpabeidz: jscripts\horizonImport.js
	api.bills
		.importHorizon({ pvz: billNrCript, creditor: isCreditor })
		.then(response => {
			if (response.success === 0) {
				toast.warn(`Kļūda: ${response.message}`, { autoClose: 7500 });
			} else {
				toast.success('Rēķins importēts', { autoClose: 4500 });
				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//cb();
			}
		})
		.catch(error => {
			toast.warn('Atgriezta kļūda', { autoClose: 7500 });
		});
};

export const paymentTypeFormatter = (cell) => {
	const {billPaymentType, hasBeenChangedType} = cell;

	const hasBeenChanged = hasBeenChangedType ? <span style={{color:'red'}} title="Horizonā mainījies apmaksas veids!"><i className="fas fa-exclamation-circle"></i></span> : null;

	return <>{billPaymentType}{hasBeenChanged}</>;
};

export const billSentStatus = (cell) => {
	const { variosActions } = cell;
	const { send: { sentByPerson, alreadySent, billSentDate } } = variosActions;

	const hoverTitle = alreadySent ? `${sentByPerson}\n${billSentDate}` : 'Nav nosūtīts';
	const color = alreadySent ? 'green' : 'red';

	return <i className='fas fa-paper-plane' style={{fontSize: '1.4em', textAlign: 'center', color}} title={hoverTitle}></i>;
}
