import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const columns = [
	{
		title: 'Lietotājs',
		field: 'userName',
		width: 180
	},
	{
		title: 'Nedēļa',
		align: 'center',
		field: 'week',
		width: 150
	},
	{
		title: 'Pirmdiena',
		align: 'center',
		field: 'monday'
	},
	{
		title: 'Otrdiena',
		align: 'center',
		field: 'tuesday'
	},
	{
		title: 'Trešdiena',
		align: 'center',
		field: 'wednesday'
	},
	{
		title: 'Ceturtdiena',
		align: 'center',
		field: 'thursday'
	},
	{
		title: 'Piektdiena',
		align: 'center',
		field: 'friday'
	},
	{
		title: 'Sestdiena',
		align: 'center',
		field: 'saturday'
	},
	{
		title: 'Svētdiena',
		align: 'center',
		field: 'sunday'
	},
	{
		title: 'Kopā',
		align: 'center',
		field: 'totalInWeek'
	}
];

const Table = ({ data, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let { userName } = filters;
	userName = userName.toLowerCase();

	const filterObj = { userName };

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;
	})

	if (finalData.length < 1) {
		return <>Nav datu</>;
	}

	let totalOrders = 0
	finalData.forEach(obj => {
		totalOrders += obj.totalInWeek;
	});

	return (
		<>
			<Tabulator
				columns={columns}
				options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
				data={finalData}
			/>
			<div className='report_overview'>
				<h4>Kopsavilkums</h4>
				<div>Pasūtījumu skaits laika posmā: {totalOrders}</div>
			</div>
		</>
	);
};

export default Table;
