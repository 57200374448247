import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import { actionButton } from 'shared/icons';
import api from '@api';
import { toast } from 'react-toastify';
import { checkPermission } from 'permissions';
import MyCustomTable from '../../latraps_table';

const amountWithUnit = (cell, key) => {
	return <>{cell[key]} {cell.measurementUnit}</>;
};

const storageFormatter = cell => {
	const {
		storageAddress: { name: stName, address: stAdd },
		deliveryNotes,
		payerAddress
	} = cell;
	const notes = deliveryNotes == '' ? '' : <><br />- piezīmes: {deliveryNotes}</>;
	return <>{stName}, {stAdd} -&gt; {payerAddress}{notes}</>;
};

const materialFormatter = cell => {
	const { material, orderId, orderMaterialNotes, ordererPriceApproved } = cell;

	const ordererPriveNote = !ordererPriceApproved || ordererPriceApproved == 0 ? <><br /><span style={{color:'red'}}><span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> pasūtītāja cena nav apstiprināta!</span></> : '';

	return <><b>{material}</b><br />- Pas. nr. #{orderId}<br />- Piezīmes: {orderMaterialNotes}{ordererPriveNote}</>; // + ', '+ stAdd;
};

const carrierFormatter = cell => {
	const { carrierName, carrierDriver, carrierType } = cell;
	return [carrierName, carrierDriver, carrierType].join(', ');
};

const priceFormatter = cell => {
	const { customerPrice, par } = cell;
	return <b>{customerPrice} EUR / {par}</b>;
};
const inclColumnBackground = row => {
	const rowValue = row.isIncluded;

	if (rowValue) {
		return '#ADFF2F';
	}

	return '#FF7F50';
}

const includingFormatter = row => {
	const rowValue = row.isIncluded;

	if (rowValue) {
		return <i className="fas fa-check-circle fa-lg" title='Iekļauts' style={{fontSize: '1.2em'}}></i>;
	}

	return <i className="fas fa-check-circle fa-lg" title='Nav iekļauts' style={{fontSize: '1.2em'}}></i>;
};
//glyphicon glyphicon-remove
//glyphicon glyphicon-ok
//<span class="glyphicon glyphicon-search" aria-hidden="true"></span>

class FreePiegades extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			isLoaded: false,
			error: false,
			includedDeliveries: []
		};
	}

	handleCellClick =(cell) => {
		console.log(cell)
		const { deliveryId } = cell;
		this.handleSetttingAsIncluded(deliveryId);
	}

	columns = [
		{
			Header: 'Datums',
			accessor: 'pickupdate',
		},
		{
			Header: 'Iekļaut',
			clickHandler: this.handleCellClick,
			accessor: 'isIncluded',
			bgColor: row => inclColumnBackground(row.original),
			Cell: ({ row }) => (
				<div>
					{includingFormatter(row.original)}
				</div>)
		},
		{
			Header: 'Maksātājs',
			columnClass: 'bolded',
			accessor: 'customerTitle',
		},
		{
			Header: 'Maršruts',
			accessor: 'storageAddress',
			Cell: ({ row }) => storageFormatter(row.original)
		},
		{
			Header: 'Materiāls',
			accessor: 'material',
			Cell: ({ row }) => materialFormatter(row.original)
		},
		{
			Header: 'Pārvadātājs',
			accessor: 'carrier',
			Cell: ({ row }) => carrierFormatter(row.original)
		},
		{
			Header: <>Saņemtais<br />daudzums</>,
			accessor: 'gotAmount',
			Cell: ({ row }) => amountWithUnit(row.original, 'gotAmount'),
			bottomCalc: 'sum',
			bottomCalcParams: { precision: 3 }
		},
		{
			Header: <>Piegādātais<br />daudzums</>,
			accessor: 'deliveredAmount',
			Cell: ({ row }) => amountWithUnit(row.original, 'deliveredAmount'),
			bottomCalc: 'sum',
			bottomCalcParams: { precision: 3 }
		},
		{
			Header: <>Nostrādātās<br />stundas</>,
			accessor: 'workedHours',
		},
		{
			Header: <>Dīkstāves<br />stundas</>,
			accessor: 'idleHours',
		},
		{
			Header: 'Virsstundas',
			accessor: 'overtimeHours',
		},
		{
			Header: 'Cena',
			accessor: 'customerPrice',
			Cell: ({ row }) => priceFormatter(row.original)
		},
		{
			Header: 'PVZ',
			accessor: 'cmr',
		}
	];

	saveDeliveryAsIncluded(delivery, newStatus) {
		const { pvz } = this.props;

		api.bills.addDelivery({ pvz, order: delivery, newStatus }).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
			} else {
				toast.warn('Ir problēmas ar datu saglabāšanu', {
					autoClose: 4000
				});
			}
		});
	}

	handleSetttingAsIncluded = delivery => {
		const { readOnly } = this.props;

		if (readOnly) {
			toast.warn('Sadaļa ir lasīšanas režīmā');
			return false;
		}
		if (!checkPermission('edit', 'bills')) {
			return false;
		}
		const { data } = this.state;

		const newDataState = data.map(currItem => {
			const { deliveryId } = currItem;

			let newElementState = {};
			if (deliveryId == delivery) {
				newElementState = Object.assign({}, currItem, {
					isIncluded: !currItem.isIncluded
				});
				//if (currItem.isIncluded == false) {
				//tātad tagad būs true
				this.saveDeliveryAsIncluded(deliveryId, !currItem.isIncluded);
				//}
			} else {
				newElementState = currItem;
			}

			return newElementState;
		});
		const newState = Object.assign({}, this.state, { data: newDataState });

		this.setState(newState);
	};

	setData = () => {
		const { payer, type } = this.props;

		this.setState({ loading: true });
		api.bills.free_pieg({ payer, type }).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	};

	componentDidMount() {
		this.setData();
	}

	toggle = () => {
		if (!checkPermission('edit', 'bills')) {
			return false;
		}
		const { data } = this.state;

		let deliveries = [];

		const newDataState = data.map(currItem => {
			const { deliveryId } = currItem;

			deliveries.push(deliveryId);

			return {
				...currItem,
				isIncluded: true
			};
		});
		//console.log(deliveries);
		//return;

		const newState = Object.assign({}, this.state, { data: newDataState });

		this.saveDeliveryAsIncluded(deliveries, true);

		this.setState(newState);
	};

	render() {
		const { data } = this.state;
		const { readOnly } = this.props;

		return (
			<div>
				<div>
					<div style={{ paddingTop: 10 }} />
					<button
						type='button'
						className='btn btn-primary'
						onClick={this.toggle}
						disabled={readOnly}>
						Iekļaut visas piegādes
					</button>
								<MyCustomTable orders={data} columns={this.columns} />
					Kopā iekļautas {
						this.state.data.filter(item => item.isIncluded === true).length
					}{' '}
					piegādes
				</div>
			</div>
		);
	}
}

export default FreePiegades;
