import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../shared/form';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
	formatNumber,
	isNumeric,
	allFieldsHaveValues,
	allFieldsHaveValues_new,
	reloadPage
} from '../../util';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import clsx from 'clsx';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import { CustomModal } from 'shared/modal';
import CreateNew from 'sections/admin/forms/create';

const formatAddress = addressData => {
	return 'title' in addressData ? addressData.title : '';
	//{"id":1,"short_name":"LATRAPS LPKS - ELEJA","address":"LATRAPS LPKS - ELEJA","title":"LATRAPS LPKS - LIETUVAS IELA 16A, ELEJA, ELEJAS PAG., JELGAVAS NOV., LV-3023","phone":"","mappostId":31416}
	//{"address":2304,"title":"PUTRAS, UKRU PAG., AUCES NOV., LV-3729","phone":"29479324,26302305","contacts":"PUTRAS Z.S., VĪTOLS ULDIS","mappostId":34458}
};

//https://www.npmtrends.com/react-bootstrap-date-picker-vs-react-bootstrap-daterangepicker-vs-react-bootstrap-datetimepicker-vs-react-dates-vs-react-datetime-vs-react-date-picker-vs-react-infinite-calendar-vs-react-datepicker

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] === 'object' || typeof initialData[key] === 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

const formatOption = (option, props, idx) => {
	if (props.id == 'driverSearch') {
		return (
			<span style={{ fontSize: '75%' }} key={idx}>
				{option.driverName}{' '}
				{option.mappostUserID > 0 ? (
					<span style={{ color: 'green' }}>(MAPPOST lietotājs)</span>
				) : (
					<span style={{ color: '#ff0000' }}>(Nav reģistrēts MAPPOST sistēmā)</span>
				)}{' '}
				- {option.client} - {option.vilcejs}
			</span>
		);
	}

	if (props.id == 'clientSearch') {
		return (
			<span style={{ fontSize: '75%' }} key={idx}>
				{option.client} - {option.clientRegNr}
			</span>
		);
	}
};

const handleEnablingMappost = props => {
	const { mappostEnabled, storage, delivery } = props;
	if (storage.mappostId > 0 && delivery.mappostId > 0) {
		return mappostEnabled;
	}

	return false;
};

//https://www.npmjs.com/package/react-inputs-validation - apskatīt

class TaskEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			clientOptions: [],
			options: [],
			dataerrors: {},
			sending: false,
			wrongData: false,
			incomingInvoice: false,
			needManipulator: false,
		};
	}

	componentDidMount() {
		this.setState({ loading: true, error: false });
		const { orderId } = this.props; //'21774';//šis jāpadod no this.props;

		api.orders.infoNewTask(orderId).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'taskPrice',
				'title',
				'measurementId',
				'notes',
				'taskPriceForID',
				'ordererPrice',
				'ordererPriceForID',
				//'mappostEnabled',
				'gadget'
			]);

			const attrTransformation = {
				amount: '', //formatNumber(response['remaining'], 3),
				taskPrice: formatNumber(moreAttrs['taskPrice'], 3),
				ordererPrice: formatNumber(moreAttrs['ordererPrice'], 3),
				taskDate: moment(response['taskDate']).format('DD.MM.YYYY.'),
				amountKavas: 1,
				gadget: response['gadget'],
				mappostEnabled: handleEnablingMappost(response)
			};
			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState, () => this._handleSearch(''));
			this.setState(newState, () => this._handleClientSearch(''));
		});
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });

		if (key == 'gadget') {
			this.setState({ options: [], clientOptions: []})
			this._handleSearch('');
			this._handleClientSearch('');

			if (value == 1 && 'client' in this.state.toSaveData) {
				this.setDataToState('client', null)
			}

			if (value == 2 && 'driver' in this.state.toSaveData) {
				this.setDataToState('driver', null)
			}
		}

		if (key == 'client') {
			this.setState({ options: [] });
			this._handleSearch('');

			if ('driver' in this.state.toSaveData) {
				this.setDataToState('driver', null)
			}
		}
	};

	handleDateChange = value => {
		if (typeof value == 'string' && value != '') {
			this.setState({ wrongData: true });
		} else {
			const valueToSave = value == '' ? value : value.format('YYYYMMDD');
			this.setDataToState('taskDate', valueToSave);
			this.setState({ wrongData: false });
		}
	};

	handleChange = event => {
		let inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value; //isNaN(event.target.name) ? event.target.value : parseFloat(event.target.name);
		const name = event.target.name;

		if (
			inputValue.length > 0 &&
			(name == 'amount' || name == 'amountKavas' || name == 'taskPrice')
		) {
			if (isNumeric(inputValue) == false) {
				//  inputValue = parseFloat(inputValue).toFixed(3);
				//} else {
				return false;
			}

			inputValue = inputValue.trim();

			if (name == 'amountKavas' && inputValue > 100) {
				inputValue = 100;
			}
		}
		//.remaining.toFixed(3)

		this.setDataToState(name, inputValue);
	};

	setFieldError = field => {
		this.setState({
			dataerrors: {
				[field]: true
			}
		});
	};

	saveData = e => {
		//todo: jānotestē, kas notiek, ja vērtību izdzēš no input (jo ir nepareiza vērtība)
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();
		this.setState({ dataerrors: {} });

		const { toSaveData, data, incomingInvoice } = this.state;
		const gadget = toSaveData.gadget;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		}

		const defaultFields = {
			addressFrom: data.storage.title,
			addressTo: data.delivery.title,
			material: data.material,
			typeID: 9,
			incomingInvoice
		};

		const dataToSendAsDefaults = [
			'taskDate',
			'notes',
			'priceType',
			'taskPrice',
			'title',
			'addressFrom',
			'addressTo',
			'material',
			'cargoCount',
			'typeID',
			'gadget',
			'measurementUnit',
			'taskPriceForID',
			'amount',
			'amountKavas',
			'ordererPrice',
			'ordererPriceForID',
			'incomingInvoice',
			'addManipulatorToTransport',
		]; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga

		//todo: šo laikam varētyu apvienot ar reallyChanged masīva izveidi
		let try2Final = {};

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const carrierInfo = 'client' in toSaveData ? toSaveData.client : {};
		const driverInfo = 'driver' in toSaveData ? toSaveData.driver : {};

		const amount = typeof try2Final.amount !== 'undefined' ? try2Final.amount : data.remaining;

		const taskPriceFINAL =
			typeof try2Final.taskPrice === 'undefined' || try2Final.taskPrice === null
				? 0
				: try2Final.taskPrice;

		const cargoCounterReal =
			typeof try2Final['amountKavas'] === 'undefined' || !('amountKavas' in try2Final)
				? 1
				: try2Final['amountKavas'];

		const finalToSend = Object.assign(
			{},
			try2Final,
			defaultFields,
			{ client: carrierInfo },
			{ driver: driverInfo },
			{ amount },
			{ cargoCount: cargoCounterReal },
			{ taskPrice: taskPriceFINAL }
		);

		console.log(
			'to save',
			JSON.stringify(finalToSend),
			try2Final,
			data,
			reallyChanged,
			this.props
		);

		const nameMap = {
			taskDate: 'Datums',
			notes: 'Piezīmes',
			taskPrice: 'Pārvadātāja cena',
			title: 'Apraksts',
			addressFrom: 'Maršruts no',
			addressTo: 'maršruts uz',
			material: 'Materiāls',
			cargoCount: 'Kravu skaits',
			gadget: 'Izpildes veids',
			measurementUnit: 'Mērvienība',
			taskPriceForID: 'Pārvadātāja cenas mērvienība',
			ordererPriceForID: 'Klienta rēķina cenas mērvienība',
			ordererPrice: 'Klienta rēķina cena',
			typeID: '',
			//workerID: '',
			amount: 'Daudzums',
			driver: 'Šoferis',
			client: 'Pārvadātājs'
		};

		const requiredFields = [
			'taskDate',
			'notes',
			'taskPrice',
			'title',
			'addressFrom',
			'addressTo',
			'material',
			'cargoCount',
			'typeID',
			'gadget',
			'measurementUnit',
			'taskPriceForID',
			'amount',
			'driver',
			'ordererPrice',
			'ordererPriceForID'
		];

		if (gadget == 2) {
			requiredFields.push('client')
		}

		const confirmAllFieldsHasValues = allFieldsHaveValues_new(requiredFields, finalToSend, nameMap);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			this.setFieldError(confirmAllFieldsHasValues.field);
			return;
		}

		if (parseFloat(finalToSend.amount) == 0 || finalToSend.amount.toString().trim() == '') {
			toast.warn('Nav norādīts daudzums', { autoClose: 7500 });
			this.setFieldError('amount');
			return;
		}

		if (
			parseFloat(finalToSend.cargoCount) == 0 ||
			finalToSend.cargoCount.toString().trim() == ''
		) {
			toast.warn('Nav norādīts braucienu daudzums', { autoClose: 7500 });
			this.setFieldError('cargoCount');
			return;
		}

		this.setState({ sending: true });

		api.orders.saveTask({ data: finalToSend, order: this.props.orderId }).then(response => {
			this.setState({ sending: false });
			if (response.status == 'error') {
				toast.warn(response.message, { autoClose: 10000 });
			} else if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				setRespectTabs();

				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn('Ir problēmas!', { autoClose: 7000 });
			}
		});

		//vēl skatīt datu validāciju (validateDeliveryCostFields) - saveNewTaskSingle funkcija
	};

	_handleClientSearch = client => {
		this.setState({ isLoading: true });

		const { clientID = 0 } = this.props;
		const gadget = this.state.toSaveData?.gadget ?? this.state.gadget;

		if (gadget == 2) {
			api.search.transport_simple(client).then(response => {
				this.setState({ clientOptions: response, isLoading: false });
			});
		} else {
			api.search.transport({ client, driver: '', vilcejs: '', clientID }).then(response => {
				this.setState({  clientOptions: response, isLoading: false });
			});
		}
	};

	_handleSearch = query => {
		this.setState({ isLoading: true });

		const gadget = this.state.toSaveData?.gadget ?? this.state.gadget;

		if (gadget == 2) {
			const clientID = this.state.toSaveData.client?.clientRegNr ?? '';
			api.search.drivers_simple({ query, clientID }).then(response => {
				this.setState({ options: response, isLoading: false });
			});
		} else {
			const mappost = gadget == 1 ? true : null;
			api.search.drivers({ query, mappost }).then(response => {
				this.setState({ options: response, isLoading: false });
			});
		}
	};

	onOptionSelect = (value, mode) => {
		if (value != '') {
			this.setDataToState(mode, value);

			if (mode === 'driver') {
				const orderNeedsManipulator = this.state.data.manipulator;
				const driverHasManipulator = value?.manipulator;

				if (orderNeedsManipulator && !driverHasManipulator && value) {
					this.setState({ needManipulator: true });
					toast.warn('Izvēlētajam pārvadātājam nav manipulatora', { autoClose: 5000 });
				}

				if (driverHasManipulator || !value) {
					this.setState({ needManipulator: false });
					this.setDataToState('addManipulatorToTransport', false);
				}
			}
		}
	};

	formatAsString = event => {
		const number = event.target.value;
		const name = event.target.name;

		this.setDataToState(name, formatNumber(number, 3));
	};

	handleToggleModal = (ev, data = null, modalType = '') => {
		if (data != null && modalType != '') {
			if (modalType == 'driver') {
				this.setDataToState('driver', data.driver_data[0]);
				this.setDataToState('client', data.carrier_data[0]);
			} else {
				this.setDataToState(modalType, data);
			}
		}
		this.setState({ showModal: !this.state.showModal });
	};

	handleOpenModal(openType, cell) {
		this.setState({ showModal: true, modalType: openType });
	}

	getDefaultSelected(value) {
		if (!value) {
			return [];
		}
		return Array.isArray(value) ? value : [value];
	}

	handleSwitch(elem, state, name) {
		const inputValue = state;
		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	handleNumberValidation = event => {
		/*
    const number = event.target.value;
    const name = event.target.name;
    //console.log('handleNumberValidation',number,event);

    this.setDataToState(name,formatNumber(number,3));
    */
	};

	render() {
		const {
			wrongData,
			gadget,
			client,
			driver,
			loading,
			data,
			priceType,
			taskPrice,
			notes,
			title,
			amount,
			taskDate,
			amountKavas,
			dataerrors,
			taskPriceForID,
			ordererPrice,
			ordererPriceForID,
			sending,
			mappostEnabled,
			showModal,
			modalType,
			incomingInvoice,
			needManipulator,
		} = this.state;

		const gadgetOptionsWithMappost = [
			{ label: 'Ar Mappost', value: 1 },
			{ label: 'Bez Mappost', value: 2 }
		];

		const gadgetOptionsWitouthMappost = [{ label: 'Bez Mappost', value: 2 }];

		return (
			<div style={{ width: '100%' }}>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Izpildes veids'
								name='gadget'
							/>
							<Select
								selectClass='col-sm-8'
								handleChange={this.handleChange}
								name='gadget'
								value={gadget}
								placeholder='Izvēlies vērtību'
								options={
									mappostEnabled
										? gadgetOptionsWithMappost
										: gadgetOptionsWitouthMappost
								}
							/>
						</div>
						{gadget == 2 &&
							<div className='row'>
								<Label
								labelClass='col-sm-3'
								title='Pārvadātājs'
								name='client'
								required={gadget == 2}
							/>
							<div className='col-sm-7' style={{paddingRight: 23}}>
								<AsyncTypeahead
									id='clientSearch'
									isLoading={this.state.isLoading}
									options={this.state.clientOptions}
									labelKey='client'
									filterBy={(option, props) => {
										return option;
									}}
									selected={this.getDefaultSelected(client)}
									onChange={selected => this.onOptionSelect(selected[0], 'client')}
									minLength={0}
									clearButton={true}
									onSearch={this._handleClientSearch}
									placeholder='Meklēt pārvadātāju'
									renderMenuItemChildren={(option, props, idx) => {
										return formatOption(option, props, idx);
									}}
								/>
							</div>
							<button
								type='button'
								className='btn btn-success'
								style={{borderColor: '#E7E7E7', marginLeft: -23}}
								onClick={() => this.handleOpenModal('newCarrier')}
								disabled={sending}>
								Izveidot
							</button>
						</div>}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Šoferis'
								name='driver'
								required={true}
							/>
							<div className='col-sm-7' style={{paddingRight: 23}}>
								<AsyncTypeahead
									id='driverSearch'
									disabled={!client && gadget == 2}
									isLoading={this.state.isLoading}
									options={this.state.options}
									labelKey='driverName'
									filterBy={(option, props) => {
										return option;
									}}
									selected={this.getDefaultSelected(driver)}
									onChange={selected => this.onOptionSelect(selected[0], 'driver')}
									minLength={0}
									clearButton={true}
									onSearch={this._handleSearch}
									placeholder='Meklēt šoferi'
									renderMenuItemChildren={(option, props, idx) => {
										return formatOption(option, props, idx);
									}}
									useCache={false}
								/>
							</div>
							<button
								type='button'
								className='btn btn-success'
								style={{borderColor: '#E7E7E7', marginLeft: -23}}
								onClick={() => this.handleOpenModal('newDriver')}
								disabled={sending || gadget == 1 || !client}>
								Izveidot
							</button>
						</div>

						{needManipulator &&
							<div className='row' style={{ marginLeft: 10, marginBottom: 10 }}>
								<div className='col-sm-offset-3 alert-info' style={{ marginTop: 5, borderRadius: 5, width: 450, padding: 5 }}>Pasūtījuma izpildei nepieciešams transports ar manipulatoru!</div>
								<Label labelClass='col-sm-3' />
								<Check
									className='col-sm-8'
									name='addManipulatorToTransport'
									checked={false}
									disabled={!needManipulator}
									label='Norādītais transports ir ar manipulatoru'
									handleChange={this.handleChange}
								/>
							</div>
						}

						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Maršruts no'
								name='starting_point'
							/>
							<Input
								inputClass='col-sm-8'
								name='starting_point'
								value={formatAddress(data.storage)}
								disabled={true}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Maršruts uz' name='end_point' />
							<Input
								inputClass='col-sm-8'
								name='end_point'
								value={formatAddress(data.delivery)}
								disabled={true}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Materiāls' name='material' />
							<Input
								inputClass='col-sm-8'
								name='material'
								value={data.material}
								disabled={true}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Pieejamais daudzums'
								name='remaining'
							/>
							<Input
								inputClass='col-sm-4'
								name='remaining'
								value={data.remaining.toFixed(3)}
								disabled={true}
							/>
							<Input
								inputClass='col-sm-4'
								name=''
								value={data.measurementName}
								disabled={true}
							/>
						</div>
						{gadget == 1 ? (
							<div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Daudzums'
										name='amount'
										//required={true}
									/>
									<Input
										inputClass='col-sm-4'
										name='amount'
										isError={dataerrors.amount}
										blur={this.formatAsString}
										value={amount}
										handleChange={this.handleChange}
									/>
									<Input
										inputClass='col-sm-4'
										name=''
										isError={dataerrors.measurementName}
										value={data.measurementName}
										disabled={true}
									/>
								</div>
							</div>
						) : (
							<div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Daudzums vienā kravā'
										name='amount'
									/>
									<Input
										inputClass='col-sm-4'
										name='amount'
										blur={this.formatAsString}
										isError={dataerrors.amount}
										value={amount}
										handleChange={this.handleChange}
									/>
									<Input
										inputClass='col-sm-4'
										name=''
										isError={dataerrors.measurementName}
										value={data.measurementName}
										disabled={true}
									/>
								</div>
								<div className='row'>
									<Input
										inputClass='col-sm-4 col-sm-offset-3'
										name='amountKavas'
										value={amountKavas}
										isError={dataerrors.amountKavas}
										handleChange={this.handleChange}
									/>
									<Label
										labelClass='col-sm-4'
										title='kravu sk.'
										name='amountKavas'
									/>
								</div>
							</div>
						)}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Pārvadātāja cena'
								name='taskPrice'
							/>
							<Input
								inputClass='col-sm-4'
								name='taskPrice'
								blur={this.formatAsString}
								isError={dataerrors.taskPrice}
								value={taskPrice}
								handleChange={this.handleChange}
							/>
							<Label labelClass='col-sm-1' title='EUR / par' name='taskPrice' />
							<Select
								selectClass='col-sm-3'
								handleChange={this.handleChange}
								name='taskPriceForID'
								value={taskPriceForID}
								isError={dataerrors.taskPriceForID}
								placeholder='Izvēlies vērtību'
								options={
									data
										? data.priceForData.map(price => ({
												label: price.par,
												value: price.par_id
										  }))
										: []
								}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Klienta rēķina cena'
								name='ordererPrice'
							/>
							<Input
								inputClass='col-sm-4'
								name='ordererPrice'
								isError={dataerrors.ordererPrice}
								blur={this.formatAsString}
								value={ordererPrice}
								handleChange={this.handleChange}
							/>
							<Label labelClass='col-sm-1' title='EUR / par' name='ordererPrice' />
							<Select
								selectClass='col-sm-3'
								handleChange={this.handleChange}
								name='ordererPriceForID'
								isError={dataerrors.ordererPriceForID}
								value={ordererPriceForID}
								placeholder='Izvēlies vērtību'
								options={
									data
										? data.priceForData.map(price => ({
												label: price.par,
												value: price.par_id
										  }))
										: []
								}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Piegādi sākt' name='taskDate' />
							<div
								className={clsx({
									'col-sm-8': !wrongData,
									'col-sm-7': wrongData
								})}>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange}
									defaultValue={taskDate}
								/>
							</div>
							{wrongData && (
								<div className='col-sm-1'>
									<OverlayTrigger
										placement='right'
										overlay={
											<Tooltip id='tooltip-right'>
												Datums ir jāapstiprina kalendāra izvēlnē
											</Tooltip>
										}>
										<i className='fas fa-exclamation-triangle'></i>
									</OverlayTrigger>
								</div>
							)}
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Ienākošais rēķins'
								name='incomingInvoice'
							/>
							<div className='col-sm-3'>
								<Switch
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={incomingInvoice}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'incomingInvoice')
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Piezīmes' name='notes' />
							<Textarea
								inputClass='col-sm-8'
								name='notes'
								value={notes}
								isError={dataerrors.notes}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Uzdevuma apraksts'
								name='description'
							/>
							<Textarea
								inputClass='col-sm-8'
								name='title'
								value={title}
								isError={dataerrors.title}
								handleChange={this.handleChange}
							/>
						</div>
						<button
							type='button'
							className='btn btn-primary'
							onClick={this.saveData}
							disabled={sending}>
							Saglabāt!
						</button>
						<CustomModal
							title={
							modalType == 'newCarrier'
									? 'Pārvadātāja pievienošana'
									: 'Šofera pievienošana'
							}
							showModal={showModal}
							handleCloseModal={this.handleToggleModal}
							content={
								<CreateNew
									modalType={modalType}
									handleCloseModal={this.handleToggleModal}
									client={client}
								/>
							}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default TaskEdit;
