import React, { memo } from 'react';
import clsx from 'clsx';
import './customTabulStyles.css';

const CustomTable = memo(({ columns, data }) => {
	console.log('CustomTableCustomTableCustomTableCustomTableCustomTableCustomTableCustomTable');
	return (
		<>
			<table>
				<thead>
					<tr>
						{columns.map(column => {
							return (
								<th
									className='customTableStyleHeader'
									style={
										column.width
											? { width: column.width, fontSize: '90%' }
											: { fontSize: '90%' }
									}
									key={Math.floor(Math.random() * (99999 - 0 + 1) + 0)}>
									{column.title}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{data.map((row, rowIndex) => (
						<tr key={Math.floor(Math.random() * (99999 - 0 + 1) + 0)}>
							{columns.map(column => (
								<td
									onClick={() =>
										column.cellClick && column.cellClick(row, rowIndex)
									}
									style={{
										fontSize: '90%',
										paddingLeft: '10px',
										paddingBottom: '5px',
										borderBottom: '1px solid #ddd'
									}}
									className={clsx(column.cssClass, {
										pointerCursor: !!column.cellClick
									})}
									key={Math.floor(Math.random() * (99999 - 0 + 1) + 0)}>
									{column.formatter ? column.formatter(row) : row[column.field]}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
});

export default CustomTable;
