import React, { Component } from 'react';
import api from '@api';
import Deliveries from './table';
import Header from './header';
import Loading from 'shared/loading';
import FilterText from '../../shared/filter/format';
import { handleDataUpdate } from '../../util';
import ErrorOnLoading from 'shared/errorOnLoad';
import NoData from 'shared/noDataNotification';
//
//virtualized
//https://blog.logrocket.com/rendering-large-lists-with-react-virtualized-82741907a6b3/

//{"description":"Rap\u0161u rau\u0161i, ","notes":"29.11.2018 \nNo r\u012bta uzkraujamies... Cenas iepriek\u0161\u0113j\u0101s !","carrierOrg":"ZIEDI\u0145I Z.S., RAMANIS ANDRIS","taskId":27866,"taskIzpilde":"MAPPOST","isMappostTask":true,"mappostTaskId":8374584,"mappostTaskState":1,"taskStartDate":"20181129","driver":"ALDIS LATAITIS","taskDescription":"Rap\u0161u rau\u0161i, [Pas\u016bt. PALSA, SIA], ","taskDaudz":25,"taskDaudzUnit":"t","worker":"L. Sni\u0137ers","mappostDriverId":10407,"orderedAmount":"25.000 t","givenAmount":"25.000 t","deliveredAmount":"0.000 t"}

class DriverDeliveries extends Component {
	intervalID;
	constructor(props) {
		super(props);

		this.state = {
			key: 0,
			loading: false,
			error: false,
			data: []
		};
		//this.handleSelect = this.handleSelect.bind(this);
	}

	oneDelivery(delivery, mode, key) {
		const modeForLower = mode == 'calendar' ? mode : 'normal';

		return (
			<div>
				<Header data={delivery} mode={modeForLower} resetData={this.setup} />
				<br />
				<Deliveries data={delivery.taskDeliveries.deliveries} mode='driverDeliveries' reload_data={this.setup} />
			</div>
		);
	}
	componentWillUnmount() {
		clearTimeout(this.intervalID);
	}

	setup = (loader = true) => {
		if (loader) {
			this.setState({ loading: true, error: false });
		}

		const { carrier, driver, date } = this.state;

		api.deliveries.driverTasks({ carrier, driver, date }).then(response => {
			this.setState({ loading: false, error: false, data: response });
			this.intervalID = handleDataUpdate(this.setup.bind(this));
		});
	}

	componentDidMount() {
		const { carrier, driver, date } = this.props;
		//carrier={tab.carrierId} driver={tab.driverId}
		let dateForState = date;
		if (typeof date == 'undefined') {
			dateForState = null;
		}
		this.setState({ carrier, driver, date: dateForState }, () => this.setup());
	}

	componentDidUpdate(prevProps) {
		if (this.props.carrier !== prevProps.carrier || this.props.driver !== prevProps.driver) {
			const { carrier, driver, date } = this.props;
			let dateForState = date;
			if (typeof date == 'undefined') {
				dateForState = null;
			}
			this.setState({ carrier, driver, date: dateForState }, () => this.setup());
		}
	}

	handleSelect(key) {
		this.setState({ key, data: null });
	}

	render() {
		const { data, error, loading } = this.state;

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length > 0 ? (
									data.map((delivery, ind) => (
										<div key={ind}>
											{this.oneDelivery(delivery, this.props.mode, ind)}
										</div>
									))
								) : (
									<NoData message='0 piegādes' />
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default DriverDeliveries;
