const checkIfValidPassword = (password, currState) => {
	const strippedPassword = password.trim();

	const specSymbols = `!"#$%&'()*+,-.:;<=>?@[]^_|~`;

	const re = {
		capital: /[A-Z]/,
		small: /[a-z]/,
		digit: /[0-9]/,
		spec: /[!"#$%&'()*+,-.:;<=>?@[\]^_|~]/
	};

	/*
	8 simboli, vismaz 1 lielais, 1 mazai, viens cipars, viens spec. Nedrīkst saturēt lietotāja vārdu un uzvārdu, uzņēmuma nosaukumu, lietotāja epasta adresi vai tās daļu, kā arī lietotāja telefona nummuru
	*/

	if (strippedPassword.length < 8) {
		return {
			passwordValid: false,
			passwordValidText: 'tai jābūt vismaz 8 simbolus garai'
		};
	}
	if (re.capital.test(strippedPassword) == false) {
		return {
			passwordValid: false,
			passwordValidText: 'tajā jābūt vismaz vienam lielajam burtam'
		};
	}
	if (re.small.test(strippedPassword) == false) {
		return {
			passwordValid: false,
			passwordValidText: 'tajā jābūt vismaz vienam mazajam burtam'
		};
	}
	if (re.digit.test(strippedPassword) == false) {
		return {
			passwordValid: false,
			passwordValidText: 'tajā jābūt vismaz vienam ciparam'
		};
	}
	if (re.spec.test(strippedPassword) == false) {
		return {
			passwordValid: false,
			passwordValidText: `tajā jābūt vismaz īpašajam simbolam (${specSymbols})`
		};
	}

	if (currState == false) {
		//lai lieki neapdeito state
		return {
			passwordValid: true,
			passwordValidText: ''
		};
	}

	return true;
};

export default checkIfValidPassword;
