import React, { Component, Suspense } from 'react';
import { Input, Label, Select, Textarea } from '../../../shared/form';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';
import { formatFullDate, allFieldsHaveValues_new, reloadPage } from '../../../util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
const MapObject = React.lazy(() => import('shared/map'));

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class StorageEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			clientSearch: false,
			options: [],
			userFavorites: {
				storage: [],
				destination: []
			},
		};
	}

	getUserFavorites = () => {
		return api.favorites.userFavorites()
			.then(response => {
				this.setState({ userFavorites: response })
			})
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { id } = this.props;

		api.admin.storage_get(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'title',
				'titleShort',
				'category',
				'storageCategoryOptions',
				'address',
				'addressID',
				'parzinis',
				'parzinisOptions',
				'contacts',
				'informingAboutDelivery',
				'changeLog',
				'lastUpdate',
				'coordinates',
				'archivedStorage'
			]);

			const attrTransformation = {
				lastUpdate: formatFullDate(moreAttrs.lastUpdate),
				address: moreAttrs.address == null ? '' : moreAttrs.address
			};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState, () => {
				this.getUserFavorites();
			});
		});
	};

	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		if (Array.isArray(key) && key.length === 1 && key[0] === 'address') {
			if (value !== undefined) {
				this.setState({ 'addressID': value.id });
			}
		}

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	handleFavorite(type, id) {
		this.setState({ favIsLoading: true });
		api.favorites.add({type, id})
			.then(() => {
				this.getUserFavorites();
				this.setState({ favIsLoading: false })
			});
	}

	saveData = e => {
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			console.log('nav ko saglabāt');
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			console.log('Data was not changed');
			return;
		}

		const defaultFields = {};
		let try2Final = {};
		const dataToSendAsDefaults = [
			'title',
			'titleShort',
			'category',
			'parzinis',
			'contacts',
			'informingAboutDelivery',
			'archivedStorage'
		];
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const addressID = 'address' in toSaveData ? toSaveData['address']['id'] : data.addressID;

		const finalToSend = Object.assign({ addressID }, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend), toSaveData);

		const nameMap = {
			title: 'Nosaukums',
			titleShort: 'Īsais nosaukums',
			category: 'Kategorija',
			parzinis: 'Pārzinis',
			contacts: 'Kontakti',
			informingAboutDelivery: 'Paziņot par piegādi',
			addressID: 'Adrese'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			[
				'title',
				'titleShort',
				'category',
				'informingAboutDelivery',
				'addressID'
			],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		if (finalToSend.informingAboutDelivery && finalToSend.parzinis < 1) {
			toast.warn(`Izvēlēta noliktavas informēšana, bet nav norādīts atbildīgais`, {
				autoClose: 7500
			});
			return;
		}

		if (id == null) {
			//new user
			api.admin.storage_create(finalToSend).then(response => {
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 4000 }
					);
				}
				console.log(response);
			});
		} else {
			api.admin.storage_save({ id, data: finalToSend }).then(response => {
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 4000 }
					);
				}
				console.log(response);
			});
		}
	};

	_handleSearchMeta(field, query, searchCB) {
		console.log(field, query, searchCB);

		this.setState({ isLoading: true });
		console.log('handle search', query);

		let searcbParams = null;

		console.log('search props', this.props);
		const { clientRegNr } = this.props;

		if (field == 'address') {
			const { clientSearch } = this.state;
			searcbParams = { query, clientSearch, includeArchived: 1 };
		}

		searchCB(searcbParams)
			.then(response => {
				console.log(response);
				this.setState({ options: response, isLoading: false });
			});
	}

	onDriverSelect(driver) {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	}

	onDeptSelect = departments => {
		const deptForSave = departments.map(dept => dept.value);
		this.setDataToState('departments', deptForSave);
	};

	getDefaultSelected(value) {
		if (value == '' || typeof value == 'undefined') {
			return [];
		}
		return [value];
	}

	mainStyle = {
		padding: '3px',
		borderLeft: '3px solid #ff9933',
		marginTop: '3px',
		fontSize: '75%'
	};
	subStyle = {
		padding: '3px',
		borderLeft: '3px solid #00CC66',
		marginTop: '3px',
		fontSize: '75%'
	};

	handleSwitch(elem, state, name) {
		const inputValue = state;
		console.log('handleSwitch. elem:', elem);
		console.log('name:', elem.props.name);
		console.log('new state:', state);

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	addrFormat(option, idx, field) {
		const mainTitle =
			field == 'storage' ? (
				<span>
					{option.title} - {option.nosaukums}
				</span>
			) : (
				<span>{option.name}</span>
			);

		return (
			<div>
				<span style={this.mainStyle} key={idx}>
					{mainTitle}
				</span>
				<br />
				{option.clients.map((cl, currKey) => (
					<span style={this.subStyle} key={currKey}>
						&nbsp;&nbsp;&nbsp;&nbsp;{cl}
					</span>
				))}
			</div>
		);
	}
	onTypeAheadSelect(result, whatWasSearched) {
		if (
			whatWasSearched == 'parzinis' &&
			typeof result !== 'undefined' &&
			Object.entries(result).length > 0
		) {
			this.setDataToState([whatWasSearched], result.value);
		} else if (result != '') {
			this.setDataToState([whatWasSearched], result);
		}
	}
	render() {
		const {
			loading,
			title,
			titleShort,
			category,
			storageCategoryOptions,
			address,
			addressID,
			parzinis,
			parzinisOptions,
			contacts,
			informingAboutDelivery,
			changeLog,
			lastUpdate,
			coordinates,
			clientSearch,
			archivedStorage,
			userFavorites,
		} = this.state;

		const isNew = this.props.id == null ? true : false;
		const storageID = this.props.id

		const destinationFav = userFavorites.destination.includes(parseInt(addressID)) ? 'gold' : '#ECEBEB';
		const storageFav = userFavorites.storage.includes(parseInt(storageID));

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Noliktavas nosaukums'
								name='title'
							/>
							<Input
								inputClass='col-sm-8'
								name='title'
								value={title}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title={
									<span>
										Noliktavas nosaukuma
										<br />
										īsā versija&nbsp;
										<OverlayTrigger
											placement='top'
											overlay={
												<Tooltip id='tooltip-right'>
													Ja šī adrese ir ievadīta, tad tā tiks izmantota
													uzdevumu nosūtīšanai uz Mappost
												</Tooltip>
											}>
											<i className='fas fa-question-circle'></i>
										</OverlayTrigger>
									</span>
								}
								name='titleShort'
							/>
							<Input
								inputClass='col-sm-8'
								name='titleShort'
								value={titleShort}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Noliktavas kategorija'
								name='category'
							/>
							<Select
								selectClass='col-sm-8'
								name='category'
								handleChange={this.handleChange}
								value={category}
								placeholder='Izvēlies vērtību'
								options={storageCategoryOptions}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Noliktavas adrese' name='address' />
							<div className='col-sm-8'>
								<AsyncTypeahead
									isLoading={this.state.isLoading}
									options={this.state.options}
									onChange={selected =>
										this.onTypeAheadSelect(selected[0], 'address')
									}
									filterBy={(option, props) => {
										return option;
									}}
									minLength={2}
									selected={this.getDefaultSelected(address)}
									labelKey='name'
									id='destinationSearch'
									clearButton={true}
									onSearch={query =>
										this._handleSearchMeta('address', query, api.search.address)
									}
									renderMenuItemChildren={(option, idx) =>
										this.addrFormat(option, idx, 'address')
									}
								/>
							</div>
							{address &&
								<span onClick={() => this.handleFavorite('destination', addressID)} style={{ cursor: 'pointer' }}
										title={destinationFav === 'gold' ? 'Pievienots favorītiem' : 'Nav pievienots favorītiem'}>
									<i className='fas fa-star'
										style={{ color: destinationFav, fontSize: 22, paddingTop: 6, marginLeft: -10 }}></i>
								</span>
							}
						</div>
						<div className='row'>
							<div className='col-sm-3'></div>
							<div className='col-sm-4'>
								<Switch
									name='clientSearch'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={clientSearch ? clientSearch : false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'clientSearch')
									}
								/>{' '}
								Meklēt starp klientiem
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Noliktavas pārzinis'
								name='parzinis'
							/>
							<div className='col-sm-8'>
								<Typeahead
									clearButton
									id='parzinisSearch'
									defaultSelected={
										parzinisOptions && parzinisOptions.length > 0
											? parzinisOptions.filter(opt => parzinis == opt.value)
											: []
									}
									onChange={selected =>
										this.onTypeAheadSelect(selected[0], 'parzinis')
									}
									options={
										parzinisOptions && parzinisOptions.length > 0
											? parzinisOptions
											: []
									}
									placeholder='Izvēlies pārzini'
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Kontakti' name='contacts' />
							<Input
								inputClass='col-sm-8'
								name='contacts'
								value={contacts}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Informēt pārzini par pasūtījumiem'
								name='informingAboutDelivery'
							/>
							<div className='col-sm-8'>
								<Switch
									name='informingAboutDelivery'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={
										informingAboutDelivery ? informingAboutDelivery : false
									}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'informingAboutDelivery')
									}
								/>
							</div>
						</div>
						{!isNew &&
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Noliktava arhivēta'
								name='archivedStorage'
							/>
							<div className='col-sm-8'>
								<Switch
									name='archivedStorage'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={
										archivedStorage ? archivedStorage : false
									}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'archivedStorage')
									}
								/>
							</div>
						</div>
					}
					{!isNew &&
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Noliktava pievienota favorītiem'
								name='storageFav'
							/>
							<div className='col-sm-8'>
								<Switch
									name='storageFav'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									value={storageFav}
									onChange={(el, state) =>
										this.handleFavorite('storage', storageID)
									}
								/>
								&nbsp;
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-right'>
											Mainot pogas stāvokli, noliktava tiks pievienota/noņemta favorītiem
											un tas automātiski tiks saglabāts datubāzē.
										</Tooltip>
									}>
									<i className='fas fa-question-circle'></i>
								</OverlayTrigger>
							</div>
						</div>
					}
						{isNew ? (
							''
						) : (
							<div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Izmaiņu vēsture'
										name='changeLog'
									/>
									<Textarea
										disabled={true}
										inputClass='col-sm-8'
										name='changeLog'
										value={changeLog}
										handleChange={this.handleChange}
									/>
								</div>
								<div className='row'>
									<Label
										labelClass='col-sm-3'
										title='Pēdējās izmaiņas'
										name='lastUpdate'
									/>
									<Input
										disabled={true}
										inputClass='col-sm-8'
										name='lastUpdate'
										value={lastUpdate}
										handleChange={this.handleChange}
									/>
								</div>
							</div>
						)}
						{checkPermission('edit', 'storage') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
						)}
						<div style={{ clear: 'both', padding: 10 }} />
						{coordinates !== null && (
							<div className='col-sm-11'>
								<Suspense fallback={null}>
									<MapObject coordinates={coordinates} />
								</Suspense>
							</div>
						)}
						<div style={{ clear: 'both' }} />
					</div>
				)}
			</div>
		);
	}
}

export default StorageEdit;
