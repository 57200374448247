class TabHandler {
	currentDay = new Date().getDate();
	getSettings = name => {
		const currentFullPage = window.location.hash.toString();

		const defaults = localStorage.getItem(
			`logistika-tabs-${name}-${this.currentDay}-${currentFullPage}`
		);
		if (defaults == null) {
			return 0;
		}

		const parsed = defaults;
		const shouldRespect = localStorage.getItem(
			`logistika-tabs-respect-${currentFullPage}-${this.currentDay}`
		);

		if (shouldRespect === 'yes') {
			//localStorage.setItem(`logistika-tabs-respect`, '');
			return parseInt(parsed);
		}

		return 0;
	};

	getDriver = name => {
		const currentFullPage = window.location.hash.toString();
		return localStorage.getItem(
			`logistika-driver-${name}-${this.currentDay}-${currentFullPage}`
		);
	};

	setRespecting = () => {
		const currentFullPage = window.location.hash.toString();
		localStorage.setItem(`logistika-tabs-respect-${currentFullPage}-${this.currentDay}`, 'yes');
	};

	setUnRespecting = () => {
		const currentFullPage = window.location.hash.toString();
		localStorage.setItem(`logistika-tabs-respect-${currentFullPage}-${this.currentDay}`, '');
	};

	saveSettings = (name, props, specificName = '') => {
		const currentFullPage = window.location.hash.toString();
		localStorage.setItem(`logistika-tabs-${name}-${this.currentDay}-${currentFullPage}`, props);
		if (specificName) {
			localStorage.setItem(
				`logistika-driver-${name}-${this.currentDay}-${currentFullPage}`,
				specificName
			);
		} else {
			localStorage.removeItem(
				`logistika-driver-${name}-${this.currentDay}-${currentFullPage}`
			);
		}

		return true;
	};
}

export default TabHandler;
