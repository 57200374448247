/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import { Input, Label, Select } from '../../../shared/form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';
import { formatFullDate, allFieldsHaveValues_new, reloadPage } from 'util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import UserSMTP from './userSMTP';
import { CustomModal } from 'shared/modal';

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class UserEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: [],
			smtpOpen: false,
			sending: false
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { id } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		api.admin.user_get(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'name',
				'surname',
				'phone',
				'billSigner',
				'email',
				'departments',
				'departmentOptions',
				'position',
				'login',
				'passwordLastChange',
				'canApproveOrdererPrice',
				'ignoreMaterialTransportationPrice',
				'canUpdateDeliveriesInBatch',
				'lastLogin',
				'role',
				'status',
				'roles',
				'entryVisibility',
				'visibilityOptions',
				'canSyncHorizon',
				'editActiveTasks',
				'receivesPriceDataEmail',
				'billFastLoad',
				'emailEnabled',
				'canSend',
				'canEditCarrierPrice',
			]);

			const attrTransformation = {
				passwordLastChange: formatFullDate(moreAttrs.passwordLastChange),
				password: '',
				password2: '',
				lastLogin: formatFullDate(moreAttrs.lastLogin)
			};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	handleSMTPToggle = () => {
		this.setState({ smtpOpen: !this.state.smtpOpen });
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, {
			[key]: value
		});

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id: userID } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {}; /*{
      'addressFrom':data.storage.title,
      'addressTo':data.delivery.title,
      'material':data.material,
      'cargoCount':1,//tā vismaz spriežu pēc vecā koda - 'uzd_cargo_count'
      'typeID':9
    }*/
		let try2Final = {};
		const dataToSendAsDefaults = [
			'name',
			'surname',
			'phone',
			'email',
			'departments',
			'position',
			'login',
			'role',
			'status',
			'password',
			'password2',
			'entryVisibility',
			'canApproveOrdererPrice',
			'ignoreMaterialTransportationPrice',
			'canUpdateDeliveriesInBatch',
			'canSyncHorizon',
			'editActiveTasks',
			'receivesPriceDataEmail',
			'billSigner',
			'billFastLoad',
			'canEditCarrierPrice',
		]; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			name: 'Vārds',
			surname: 'Uzvārds',
			phone: 'Telefons',
			email: 'E-pasts',
			departments: 'Nodaļas',
			position: 'Amats',
			login: 'login',
			role: 'Loma',
			status: 'Statuss',
			entryVisibility: 'Ierakstu redzamība',
			canSyncHorizon: 'Var sinhronizēt Horizon',
			editActiveTasks: 'Labot aktīvos braucienus',
			receivesPriceDataEmail: 'Ir cenu lapas e-pasta saņēmējs',
			billSigner: 'Rēķina paraksta persona',
			billFastLoad: 'Rēķinu ātrā ielāde',
			canEditCarrierPrice: 'Var labot pārvadātāja cenu',
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			[
				'name',
				'surname',
				'phone',
				'email',
				'departments',
				'position',
				'login',
				'role',
				'status',
				'entryVisibility',
				'canSyncHorizon',
				'editActiveTasks',
				'canEditCarrierPrice',
				'billSigner',
				'billFastLoad',
				'receivesPriceDataEmail',
			],
			finalToSend,
			nameMap
		);
		// @ts-ignore
		if (confirmAllFieldsHasValues.status == false || finalToSend.departments.length < 1) {
			//,"showNotesInBill"
			//console.log('Jāaizpilda visi lauki: ', finalToSend, defaultFields, data, this.state);
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		this.setState({ sending: true });

		if (userID == null) {
			//new user
			api.admin.user_create(finalToSend).then(response => {
				this.setState({ sending: false });
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 8000 }
					);
				}
			});
		} else {
			api.admin.user_save({ id: userID, data: finalToSend }).then(response => {
				this.setState({ sending: false });
				if (response.status == 'ok') {
					toast.success('Dati saglabāti');

					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(
						`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
						{ autoClose: 8000 }
					);
				}
			});
		}
	};

	onDriverSelect(driver) {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	}

	onDeptSelect = departments => {
		// if (departments != "") {
		const deptForSave = departments.map(dept => dept.value);
		this.setDataToState('departments', deptForSave);
		// }
	};

	handleSwitch = (elem, state, name) => {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, {
			[name]: inputValue
		});

		this.setState({ toSaveData, [name]: inputValue });
	};

	handleResendSetToken = () => {
		api.admin.user_send_set_token(this.props.id).then(response => {
			this.setState({ sending: false });
			if (response.status == 'ok') {
				toast.success('E-pasts nosūtīts');

				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 8000
				});
			}
		});
	};

	render() {
		const {
			data,
			name,
			loading,
			surname,
			phone,
			email,
			position,
			login,
			password,
			password2,
			departments,
			receivesPriceDataEmail,
			departmentOptions,
			canEditCarrierPrice,
			role,
			roles,
			status,
			passwordLastChange,
			lastLogin,
			visibilityOptions,
			entryVisibility,
			canApproveOrdererPrice,
			ignoreMaterialTransportationPrice,
			canUpdateDeliveriesInBatch,
			canSyncHorizon,
			billSigner,
			billFastLoad,
			emailEnabled,
			canSend,
			smtpOpen,
			editActiveTasks,
			sending
		} = this.state;
		const { id } = this.props;

		//const {}

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						{id === 0 && !emailEnabled && (
							<div className='alert alert-danger'>
								<strong>Uzmanību!</strong> Nedarbojas e-pastu sūtīšana (lietotājs
								nesaņems e-pastu par lietotāja izveidi loģistikas sistēmā)! E-pastu
								varēsiet nosūtīt vēlāk
							</div>
						)}
						<div className='row'>
							<Label labelClass='col-sm-3' title='Vārds' name='name' />
							<Input
								inputClass='col-sm-8'
								name='name'
								value={name}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Uzvārds' name='surname' />
							<Input
								inputClass='col-sm-8'
								name='surname'
								value={surname}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Telefons' name='phone' />
							<Input
								inputClass='col-sm-8'
								name='phone'
								value={phone}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='E-pasts' name='email' />
							<Input
								inputClass='col-sm-8'
								name='email'
								value={email}
								handleChange={this.handleChange}
							/>
						</div>

						<div className='row'>
							<Label labelClass='col-sm-3' title='Nodaļas' name='departments' />
							<div className='col-sm-8'>
								<Typeahead
									clearButton
									id='deptSelect'
									defaultSelected={
										departmentOptions && departmentOptions.length > 0
											? departmentOptions.filter(opt =>
													departments.includes(opt.value)
											)
											: []
									}
									multiple
									onChange={selected => this.onDeptSelect(selected)}
									options={
										departmentOptions && departmentOptions.length > 0
											? departmentOptions
											: []
									}
									placeholder='Izvēlies nodaļas'
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Amats' name='position' />
							<Input
								inputClass='col-sm-8'
								name='position'
								value={position}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Lietotājvārds' name='login' />
							<Input
								inputClass='col-sm-8'
								name='login'
								value={login}
								handleChange={this.handleChange}
							/>
						</div>
						{/* id == 0 &&<div>
        <div className="row">
            <Label labelClass={"col-sm-3"} title={"Parole"} name={"password"}/>
            <Input inputClass={"col-sm-8"} type={"password"} name={"password"} value={password} handleChange={this.handleChange} />
        </div>
        <div className="row">
            <Label labelClass={"col-sm-3"} title={"Parole (atkārtoti)"} name={"password2"}/>
            <Input inputClass={"col-sm-8"} type={"password"} name={"password2"} value={password2} handleChange={this.handleChange} />
        </div>
        </div>
         */}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Pēdējoreiz pieslēdzies'
								name='lastLogin'
							/>
							<Input
								inputClass='col-sm-8'
								disabled={true}
								name='lastLogin'
								value={lastLogin}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Parole pēdējoreiz mainīta'
								name='passwordLastChange'
							/>
							<Input
								inputClass='col-sm-8'
								disabled={true}
								name='passwordLastChange'
								value={passwordLastChange}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Sistēmas loma' name='role' />
							<Select
								selectClass='col-sm-8'
								name='role'
								handleChange={this.handleChange}
								value={role}
								placeholder='Izvēlies vērtību'
								options={roles && roles.length > 0 ? roles : []}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Ierakstu redzamība'
								name='entryVisibility'
							/>
							<Select
								selectClass='col-sm-8'
								name='entryVisibility'
								handleChange={this.handleChange}
								value={entryVisibility}
								placeholder='Izvēlies vērtību'
								options={visibilityOptions ? visibilityOptions : []}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Statuss' name='status' />
							<Select
								selectClass='col-sm-8'
								name='status'
								handleChange={this.handleChange}
								value={status}
								placeholder='Izvēlies vērtību'
								options={[
									{ value: 0, label: 'aktīvs' },
									{ value: 1, label: 'bloķēts' }
								]}
							/>
						</div>
						<div style={{ columnCount: 2 }}>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Drīkst sinhronizēt datus ar Horizon'
									name='canSyncHorizon'
								/>
								<div className='col-sm-2'>
									<Switch
										name='canSyncHorizon'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={canSyncHorizon ? canSyncHorizon : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'canSyncHorizon')
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Ignorēt materiāla transportēšanas cenu'
									name='ignoreMaterialTransportationPrice'
								/>
								<div className='col-sm-2'>
									<Switch
										name='ignoreMaterialTransportationPrice'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={ignoreMaterialTransportationPrice ? ignoreMaterialTransportationPrice : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'ignoreMaterialTransportationPrice')
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Var labot pārvadātāja cenu'
									name='canEditCarrierPrice'
								/>
								<div className='col-sm-2'>
									<Switch
										name='canEditCarrierPrice'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={canEditCarrierPrice ? canEditCarrierPrice : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'canEditCarrierPrice')
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Var apstiprināt pasūtītāja cenu'
									name='canApproveOrdererPrice'
								/>
								<div className='col-sm-2'>
									<Switch
										name='canApproveOrdererPrice'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={canApproveOrdererPrice ? canApproveOrdererPrice : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'canApproveOrdererPrice')
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Rēķina paraksta persona'
									name='billSigner'
								/>
								<div className='col-sm-2'>
									<Switch
										name='billSigner'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={billSigner ? billSigner : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'billSigner')
										}
									/>
								</div>
								{billSigner && (
									<div className='col-sm-5'>
										<button
											type='button'
											disabled={false}
											className='btn btn-primary'
											onClick={this.handleSMTPToggle}>
											Labot SMTP datus
										</button>
									</div>
								)}
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Rēķinu ātrā ielāde'
									name='billFastLoad'
								/>
								<div className='col-sm-2'>
									<Switch
										name='billFastLoad'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={billFastLoad ? billFastLoad : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'billFastLoad')
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Aktīvu un arhivētu braucienu labošana'
									name='editActiveTasks'
								/>
								<div className='col-sm-2'>
									<Switch
										name='editActiveTasks'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={editActiveTasks ? editActiveTasks : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'editActiveTasks')
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Ir cenu lapas e-pasta saņēmējs'
									name='receivesPriceDataEmail'
								/>
								<div className='col-sm-2'>
									<Switch
										name='receivesPriceDataEmail'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={receivesPriceDataEmail ? receivesPriceDataEmail : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'receivesPriceDataEmail')
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-8'
									title='Var veikt masveida braucienu labošanu'
									name='canUpdateDeliveriesInBatch'
								/>
								<div className='col-sm-2'>
									<Switch
										name='canUpdateDeliveriesInBatch'
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										defaultValue={canUpdateDeliveriesInBatch ? canUpdateDeliveriesInBatch : false}
										onChange={(el, state) =>
											this.handleSwitch(el, state, 'canUpdateDeliveriesInBatch')
										}
									/>
								</div>
							</div>
						</div>
						<div className='btn-group' role='group'>
							{canSend && emailEnabled && (
								<button
									type='button'
									className='btn btn-default'
									onClick={this.handleResendSetToken}>
									<i className='fas fa-file'></i> Nosūtīt apstiprināšanas saiti
								</button>
							)}
							{checkPermission('edit', 'users') && (
								<button
									disabled={sending} // || (id === 0 && !emailEnabled)
									type='button'
									className='btn btn-primary'
									onClick={this.saveData}>
									Saglabāt!
								</button>
							)}
						</div>
						<CustomModal
							title='SMTP datu labošana'
							showModal={smtpOpen}
							handleCloseModal={this.handleSMTPToggle}
							content={<UserSMTP id={id} handleCloseModal={this.handleSMTPToggle} />}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default UserEdit;
