import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const Table = ({ data, archiver, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	const columns = [
		{
			title: 'Arhivēt',
			field: 'id',
			width: 85,
			headerSort: false,
			cellClick: (e, cell) => archiver(e, cell),
			formatter: () => `<i class="fas fa-archive fa-lg"></i>`
		},
		{
			title: 'Noliktava',
			field: 'storage.name',
			//headerFilter: 'input',
			width: 180
		},
		{
			title: 'Piegādes adrese',
			field: 'destination.name',
			//headerFilter: 'input',
			width: 250
		},
		{
			title: 'Materiāls',
			field: 'material.title',
			//headerFilter: 'input',
			width: 180
		},
		{
			title: 'Cena',
			field: 'price'
		},
		{
			title: 'Mērvienība',
			field: 'priceTitle',
			//headerFilter: 'input',
			width: 180
		},
		{
			title: 'Pasūtījuma nr.',
			field: 'orderNR',
			//headerFilter: 'input',
			width: 180
		},
		{
			title: 'Pasūtījuma<br />datums',
			field: 'orderAdded'
		}
	];

	let {storage, destination, material, priceTitle, orderNR} = filters;
	storage = storage.toLowerCase();
	destination = destination.toLowerCase();
	material = material.toLowerCase();
	priceTitle = priceTitle.toLowerCase();
	orderNR = orderNR.toLowerCase();
	
	const filterObj = {storage, destination, material, priceTitle, orderNR};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === null) {
				return true;
			}

			let whatToSearch = entry[key];

			if (key === 'destination') {
				whatToSearch = entry.destination.name;
			}

			if (key === 'material') {
				whatToSearch = entry.material.title;
			}

			if (key === 'storage') {
				whatToSearch = entry.storage.name;
			}
			
			if (whatToSearch === undefined || !whatToSearch.toString().toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;
  
	})

	return (
		<Tabulator
			columns={columns}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
