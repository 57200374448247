import api from '../api';

const deliveries = {
	drivers: () => api.get(`deliveries/drivers`),

	driverTasks: ({ carrier, driver, date }) =>
		api.get(`deliveries/tasks_driver?carrier=${carrier}&driver=${driver}&date=${date}`),

	overview: () => api.get(`deliveries/izpilde`),

	list: () => api.get(`deliveries/tasks_list`),

	kravas: ({ page, rows }) => api.get(`deliveries/delivered?page=${page}&rows=${rows}`),

	calendarMonth: ({ year, month }) => api.get(`calendar/get_calendar?year=${year}&mon=${month}`),

	calendarDay: date => api.get(`calendar/get_driver_list?date=${date}`),

	archive: ({ page, rows, client }) =>
		api.get(`deliveries/archive?page=${page}&rows=${rows}&client=${client}`),

	main: ({ page, rows, client }) =>
		api.get(`deliveries/main?page=${page}&rows=${rows}&client=${client}`),

	deliveryOrder: orderId => api.get(`deliveries/specific?order=${orderId}`),

	forma: formID => api.get(`bol/${formID}`),
	forma_upd: ({ data }) => api.post(`bol/save`, { data }),

	editInfo: ({ delivery, task }) => api.get(`deliveries/info?delivery=${delivery}&task=${task}`),

	//planning: section => api.get(`deliveries/planosana?section=${section}`),

	planning2: () => api.get(`deliveries/planning`),

	orderDeliveries: orderId => api.get(`deliveries/order_deliveries?order=${orderId}`),

	update: ({ delivery, data }) => api.post(`deliveries/update`, { delivery, data }),

	updateStatus: ({ delivery, status }) => api.post(`deliveries/update_status`, { delivery, status }),

	updateInvoice: ({ delivery, data }) => api.post(`deliveries/update_invoice`, { delivery, data }),

	delete: delivery => api.post(`deliveries/delete`, { delivery }),

	massUpdate: (data) => api.post(`deliveries/batch_update`, data),

	clientProblematic: ({ client, page }) =>
		api.get(`deliveries/problematic?client=${client}&page=${page}`),

	clientInvoiceReady: ({ client, page }) =>
		api.get(`deliveries/invoice_ready?client=${client}&page=${page}`),

	actions: ({ delivery, task }) => api.get(`deliveries/actions?delivery=${delivery}&task=${task}`),

	sendEmail: ({ data }) => api.post(`deliveries/email/send`, { data }),
};

export default deliveries;
