/* eslint-disable react/jsx-max-depth */
import React, { Component, useEffect, useState } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import { Input, Label, Select, Textarea } from 'shared/form';
import { toast } from 'react-toastify';
import { Collapse } from 'react-collapse';

import ErrorOnLoading from 'shared/errorOnLoad';
import NoData from 'shared/noDataNotification';
import { formatNumber } from 'util';
import { CustomModal } from 'shared/modal';
import EmailTemplateEdit from './forms/email_templates';
import './prices.css';
import { fullCarPrice } from 'sections/orders/edit/helpers';

const getLargeDistanceSteps = ({ firstStepMin, firstStepMax, max, step }) => {
	const toRet = [];

	let currMax = firstStepMax;

	toRet.push([firstStepMin, currMax]);

	while (currMax < max) {
		currMax += step;
		const currMinimal = currMax - step + 1;

		toRet.push([currMinimal, currMax]);
	}

	return toRet;
};

const OneTableComplect = ({ basePrice, volume, category }) => {
	const smallDistances = [30, 40, 50, 60, 70, 80, 100];
	const largeDistances = getLargeDistanceSteps({
		firstStepMin: 101,
		firstStepMax: 130,
		max: 510,
		step: 20
	});

	const columnDefinitions = {
		AAL: [
			<>Cena EUR par paleti<br />(Fpl&Epl) pilnai kravai</>,
			<>Reisa cena EUR</>,
			<>Cena EUR par paleti<br />(Fpl&Epl) saliktai kravai</>,
		],
		_default: [
			<>Cena EUR par t<br />pilnai kravai ({volume} t)</>,
			<>Reisa cena EUR<br />pilnai kravai ({volume} t)</>,
			<>Cena EUR par t<br />saliktai kravai</>,
		]
	}

	const columns = columnDefinitions[category] ?? columnDefinitions['_default'];

	return (
		<div className='prices row'>
			<div className='col-md-6 one_container'>
				<div className='table_label'>Transporta izmaksas līdz 100 km vienā virzienā</div>
				<table className='table table-hover table-condensed table-bordered'>
					<thead>
						<tr />
						<tr>
							<th style={{ width: 105 }}>Km vienā virzienā (aplis)</th>
							{columns.map((col, idx) => <th key={idx} style={{ width: 150 }}>{col}</th>)}
						</tr>
					</thead>
					<tbody>
						{smallDistances.map((distance, idx) => {
							const fullCarPriceVal = fullCarPrice({
								minAmount: volume,
								price: basePrice,
								distance
							});

							const ridePrice = volume ? volume * fullCarPriceVal : 0;
							const rangeStart = idx > 0 ? smallDistances[idx - 1] + 1 : 1;

							return (
								<tr key={distance}>
									<td>
										{rangeStart}-{distance} km
									</td>
									<td>{formatNumber(fullCarPriceVal, 2)}</td>
									<td>{formatNumber(ridePrice, 2)}</td>
									<td>{formatNumber(fullCarPriceVal*1.2, 2)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className='col-md-6 one_container'>
				<div className='table_label'>Transporta izmaksas no 100 km vienā virzienā</div>
				<table className='table table-hover table-condensed table-bordered'>
					<thead>
						<tr />
						<tr>
							<th style={{ width: 105 }}>Km vienā virzienā</th>
							<th style={{ width: 150 }}>
								Cena EUR par t<br />
								pilnai kravai ({volume} t)
							</th>
							<th style={{ width: 100 }}>
								Reisa cena EUR
							</th>
						</tr>
					</thead>
					<tbody>
						{largeDistances.map(distance => {
							const [minDist, maxDist] = distance;
							const fullCarPriceVal = fullCarPrice({
								minAmount: volume,
								price: basePrice,
								distance: maxDist
							});
							const ridePrice = volume ? volume * fullCarPriceVal : 0;

							return (
								<tr key={maxDist}>
									<td>
										{minDist}-{maxDist} km
									</td>
									<td>{formatNumber(fullCarPriceVal, 2)}</td>
									<td>{formatNumber(ridePrice, 2)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const MaterialCategory = ({ data, toggleIcon, setOpen, id, checkOpen }) => {
	const mainData = data.main;

	return (
		<>
			{mainData &&
				<div>
					<div className='material-title-large' onClick={() => setOpen(id)}>
						<span>{toggleIcon(id)}</span> {data.name} {mainData && <small>(cena spēkā no {mainData.active_from})</small>}
					</div>

					<Collapse isOpened={checkOpen(id)} theme={{ collapse: 'accordion-collapse' }}>
						<OneTableComplect basePrice={mainData.price} volume={mainData.volume} category={data.name} />
					</Collapse>
				</div>
			}

			{data.rows && data.rows.length > 0 && (
				<div>
					<div className='material-list'>
						{data.rows.map(material => (
							<div key={material.material_id} style={{ marginBottom: 3 }}>
								<div className='material-title-small' onClick={() => setOpen(id + '_' + material.material_id)}>
									<span>{toggleIcon(id + '_' + material.material_id)}</span> {material.material_name} <small>(cena spēkā no {material.active_from})</small>
								</div>
								<Collapse isOpened={checkOpen(id + '_' + material.material_id)} theme={{ collapse: 'accordion-collapse' }}>
									<OneTableComplect
										basePrice={material.price}
										volume={material.volume}
										category={data.name}
									/>
								</Collapse>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

const CATEGORY = 'PRICE_INFO_FOR_USERS';

const EmailSendModal = () => {
	const [templates, setTemplates] = useState([]);
	const [template, setTemplate] = useState('');
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [previewOpen, setPreviewOpen] = useState(false);

	useEffect(() => {
		setLoading(true);

		api.admin
			.get_email_templates(CATEGORY)
			.then(response => {
				setTemplates(response);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const toggleTemplate = () => {
		setPreviewOpen(!previewOpen);
	};

	const handleSend = () => {
		if (template === '') {
			toast.warn(`Nav izvēlēta e-pasta teksta veidne`, {
				autoClose: 4000
			});
			return;
		}
		setSending(true);
		api.admin
			.sendPriceListEmail({ template })
			.then(response => {
				if (response.status === 'success') {
					toast.success(`E-pasts nosūtīts`);
				} else {
					toast.warn(`E-pasts netika nosūtīts`, {
						autoClose: 4000
					});
				}
			})
			.finally(() => {
				setSending(false);
			});
	};

	return (
		<div>
			{!loading && (
				<>
					<div className='form-group row'>
						<label htmlFor='template' className='col-sm-4 col-form-label'>
							E-pasta teksta veidne
						</label>
						<Select
							id='template'
							selectClass='col-sm-6'
							name='category'
							handleChange={event => setTemplate(event.target.value)}
							value={template}
							placeholder='Izvēlies veidni'
							options={templates.map(category => ({
								value: category.id,
								label: category.name
							}))}
						/>
						<button
							type='button'
							className='col-sm-2 btn btn-primary'
							disabled={template === ''}
							onClick={toggleTemplate}>
							Priekšstatīt
						</button>
					</div>
				</>
			)}
			<br />
			<button
				type='button'
				className='btn btn-primary'
				onClick={handleSend}
				disabled={sending}>
				Sūtīt
			</button>
			<CustomModal
				title='E-pasta veidnes priekšstatījums'
				showModal={previewOpen}
				handleCloseModal={toggleTemplate}
				content={<EmailTemplateEdit id={template} preview={true} />}
			/>
		</div>
	);
};

class PriceSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: false,
			showModal: false,
			data: {},
			open: [],
		};
	}

	timeout = null;

	setupData() {
		this.setState({ loading: true, error: false });

		api.orders.currentPrices().then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleCollapse = id => {
		const currState = this.state.open;
		let newState = this.state.open;
		if (currState.includes(id)) {
			newState = newState.filter(item => item !== id);
		} else {
			newState = [...newState, id];
		}

		this.setState({ open: newState });
	};

	checkCollapseState = id => {
		return this.state.open.includes(id);
	};

	toggleIcon = id => {
		return this.checkCollapseState(id) ? (
			<i className='fas fa-chevron-up' />
		) : (
			<i className='fas fa-chevron-down' />
		);
	};

	render() {
		const { data, error, loading, showModal } = this.state;

		if (error) {
			return (
				<div className='bodyWrapper'>
					<ErrorOnLoading />
				</div>
			);
		}

		if (loading) {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		if (Object.keys(data).length < 1) {
			return (
				<div className='bodyWrapper'>
					<NoData />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				<div className='rightFloat'>
					<button
						type='button'
						className='btn btn-primary'
						onClick={() => this.handleToggleModal()}>
						Izsūtīt e-pastu
					</button>
				</div>
				<div style={{ marginBottom: 20 }}>&nbsp;</div>
				{Object.keys(data).map(materialCategoryId => (
					<div key={materialCategoryId} style={{ marginBottom: 15 }}>
						<MaterialCategory
							id={materialCategoryId}
							data={data[materialCategoryId]}
							setOpen={this.handleCollapse}
							toggleIcon={this.toggleIcon}
							checkOpen={this.checkCollapseState}
						/>
					</div>
				))}
				<CustomModal
					title='E-pasta izsūtīšana'
					showModal={showModal}
					handleCloseModal={this.handleToggleModal}
					content={<EmailSendModal />}
				/>
			</div>
		);
	}
}

export default PriceSummary;
