import api from '../api';

const tasks = {
	changeStatus: ({ task, state }) => api.post(`tasks/change_task_state`, { task, state }),

	changeMappostStatus: ({ task, state }) =>
		api.post(`tasks/change_mappost_state`, { task, state }),

	dataForMultitask: orders => api.get(`tasks/info_for_multi?orders=${orders}`),

	dataForEdit: task => api.get(`tasks/info?task=${task}`),

	edit: ({ task, data }) => api.post(`tasks/update`, { task, data }),

	saveMultitask: data => api.post(`tasks/multitask_save`, { data })
};

export default tasks;
