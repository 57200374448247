import React, { Component } from 'react';
import RoleTable from './table';
import api from '../../../api';
import Loading from 'shared/loading';
import { toast } from 'react-toastify';
import ErrorOnLoading from 'shared/errorOnLoad';

class Roles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			headers: [
				'Sadaļa',
				'Apskate',
				'Labot',
				'Pievienot',
				'Dzēst',
				'Eksportēt',
				'Sinhronizēt',
				'Sūtīt'
			],
			data: [],
			title: '',
			description: '',
			active: 1,
			permissions: []
		};
	}

	componentDidMount() {
		this.setData();
	}

	setData() {
		this.setState({ loading: true, error: false });

		const { role } = this.props;

		api.roles.data(role).then(response => {
			const { title, description, active, permissions } = response;
			this.setState({
				loading: false,
				error: false,
				title,
				description,
				active,
				permissions
			});
		});
	}

	handleChange = (group, newRoles) => {
		const { permissions } = this.state;

		let newData = permissions;
		newData[group].roles = newRoles;

		this.setState({ permissions: newData });
	};

	saveData = () => {
		const { permissions } = this.state;
		const { role } = this.props;

		api.roles.savePerms({ role, permissions }).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
			} else {
				toast.warn('Dati netika laboti!', { autoClose: 7500 });
			}
		});
	};

	handleCheckAll = sectionIndex => () => {
		/*
        const {data} = this.state;

        const newPerms = data.map(item => {
            const {group, roles} = item;

        });
        */
	};

	render() {
		const { loading, error, title, description, active, permissions } = this.state;

		if(error) {
			return <ErrorOnLoading />;
		}

		if(loading) {
			return <Loading />;
		}

		return (
			<div className='bodyWrapper'>
				<div>
					<h2>
						{title} <small>({active == 1 ? 'aktīva' : 'neaktīva'})</small>
					</h2>
					<h4>{description}</h4>
					<br />
					{permissions.map((section, ind) => (
						<div key={ind}>
							<h4>{section.group}</h4>
							<div>
								<RoleTable
									groupIndex={ind}
									group={section.group}
									roles={section.roles}
									onDataChange={this.handleChange}
								/>
							</div>
						</div>
					))}
					<br />
					<button
						disabled={false}
						className='btn btn-success'
						onClick={this.saveData}>
						Saglabāt
					</button>
				</div>
			</div>
		);
	}
	//    <button className="" onClick={this.handleCheckAll(ind)}>Atzīmēt visu</button>
}

export default Roles;
