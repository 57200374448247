import React from 'react';

const NoData = props => {
	let message = 'Nav datu';

	if (props) {
		message = props.message;

		if (message == null) {
			message = 'Nav datu';
		}
	}

	return (
		<span>
			<i className='fas fa-folder-open'></i> {message}
		</span>
	);
};

export default NoData;
