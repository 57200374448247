//http://jsfiddle.net/infiniteluke/908earbh/9/
import React, { Component } from 'react';
import Table from './table';
import './planning.scss';
import styles from './planning.module.css';
import NoData from 'shared/noDataNotification.js';
import { connect } from 'react-redux';
import { sectionDataSelector } from './slice';

const subdirectionTitle = ({ title, address, phone }) => {
	return `${title}, ${address}, ${phone}`;
};

class Planning extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			expanded: []
		};
	}

	handleToggleClick = (directionId) => {
		const isExpanded = this.state.expanded.includes(directionId);
		const newExpanded = isExpanded ? this.state.expanded.filter(e => e !== directionId) : [...this.state.expanded, directionId]
		this.setState({expanded: newExpanded})
	}

	render() {
		const { data } = this.props;
		const {expanded} = this.state;

		if (!data || data.length === 0) {
			return (
				<div style={{ height: '80vh' }}>
					<NoData />
				</div>
			);
		}

		return (
			<div style={{ height: '80vh' }}>
				{data.map((direction, ind) => (
					<div key={ind} className='direction'>
						<span className={styles.directionTitle}>{direction.directionTitle} <span><button onClick={() => this.handleToggleClick(direction.directionId)} type="button" className="btn btn-default btn-xs">{expanded.includes(direction.directionId) ? <span className="glyphicon glyphicon glyphicon-minus" aria-hidden="true"></span> : <span className="glyphicon glyphicon glyphicon-plus" aria-hidden="true"></span>}</button></span></span>
						<div className='direction-wrapper'>
							{direction?.parishes?.length > 0 && expanded.includes(direction.directionId) &&
								direction.parishes.map((parish, subInd) => (
									<div key={subInd}>
										{parish.length > 0 &&
											parish.map(
												(subdirection, subdirInd) =>
													subdirection?.deliveries?.subdeliveries?.length >
														0 && (
														<div key={subdirInd} className='subdirection'>
															<span className='title'>
																{subdirectionTitle(
																	subdirection.deliveries
																)}
															</span>
															<div>
																<Table
																	data={
																		subdirection.deliveries
																			.subdeliveries
																	}
																/>
																<div className='clearBoth' />
															</div>
														</div>
													)
											)}
									</div>
								))}
							</div>
					</div>
				))}
			</div>
		);
	}
}

const makeMapStateToProps = () => {
	const mapStateToProps = state => {
		return {
			data: sectionDataSelector(state)
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(Planning);
