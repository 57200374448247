/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../shared/form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '@api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import { toast } from 'react-toastify';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { checkPermission } from '../../permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE, invoicePDFlink } from '../../config';
import { reloadPage, isNumeric } from 'util';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import { Collapse } from 'react-collapse';

const formatAddress = addressData => {
	return addressData.title;
	//{"id":1,"short_name":"LATRAPS LPKS - ELEJA","address":"LATRAPS LPKS - ELEJA","title":"LATRAPS LPKS - LIETUVAS IELA 16A, ELEJA, ELEJAS PAG., JELGAVAS NOV., LV-3023","phone":"","mappostId":31416}
	//{"address":2304,"title":"PUTRAS, UKRU PAG., AUCES NOV., LV-3729","phone":"29479324,26302305","contacts":"PUTRAS Z.S., VĪTOLS ULDIS","mappostId":34458}
};

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key) && key in initialData) {
			dataForReturn[key] =
				initialData[key] == null
					? ''
					: typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

const driverFormat = (option, props, idx) => {
	return (
		<span style={{ fontSize: '75%' }} key={idx}>
			{option.driverName}{' '}
			{option.mappostUserID > 0 ? (
				<span style={{ color: 'green' }}>(MAPPOST lietotājs)</span>
			) : (
				<span style={{ color: '#ff0000' }}>(Nav reģistrēts MAPPOST sistēmā)</span>
			)}{' '}
			- {option.client} - {option.vilcejs}
		</span>
	);
};

const deliveryIncludedInPriceFormatter = data => {
	const { deliveryIncludedInPrice, billID, billIDHash } = data;
	let style, text, link;

	if (deliveryIncludedInPrice) {
		style = { color: 'green' };
		text = 'piegāde iekļauta produkta cenā';
	} else {
		if (billIDHash != '') {
			link = billIDHash;
			text = billID;
			style = {};
		} else {
			text = 'Piegāde nav iekļauta rēķinā!';
			style = { color: '#ff0000' };
		}
	}

	return { link, text, style };
};

const validateDrivers = options => {
	for (let i = 0; i < options.length; i++) {
		if (
			typeof options[i]['driverName'] == 'undefined' ||
			options[i]['driverName'] == null ||
			options[i]['driverName'] == ''
		) {
			return false;
		}
	}

	return true;
};

class DeliveryEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: [],
			hoursInputVisible: false,
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { delivery, task } = this.props;
		/*
    const delivery = '8178';//šis jāpadod no this.props;
    const task = '3446';//šis jāpadod no this.props;
    */
		api.deliveries.editInfo({ delivery, task }).then(response => {
			//ko darīt ar driverData? + pg_parbaudita_gatava_iekl_rekina
			const moreAttrs = getDataFromAPIResponse(response, [
				'uzkrauts',
				'izkrauts',
				'ordererPriceApprove',
				'amountToDeliver',
				'deliveredInReality',
				'price',
				'priceFor',
				'routeLength',
				'travelTime',
				'ordererPrice',
				'workingHours',
				'dikstavePrice',
				'dikstave_h',
				'overworkPrice',
				'ordererPriceFor',
				'madeLength',
				'overworkHours',
				'piekabe',
				'crm',
				'extraNr',
				'cmr',
				'deliveryNotes',
				'deliveryIncludedInPrice',
				'deliveryCheckedForBill',
				'deliveryExpenses',
				'st',
				'driver',
				'gadget',
				'incomingInvoice',
				'invoiceNr',
				'invoiceDate',
				'invoiceTotal',
				'invoiceReceived'
			]);

			const deliveryPriceFormat = deliveryIncludedInPriceFormatter(response);

			const attrTransformation = {
				deliveryIncludedStyle: deliveryPriceFormat['style'],
				deliveryIncludedText: deliveryPriceFormat['text'],
				uzkrauts: moment(moreAttrs['uzkrauts']).format('DD.MM.YYYY.'),
				izkrauts: moment(moreAttrs['izkrauts']).format('DD.MM.YYYY.'),
				invoiceDate: moment(moreAttrs['invoiceDate']).format('DD.MM.YYYY.'),
				billLink: deliveryPriceFormat['link']
			};
			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);

			const deliveryExpenses = parseFloat(newState['deliveryExpenses']).toFixed(2);

			if(this.state.incomingInvoice) {
				this.setDataToState('invoiceTotal', !this.state.invoiceTotal ? deliveryExpenses : this.state.invoiceTotal);
			}
		});
	};

	getDefaultSelected(value) {
		if (value == '' || typeof value == 'undefined') {
			return [];
		}
		return [value];
	}
	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleDateChange = val => event => {
		try {
			this.setDataToState(val, event.format('YYYYMMDD'));
		} catch (err) {
			return;
		}
	};

	handleChange = event => {
		let inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (
			inputValue.length > 0 &&
			[
				'amountToDeliver',
				'deliveredInReality',
				'price',
				'madeLength',
				'ordererPrice',
				'workingHours',
				'dikstavePrice',
				'dikstave_h',
				'overworkPrice',
				'overworkHours',
				'invoiceTotal'
			].includes(name)
		) {
			if (isNumeric(inputValue) == false) {
				//  inputValue = parseFloat(inputValue).toFixed(3);
				//} else {
				return false;
			}

			inputValue = inputValue.trim();
		}

		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//{"piekabe":"AAA","uzkrauts":"20181129","izkrauts":"20190403","amountToDeliver":25,"deliveredInReality":"3","dikstave_h":"5","overworkHours":"5","overworkPrice":"50","dikstavePrice":"50","ordererPrice":"3","price":"6","crm":"gfgdfgfgd","deliveryNotes":"29.11.2018 \nNo rīta uzkraujamies... Cenas iepriekšējās !","delivery":"123","task":"456","driver":{"mappostUserID":12598,"carrierDriverID":376,"driverName":"AIGARS BUTKEVIČS","driverId":281,"vilcejs":"JJ1570","client":"OZOLIŅI JO, SIA","carrierId":200}}
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { delivery, task } = this.props;
		const { toSaveData, data } = this.state;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika izmainīti');
			return;
		}

		const defaultFields = { delivery, task, measurementUnit: data.measurementUnitId };
		//{"piekabe":"sdfffs","uzkrauts":"20181129","izkrauts":"20190411","amountToDeliver":25,"deliveredInReality":0,"dikstave_h":0,"overworkHours":0,"overworkPrice":0,"dikstavePrice":0,"ordererPrice":0,"price":0,"crm":"lkjljklkjk","deliveryNotes":"29.11.2018 \nNo rīta uzkraujamies... Cenas iepriekšējās !","priceFor":1,"madeLength":0,"ordererPriceFor":1,"workingHours":0,"deliveryCheckedForBill":false,"deliveryIncludedInPrice":true,"delivery":64091,"task":21750,"driver":{"mappostUserID":13158,"carrierDriverID":688,"driverName":"AIGARS GARKALNS","driverId":79,"vilcejs":"KR983","client":"A M UNA, SIA","carrierId":562}}
		const dataToSendAsDefaults = [
			'ordererPriceApprove',
			'driver',
			'piekabe',
			'uzkrauts',
			'izkrauts',
			'amountToDeliver',
			'deliveredInReality',
			'routeLength',
			'travelTime',
			'dikstave_h',
			'overworkHours',
			'overworkPrice',
			'dikstavePrice',
			'ordererPrice',
			'price',
			'crm',
			'extraNr',
			'cmr',
			'deliveryNotes',
			'priceFor',
			'madeLength',
			'ordererPriceFor',
			'workingHours',
			'deliveryCheckedForBill',
			'deliveryIncludedInPrice',
			'st',
			'incomingInvoice',
			'invoiceNr',
			'invoiceDate',
			'invoiceTotal',
			'invoiceReceived'
		]; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga

		//todo: šo laikam varētyu apvienot ar reallyChanged masīva izveidi
		let try2Final = {};

		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const driverInfo = 'driver' in toSaveData ? toSaveData.driver : data.driver;

		const finalToSend = Object.assign({}, try2Final, defaultFields, { driver: driverInfo });

		console.log('to save', JSON.stringify(finalToSend));

		//vēl skatīt datu validāciju (validateDeliveryCostFields) - saveNewTaskSingle funkcija
		//return;
		api.deliveries.update({ delivery, data: finalToSend }).then(response => {
			if (response.status == 'error') {
				toast.warn(response.message, { autoClose: 10000 });
			} else if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				setRespectTabs();
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas! ${response.message}`, { autoClose: 7000 });
			}
		});
	};

	_handleSearch = query => {
		this.setState({ isLoading: true });
		const mappost = this.state.gadget == 1 ? true : null;
		api.search.drivers({ query, mappost }).then(response => {
			this.setState({ options: response, isLoading: false });
		});
	};

	onDriverSelect = driver => {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	};

	handleSwitch(elem, state, name) {
		const inputValue = state;
		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		if(name == 'incomingInvoice') {
			if(inputValue) {
				this.setDataToState('invoiceTotal', !this.state.invoiceTotal ? this.state.deliveryExpenses : this.state.invoiceTotal);
			} else {
				this.setDataToState('invoiceTotal', '');
			}
		}

		this.setState({ toSaveData, [name]: inputValue });
	}

	toggleHoursVisibility = () => {
		this.setState({ hoursInputVisible: !this.state.hoursInputVisible });
	};

	toggleIcon = () => {
		return this.state.hoursInputVisible ? (
			<i className='fas fa-chevron-up' />
		) : (
			<i className='fas fa-chevron-down' />
		);
	};

	render() {
		const {
			uzkrauts,
			izkrauts,
			loading,
			amountToDeliver,
			data,
			price,
			priceFor,
			ordererPriceFor,
			ordererPrice,
			dikstavePrice,
			overworkPrice,
			deliveredInReality,
			routeLength,
			travelTime,
			madeLength,
			ordererPriceApprove,
			workingHours,
			dikstave_h,
			overworkHours,
			piekabe,
			crm,
			extraNr,
			cmr,
			deliveryNotes,
			deliveryCheckedForBill,
			deliveryIncludedInPrice,
			deliveryIncludedStyle,
			deliveryIncludedText,
			deliveryExpenses,
			billLink,
			driver,
			options,
			incomingInvoice,
			invoiceNr,
			invoiceDate,
			invoiceTotal,
			hoursInputVisible,
		} = this.state;

		const { readOnlyMode } = this.props;
		const { options: opt1, data: dat1, ...stateToShow } = this.state;

		const greenBGColor = 'greenyellow';
		const redBGColor = '#FA8072';

		const invoiceReceived = invoiceNr && (invoiceDate && invoiceDate !== 'Invalid date') && invoiceTotal;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							{/*<pre>{JSON.stringify(this.state, null, "\t")}</pre>*/}
							{/*<pre>{JSON.stringify(stateToShow, null, "\t")}</pre>*/}
							{/*<Label labelClass={"col-sm-3"} title={"Info"} name={"info"}/>*/}
							<div className='deliveryEditInfo'>
								<div
									className='deliveryEditInfoEntry'
									style={{ backgroundColor: '#ccc' }}>
									{data.orderedAmount}
									<br />
									Pasūtījuma daudzums
								</div>
								<div
									className='deliveryEditInfoEntry'
									style={{ backgroundColor: '#FFCCCC', color: '#FF0033' }}>
									{data.remaining}
									<br />
									Pasūtījuma atlikums
								</div>
								<div
									className='deliveryEditInfoEntry'
									style={{ backgroundColor: '#FFCC66' }}>
									{data.amountInTasks}
									<br />
									Uzdevumos atdots
								</div>
								<div
									className='deliveryEditInfoEntry'
									style={{ backgroundColor: '#CCFF99' }}>
									{data.deliveredInReality}
									<br />
									Aizvests pēc fakta
								</div>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Šoferis' name='driver' />
							<div className='col-sm-9' id='driverSearchEdit'>
								{/*options.length>0 && validateDrivers(options) ? */}
								<AsyncTypeahead
									isLoading={this.state.isLoading}
									options={options}
									labelKey='driverName'
									filterBy={(option, props) => {
										return option;
									}}
									//filterBy={['driverName', 'client']}
									selected={this.getDefaultSelected(driver)}
									onChange={selected => this.onDriverSelect(selected[0])}
									minLength={0}
									id='driverSearch'
									clearButton={true}
									onSearch={this._handleSearch}
									placeholder='Meklēt šoferi'
									renderMenuItemChildren={(option, props, idx) =>
										driverFormat(option, props, idx)
									}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Piekabe' name='piekabe' />
							<Input
								inputClass='col-sm-9'
								name='piekabe'
								value={piekabe}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Uzkrauts' name='uzkrauts' />
							<div className='col-sm-9'>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange('uzkrauts')}
									defaultValue={uzkrauts !== 'Invalid date' ? uzkrauts : ''}
								/>
							</div>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Izkrauts' name='izkrauts' />
							<div className='col-sm-9'>
								<Datetime
									locale='lv'
									timeFormat={false}
									closeOnSelect={true}
									onChange={this.handleDateChange('izkrauts')}
									defaultValue={izkrauts !== 'Invalid date' ? izkrauts : ''}
								/>
							</div>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Nosūtīts daudzums'
								name='amountToDeliver'
							/>
							<Input
								inputClass='col-sm-6'
								name='amountToDeliver'
								value={amountToDeliver}
								handleChange={this.handleChange}
							/>
							<Label
								labelClass='col-sm-3'
								title={data.measurementUnit}
								name='amountToDeliver'
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Saņemts pēc fakta'
								name='deliveredInReality'
							/>
							<Input
								inputClass='col-sm-6'
								inputStyle={
									parseFloat(deliveredInReality) > 0
										? {}
										: { borderColor: redBGColor }
								}
								name='deliveredInReality'
								value={deliveredInReality}
								handleChange={this.handleChange}
							/>
							<Label
								labelClass='col-sm-3'
								title={data.measurementUnit}
								name='measurementUnit'
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Pārvadātāja cena' name='price' />
							<Input
								inputClass='col-sm-6'
								inputStyle={
									parseFloat(price) > 0 ? {} : { borderColor: redBGColor }
								}
								name='price'
								value={price}
								handleChange={this.handleChange}
							/>
							<Select
								selectClass='col-sm-3'
								inputStyle={
									parseInt(priceFor) > 1 ? {} : { borderColor: redBGColor }
								}
								handleChange={this.handleChange}
								name='priceFor'
								value={priceFor}
								placeholder='Izvēlies vērtību'
								options={
									data
										? data.priceForData.map(price => ({
												label: price.par,
												value: price.par_id
										  }))
										: []
								}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Nobraukti' name='madeLength' />
							<Input
								inputClass='col-sm-6'
								inputStyle={
									parseFloat(madeLength) > 0 ? {} : { borderColor: redBGColor }
								}
								name='madeLength'
								value={madeLength}
								handleChange={this.handleChange}
							/>
							<Label labelClass='col-sm-3' title='km' name='madeLength' />
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Klienta rēķina cena'
								name='ordererPrice'
							/>
							<Input
								inputClass='col-sm-6'
								inputStyle={
									parseFloat(ordererPrice) > 0 ? {} : { borderColor: redBGColor }
								}
								name='ordererPrice'
								value={ordererPrice}
								handleChange={this.handleChange}
							/>
							<Select
								selectClass='col-sm-3'
								inputStyle={
									parseInt(ordererPriceFor) > 1 ? {} : { borderColor: redBGColor }
								}
								handleChange={this.handleChange}
								name='ordererPriceFor'
								value={ordererPriceFor}
								placeholder='Izvēlies vērtību'
								options={
									data
										? data.priceForData.map(price => ({
												label: price.par,
												value: price.par_id
										  }))
										: []
								}
							/>
							{/* priceFor - šeit un "Pārvadātāja cena" jābūt atšķirīgiem - atsevišķi select */}
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Klienta rēķina cena ir apstiprināta'
								name='ordererPriceApprove'
							/>
							<div className='col-sm-3'>
								<Switch
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={
										ordererPriceApprove
									}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'ordererPriceApprove')
									}
								/>
							</div>
						</div>

						<div style={{ cursor: 'pointer' }} onClick={() => this.toggleHoursVisibility()}>
							<label className='control-label' style={{ cursor: 'pointer' }}>Stundu ievade {this.toggleIcon()}</label>
						</div>
						<Collapse isOpened={hoursInputVisible} theme={{ collapse: 'accordion-collapse' }}>
							<div className='row'>
								<Label labelClass='col-sm-3' title='Nostrādātas' name='workingHours' />
								<Input
									inputClass='col-sm-6'
									name='workingHours'
									value={workingHours}
									handleChange={this.handleChange}
								/>
								<Label labelClass='col-sm-3' title='h' name='workingHours' />
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-3'
									title='Dīkstāves cena'
									name='dikstavePrice'
								/>
								<Input
									inputClass='col-sm-6'
									name='dikstavePrice'
									value={dikstavePrice}
									handleChange={this.handleChange}
								/>
								<Label labelClass='col-sm-3' title='€/h' name='dikstavePrice' />
							</div>
							<div className='row'>
								<Label labelClass='col-sm-3' title='Dīkstāvē' name='dikstave_h' />
								<Input
									inputClass='col-sm-6'
									name='dikstave_h'
									value={dikstave_h}
									handleChange={this.handleChange}
								/>
								<Label labelClass='col-sm-3' title='h' name='dikstave_h' />
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-3'
									title='Virsstundas cena'
									name='overworkPrice'
								/>
								<Input
									inputClass='col-sm-6'
									name='overworkPrice'
									value={overworkPrice}
									handleChange={this.handleChange}
								/>
								<Label labelClass='col-sm-3' title='€/h' name='overworkPrice' />
							</div>
							<div className='row'>
								<Label labelClass='col-sm-3' title='Virsstundas' name='overworkHours' />
								<Input
									inputClass='col-sm-6'
									name='overworkHours'
									value={overworkHours}
									handleChange={this.handleChange}
								/>
								<Label labelClass='col-sm-3' title='h' name='overworkHours' />
							</div>
						</Collapse>

						<div className='row'>
							<Label labelClass='col-sm-3' title='Pārvietošanas akta nr.' name='crm' />
							<Textarea
								inputClass='col-sm-9'
								name='crm'
								value={crm}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Papildnumurs' name='extraNr' />
							<Input
								inputClass='col-sm-9'
								name='extraNr'
								value={extraNr}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='CMR' name='cmr' />
							<Input
								inputClass='col-sm-9'
								name='cmr'
								value={cmr}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Ienākošais rēķins'
								name='incomingInvoice'
							/>
							<div className='col-sm-3'>
								<Switch
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={incomingInvoice}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'incomingInvoice')
									}
								/>
							</div>
						</div>
						{incomingInvoice && <>
							<div className='row'>
								<Label labelClass='col-sm-3' title='Saņemtā rēķina numurs' name='invoiceNr' />
								<Input
									inputClass='col-sm-9'
									name='invoiceNr'
									value={invoiceNr}
									handleChange={this.handleChange}
								/>
							</div>
							<div className='row'>
								<Label labelClass='col-sm-3' title='Saņemtā rēķina datums' name='invoiceDate' />
								<div className='col-sm-9'>
									<Datetime
										locale='lv'
										timeFormat={false}
										closeOnSelect={true}
										onChange={this.handleDateChange('invoiceDate')}
										defaultValue={invoiceDate !== 'Invalid date' ? invoiceDate : ''}
									/>
								</div>
							</div>
							<div className='row'>
								<Label labelClass='col-sm-3' title='Saņemtā rēķina rindas summa' name='invoiceTotal' />
								<Input
									inputClass='col-sm-9'
									name='invoiceTotal'
									value={invoiceTotal}
									handleChange={this.handleChange}
								/>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-3'
									title='Saņemts ienākošais rēķins'
									name='invoiceReceived'
								/>
								<div className='col-sm-3'>
									<Switch
										offColor='warning'
										onColor='success'
										onText='Jā'
										offText='Nē'
										value={invoiceReceived ? true : false}
										readonly={true}
									/>
								</div>
							</div>
						</>}
						<div className='row'>
							<Label labelClass='col-sm-3' title='Piezīmes' name='deliveryNotes' />
							<Textarea
								inputClass='col-sm-9'
								name='deliveryNotes'
								value={deliveryNotes}
								handleChange={this.handleChange}
							/>
						</div>
						{/* style={deliveryIncludedInPrice ? {cursor:'not-allowed'} : {}} */}
						<div className='row'>
						<Label
							labelClass='col-sm-3'
							title='Dati ir pārbaudīti un gatavi iekļaušanai rēķinā'
							name='deliveryCheckedForBill'
						/>
						<div className='col-sm-3'>
							<Switch
								offColor='warning'
								onColor='success'
								onText='Jā'
								offText='Nē'
								defaultValue={
									deliveryCheckedForBill ? deliveryCheckedForBill : false
								}
								onChange={(el, state) =>
									this.handleSwitch(el, state, 'deliveryCheckedForBill')
								}
								disabled={
									deliveryIncludedInPrice ? deliveryIncludedInPrice : false
								}
								readonly={
									deliveryIncludedInPrice ? deliveryIncludedInPrice : false
								}
							/>
						</div>
					</div>
						{data.mappostImageURL && data.mappostImageURL !== '' ? (
							<div className='row'>
								<Label
									labelClass='col-sm-3'
									title='Šofera pievienotais attēls'
									name='mappostImageURL'
								/>
								<div
									className='col-sm-3'
									dangerouslySetInnerHTML={{ __html: data.mappostImageURL }}
								/>
							</div>
						) : (
							''
						)}
						<div className='row'>
							<Label
								labelClass='col-sm-3'
								title='Iekļauts rēķinā'
								name='deliveryIncludedInPrice'
							/>
							<div className='col-sm-3'>
								<span style={deliveryIncludedStyle}>
									{billLink ? (
										<a
											href={invoicePDFlink + billLink}
											target='_blank'
											rel='noopener noreferrer'>
											{deliveryIncludedText}
										</a>
									) : (
										deliveryIncludedText
									)}
								</span>
							</div>
						</div>
						<button
							type='button'
							disabled={
								readOnlyMode || !checkPermission('add') || !checkPermission('edit')
							}
							className='btn btn-primary'
							onClick={this.saveData}>
							Saglabāt!
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default DeliveryEdit;
