import { toast } from 'react-toastify';
import formatUrl from './formatUrl';
//import * as Sentry from '@sentry/browser';

//https://stackoverflow.com/questions/35417507/how-to-make-a-global-error-handler-in-redux-and-override-it-when-needed
const api = {
	async fetch(url, options) {
		const { url: finalUrl, options: finalOptions } = formatUrl({ url, options });
		let request = await fetch(finalUrl, finalOptions);
		let response = null;
		let throwError = null;
		try {
			let contentType = request.headers.get('Content-Type');
			if (contentType && contentType.indexOf('application/json') !== -1) {
				response = await request.json();
			} else {
				response = await request.text();
			}

			switch (request.status) {
				case 200:
				case 201:
					break;
				default:
					throwError = response || request.statusText;
					break;
			}
		} catch (error) {
			throwError = error;
			toast.error('Serveris atgrieza kļūdu!', { autoClose: 7500 });
			//Sentry.captureMessage('Something went wrong');
		}

		if (throwError) {
			if (typeof throwError === 'string') {
				throwError = { message: throwError };
			}
			toast.error(`Serveris atgrieza kļūdu!: ${JSON.stringify(throwError)}`, {
				autoClose: 7500
			});
			throw throwError;
		}

		return response;
	},

	get(url, params = {}) {
		return this.fetch(url, { method: 'get', params });
	},

	post(url, data) {
		return this.fetch(url, { method: 'post', body: data });
	},

	put(url, data) {
		return this.fetch(url, { method: 'put', body: data });
	},

	delete(url, data) {
		return this.fetch(url, { method: 'delete', body: data });
	}
};

export default api;
