export const calculatePrice = ({ amount, minAmount, price, distance, materialCategory }) => {
	const distanceOneDirection = distance / 2;
	const carPrice = fullCarPrice({price, distance: distanceOneDirection, minAmount});

	// console.log('DSFDFDFDF', { amount, minAmount, price, distance, distanceOneDirection, carPrice, ddd: amount < 20 })
	if (materialCategory !== 'AAL' && amount < 20) {
		return carPrice * 1.2;
	}

	// console.log('calculatePrice', { amount, minAmount, price, distance: distanceOneDirection })

	return carPrice;
}

const roundNumber = inputNumber => {
	const decimalPortion = inputNumber % 1;
	const fullNumber = Math.trunc(inputNumber);

	if (decimalPortion <= 0.25) {
		return fullNumber;
	}

	if (decimalPortion <= 0.75) {
		return fullNumber + 0.5;
	}

	return fullNumber + 1;
}

export const fullCarPrice = ({price, distance, minAmount}) => {
	// funkcijas darbība izsekojama LATR-23 specifikācijā
	const distanceToTakeIntoAccount = getClosestSmall(distance);

	// console.log('fullCarPrice', { distanceToTakeIntoAccount, minAmount, price })

	if (!distanceToTakeIntoAccount) {
		return 0;
	}

	if (distanceToTakeIntoAccount <= 30) {
		return 3;
	}

	if (minAmount === null || minAmount === 0) {
		return 0;
	}

	// ja max distance ir pēdējā no mazo km tabulas, tad rēķinam ar 90 km
	// ja max distance ir pirmā no lielo km tabulas, tad rēķinam tāpat kā 1. tabulas pēdējai rindai
	const distanceChanges = {
		100: 90,
		130: 90
	}

	const distanceToTake = distanceChanges[distanceToTakeIntoAccount] ?? distanceToTakeIntoAccount;

	if (distanceToTakeIntoAccount > 130) {
		// lielo km tabulai rēķināšana cita:
		// paņem lielo km tabulas pirmo ierakstu (100-130)
		// un tai cenai pieskaita tik EUR, cik ir bijušas rindas lielo km tabulā
		// basically - katra nākamā rinda ir par +1 EUR lielāka cena
		const priceFor130km = fullCarPrice({price, distance: 130, minAmount});
		const toAdd = Math.round(distanceToTakeIntoAccount / 20) - 7;
		return roundNumber(priceFor130km + toAdd);
	}

	// console.log(distanceToTake, price, ((price * distanceToTake) / minAmount) * 2)
	// nākamās rindas komentāru vēlams nedzēst, lai var saprast, kā rēķiņās "finalPrice" ;)
	// ((Materiāla transporta cena par km*attāluma grupas lielākā vērtība)/ Pārvadāšanas apjoms tonnās)*2
	const finalPrice = roundNumber(((price * distanceToTake) / minAmount) * 2);

	return finalPrice;
}

export const getCargoNaming = cargoCount => {
	return cargoCount % 10 === 1 && cargoCount % 100 !== 11 ? 'kravas' : 'kravu';
}

export const getClosestSmall = inputKm => {

	const smallDistances = [30, 40, 50, 60, 70, 80, 100];

	for(const i in smallDistances) {
		// console.log('getClosestSmall', {inputKm, i, smallDistances, cmp: smallDistances[i], smaller: inputKm <= smallDistances[i]})
		if (inputKm <= smallDistances[i]) {
			return smallDistances[i];
		}
	}

	const MAX_DISTANCE = 10000;
	const DISTANCE_ONE_STEP = 20;

	let currMax = 130;
	while(true) {
		if (currMax > MAX_DISTANCE) { // safety measure
			return null;
		}
		if (inputKm <= currMax) {
			return currMax;
		}

		currMax += DISTANCE_ONE_STEP;
	}
}

/*
calculatePrice({amount: '32.000', minAmount: 25, price: 2.45, distance: 10000})
calculatePrice({amount: '32.000', minAmount: 25, price: 2.45, distance: 1000})
calculatePrice({amount: '32.000', minAmount: 25, price: 2.45, distance: 9500})
calculatePrice({amount: '32.000', minAmount: 25, price: 2.45, distance: 550})
calculatePrice({amount: '32.000', minAmount: 25, price: 2.45, distance: 125})
calculatePrice({amount: '32.000', minAmount: 25, price: 2.45, distance: 95})
calculatePrice({amount: '32.000', minAmount: 25, price: 2.45, distance: 50})
 */
