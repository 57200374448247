export const convertColumn = (data) => {
	const {title, headerSort, field, width, formatter, headerTooltip, titleFormatter, cellClick} = data;


	return {
		Header: title,
		accessor: field,
		width
		,
		Cell: formatter
	}
}
