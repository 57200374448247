import React, { Component } from 'react';
import { toast } from 'react-toastify';
import api from '@api';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';

import './directions.css';

import Reorder from 'react-reorder';

//https://stackoverflow.com/a/5306832
function array_move(arr, old_index, new_index) {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
}

class DirectionsEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			options: [],
			error: false
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });

		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		//{
		api.admin.directions_get().then(response => {
			this.setState({ options: response, loading: false, error: false });
		});
	};

	componentDidMount() {
		this.setData();
	}

	onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
		//this.props.updateOrder(previousIndex, nextIndex);

		const { options } = this.state;

		const newArray = array_move(options, previousIndex, nextIndex);

		const newState = Object.assign({}, this.state, { options: newArray });

		this.setState(newState);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { options } = this.state;

		api.admin.directions_save(options).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				//this.setData();
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	render() {
		const { loading, options, error } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<h2>Piegādes virzienu secības maiņa</h2>
						<div className='directionContainer'>
							<Reorder
								reorderId='waypoint-list'
								reorderGroup='reorder-group'
								draggedClassName='dragged'
								lock='horizontal'
								holdTime={200}
								touchHoldTime={200}
								mouseHoldTime={200}
								onReorder={this.onReorder}
								autoScroll={true}
								//disabled={waypointCount < 2 ? true : false}
								disableContextMenus={true}>
								{options.length > 0
									? options.map(direction => (
											<div key={direction.title} className='directionItem'>
												{direction.title}
											</div>
									  ))
									: ''}
							</Reorder>
						</div>

						{checkPermission('edit', 'smtp') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default DirectionsEdit;
