import React from 'react';

const tableSummary = data => {
	return (
		<div className='deliveryEditInfo'>
			<div className='deliveryEditInfoEntry' style={{ backgroundColor: '#F0FFFF' }}>
				{data.numberOfResults || 0}
				<br />
				Pasūtījumu skaits
			</div>
			<div className='deliveryEditInfoEntry' style={{ backgroundColor: '#ccc' }}>
				{data.orderedAmount || 0}
				<br />
				Pasūtīts
			</div>
			<div
				className='deliveryEditInfoEntry'
				style={{ backgroundColor: '#FFCCCC', color: '#FF0033' }}>
				{data.remainder || 0}
				<br />
				Atlikums
			</div>
			<div className='deliveryEditInfoEntry' style={{ backgroundColor: '#FFCC66' }}>
				{data.inTasks || 0}
				<br />
				Uzdevumos atdots
			</div>
			<div className='deliveryEditInfoEntry' style={{ backgroundColor: '#CCFF99' }}>
				{data.deliveredByFact || 0}
				<br />
				Aizvests
			</div>
		</div>
	);
};

export default tableSummary;
