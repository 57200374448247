import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const deliveryDatesFormatter = cell => {
	const { start, end } = cell.getRow().getData();
	if (start === end) {
		return start;
	}

	return `${start} - ${end}`;
};

const materialsFormatter = cell => {
	const { materials } = cell.getRow().getData();

	return materials.join(', ');
};

const apstradesFormatter = cell => {
	const { apstradesLaiks } = cell.getRow().getData();

	if (apstradesLaiks > 10) {
		cell.getElement().style.backgroundColor = 'lightcoral';
	}

	return apstradesLaiks;
};

const columns = [
	{
		title: 'Klients',
		field: 'client',
		//headerFilter: 'input'
	},
	{
		title: 'Pārvadājuma datums',
		formatter: cell => deliveryDatesFormatter(cell),
		field: 'start'
	},
	{
		title: 'Produkcija',
		field: 'materials',
		headerSort: false,
		formatter: cell => materialsFormatter(cell),
		//headerFilter: 'input',
		width: 250
	},
	{
		title: 'Rēķins',
		field: 'billNr',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Rēķins<br />izrakstīts',
		field: 'invoiceDate'
	},
	{
		title: 'Apstrādes laiks',
		field: 'apstradesLaiks',
		formatter: cell => apstradesFormatter(cell)
	},
	{
		title: 'Summa<br />bez PVN',
		field: 'invoice_wo_pvn'
	},
	{
		title: 'PVN',
		field: 'invoice_pvn'
	},
	{
		title: 'Summa ar PVN',
		field: 'invoice_sum'
	},
];

const Table = ({ data, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let {billNr, materials, client} = filters;
	billNr = billNr.toLowerCase();
	materials = materials.toLowerCase();
	client = client.toLowerCase();

	const filterObj = {billNr, materials, client};

	let finalData = data.filter(entry => {
		console.log(entry, entry[key])
		for (var key in filterObj) {
			const whatToSearch = typeof entry[key] === 'object' ? entry[key].join() : entry[key];
			if (whatToSearch === undefined || !whatToSearch.toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;

	})

	return (
		<Tabulator
			columns={columns}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
