/* eslint-disable react/jsx-max-depth */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Input, Label, Select, Textarea } from '../../../../shared/form';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import './style.scss';
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TableContext } from "./plugins/TablePlugin";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import { $getRoot, $getSelection, $createParagraphNode, $createTextNode } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import TableCellResizer from './plugins/TableCellResizer';

const exampleTheme = {
	ltr: 'ltr',
	rtl: 'rtl',
	placeholder: 'editor-placeholder',
	paragraph: 'editor-paragraph',
	quote: 'editor-quote',
	heading: {
		h1: 'editor-heading-h1',
		h2: 'editor-heading-h2',
		h3: 'editor-heading-h3',
		h4: 'editor-heading-h4',
		h5: 'editor-heading-h5',
		h6: 'editor-heading-h6'
	},
	list: {
		nested: {
			listitem: 'editor-nested-listitem'
		},
		ol: 'editor-list-ol',
		ul: 'editor-list-ul',
		listitem: 'editor-listitem'
	},
	image: 'editor-image',
	link: 'editor-link',
	text: {
		bold: 'editor-text-bold',
		italic: 'editor-text-italic',
		overflowed: 'editor-text-overflowed',
		hashtag: 'editor-text-hashtag',
		underline: 'editor-text-underline',
		strikethrough: 'editor-text-strikethrough',
		underlineStrikethrough: 'editor-text-underlineStrikethrough',
		code: 'editor-text-code'
	},
	code: 'editor-code',
	table: "table",
	tableAddColumns: "tableAddColumns",
	tableAddRows: "tableAddRows",
	tableCell: "tableCell",
	tableCellActionButton: "tableCellActionButton",
	tableCellActionButtonContainer: "tableCellActionButtonContainer",
	tableCellEditing: "tableCellEditing",
	tableCellHeader: "tableCellHeader",
	tableCellPrimarySelected: "tableCellPrimarySelected",
	tableCellResizer: "tableCellResizer",
	tableCellSelected: "tableCellSelected",
	tableCellSortedIndicator: "tableCellSortedIndicator",
	tableResizeRuler: "tableCellResizeRuler",
	tableSelected: "tableSelected",
	codeHighlight: {
		atrule: 'editor-tokenAttr',
		attr: 'editor-tokenAttr',
		boolean: 'editor-tokenProperty',
		builtin: 'editor-tokenSelector',
		cdata: 'editor-tokenComment',
		char: 'editor-tokenSelector',
		class: 'editor-tokenFunction',
		'class-name': 'editor-tokenFunction',
		comment: 'editor-tokenComment',
		constant: 'editor-tokenProperty',
		deleted: 'editor-tokenProperty',
		doctype: 'editor-tokenComment',
		entity: 'editor-tokenOperator',
		function: 'editor-tokenFunction',
		important: 'editor-tokenVariable',
		inserted: 'editor-tokenSelector',
		keyword: 'editor-tokenAttr',
		namespace: 'editor-tokenVariable',
		number: 'editor-tokenProperty',
		operator: 'editor-tokenOperator',
		prolog: 'editor-tokenComment',
		property: 'editor-tokenProperty',
		punctuation: 'editor-tokenPunctuation',
		regex: 'editor-tokenVariable',
		selector: 'editor-tokenSelector',
		string: 'editor-tokenSelector',
		symbol: 'editor-tokenProperty',
		tag: 'editor-tokenProperty',
		url: 'editor-tokenOperator',
		variable: 'editor-tokenVariable'
	}
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
	console.error(error);
}

function Editor({ initState, setText, variables, category }) {
	const [editor] = useLexicalComposerContext();
	const [html, setHtmlContent] = useState('');
	const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

	editor.update(() => {
		const htmlString = $generateHtmlFromNodes(editor, null);
		// console.log('htmlString', htmlString);
		// @todo: remove hack
		if (html !== htmlString) {
			setHtmlContent(htmlString);
		}
	});

	const initialConfig = {
		namespace: 'MyEditor',
		theme: exampleTheme,
		onError,
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			QuoteNode,
			CodeNode,
			CodeHighlightNode,
			TableNode,
			TableCellNode,
			TableRowNode,
			AutoLinkNode,
			LinkNode
		]
	};

	if (initState) {
		initialConfig.editorState = initState;
	}

	const onRef = _floatingAnchorElem => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const onButtonClick = text => {
		// todo: implement
	};

	return (
		<div>
				<div className='latraps_editor'>
				<TableContext>
					<div className='editor-container'>
						<ToolbarPlugin category={category}/>
					<TablePlugin />
					<TableCellResizer />
						<div className='editor-inner'>
							<RichTextPlugin
								contentEditable={<ContentEditable className='editor-input' />}
								placeholder={null}
								ErrorBoundary={LexicalErrorBoundary}
							/>
							<HistoryPlugin />
							<AutoFocusPlugin />
							<ListPlugin />
							<OnChangePlugin
								onChange={editorState => {
									editorState.read(() => {
										const value = JSON.stringify(editorState);
										setText(value, html);
									});
								}}
							/>
							<HistoryPlugin />
						</div>
					</div>
        {/*floatingAnchorElem &&
          <>
            <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
          </>
         */}
      </TableContext>
				</div>
		</div>
	);
}

function Wraper({ initState, setText, variables, category }) {
	const initialConfig = {
		namespace: 'MyEditor',
		theme: exampleTheme,
		onError,
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			QuoteNode,
			CodeNode,
			CodeHighlightNode,
			TableNode,
			TableCellNode,
			TableRowNode,
			AutoLinkNode,
			LinkNode
		]
	};

	if (initState) {
		initialConfig.editorState = initState;
	}

	const onButtonClick = text => {
		// todo: implement
	};

	const handleTextChange = useCallback(
		(jsonString, html) => {
			setText(jsonString, html);
		},
		[initState]
	);

	return (
		<div>
			{variables.length > 0 && (
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title='Pieejamie mainīgie veidnes tekstam'
						name='template'
					/>
					<div className='col-sm-8'>
						{variables.map(item => (
							<span
								className = {item.endsWith('edit') ? 'editable_variable_badge' : 'variable_badge'}
								// style={{ cursor: 'pointer' }}
								// onClick={() => onButtonClick(item)}
								title={item.endsWith('edit') ? 'Rediģējams ievades formā' : 'Nerediģējams'}
								key={item}>
								{item.replace('_edit', '')}
							</span>
						))}
					</div>
				</div>
			)}

			<LexicalComposer initialConfig={initialConfig}>
				<Editor initState={initState} setText={handleTextChange} variables={variables} category={category} />
			</LexicalComposer>
		</div>
	);
}

export default Wraper;
