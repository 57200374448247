import React, { Component } from 'react';

import api from '@api';
import Tabulator from 'shared/table/Tabulator';
import NoData from 'shared/noDataNotification';
import { Input, Label, Select, Textarea } from 'shared/form';
import Loading from 'shared/loading';
import { customTitle } from 'util';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import EmailTemplateEdit from './forms/email_templates';

const CATEGORIES = [
	{name: 'Cenu lapu e-pasts LLIS lietotājiem', code: 'PRICE_INFO_FOR_USERS'},
	{name: 'Brauciena pavadzīmes e-pasts pārvadātājam', code: 'EMAIL_TEXT_INVOICE_CARRIER'},
	{name: 'Brauciena pavadzīmes e-pasts noliktavai', code: 'EMAIL_TEXT_INVOICE_STORAGE'},
	{name: 'Brauciena pavadzīmes e-pasts citiem', code: 'EMAIL_TEXT_INVOICE_SPECIFIC'},
	{name: 'Brauciena pavadzīmes PDF teksts', code: 'EMAIL_PDF_LADING'},
	{name: 'Mazās pavadzīmes PDF teksts', code: 'EMAIL_PDF_LADING_SMALL'},
	{name: 'Plānošanas pasūtījuma paziņojums', code: 'PLANNING_ORDER_NOTIFICATION'},
];

const actionsButtonFormatter = () => actionButton;

class EmailTemplates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			category: 'PRICE_INFO_FOR_USERS',
			templates: [],
			showModal: false,
			editId: null
		};
	}

	setData = () => {
		if (this.state.category === '') {
			this.setState({templates: []});
			return;
		}
		this.setState({ loading: true });

		api.admin.get_email_templates(this.state.category).then(response => {
			this.setState({templates: response});
		}).finally(() => {
			this.setState({ loading: false });
		})
	};

	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.category !== prevState.category) {
			this.setData();
		}
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setState({ [name]: inputValue });
	};

	handleOpenModal(openType, cell) {
		const id = openType == 'new' ? null : cell.getRow().getData().id;

		this.setState({ showModal: true, editId: id });
	}

	columns = [
		{
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		},
		{
			title: 'Nosaukums',
			field: 'name',
		},
		{
			title: 'Apraksts',
			field: 'description',
		}
	];

	handleCloseModal = (reloadData = false) => {
		if (reloadData) {
			this.setData();
		}
		this.setState({ showModal: false });
	};

	render() {
		const { loading, category, templates, showModal, editId: id } = this.state;
		const categoryName = category === '' ? '' : CATEGORIES.find(e => e.code === category).name;

		if (loading) {
			return <div className='bodyWrapper'><Loading /></div>
		}

		return (
			<div className='bodyWrapper'>
				<h2>Teksta veidnes</h2>

				<Select
					selectClass='col-sm-3'
					name='category'
					handleChange={this.handleChange}
					value={category}
					placeholder='Izvēlies kategoriju'
					options={CATEGORIES.map(category => ({
						value: category.code,
						label: category.name
					}))}
				/>
				<br />
				<h3>{categoryName}</h3>
				<button
					type='button'
					className='btn btn-primary'
					onClick={() => this.handleOpenModal('new', null)}>
					Pievienot jaunu veidni
				</button>
				{(categoryName !== '' && templates.length === 0) && <NoData />}
				{templates.length > 0 && <div className='tableWrapper'>
									<Tabulator
										columns={this.columns}
										options={{ pagination: false }}
										data={templates}
									/>
								</div>}
				<CustomModal
					title={
						id == null
							? 'Veidnes pievienošana'
							: 'Veidnes labošana'
					}
					showModal={showModal}
					handleCloseModal={(ev, reload) => this.handleCloseModal(reload)}
					content={
						<EmailTemplateEdit
							id={id}
							category={category}
							handleCloseModal={(ev, reload) => this.handleCloseModal(reload)}
						/>
					}
				/>
			</div>
		);
	}
}

export default EmailTemplates;
