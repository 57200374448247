import React, { Component } from 'react';
import Tabulator from '../../../shared/table/Tabulator';
import api from '../../../api';
import Loading from '../../../shared/loading';
import { actionButton } from '../../../shared/icons';
import { CustomModal } from '../../../shared/modal';
import RoleEdit from './roleEdit';
import ErrorOnLoading from '../../../shared/errorOnLoad';
import { customTitle } from '../../../util';
import NoData from '../../../shared/noDataNotification';

const actionsButtonFormatter = () => actionButton;

class RoleAdministration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			currTask: null,
			error: false,
			title: ''
		};
	}

	handleOpenActions = cell => {
		const { id } = cell.getRow().getData();

		this.setState({ showModal: true, roleId: id }); //,userId:id
	};

	setupData() {
		this.setState({ loading: true, error: false });

		api.roles.get_all().then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	columns = [
		{
			title: 'Nosaukums',
			field: 'title',
			width: 250,
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-users', '#333334')
		},
		{
			title: 'Darbības',
			headerSort: false,
			field: 'id',
			cellClick: (e, cell) => this.handleOpenActions(cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		}
		//{ title: 'Apraksts', field: 'description' },
	];

	handleCloseModal = () => {
		this.setState({ showModal: false });
	};

	render() {
		const { data, error, loading, showModal, roleId } = this.state;

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<NoData />
								) : (
									<div className='tableWrapper'>
										<Tabulator
											columns={this.columns}
											options={{ pagination: false }}
											data={data}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				)}
				<CustomModal
					title='Lomas datu labošana'
					showModal={showModal}
					handleCloseModal={this.handleCloseModal}
					content={<RoleEdit role={roleId} />}
				/>
			</div>
		);
	}
}

export default RoleAdministration;
