import React, {Component} from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import { Input, Radio, Select, Label } from '../../../shared/form';
import Switch from 'react-bootstrap-switch';
import { checkPermission } from '../../../permissions';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import { toast } from 'react-toastify';

class Invoice extends Component {
	handleInvoiceSave = () => {
		const { toSaveData, data, deliveryId: delivery } = this.props;

		const origStatus = {
			invoiceNr: data.invoiceNr,
			invoiceDate: data.invoiceDate,
			invoiceTotal: data.invoiceTotal,
		}

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Rēķina dati netika izmainīti!');
			return;
		}

		let reallyChanged = {};
		for (let key in toSaveData) {
			if (toSaveData[key] !== origStatus[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Rēķina dati netika izmainīti!');
			return;
		}

		const dataToSendAsDefaults = [
			'invoiceNr',
			'invoiceDate',
			'invoiceTotal',
		];

		let finalToSend = {};
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				finalToSend[theKey] = reallyChanged[theKey];
			} else {
				finalToSend[theKey] = origStatus[theKey];
			}
		}

		console.log('to update delivery invoice', JSON.stringify(finalToSend));

		api.deliveries.updateInvoice({ delivery, data: finalToSend }).then(response => {
			if (response.status == 'ok') {
				toast.success('Saņemtā rēķina dati saglabāti!');
				setRespectTabs();
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Saņemtā rēķina dati netika saglabāti! ${response.message}`, { autoClose: 7500 });
			}
		});
	}
	render() {

		return <div className='form-group'>
			<div className='row'>
				<Label labelClass='col-sm-3' title='Numurs' name='invoiceNr' />
				<Input
					inputClass='col-sm-9'
					name='invoiceNr'
					value={this.props.invoiceNr}
					handleChange={this.props.handleChange}
				/>
			</div>
			<div className='row'>
				<Label labelClass='col-sm-3' title='Datums' name='invoiceDate' />
				<div className='col-sm-9'>
					<Datetime
						locale='lv'
						timeFormat={false}
						closeOnSelect={true}
						onChange={() => this.props.handleDateChange('invoiceDate')}
						defaultValue={this.props.invoiceDate !== '' ?
							moment(this.props.invoiceDate).format('DD.MM.YYYY.')
							: this.props.invoiceDate}
					/>
				</div>
			</div>
			<div className='row'>
				<Label labelClass='col-sm-3' title='Summa' name='invoiceTotal' />
				<Input
					inputClass='col-sm-9'
					name='invoiceTotal'
					value={this.props.invoiceTotal}
					handleChange={this.props.handleChange}
				/>
			</div>
			<div className='row'>
				<Label
					labelClass='col-sm-3'
					title='Saņemts ienākošais rēķins'
					name='invoiceReceived'
				/>
				<div className='col-sm-3'>
					<Switch
						offColor='warning'
						onColor='success'
						onText='Jā'
						offText='Nē'
						value={this.props.invoiceReceived ? true : false}
						readonly={true}
					/>
				</div>
			</div>
			<button
				disabled={!checkPermission('edit')}
				type='button'
				className='btn btn-primary'
				onClick={this.handleInvoiceSave}>
				Saglabāt
			</button>
		</div>
	}
}
export default Invoice;
