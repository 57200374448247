/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import api from '@api';
import { Input, Label, Select, Textarea } from 'shared/form';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PasswordRules from './passwordRules';
import checkIfValid from './checkIfValid';

/*
paroles stiprums (varētu tādu pašu kā CSDD):
* https://www.npmjs.com/package/react-password-strength
* https://github.com/dropbox/zxcvbn
* https://scotch.io/tutorials/password-strength-meter-in-react
*/

class PasswordChange extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password1: '',
			password2: '',
			old: '',
			passwordValid: true,
			passwordValidText: ''
		};
	}

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		if (name == 'password1') {
			this.checkIfValidPassword(inputValue);
		}

		this.setState({ [name]: inputValue });
	};

	checkIfValidPassword = password => {
		const checkRes = checkIfValid(password, this.state.passwordValid);

		if (checkRes === true) {
			return;
		}

		this.setState({ ...checkRes });
	};

	//

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { password1, password2, old, passwordValid } = this.state;

		if (password1 == old) {
			toast.info('Jāizvēlas cita parole!', { autoClose: 5000 });
			return;
		}

		if (password1 != password2) {
			toast.info('Paroles nesakrīt', { autoClose: 5000 });
			return;
		}

		if (password1.trim() == '' || password2.trim() == '') {
			toast.info('Parole nedrīkst būt tukša', { autoClose: 5000 });
			return;
		}

		if (!passwordValid) {
			toast.info('Parole neatbilst noteikumiem', { autoClose: 5000 });
			return;
		}

		api.admin.change_password({ old, newP: password1 }).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				//this.setData();
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	render() {
		//"client","responsible"
		const { password1, password2, old, passwordValid, passwordValidText } = this.state;

		return (
			<div className='bodyWrapper'>
				<h3>Paroles maiņa</h3>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ minWidth: '50%' }}>
						<div className='row'>
							<Label labelClass='col-sm-2' title='Pašreizējā parole' name='old' />
							<Input
								inputClass='col-sm-8'
								type='password'
								name='old'
								value={old}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-2' title='Jaunā parole' name='password1' />
							<Input
								inputStyle={
									password1.length > 0 && passwordValid == false
										? { borderColor: '#FA8072' }
										: {}
								}
								inputClass='col-sm-8'
								type='password'
								name='password1'
								value={password1}
								handleChange={this.handleChange}
							/>
							{password1.length > 0 && passwordValid == false && (
								<OverlayTrigger
									placement='right'
									overlay={
										<Tooltip id='tooltip-right'>
											Parole neatbilst nosacījumiem: {passwordValidText}
										</Tooltip>
									}>
									<i className='fas fa-exclamation-triangle'></i>
								</OverlayTrigger>
							)}
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-2'
								title='Jaunā parole atkārtoti'
								name='password2'
							/>
							<Input
								inputClass='col-sm-8'
								type='password'
								name='password2'
								value={password2}
								handleChange={this.handleChange}
							/>
						</div>
						<button
							type='button'
							disabled={false}
							className='btn btn-primary'
							onClick={this.saveData}>
							Saglabāt!
						</button>
					</div>
					<div>
						<PasswordRules />
					</div>
				</div>
			</div>
		);
	}
}

export default PasswordChange;
