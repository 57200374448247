import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { clear } from '../slice';
import TabHandler from 'shared/tabs/tabSelect';
import { toast } from 'react-toastify';

const initialState = {
	tabs: [],
	current_tab: null,
	current_key: null,
	loading: false,
	loading_error: false,
	meta: {}
};

const orderTabsSlice = createSlice({
	name: 'planning_order_tabs',
	initialState,
	reducers: {
		startRequest: (state, action) => {
			state.loading = true;
		},
		loadTabs: (state, action) => {
			state.loading = false;
			state.tabs = action.payload;
		},
		loadMeta: (state, action) => {
			state.meta = action.payload;
		},
		setCurrentTab: (state, action) => {
			const {id, key} = action.payload;

			state.current_tab = id;
			state.current_key = key;
		}
	},
});

const {actions, reducer} = orderTabsSlice;

const formatTabs = orders => {
	let allTabs = [];

	const departments = orders.map(department => ({
		id: department.department_id,
		title: department.department_title,
		orderCount: department.order_count
	}));

	if (departments && departments.length>0) {
		allTabs = [...departments];
	}

	return allTabs;
}

export const loadData = () => (dispatch) => {
	dispatch(actions.startRequest())

	api.planning.overview().then(response => {
		if (response.status === 'error') {
			toast.warn(response.message, { autoClose: 10000 });
			return;
		}

		const format = formatTabs(response);
		dispatch(actions.loadMeta(response));
		dispatch(actions.loadTabs(format));

		const wantedTab = (new TabHandler()).getSettings('OrderTabs');
		dispatch(actions.setCurrentTab({key:wantedTab}));
	})
}

export const setCurrentTab = ({key, id}) => (dispatch, getState) => {
	const currData = getState().planningOrderTabs;

	if (currData.current_key === key && currData.current_tab === id) {
		return;
	}
	dispatch(actions.setCurrentTab({key, id}));
	dispatch(clear())
}

export default reducer;
