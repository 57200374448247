import React, { Component } from 'react';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';

import { allFieldsHaveValues, allFieldsHaveValues_new } from 'util';
import api from '@api';
import { Input, Label, Select, Textarea } from 'shared/form';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class SMSEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: []
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });

		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		//{
		api.admin.sms_get().then(response => {
			const moreAttrs = getDataFromAPIResponse(response, ['client', 'responsible']);

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {
			replaceResposibles: this.state.replaceResposibles || false,
			replaceClient: this.state.replaceClient || false
		}; /*{
      'addressFrom':data.storage.title,
      'addressTo':data.delivery.title,
      'material':data.material,
      'cargoCount':1,//tā vismaz spriežu pēc vecā koda - 'uzd_cargo_count'
      'typeID':9
    }*/
		let try2Final = {};
		const dataToSendAsDefaults = ['client', 'responsible']; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			client: 'SMS klientiem',
			responsible: 'SMS atbildīgajiem'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['client', 'responsible'],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		//return;

		api.admin.sms_save(finalToSend).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				//this.setData();
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	onDriverSelect(driver) {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	}

	onDeptSelect = departments => {
		// if (departments != "") {
		const deptForSave = departments.map(dept => dept.value);
		this.setDataToState('departments', deptForSave);
		// }
	};

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	}

	render() {
		//"client","responsible"
		const { loading, client, responsible } = this.state;

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<h2>Noklusētie SMS teksti</h2>

						<h3>Klientiem</h3>
						<div className='row'>
							<Label labelClass='col-sm-1' title='SMS teksts' name='client' />
							<Textarea
								inputClass='col-sm-4'
								name='client'
								value={client}
								handleChange={this.handleChange}
							/>
							<span className='col-sm-4'>
								Pieejamie mainīgie: {'{'}AMOUNT{'}'}, {'{'}AMOUNTUNITS{'}'}, {'{'}
								MATERIAL{'}'}, {'{'}ADDRESS{'}'}, {'{'}VILCEJS{'}'}, {'{'}DRIVERNAME
								{'}'}, {'{'}DRIVERPHONE{'}'}
							</span>
						</div>
						<div className='row'></div>
						<div className='row'>
							<Label
								labelClass='col-sm-1'
								title='Pārrakstīt visas vērtības'
								name='replaceClient'
							/>
							<div className='col-sm-8'>
								<Switch
									name='replaceClient'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'replaceClient')
									}
								/>
							</div>
						</div>
						<h3>Atbildīgajiem</h3>
						<div className='row'>
							<Label labelClass='col-sm-1' title='SMS teksts' name='responsible' />
							<Textarea
								inputClass='col-sm-4'
								name='responsible'
								value={responsible}
								handleChange={this.handleChange}
							/>
							<span className='col-sm-4'>
								Pieejamie mainīgie: {'{'}AMOUNT{'}'}, {'{'}AMOUNTUNITS{'}'}, {'{'}
								RECEIVERNAME{'}'}, {'{'}MATERIAL{'}'}, {'{'}ADDRESS{'}'}, {'{'}
								VILCEJS{'}'}, {'{'}DRIVERNAME{'}'}, {'{'}DRIVERPHONE{'}'}
							</span>
						</div>
						<div className='row'>
							<Label
								labelClass='col-sm-1'
								title='Pārrakstīt visas vērtības'
								name='replaceResposibles'
							/>
							<div className='col-sm-8'>
								<Switch
									name='replaceResposibles'
									offColor='warning'
									onColor='success'
									onText='Jā'
									offText='Nē'
									defaultValue={false}
									onChange={(el, state) =>
										this.handleSwitch(el, state, 'replaceResposibles')
									}
								/>
							</div>
						</div>
						{checkPermission('edit', 'sms') && (
							<button
								type='button'
								disabled={false}
								className='btn btn-primary'
								onClick={this.saveData}>
								Saglabāt!
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default SMSEdit;
