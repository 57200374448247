import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import BillActions from '../actions';
import Pagination from '../../../shared/pagination/pagination';
import { toast } from 'react-toastify';
import { handleDataUpdate, customTitle, calculateOffset, calculatePages } from '../../../util';
import {
	MILISECONDS_BEFORE_SEARCH
} from '../../../config';
import { horizonCheck } from '../../../permissions';
import FieldForm from '@sections/admin/filterForm';
import VedumiModal from '@sections/orders/vedumiModal';
import * as formatters from './formatters';
import { getSum } from './helpers';
import { Check } from 'shared/form';
import getFilterStartPeriod from './filter';
import MyCustomTable from '../../../latraps_table';

const HOW_OFTEN_UPDATE = 5000;
const ROWS_IN_ONE_PAGE = 20;

class BillTable extends Component {
	intervalID;

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			loading: false,
			error: false,
			showModal: false,
			bill: '',
			invoiceNr: '',
			payer: '',
			date_from: '',
			date_till: '',
			currentPage: 1,
			totalPages: 1,
			import_errors: [],
			billTitle: null,
			openedModal: null,
			storned: false,
			open: false,
			no_horizon: false,
			horizon_changed: false,
			currentImports: [], //no kolonnas "Horizon imports"/"Horizon imports (apg.)"
			horizonImporting: false //no pogas "Atjaunināt Horizon statusu"
		};
	}
	timeout = null;

	componentWillUnmount() {
		clearTimeout(this.intervalID);
	}

	handleOpenModal = (cell, modalToOpen) => {
		const { billNr, billNrCript, year } = cell;

		this.setState({
			showModal: true,
			openedModal: modalToOpen,
			bill: billNrCript,
			billTitle: `${billNr}/${year}`
		});
	};

	//horizonImportClickHandler

	openActionsModal = (row) => {
		this.handleOpenModal(row, 'actions')
	}

	columns = [
		{
			Header: () => (
				<i title='Rēķina numurs' className='fas fa-list-ol' style={{ color: '#333334' }}></i>
			),
			accessor: 'billNr',
			width: 45,
			Cell: ({ row }) => formatters.nrFormatter(row.original)
		},
		{
			Header: () => (
				<i
					title='Atvērt darbību sarakstu'
					className='fas fa-edit'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			accessor: 'billId',
			clickHandler: this.openActionsModal,
			Cell: ({ row }) => (
				<div style={{textAlign: 'center'}}>
					<i
						className='fas fa-ellipsis-v'
						style={{ fontSize: '1.5em', textAlign: 'center', color: 'green' }}></i>
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Horizon imports'
					className='fas fa-file-upload'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			columnClass: 'horizonFormat',
			accessor: 'horizonStatus',
			Cell: ({ row }) => (
				<div
					onClick={() => formatters.horizonImportClickHandler(row.original,
						'ordinar',
						this.state.serviceStatus.horizon,
						this.setData,
						this.state.horizonImporting
					)}>
					{formatters.horizonStatus(row.original)}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Horizon imports (apg)'
					className='fas fa-file-upload'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			columnClass: 'horizonFormat',
			accessor: 'horizon2Status',
			Cell: ({ row }) => (
				<div
					onClick={() => formatters.horizonImportClickHandler(row.original,
						'creditor',
						this.state.serviceStatus.horizon,
						this.setData,
						this.state.horizonImporting
					)}>
					{formatters.horizon2Status(row.original)}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Piegāžu skaits' className='fas fa-tasks' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			accessor: 'deliveries',
			Cell: ({ row }) => (
				<div
					onClick={() => this.props.handleOpenModal(row.original, 'vedumi')}>
					{formatters.deliveryColumn(row.original)}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Rēķins nosūtīts e-pastā' className='fas fa-envelope' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 30,
			align: 'center',
			accessor: 'billSent',
			Cell: ({ row }) => (formatters.billSentStatus(row.original)),
		},
		{
			Header: () => (
				<i title='Maksātājs' className='fas fa-hand-holding-usd' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 200,
			align: 'center',
			accessor: 'clName'
		},
		{
			Header: () => (
				<i title='Rēķina izrakstīšana - datums un sagatavotājs' className='fas fa-calendar-alt' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			accessor: 'billWritePerson',
			Cell: ({ row }) => (formatters.writerFormatter(row.original))
		},
		{
			Header: () => (
				<i title='Apmaksas termiņš' className='fas fa-clock' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 80,
			align: 'center',
			accessor: 'billPaymentDeadline',
			Cell: ({ row }) => (formatters.deadlineFormatter(row.original))
		},
		{
			Header: () => (
				<i title='Apmaksas veids' className='fas fa-tags' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 100,
			align: 'center',
			accessor: 'billPaymentType',
			Cell: ({ row }) => (formatters.paymentTypeFormatter(row.original))
		},
		{
			Header: () => (
				<i title='Rēķina veids' className='fas fa-copy' style={{ color: '#333334' }}></i>
			),
			accessor: 'billType'
		},
		{
			Header: () => (
				<i title='Pamatojums' className='fas fa-sticky-note' style={{ color: '#333334' }}></i>
			),
			accessor: 'billJustification'
		},
		{
			Header: () => (
				<i title='Rēķina statuss' className='fas fa-signal' style={{ color: '#333334' }}></i>
			),
			accessor: 'billStatus',
			Cell: ({ row }) => (formatters.billStatus(row.original))
		},
		{
			Header: () => (
				<i title='Rēķina summa' className='fas fa-euro-sign' style={{ color: '#333334' }}></i>
			),
			accessor: 'billSumm',
			Cell: ({ row }) => (formatters.billSum(row.original))
		}
	];

	setTotalPages = () => {
		const { invoiceNr, payer, currentPage, date_from, date_till, storned, no_horizon, horizon_changed} = this.state;
		const { client } = this.props;

		const clFinal = typeof client == 'undefined' ? '' : client;

		let searchParams = {
			maks: payer, nr: invoiceNr, client: clFinal, date_from, date_till
		};

		['storned',
		'no_horizon',
		'horizon_changed', 'open'].forEach(item => {
			if (this.state[item] === true) {
				searchParams = {...searchParams, [item]: true};
			}
		})

		api.bills
			.getBillCount(searchParams)
			.then(response => {
				const { bills } = response;

				this.setState({ bills: response, totalPages: calculatePages(bills, ROWS_IN_ONE_PAGE) });
			});
	};

	resetPage = () => {
		this.setState({ currentPage: 1 });
	}

	setData() {
		const { mode, client } = this.props;
		const { invoiceNr, payer, currentPage, date_from, date_till, storned, no_horizon, horizon_changed} = this.state;

		const howManyBills = ROWS_IN_ONE_PAGE;
		const offset = calculateOffset(currentPage, ROWS_IN_ONE_PAGE);

		let searchParams = {
			count: howManyBills,
			maks: payer,
			nr: invoiceNr,
			offset,
			date_from,
			date_till,
			client: ''
		};
		this.setState({ loading: true });

		/* vai komponente izsaukta no atsevišķas rēķinu lapas vai arī no klienta kartiņas (kur jābūt norādītam klienta ID) */
		if (mode === 'specific' && client !== '') {
			searchParams = { ...searchParams, client };
		}

		['storned',
		'no_horizon',
		'horizon_changed', 'open'].forEach(item => {
			if (this.state[item] === true) {
				searchParams = {...searchParams, [item]: true};
			}
		})

		api.bills.getAllBills3(searchParams).then(response => {
			const { data, serviceStatus } = response;

			this.setState({ loading: false, error: false, data, serviceStatus });
			this.intervalID = handleDataUpdate(this.setData.bind(this));
		});
	}

	handleHorizonUpdateResponse = respPar => {
		this.setState({ horizonImporting: false });

		this.setData();
	};

	importToHorizonAll = () => {
		this.setState({ horizonImporting: true, import_errors: [] });

		api.bills
			.importToHorizonAll()
			.then(response => {
				if (response.length === 0) {
					toast.success('Nav rēķinu, ko importēt', { autoClose: 3500 });
					return;
				}
				const errors = [];
				Object.keys(response).map(key => {
					const data = response[key];
					if (data.success === 0) {
						errors.push(data.message);
					}
				});
				if (errors.length>0) {
					toast.error('Daļa rēķinu netika importēti', { autoClose: 7500 });
					this.setState({ import_errors: errors });
				} else {
					toast.success('Pabeigts imports', { autoClose: 3500 });
				}

				this.setData();
			})
			.catch(err => {
				toast.error('Serveris atgrieza kļūdu', { autoClose: 7500 });
			})
			.finally(() => {
				this.setState({ horizonImporting: false });
			});
	};

	handleHorizon = () => {
		//jscripts\HorizonBillStatusRefresher.js
		const { data } = this.state;

		this.setState({ horizonImporting: true });

		let passData = [];
		const passedData = [];

		//<img data-bill-stat="1" class="horizon-import-imported horizon-import-1" data-type="regular" data-bill-id="1037" data-hor-check-class="horizon-import-1" data-pvzdoc="5dabad9d07c66b12bd03e44ccb1428e0ff14e67f" src="./images/export.png" alt="pic" style="vertical-align:middle; width:27px; height:27px; margin-left:20px; cursor: pointer" border="0">
		const neededBills = data.filter((item, ind) => {
			return item.variosActions.importStatus;
		});

		for (let i = 0; i < neededBills.length; i++) {
			//pvzKey - cryptnr
			//billId - bill ID

			const { billNrCript, billId } = neededBills[i];

			if (passedData.includes(billNrCript)) {
				continue;
			}

			passedData.push(billNrCript);
			passData.push(billId);
		}

		if (passData.length < 1) {
			this.setState({ horizonImporting: false });
			return;
		}

		api.bills
			.checkBills(passData)
			.then(response => {
				this.handleHorizonUpdateResponse(response);
			})
			.catch(err => {
				toast.error('Serveris atgrieza kļūdu', { autoClose: 7500 });
				this.setState({ horizonImporting: false });
			});
	};

	componentDidMount() {
		this.setState({
			date_from: getFilterStartPeriod()
		}, () => {
			this.setData();
			this.setTotalPages();
		})
	}

	handleCloseModal = () => {
		this.setState({ openedModal: null });
	};

	componentDidUpdate(prevProps) {
		if (this.props.mode !== prevProps.mode || this.props.client !== prevProps.client) {
			this.setData();
		}
	}

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		const value = ['date_from', 'date_till'].includes(whatToSearch)
			? e.format('YYYYMMDD')
			: e.target.value;

		this.setState({ [whatToSearch]: value, currentPage: 1, totalPages: 1 }, () => {
			this.timeout = setTimeout(() => {
				_self.setTotalPages();
				_self.setData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handlePageChange = currentPage => {
		this.setState({ currentPage }, () => this.setData());
	};

	filterItems = (date_from, date_till) => ([
		{
			visible: true,
			title: 'Rēķina nr.',
			inputID: 'invoiceNr',
			value: '',
			cb: this.handleSearch,
			cbToSearch: 'invoiceNr'
		},
		{
			visible: this.props.mode == 'separate',
			title: 'Maksātājs',
			inputID: 'payer',
			value: '',
			cb: this.handleSearch,
			cbToSearch: 'payer'
		},
		{
			visible: true,
			field_type: 'date',
			title: 'Izrakstīts no',
			inputID: 'date_from',
			value: date_from,
			cb: this.handleSearch,
			cbToSearch: 'date_from'
		},
		{
			visible: true,
			field_type: 'date',
			title: 'Izrakstīts līdz',
			inputID: 'date_till',
			value: date_till,
			cb: this.handleSearch,
			cbToSearch: 'date_till'
		}
	]);

	handleFilterChange = (name) => {
		this.setState(prevState => ({
		  [name]: !prevState[name],
		  currentPage: 1, totalPages: 1
		}), () => {
			this.setData();
			this.setTotalPages();
		});
	}

	render() {
		const {
			data,
			loading,
			showModal,
			bill,
			currentPage,
			totalPages,
			horizonImporting,
			openedModal,
			import_errors,
			billTitle,
			storned,
			open,
			no_horizon,
			horizon_changed,date_from, date_till
		} = this.state;

		const summs = getSum(data);

		return (
			<div>
				{horizonCheck() && (
					<div style={{ float: 'right', display: 'flex', flexDirection: 'column', maxWidth: 250 }}>
						<button
							type='button'
							disabled={horizonImporting}
							className='btn btn-primary'
							onClick={this.handleHorizon}>
							Atjaunināt Horizon statusu
						</button>
						<button
							type='button'
							disabled={horizonImporting}
							className='btn btn-primary'
							onClick={this.importToHorizonAll}>
							Importēt Horizon visus rēķinus
						</button>
						{import_errors.length > 0 && (
							<div>
								Importa kļūdas:
								<ul>
									{import_errors.map((error, key) => (
										<li key={key}>{error}</li>
									))}
								</ul>
							</div>
						)}
					</div>
				)}
				<div style={{display: 'flex'}}>
					<div style={{minWidth: 500}}>
						<FieldForm fullWidth={true} items={this.filterItems(date_from, date_till)} />
					</div>

					<div style={{marginLeft: 25}}>
						<Check name="open" value={open} handleChange={() => this.handleFilterChange('open')} label="Rādīt tikai atvērtos rēķinus" />
						<Check name="storned" value={storned} handleChange={() => this.handleFilterChange('storned')} label="Rādīt tikai stornētos rēķinus" />
						<Check name="no_horizon" value={no_horizon} handleChange={() => this.handleFilterChange('no_horizon')} label="Rādīt tikai Horizonā neimportētos rēķinus" />
						<Check name="horizon_changed" value={horizon_changed} handleChange={() => this.handleFilterChange('horizon_changed')} label="Rādīt tikai Horizonā izmainītos rēķinus" />
					</div>

				</div>

				<div className='clearBoth' style={{ padding: 6 }} />
				{totalPages == 1 || loading ? (
					''
				) : (
					<div>
						<Pagination
							page={currentPage}
							total={totalPages}
							handlePageChange={this.handlePageChange}
						/>
					</div>
				)}
				<div className='clearBoth'></div>
				{loading ? (
					<Loading />
				) : (
					<div>
						{data.length < 1 ? (
							'Nav datu'
						) : (<>
							<div className='tableWrapper'>
								<MyCustomTable orders={data} columns={this.columns} />
							</div>
							<div>
							Rēķinu kopsumma. bez PVN: {summs.woTax.toFixed(2)} EUR, PVN: {summs.tax.toFixed(2)} EUR, kopā: {summs.summ.toFixed(2)} EUR
							</div>
							</>
						)}
					</div>
				)}
				{totalPages == 1 || loading ? (
					''
				) : (
					<div>
						<Pagination
							page={currentPage}
							total={totalPages}
							handlePageChange={this.handlePageChange}
						/>
					</div>
				)}
				{openedModal === 'actions' && (
					<BillActions
						showModal={showModal}
						handleCloseModal={this.handleCloseModal}
						bill={bill}
					/>
				)}
				{openedModal === 'vedumi' && (
					<VedumiModal
						billTitle={billTitle}
						showModal={openedModal === 'vedumi'}
						billId={bill}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
			</div>
		);
	}
}

export default BillTable;
