import React, { Component } from 'react';
import api from '@api';
import Deliveries from './table';
import Header from './header';
import Loading from 'shared/loading';
import Pagination from '../../shared/pagination/pagination';
import FilterText from '../../shared/filter/format';
import { handleDataUpdate } from '../../util';
import ErrorOnLoading from 'shared/errorOnLoad';

const ROWS_IN_ONE_PAGE = 20;

class ProblematicTasks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: 0,
			loading: false,
			error: false,
			data: [],
			currentPage: 1,
			pages: 1
		};
	}

	oneDelivery(delivery, key) {
		return (
			<div key={key}>
				<Header data={delivery} mode='piegade' resetData={this.setup} />
				<br />
				<Deliveries mode='piegade' data={delivery.taskDeliveries.deliveries} reload_data={this.setup} />
			</div>
		);
	}

	setup = (loader = true) => {
		if (loader) {
			this.setState({ loading: true, error: false });
		}

		const { regNr, mode } = this.props;
		const { currentPage } = this.state;

		api.deliveries.clientProblematic({ page: currentPage, client: regNr }).then(response => {
			let deliveries = [];
			let pages = 1;
			if ('status' in response && response.status == 'error') {
				deliveries = [];
			} else {
				deliveries = response.deliveries;
			}

			this.setState({ loading: false, error: false, data: deliveries });
		});
	}

	componentDidMount() {
		this.setup();
		const pages = Math.ceil(this.props.number / ROWS_IN_ONE_PAGE);

		this.setState({ pages });
	}

	handleSelect(key) {
		this.setState({ key, data: null });
	}

	handlePageChange = currentPage => {
		this.setState({ currentPage }, () => this.setup());
	};

	render() {
		const { data, error, loading, currentPage, pages } = this.state;

		return (
			<div>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								{pages == 1 ? (
									''
								) : (
									<div>
										<Pagination
											page={currentPage}
											total={pages}
											handlePageChange={this.handlePageChange}
										/>
									</div>
								)}
								{data.length > 0 ? (
									data.map((delivery, ind) => this.oneDelivery(delivery, ind))
								) : (
									<div>0 piegādes</div>
								)}
								{pages == 1 ? (
									''
								) : (
									<div>
										<Pagination
											page={currentPage}
											total={pages}
											handlePageChange={this.handlePageChange}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default ProblematicTasks;
