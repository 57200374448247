import React, { Component } from 'react';
import { Input, Label, Select, Textarea, Radio, Check } from '../../../shared/form';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../../../api';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/lv';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css';
import { toast } from 'react-toastify';

import { allFieldsHaveValues, formatFullDate, allFieldsHaveValues_new, reloadPage } from 'util';
import { checkPermission } from 'permissions';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';

//pārvietot uz util.js
const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

class UserSMTP extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: []
		};
	}

	setData = () => {
		this.setState({ loading: true, error: false });
		const { id } = this.props;
		//const delivery = '8178';//šis jāpadod no this.props;
		//const task = '3446';//šis jāpadod no this.props;

		api.admin.user_get_smtp(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'darbinieks_id',
				'email',
				'password'
			]);

			//debugger;
			const initState = moreAttrs.email == '' || moreAttrs.password == '' ? false : true;

			const attrTransformation = {};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				{ initState },
				attrTransformation
			);

			this.setState(newState);
		});
	};

	deleteData = () => {
		const { id: userID } = this.props;

		api.admin.user_delete_smtp(userID).then(response => {
			if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 8000
				});
			}
		});
	};

	componentDidMount() {
		//this._handleSearch('');
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.delivery !== prevProps.delivery || this.props.task !== prevProps.task) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id: userID } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {}; /*{
      'addressFrom':data.storage.title,
      'addressTo':data.delivery.title,
      'material':data.material,
      'cargoCount':1,//tā vismaz spriežu pēc vecā koda - 'uzd_cargo_count'
      'typeID':9
    }*/
		let try2Final = {};
		const dataToSendAsDefaults = ['email', 'password']; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			email: 'e-pasts',
			password: 'parole'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			['email', 'password'],
			finalToSend,
			nameMap
		);
		// @ts-ignore
		if (confirmAllFieldsHasValues.status == false) {
			//,"showNotesInBill"
			//console.log('Jāaizpilda visi lauki: ', finalToSend, defaultFields, data, this.state);
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		api.admin.user_save_smtp({ id: userID, data: finalToSend }).then(response => {
			if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 8000
				});
			}
		});
	};

	onDriverSelect(driver) {
		if (driver != '') {
			this.setDataToState('driver', driver);
		}
	}

	onDeptSelect = departments => {
		// if (departments != "") {
		const deptForSave = departments.map(dept => dept.value);
		this.setDataToState('departments', deptForSave);
		// }
	};

	handleSwitch = (elem, state, name) => {
		//const name = "deliveryIncluded";
		const inputValue = state;

		const toSaveData = Object.assign(this.state.toSaveData, { [name]: inputValue });

		this.setState({ toSaveData, [name]: inputValue });
	};

	render() {
		const { data, name, loading, email, password, initState } = this.state;
		const { id } = this.props;

		const isEditingPermitted = checkPermission('edit', 'smtp');
		const isDeletingPermitted = checkPermission('delete', 'smtp') && initState;

		//const {}

		return (
			<div className='bodyWrapper'>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='E-pasts' name='email' />
							<Input
								inputClass='col-sm-8'
								name='email'
								value={email}
								handleChange={this.handleChange}
							/>
						</div>
						<div className='row'>
							<Label labelClass='col-sm-3' title='Parole' name='password' />
							<Input
								inputClass='col-sm-8'
								type='password'
								name='password'
								value={password}
								handleChange={this.handleChange}
							/>
						</div>
						{isEditingPermitted && isDeletingPermitted ? (
							<div>
								<div className='btn-group'>
									<button
										type='button'
										disabled={false}
										className='btn btn-primary'
										onClick={this.saveData}>
										Saglabāt!
									</button>
									<button
										type='button'
										disabled={false}
										className='btn btn-danger'
										onClick={this.deleteData}>
										Dzēst konfigurāciju!
									</button>
								</div>
							</div>
						) : (
							<div>
								{isEditingPermitted && (
									<button
										type='button'
										disabled={false}
										className='btn btn-primary'
										onClick={this.saveData}>
										Saglabāt!
									</button>
								)}
								{isDeletingPermitted && (
									<button
										type='button'
										disabled={false}
										className='btn btn-danger'
										onClick={this.deleteData}>
										Dzēst konfigurāciju!
									</button>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default UserSMTP;
