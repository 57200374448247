import React from 'react';
import { Tab } from 'react-bootstrap';
import DriverDeliveries from 'sections/deliveries/driverDeliveries';

const oneTab = (tab, key, currKey, currDate = null) => {
	const { driverName, clientName, vilcejs } = tab;

	const title = (
		<span>
			{driverName}
			<br />
			<span className='tabClientTitle'>{clientName}</span>
			<br />
			<span className='tabCarNr'> {vilcejs}</span>
		</span>
	);

	return (
		<Tab tabClassName='tabItem' eventKey={key} key={tab.carrierId + tab.driverId} title={title}>
			{currKey === key &&
				<DriverDeliveries mode={currDate && 'calendar'} carrier={tab.carrierId} driver={tab.driverId} date={currDate} />}
		</Tab>
	);
}

export default oneTab;
