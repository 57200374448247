//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component, useEffect } from 'react';
import { Tabs, Tab, Badge } from 'react-bootstrap';
import TableWithPagination from '../table/orderTableWithPagination';
import NewOrders from 'sections/orders/newOrders';
import api from '../../../api';
import BillTable from 'sections/bills/table';
import TransportTable from '../../admin/transport';
import { handleUIPositionChange, setUIPosition, setPageOnPosition } from 'uiPosition';
import '@shared/tabs/tabs.css';
import ClientDeliveries from 'sections/deliveries/clientDeliveries';

import TabHandler from 'shared/tabs/tabSelect';
import DeliveredArchive from 'sections/deliveries/archive';
import ProblematicTasks from 'sections/deliveries/clientTasks';
import { connect } from 'react-redux';
import { setCurrentTab } from './slice';
import setRespectTabs from 'shared/tabs/setRespectTabs';

const oneTab = (tab, key, currKey, client, isClientActive, clientRegNr, additionalData) => {
	let whatToOpen = null;

	const { problematic, invoiceReady, archivedTasks } = additionalData;

	const currTime = new Date();
	const thisYear = currTime.getFullYear();
	const nextYear = thisYear + 1;

	let tabTitleStyle = {};
	let numberOfResults = '';

	//<a href="code/klienta_pasutijumi_list.php?n=<?php echo $row['nodala_id'];?>&amp;rows=<?php echo $row['sk'];?>&amp;y=<?php echo date("Y");?>&amp;klientid=<?php echo $klients_id;?>"><?php echo $row['nodala_nosaukums'];?> <span id="nodala<?php echo $row['nodala_id'];?>"><?php echo '<span style="cursor:pointer;" onclick="howMatch(\'all\', '.$row['nodala_id'].');">('.$row['sk'].')</span>'; if($row_JAUNI['sk']>0){echo $new;}?></span></a>
	if (tab.id > -1) {
		numberOfResults = tab.orderCount > 0 ? tab.orderCount : '';
		whatToOpen = (
			<TableWithPagination
				dept={tab.id}
				rows={tab.orderCount}
				client={client}
				year={thisYear}
			/>
		);
	} else if (tab.id === -1) {
		numberOfResults = tab.orderCount > 0 ? tab.orderCount : '';
		//urgent orders
		whatToOpen = <TableWithPagination dept={98} rows={tab.orderCount} year={thisYear} />;
		tabTitleStyle = { color: 'red' };
	} else if (tab.id === -2) {
		whatToOpen = (
			<NewOrders client={client} clientRegNr={clientRegNr} isActive={isClientActive} />
		);
	} else if (tab.id === -3) {
		numberOfResults = tab.orderCount > 0 ? tab.orderCount : '';
		whatToOpen = (
			<TableWithPagination
				dept={tab.id}
				rows={tab.orderCount}
				client={client}
				year={nextYear}
			/>
		);
	} else if (tab.id === -4) {
		whatToOpen = <BillTable mode='specific' client={client} />; //"rēķini";//<BillTable />;
	} else if (tab.id === -5) {
		whatToOpen = <TransportTable clientView={true} clientID={client} />; //<TransportTable />;
	} else if (tab.id === -6) {
		numberOfResults = problematic;
		whatToOpen = (
			<ProblematicTasks regNr={clientRegNr} mode='problematic' number={problematic} />
		); //<TransportTable />;
	} else if (tab.id === -7) {
		numberOfResults = invoiceReady;
		whatToOpen = (
			<ClientDeliveries regNr={clientRegNr} mode='invoiceReady' number={invoiceReady} />
		); //<TransportTable />;
	} else if (tab.id === -8) {
		numberOfResults = archivedTasks;
		whatToOpen = <DeliveredArchive regNr={clientRegNr} separatePage={false} />; //<TransportTable />;
	}

	return (
		<Tab
			eventKey={key}
			key={tab.id}
			title={
				<span>
					<span style={tabTitleStyle}>{tab.title}</span>{' '}
					{numberOfResults != '' ? <small>({numberOfResults})</small> : ''}{' '}
					{tab.newOrders > 0 ? (
						<Badge bsClass='alert-danger badge'>{tab.newOrders}</Badge>
					) : (
						''
					)}
				</span>
			}>
			{currKey === key ? whatToOpen : ''}
		</Tab>
	);
};

let handler = new TabHandler();

const OrderTabs = ({
	setCurrentTab,
	clientRegNr,
	orderData,
	isClientActive,
	clientId,
	currentTabIdx,
	meta
}) => {
	useEffect(() => {
		setRespectTabs();
		const curTab = handler.getSettings('OrderTabs');
		console.log('curTabcurTabcurTabcurTab', curTab)
		if (curTab > -1) {
			handleSelect(curTab);
		}
	}, []);
	const handleSelect = key => {
		console.log('handleSelect', key);
		setCurrentTab(key);
		handler.saveSettings('OrderTabs', key);
	};

	/* componentDidMount() {
		//window.addEventListener('scroll', handleUIPositionChange);
		this.setup();
		const curTab = handler.getSettings('OrderTabs');
		if (curTab) {
			this.handleSelect(curTab);
		}
	}

	componentWillUnmount() {
		//window.removeEventListener('scroll', handleUIPositionChange);
		//handler.saveSettings('OrderTabs',0);
	}

	handleSelect = key => {
		console.log('SET KEY', key);
		//setUIPosition(0);
		this.setState({ key });
		handler.saveSettings('OrderTabs', key);
	}; */

	if (meta.urgent == 0 && meta.nextYear == 0 && meta.normal.length < 1) {
		return 'Nav datu';
	}

	return (
		<div>
			<Tabs
				className='tabLayout'
				activeKey={currentTabIdx}
				onSelect={handleSelect}
				id='order-tabs'>
				<div style={{ paddingBottom: 20 }}></div>
				{orderData &&
					orderData.map((tab, key) =>
						oneTab(tab, key, currentTabIdx, clientId, isClientActive, clientRegNr, {
							problematic: meta.problematic,
							invoiceReady: meta.invoiceReady,
							archivedTasks: meta.archived
						})
					)}
			</Tabs>
		</div>
	);
};

const mapDispatchToPropsRes = dispatch => {
	return {
		setCurrentTab: key => {
			dispatch(setCurrentTab({ key }));
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		clientRegNr: state.client.reg_nr,
		orderData: state.orderTabs.tabs,
		meta: state.orderTabs.meta,
		problematic: state.orderTabs.meta.problematic,
		invoiceReady: state.orderTabs.meta.invoiceReady,
		archivedTasks: state.orderTabs.meta.archivedTasks,
		isClientActive: state.client.active_client,
		clientId: state.client.id,
		currentTabIdx: state.orderTabs.current_key
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(OrderTabs);
