//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '@api';
import { Collapse } from 'react-collapse';
import Loading from 'shared/loading';
import { Label, Radio, Textarea } from 'shared/form';
//import DeliveryEditModal from './DeliveryEditModal';
import { toast } from 'react-toastify';
import Card from 'shared/actionButtons/card';
import EditBill from './edit';
import { checkPermission } from 'permissions';
import OneCard from 'sections/orders/card';
import './actions.scss';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import { reloadPage } from 'util';
import HistoryModal from 'shared/history';
import setRespectTabs from 'shared/tabs/setRespectTabs';
import Switch from 'react-bootstrap-switch';

class BillActions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bill: '',
			data: {},
			searching: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null,
			includeDeliveriesDoc: true,
			defaultMessage: '',
			billSendingOpen: false,
			billSendingStatus: false
		};
	}

	handleSwitch(elem, state, name) {
		//const name = "deliveryIncluded";
		const inputValue = state;

		console.log(state, name);

		this.setState({ [name]: inputValue });
	}

	setup() {
		const { bill } = this.props;

		api.bills.actions(bill).then(response => {
			this.setState({
				searching: false,
				error: false,
				data: response,
				defaultMessage: response.message,
				isEmailEnabled: response.serviceStatus.email
			});
		});
	}

	componentDidMount() {
		this.setup();
	}

	componentDidUpdate(prevProps) {
		// console.log(this.props.bill, prevProps.bill,this.props.bill !== prevProps.bill)
		if (this.props.bill !== prevProps.bill) {
			this.setup();
		}
	}

	formatTime(timeStr) {
		if (!timeStr) {
			return '';
		}
		return `${timeStr.substring(6, 8)}.${timeStr.substring(4, 6)}.${timeStr.substring(
			0,
			4
		)}. ${timeStr.substring(8, 10)}:${timeStr.substring(10, 12)}:${timeStr.substring(12, 14)}`;
	}

	handleStatusChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		//const toSaveData = Object.assign(this.state.toSaveData,{[name]:inputValue});

		this.setState({ [name]: parseInt(inputValue, 10) });
	};

	handleBillSending = () => {
		this.setState({ billSendingOpen: !this.state.billSendingOpen });
	};

	handleStatusSave = () => {
		const { orderStatus, orderId: order } = this.state;
		const { orderStatus: origStatus } = this.state.data;

		if (orderStatus === origStatus) {
			toast.info('Statuss netika izmainīts!');
			return;
		}

		//fetch post
	};

	handleCloseModal = () => {
		this.setState({ showEditModal: false });
	};

	deleteBill = () => {
		const { billId } = this.state.data.data;

		const confirm = window.confirm('Vai tiešām vēlaties dzēst rēķinu?');
		if (confirm == false) {
			return;
		}

		api.bills.delete(billId).then(response => {
			if (response.status === 'success') {
				toast.success('Rēķins veiksmīgi izdzēsts', { autoClose: 2500 });
				setRespectTabs();
				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//this.props.handleCloseModal(true);
			} else {
				toast.info('Radās kļūda, dzēšot rēķinu');
			}
		});
	};

	editBill = () => {
		this.setState({ showEditModal: true });
	};

	sendBill = () => {
		const { defaultMessage, includeDeliveriesDoc } = this.state;
		const { billNrCript } = this.state.data.data;

		console.log(this.state, includeDeliveriesDoc);

		//return;

		this.setState({ billSendingStatus: true });
		api.bills
			.sendClient({ billId: billNrCript, defaultMessage, includeDeliveriesDoc })
			.then(response => {
				this.setState({ billSendingStatus: false });
				if (response.status === 'success') {
					toast.success('Rēķins veiksmīgi nosūtīts', { autoClose: 2500 });

					setRespectTabs();
					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.handleBillSending(), MILISECONDS_BEFORE_CLOSE);
					//this.props.handleCloseModal(true);
				} else {
					toast.info(`Radās kļūda, sūtot rēķinu: ${response.message}`, {
						autoClose: 7500
					});
				}
			})
			.catch(error => this.setState({ billSendingStatus: false }));
	};

	handleChange = event => {
		const inputValue = event.target.value;
		this.setState({ defaultMessage: inputValue });
	};

	toggleHistory = () => {
		this.setState({ historyOpen: !this.state.historyOpen });
	};

	render() {
		const {
			data,
			searching,
			historyOpen,
			deliveryStatus,
			openedModal,
			defaultMessage
		} = this.state;
		let newData = {};

		if (typeof data.data !== 'undefined') {
			// && Object(data).keys>0
			newData = data.data;
		} else {
			return '';
		}

		const hasBeenImported = newData.variosActions.importStatus;

		const canDeleteBill = hasBeenImported; //iepriekš bija "!hasBeenImported", bet vecajā versijā var dzēst tikai tās, kam ir sarkanā ikona. jāpārbauda vēlreiz

		const {
			variosActions: {
				send: { sentBy, billStatus, pvzdoc, alreadySent }
			}
		} = newData;

		//const hasAlreadySendBillText = alreadySent == true ? " (rēķins jau nosūtīts)" : "";

		const billActions = () => {
			const {
				billSendingOpen,
				billSendingStatus,
				isEmailEnabled,
				includeDeliveriesDoc
			} = this.state;

			const isDeliveryFilesAvailable = this.state.data.data.delivery_files > 0;

			const hasErrors =
				this.state.data.data.variosActions.edit.status == 'error' ? true : false;

			const { bill, data, defaultMessage, ...rest } = this.state;

			return (
				<div className='row'>
					<div className='ActionCardContainer' id='billActions'>
						{OneCard({
							isDisabled: !checkPermission('edit', 'bills'), // || hasErrors
							onClick: this.editBill,
							img: <i className='fas fa-pencil-alt fa-4x' />,
							header: 'Labot rēķinu'
						})}

						{OneCard({
							isDisabled:
								canDeleteBill || hasErrors || !checkPermission('delete', 'bills'),
							onClick: this.deleteBill,
							img: <i className='fas fa-minus-square fa-4x' />,
							header: 'Dzēst rēķinu'
						})}

						{OneCard({
							//cssStyle: {flexGrow:'1'},
							disabledActionOnText: true,
							isDisabled: !checkPermission('send', 'bills') || !isEmailEnabled,
							onClick: this.handleBillSending,
							img: <i className='fas fa-envelope fa-4x' />,
							header: 'Rēķina sūtīšana',
							text: (
								<div style={{ cursor: 'auto' }}>
									<Collapse isOpened={billSendingOpen}>
										{alreadySent ? (
											<div className='alert alert-info' role='alert'>
												<b>Brīdinājums!</b> Rēķins jau ir iepriekš sūtīts
												klientam.
											</div>
										) : (
											''
										)}
										<Textarea
											name='defaultMessage'
											value={defaultMessage}
											handleChange={this.handleChange}
											rows={5}
											cols={50}
										/>
										{isDeliveryFilesAvailable && (
											<>
												<div className='row'>
													<Label
														labelClass='col-sm-4'
														title='Iekļaut piegāžu apliecinājumu dokumentu'
														name='includeDeliveriesDoc'
													/>
													<Switch
														name='includeDeliveriesDoc'
														offColor='warning'
														onColor='success'
														onText='Jā'
														offText='Nē'
														defaultValue={includeDeliveriesDoc}
														onChange={(el, state) =>
															this.handleSwitch(
																el,
																state,
																'includeDeliveriesDoc'
															)
														}
													/>
												</div>
											</>
										)}
										<button
											disabled={
												!checkPermission('send', 'bills') ||
												billSendingStatus
											}
											type='button'
											className='btn btn-primary'
											onClick={this.sendBill}>
											Sūtīt!
										</button>
									</Collapse>
								</div>
							)
						})}
						{OneCard({
							isDisabled: false,
							onClick: this.toggleHistory,
							img: <i className='fas fa-list-alt fa-4x'></i>,
							header: 'Rēķina izmaiņu žurnāls'
						})}
					</div>
				</div>
			);
		};

		const modalContent = <div>{searching ? Loading() : billActions()}</div>;

		return (
			<div>
				<CustomModal
					title='Rēķina darbības'
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={modalContent}
				/>
				<CustomModal
					title='Labošana'
					modalClass='billModalClass'
					showModal={this.state.showEditModal}
					handleCloseModal={this.handleCloseModal}
					content={
						<EditBill
							//fixme: šis jāsakārto
							hasBeenImported={
								typeof this.state.data.data.variosActions !== 'undefined'
									? this.state.data.data.variosActions.importStatus
									: false
							}
							data={
								this.state.data.data.variosActions.edit.status == 'error'
									? {
											payer: this.state.data.data.clientId,
											type: this.state.data.data.billNrCript,
											readOnly: '0',
											order: '-1'
									  }
									: this.state.data.data.variosActions.edit
							}
							handleCloseModal={this.handleCloseModal}
						/>
					}
				/>
				{this.state.historyOpen && (
					<HistoryModal
						showModal={this.state.historyOpen}
						ID={this.state.data.data.billId}
						mode='invoice'
						handleCloseModal={this.toggleHistory}
					/>
				)}
			</div>
		);
	}
}
//

export default BillActions;
