import React, { Component } from 'react';
import api from '@api';
import { toast } from 'react-toastify';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import { reloadPage } from 'util';
import setRespectTabs from 'shared/tabs/setRespectTabs';

class DriverMessage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: ''
		};
	}

	handleChange = event => {
		const value = event.target.value;
		this.setState({ value });
	};

	handleSend = () => {
		const { task, driver } = this.props;
		const { value } = this.state;

		api.mappost
			.sendMessageToDriver({ message: value, task, driver }) //task, state
			.then(response => {
				if (response) {
					toast.success('Ziņa veiksmīgi nosūtīta');

					setRespectTabs();
					setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
					//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn('Ziņa netika nosūtīta', { autoClose: 7500 });
				}
			});
	};

	render() {
		const { value } = this.state;

		return (
			<div>
				<textarea
					className='form-control'
					value={value}
					rows={5}
					cols={100}
					onChange={this.handleChange}></textarea>
				<br />
				<button className='btn btn-success' onClick={this.handleSend}>
					Nosūtīt ziņu
				</button>
			</div>
		);
	}
}

export default DriverMessage;
