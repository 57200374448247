import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';
import { createSelector } from 'reselect'

const amountFormatter = (cell, section, digits) => {
	const data = cell.getRow().getData();
	const { [section]: val } = data; //cell._cell.row.data;

	return parseFloat(val).toFixed(digits);
};

const columns = mode => ([
	{
		title: 'Mēnesis',
		field: 'month',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Auto',
		field: 'auto',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Šoferis',
		field: 'driver',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Nodaļa',
		field: 'department',
		//headerFilter: 'input',
		width: 150
	},
	{
		title: 'Produkts',
		field: 'material'
	},
	{
		title: 'Mērvienība',
		field: 'unit'
	},
	{
		title: 'Kopējais daudzums',
		field: 'amount',
		align:"right",
		formatter: cell => amountFormatter(cell, 'amount', 1)
	},
	{
		title: 'Nauda',
		field: 'money',
		align:"right",
		formatter: cell => amountFormatter(cell, 'money', 2)
	},
	{
		title: 'Nauda ar rēķinu',
		field: 'moneyWithInvoice',
		align:"right",
		formatter: cell => amountFormatter(cell, 'moneyWithInvoice', 2)
	},
	{
		title: 'Nauda bez rēķina',
		field: 'moneyWithoutInvoice',
		align:"right",
		formatter: cell => amountFormatter(cell, 'moneyWithoutInvoice', 2)
	},
].filter(col => {
	if (mode === 'month') {
		return true;
	}

	if (col.field === 'month') {
		return false;
	}

	return true;
}));

const Table = ({ data, level, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}
	let {auto, department, driver, material} = filters;
	auto = auto.toLowerCase();
	department = department.toLowerCase();
	driver = driver.toLowerCase();
	material = material.toLowerCase();

	const filterObj = {auto, department, driver, material};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;

	})

	const cols = columns(level);

	return (
		<Tabulator
			columns={cols}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
