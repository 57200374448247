import React from 'react';
import api from '@api';
import Tabulator from 'shared/table/Tabulator';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import NewRequestAddition from './add';

const openRequest = cell => {
	const { id } = cell._cell.row.data;
	console.log(id);

	window.open(`#/transportation_prices/${id}`, '_blank');
};

const statusMapper = {
	received: 'saņemts',
	finished: 'pabeigts',
	in_progress: 'tiek apstrādāts'
};

const statusFormatter = cell => {
	const { request_status } = cell._cell.row.data;

	return statusMapper[request_status] ?? 'NEZINĀMS';
};

const columns = [
	{
		title: 'Adrese',
		field: 'a_nosaukums',
		width: 250
	},
	{
		title: 'Darb.',
		headerVertical: 'flip',
		width: 45,
		align: 'center',
		field: 'id',
		cellClick: (e, cell) => {
			openRequest(cell);
		},
		formatter: () => actionButton,
		headerSort: false
	},
	{
		title: 'Pieprasījuma<br />statuss',
		field: 'request_status',
		formatter: cell => statusFormatter(cell),
		width: 150
	},
	{
		title: 'Pieprasījums veikts',
		field: 'request_initiated_time',
		width: 200
	},
	{
		title: 'Pieprasījums izpildīts',
		field: 'request_finished',
		width: 200
	}
];

class TransportationPrice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			requests: [],
			loading: true,
			showModal: false
		};
	}

	componentDidMount() {
		this.getData();
	}

	getData = () => {
		api.transportation
			.list()
			.then(res => {
				this.setState({ requests: res });
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		const { loading, requests, showModal } = this.state;

		if (loading) {
			return '';
		}

		return (
			<div style={{ padding: 15 }}>
				<div className='rightFloat'>
					<button
						type='button'
						className='btn btn-primary'
						onClick={this.handleToggleModal}>
						Pievienot jaunu pieprasījumu
					</button>
				</div>
				<Tabulator
					columns={columns}
					options={requests.length < 50 ? { pagination: false } : { paginationSize: 50 }}
					data={requests}
				/>
				<CustomModal
					title='Jauna pieprasījuma pievienošana'
					showModal={showModal}
					handleCloseModal={this.handleToggleModal}
					content={<NewRequestAddition handleCloseModal={this.handleToggleModal} />}
				/>
			</div>
		);
	}
}

export default TransportationPrice;
