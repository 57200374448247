import React, { Component } from 'react';
import api from '@api';
import Deliveries from './table';
import Header from './header';
import Loading from 'shared/loading';
import Pagination from '../../shared/pagination/pagination';
import ErrorOnLoading from 'shared/errorOnLoad';

const ROWS_IN_ONE_PAGE = 20;

class ClientDeliveries extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: 0,
			loading: false,
			error: false,
			data: [],
			totalPages: 1,
			currentPage: 1
		};
	}

	setPagination() {
		const { number } = this.props;

		const rowsForCeil = typeof number == 'undefined' ? 1 : number;

		const pages = Math.ceil(rowsForCeil / ROWS_IN_ONE_PAGE);
		//console.log('pages - pages',rows, ROWS_IN_ONE_PAGE, pages);

		this.setState({ totalPages: pages });
	}

	setup = (loader = true) => {
		if (loader) {
			this.setState({ loading: true, error: false });
		}

		const { regNr, mode } = this.props;
		const { currentPage } = this.state;

		let cb = null;

		if (mode == 'problematic') {
			cb = api.deliveries.clientProblematic;
		} else {
			cb = api.deliveries.clientInvoiceReady;
		}

		cb({ client: regNr, page: currentPage }).then(response => {
			this.setState({ loading: false, error: false, data: response.deliveries });
		});
	}

	componentDidMount() {
		this.setup();
		this.setPagination();
	}

	handlePageChange = currentPage => {
		this.setState({ currentPage }, () => this.setup());
	};

	render() {
		const { data, error, loading, totalPages, currentPage } = this.state;

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{loading ? (
							<Loading />
						) : (
							<div>
								{totalPages == 1 ? (
									''
								) : (
									<div>
										<Pagination
											page={currentPage}
											total={totalPages}
											handlePageChange={this.handlePageChange}
										/>
									</div>
								)}
								{data.length > 0 ? (
									<Deliveries data={data} reload_data={this.setup} />
								) : (
									<div>0 piegādes</div>
								)}
								{totalPages == 1 ? (
									''
								) : (
									<div>
										<Pagination
											page={currentPage}
											total={totalPages}
											handlePageChange={this.handlePageChange}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default ClientDeliveries;
