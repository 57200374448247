/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
//import queryString from 'query-string';
import { connect } from 'react-redux';

import api from '@api';
import { toast } from 'react-toastify';
import { CustomModal } from 'shared/modal';
import PasswordResetForm from './passwordReset';
import formatUrl from 'api/formatUrl';
import microsoftLogo from 'logo/microsoft-icon.png';

/*
https://phpenthusiast.com/blog/javascript-fetch-api-tutorial
https://gist.github.com/justsml/529d0b1ddc5249095ff4b890aad5e801
https://github.com/github/fetch/issues/190
https://codepen.io/dericksozo/post/fetch-api-json-php

https://stackblitz.com/edit/react-redux-registration-login-example?file=App%2FApp.js
*/

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			localSignin: false,
			showPassword: false,
			validName: true,
			validPassword: true,
			validLogin: true,
			errorMessage: '',
			showModal: false,
			checkingLogin: false,
			isEmailsEnabled: false
		};
	}

	handleLogin = () => {
		const { username, password } = this.state;

		if (username.trim() === '' || password.trim() === '') {
			toast.error('Lietotājvārds vai parole nav aizpildīts', { autoClose: 7500 });
			//this.setState({validLogin: false, errorMessage: "Lietotājvārds vai parole nav aizpildīts"});
			return;
		}

		this.setState({ checkingLogin: true });
		this.props.loginHandler({ username, password });
		this.setState({ checkingLogin: false });
	};

	componentDidMount() {
		document.onkeypress = function(e) {
			if (!e) e = window.event;
			const keyCode = e.keyCode || e.which;
			if (keyCode == 13) {
				// Enter pressed
				return false;
			}
		};

		api.admin.services_get().then(response => {
			const isEmailsEnabled = response.email;

			this.setState({ isEmailsEnabled });
		});
	}

	redirectToSSO = () => {
		const { url: finalUrl } = formatUrl({ url: 'oauth/redirect', options: {} });
		window.location.assign(finalUrl);
	}

	handleKeyDown(event) {
		if (
			event.key === 'Enter' &&
			this.state.password.length > 0 &&
			this.state.username.length > 0
		) {
			this.handleLogin();
		}
	}

	toggleLocalLogin = () => {
		this.setState(prevState => ({ localSignin: !prevState.localSignin }));
	};

	handleToggleModal = () => {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	render() {
		const {
			username,
			password,
			showPassword,
			showModal,
			isEmailsEnabled,
			checkingLogin,
			localSignin,
		} = this.state;
		const { error } = this.props;

		return (
			<div>
				<div className='row'>
					<div className='col-md-4'></div>
					<div className='col-md-4'>
						<div className='panel panel-default'>
							<div className='panel-heading'>LATRAPS Loģistikas IS</div>
							<div className='panel-body'>
								<div className="btn-group btn-group-justified" role="group">
									<div className="btn-group" role="group">
										<button type='button' className='btn' onClick={this.redirectToSSO}>Pieslēgties <img src={microsoftLogo}></img></button>
									</div>
								</div>
								<div style={{
									borderBottom: '0.5px solid rgb(158, 185, 200)',
									lineHeight: '0.1em',
									margin: '30px 0px 30px 0px',
									textAlign: 'center',
									width: '100%'
								}}>
									<span style={{
										background: 'rgb(255, 255, 255)',
										fontWeight: 400,
										fontSize: '16px',
										padding: '0px 30px',
								}}>vai</span>
								</div>
								<div className="btn-group btn-group-justified" role="group">
									<div className="btn-group" role="group">
										<button type='button' className='btn' onClick={this.toggleLocalLogin}>Pieslēgties, izmantojot lietotājvārdu un paroli</button>
									</div>
								</div>
								{localSignin && <><form>
									{error ? <div className='alert alert-danger'>{error}</div> : ''}
									<div className='input-group'>
										<span className='input-group-addon' id='sizing-addon1'>
											<i className='fas fa-user'></i>
										</span>
										<input
											type='text'
											name='username'
											className='form-control'
											placeholder='lietotājvārds'
											value={username}
											onKeyDown={this.handleKeyDown.bind(this)}
											onChange={e => {
												this.setState({ username: e.target.value });
											}}
											aria-describedby='basic-addon1'
										/>
									</div>
									<div className='input-group'>
										<span className='input-group-addon' id='basic-addon1'>
											<span className='glyphicon glyphicon-lock'></span>
										</span>
										<input
											type={showPassword ? 'text' : 'password'}
											name='password'
											onKeyDown={this.handleKeyDown.bind(this)}
											onChange={e => {
												this.setState({ password: e.target.value });
											}}
											className='form-control'
											value={password}
											placeholder='parole'
											aria-describedby='basic-addon1'
										/>
										<span
											className='input-group-addon'
											id='basic-addon1'
											onClick={this.handleClickShowPassword}>
											<span
												className={
													showPassword
														? 'glyphicon glyphicon-eye-open'
														: 'glyphicon glyphicon-eye-close'
												}></span>
										</span>
									</div>
								</form>
								<div className='btn-group pull-right' role='group'>
									{isEmailsEnabled && (
										<button
											type='button'
											disabled={checkingLogin}
											className='btn btn-primary'
											onClick={this.handleToggleModal}>
											Aizmirsta parole?
										</button>
									)}
									<button
										disabled={checkingLogin}
										type='button'
										className='btn btn-success'
										onClick={this.handleLogin}>
										Autentificēties
									</button>
								</div></>}
							</div>
						</div>
					</div>
					<div className='col-md-4'></div>
				</div>
				<div className='col-md-4'></div>
				<CustomModal
					title='Aizmirsta parole'
					showModal={showModal}
					handleCloseModal={this.handleToggleModal}
					content={<PasswordResetForm />}
				/>
			</div>
		);
	}
}
//(() => this.props.loginHandler(this))

export default Login;
