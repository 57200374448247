import React, { Fragment } from 'react';
import moment from 'moment';
import { Bar, Doughnut, Polar } from '@iftek/react-chartjs-3';

const CHART_COLORS = {
	red: '255, 99, 132',
	orange: '255, 159, 64',
	yellow: '255, 205, 86',
	green: '75, 192, 192',
	blue: '54, 162, 235',
	purple: '153, 102, 255',
	grey: '201, 203, 207'
};

const summer = (data, key) => {
	if (!data) {
		return 0;
	}
	return data.reduce((a, b) => a + b[key], 0);
};

const TableSummary = ({ data }) => {
	console.log(data);
	return (
		<div className='deliveryEditInfo'>
			<div className='deliveryEditInfoEntry' style={{ backgroundColor: '#F0FFFF' }}>
				{data.length || 0}
				<br />
				Rēķinu skaits
			</div>
			<div className='deliveryEditInfoEntry' style={{ backgroundColor: '#ccc' }}>
				{summer(data, 'invoice_wo_pvn').toFixed(2) || 0}
				<br />
				Summa bez PVN
			</div>
			<div
				className='deliveryEditInfoEntry'
				style={{ backgroundColor: '#FFCCCC', color: '#FF0033' }}>
				{summer(data, 'invoice_pvn').toFixed(2) || 0}
				<br />
				PVN summa
			</div>
			<div className='deliveryEditInfoEntry' style={{ backgroundColor: '#FFCC66' }}>
				{summer(data, 'invoice_sum').toFixed(2) || 0}
				<br />
				Summa ar PVN
			</div>
		</div>
	);
};

const periodSummary = data => {
	const isLatraps = data.filter(entry => entry.is_latraps).length;
	const notLatraps = data.filter(entry => !entry.is_latraps).length;
	console.log(data, isLatraps, notLatraps);

	return {
		labels: ['LATRAPS', 'Ārpakalpojums'],
		datasets: [
			{
				label: 'Rēķinu skaits',
				backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
				borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
				borderWidth: 1,
				data: [isLatraps, notLatraps]
			}
		]
	};
};

const invoicesByWeek = (data, period) => {
	const invoices = data.map(entry => ({
		...entry,
		week: moment(entry.invoiceDate, 'DD.MM.YYYY').isoWeek()
	}));
	const minWeek = moment(period.start, 'DD.MM.YYYY').isoWeek();
	const maxWeek = moment(period.end, 'DD.MM.YYYY').isoWeek();

	const latrapsInvoices = [];
	const externalInvoices = [];
	const labels = [];

	for (var i = minWeek; i <= maxWeek; i++) {
		latrapsInvoices.push(
			sumWoPVN(invoices.filter(entry => entry.is_latraps && entry.week === i))
		);
		externalInvoices.push(
			-1 * sumWoPVN(invoices.filter(entry => !entry.is_latraps && entry.week === i))
		);
		labels.push(`${i}. nedēļa`);
	}
	console.log(invoices, latrapsInvoices, externalInvoices);

	return {
		labels,
		datasets: [
			{
				label: 'LATRAPS',
				// borderRadius: 10,
				backgroundColor: `rgba(${CHART_COLORS.red},0.2)`,
				borderColor: `rgba(${CHART_COLORS.red},1)`,
				borderWidth: 1,
				data: latrapsInvoices
			},
			{
				label: 'Ārpakalpojums',
				// borderRadius: 10,
				backgroundColor: `rgba(${CHART_COLORS.blue},0.2)`,
				borderColor: `rgba(${CHART_COLORS.blue},1)`,
				borderWidth: 1,
				data: externalInvoices
			}
		]
	};
};

const invoicesByDepartment = data => {
	//const isLatraps = data.filter(entry => entry.is_latraps);
	//const notLatraps = data.filter(entry => !entry.is_latraps).length;

	const values = [];
	const labels = [];
	const bgColors = [];
	const borderColors = [];

	let mapping = {};

	data.forEach(invoice => {
		mapping[invoice.department] =
			invoice.department in mapping ? mapping[invoice.department] + 1 : 1;
	});

	Object.keys(mapping).forEach((dept, idx) => {
		console.log(dept, mapping, mapping[dept]);
		values.push(mapping[dept]);
		labels.push(dept);
		const clr = Object.values(CHART_COLORS)[idx] ?? '0,0,0';
		bgColors.push(`rgba(${clr},0.2)`);
		borderColors.push(`rgba(${clr},1)`);
	});

	return {
		labels,
		datasets: [
			{
				//label: 'LATRAPS',
				backgroundColor: bgColors,
				borderColor: borderColors,
				borderWidth: 1,
				data: values
			}
		]
	};
};

const sumWoPVN = invoices => summer(invoices, 'invoice_wo_pvn').toFixed(2);

const Summary = ({ data, filters, period }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let { billNr, materials, client } = filters;
	billNr = billNr.toLowerCase();
	materials = materials.toLowerCase();
	client = client.toLowerCase();

	const filterObj = { billNr, materials, client };

	let finalData = data.filter(entry => {
		console.log(entry, entry[key]);
		for (var key in filterObj) {
			const whatToSearch = typeof entry[key] === 'object' ? entry[key].join() : entry[key];
			if (
				whatToSearch === undefined ||
				!whatToSearch.toLowerCase().includes(filterObj[key])
			) {
				return false;
			}
		}
		return true;
	});

	return (
		<div>
			<TableSummary data={finalData} />
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
				<div
					style={{
						width: 500,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}>
					<h3>Perioda kopsavilkums</h3>
					<Doughnut
						data={periodSummary(finalData)}
						options={{
							responsive: true,
							maintainAspectRatio: true,
							plugins: {
								tooltip: {
									enabled: true,
									position: 'nearest',
									callbacks: {
										label: context => {
											const { label, parsed, dataIndex } = context;

											const filterFn =
												dataIndex === 0
													? invoice => invoice.is_latraps
													: invoice => !invoice.is_latraps;

											const _sumWoPVN = sumWoPVN(finalData.filter(filterFn));

											return [
												`${label}: ${parsed}`,
												`Summa bez PVN: ${_sumWoPVN}`
											];
										}
									}
								}
							}
						}}
					/>
				</div>
				<div
					style={{
						width: 500,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}>
					<h3>Rēķinu skaits pa nodaļām</h3>
					<Polar
						data={invoicesByDepartment(finalData)}
						options={{
							ticks: { stepSize: 1 },
							responsive: true,
							maintainAspectRatio: true,
							plugins: {
								tooltip: {
									enabled: true,
									position: 'nearest',
									callbacks: {
										label: context => {
											const { label, parsed, dataIndex } = context;

											const filterFn = invoice =>
												invoice.department === label;

											const _sumWoPVN = sumWoPVN(finalData.filter(filterFn));

											return [
												`${label}: ${parsed.r}`,
												`Summa bez PVN: ${_sumWoPVN}`
											];
										}
									}
								}
							}
						}}
					/>
				</div>
			</div>
			<div
				style={{
					width: 1500,
					height: 700
				}}>
				<h3>Izrakstīto rēķinu kopsumma nedēļu griezumā</h3>
				<Bar
					data={invoicesByWeek(finalData, period)}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							tooltip: {
								enabled: true,
								position: 'nearest'
							}
						}
					}}
				/>
			</div>
		</div>
	);
};

export default Summary;
