import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';

import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import StorageEdit from './forms/storage';
import { checkPermission } from 'permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import { customTitle } from 'util';
import NoData from 'shared/noDataNotification';

import FieldForm from './filterForm';
import { MILISECONDS_BEFORE_SEARCH } from '../../config';

const actionsButtonFormatter = () => actionButton;

const mappostFormatter = cell => {
	const data = cell.getRow().getData();
	if (data.objectId == '') {
		return '';
	}

	return `${data.objectId}`;
};

const addressFormatter = cell => {
	const data = cell.getRow().getData();

	const parzinis = data.parzinis == null ? '' : `<br>${data.parzinis}`;

	return `${data.nosaukums}${parzinis}`;
};

class StorageTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			showModal: false,
			data: [],
			currTask: null,
			error: false,
			title: '',
			address: '',
			id: ''
		};
	}

	timeout = null;

	handleOpenActions(cell) {
		const { id } = cell._cell.row.data;

		this.setState({ id, showModal: true });
	}

	setupData() {
		this.setState({ loading: true, error: false });

		const { title, address } = this.state;

		api.search.storage2({ title, address, includeArchived: 1 }).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	//{"objectId":34290,"nosaukums":"VE\u0122I, \u012aSL\u012aCES PAG., BAUSKAS NOV., LV-3914","id":2240,"title":"ABRA,  LPKS","clients":["PS L\u012aDUMS SIA"]}
	columns = [
		{
			title: 'ID',
			field: 'id',
			headerTooltip: 'Noliktavas ID',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334')
		},
		{
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		},
		{
			title: 'Noliktavas nosaukums',
			field: 'title',
			titleFormatter: column => customTitle(column, 'fas fa-warehouse', '#333334')
		},
		{
			title: 'Adrese',
			field: 'nosaukums',
			formatter: cell => addressFormatter(cell),
			titleFormatter: column => customTitle(column, 'fas fa-map-marker-alt', '#333334')
		},
		{
			title: 'Mappost ID',
			cssClass: 'rightAligned',
			field: 'objectId',
			formatter: cell => mappostFormatter(cell),
			headerTooltip: 'Objekta Mappost ID',
			titleFormatter: column => customTitle(column, 'fas fa-list-ol', '#333334')
		}
	];

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ [whatToSearch]: e.target.value }, () => {
			this.timeout = setTimeout(() => {
				_self.setupData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleOpenModal(openType, cell) {
		const id = openType == 'new' ? null : cell.getRow().getData().id;

		this.setState({ showModal: true, id });
	}

	render() {
		const { data, error, loading, showModal, id } = this.state;

		const filterItems = [
			{
				visible: true,
				title: 'Nosaukums',
				inputID: 'name_input',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'title'
			},
			{
				visible: true,
				title: 'Adrese',
				inputID: 'address_input',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'address'
			}
		];

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{checkPermission('add', 'storage') && (
							<div className='rightFloat'>
								<button
									type='button'
									className='btn btn-primary'
									onClick={() => this.handleOpenModal('new')}>
									Pievienot jaunu noliktavu
								</button>
							</div>
						)}

						<FieldForm items={filterItems} />

						<div className='clearBoth'></div>
						<div style={{ padding: 5 }} />
						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<NoData />
								) : (
									<div className='tableWrapper'>
										<Tabulator
											columns={this.columns}
											options={{ pagination: false }}
											data={data}
										/>
									</div>
								)}
								<CustomModal
									title={
										id == null
											? 'Noliktavas pievienošana'
											: 'Noliktavas datu labošana'
									}
									showModal={showModal}
									handleCloseModal={this.handleToggleModal}
									content={
										<StorageEdit
											id={id}
											handleCloseModal={this.handleToggleModal}
										/>
									}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default StorageTable;
