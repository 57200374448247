import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import OrderEditModal from 'sections/ordersPlanning/newModal';
import  NewRelatedOrder from 'sections/orders/newModal';
import OneCard from 'sections/orders/card';
import Loading from 'shared/loading';
import { toast } from 'react-toastify';
import { checkPermission } from 'permissions';
import { MILISECONDS_BEFORE_CLOSE } from '../../config';
import { reloadPage , getCurrPage } from 'util';
import setRespectTabs from 'shared/tabs/setRespectTabs';

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			data: {},
			searching: false,
			showEditModal: false,
			openedModal: '',
			permissions: {}
		};
	}

	handleOrderDelete = () => {
		if (!window.confirm('Vai tiešām vēlaties dzēst pasūtījumu?')) {
			return;
		}

		api.planning.order_delete(this.state.orderId).then(response => {
			if (response.status === 'ok') {
				toast.success('Pasūtījums veiksmīgi izdzēsts', { autoClose: 2500 });
				setRespectTabs();
				setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.info('Radās kļūda, dzēšot pasūtījumu');
			}
		});
	};

	componentDidMount() {
		const { orderId } = this.props;
		this.setState({ orderId }, () => this.setup());
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId) {
			const { orderId } = this.props;

			this.setState({ orderId }, () => this.setup());
		}
	}

	setup() {
		const { orderId } = this.state;

		if (orderId == null) {
			this.setState({ data: {} });
			return;
		}
	}

	handleOrderEdit = () => {
		this.setState({ openedModal: 'orderedit' });
	};

	handleRelatedOrder = () => {
		this.setState({ openedModal: 'relatedOrder' });
	};

	handleCloseEditModal = () => {
		this.setState({ openedModal: '' });
	};

	handleCloseModal = () => {
		this.setState({ openedModal: '' });
	};

	closeModalWithoutReload = () => {
		this.setState({ openedModal: '' });
	}

	parseOrderAdd() {
		const { remainder } = this.props;

		if (!checkPermission('add', 'orders_planning')) {
			return { cantForm: true, text: 'Lietotājam nav atbilstošu tiesību.' };
		}

		return { cantForm: false, text: remainder <= 0 ? 'pasūtījuma atlikums ir 0.000' : '' };
	}

	parseOrderDelete() {
		const { relatedData } = this.props;

		if (!checkPermission('delete', 'orders_planning')) {
			return { cantDelete: true, text: 'Lietotājam nav atbilstošu tiesību.' };
		}

		if (relatedData.deliveries > 0 || relatedData.tasks > 0) {
			return { cantDelete: false, text: 'Esošajam plānošanas pasūtījumam ir piesaistīti viens vai vairāki pasūtījumi ar izpildē esošiem uzdevumiem un braucieniem. Lūdzu pirms dzēšanas pārskatiet saistītos ierakstus. Dzēšanas darbība ir neatgriezeniska!' };
		}

		if (relatedData.relatedOrders > 0) {
			return { cantDelete: false, text: 'Esošajam plānošanas pasūtījumam ir piesaistīti viens vai vairāki pasūtījumi. Lūdzu pirms dzēšanas pārskatiet saistītos ierakstus. Dzēšanas darbība ir neatgriezeniska!' };
		}

		return { cantDelete: false, text: '' };
	}

	render() {
		const { searching, openedModal } = this.state;
		const { orderId, remainder } = this.props;
		const addOrder = this.parseOrderAdd();
		const deleteOrder = this.parseOrderDelete();

		const modalContent = (
			<div>
				{searching ? (
					Loading()
				) : (
					<div className='row'>
						<div className='ActionCardContainer'>
							{OneCard({
								isDisabled: addOrder.cantForm,
								onClick: this.handleRelatedOrder,
								img: <i className='fas fa-file fa-4x'></i>,
								header: 'Formēt pasūtījumu',
								text: addOrder.text !== '' && (
										<span>
											{' '}
											<small>({addOrder.text})</small>
										</span>
									)
							})}
							{OneCard({
								isDisabled: !checkPermission('edit', 'orders_planning'),
								onClick: this.handleOrderEdit,
								img: <i className='fas fa-pencil-alt fa-4x'></i>,
								header: 'Labot pasūtījumu'
							})}
							{OneCard({
								isDisabled: deleteOrder.cantDelete,
								onClick: this.handleOrderDelete,
								img: <i className='fas fa-minus-square fa-4x'></i>,
								header: 'Dzēst pasūtījumu',
								text: deleteOrder.text !== '' && (
										<span>
											{' '}
											<small>{deleteOrder.text}</small>
										</span>
									)
							})}
						</div>
					</div>
				)}
			</div>
		);

		return (
			<div>
				<CustomModal
					title='Plānošanas pasūtījuma darbības'
					showModal={this.props.showModal}
					handleCloseModal={this.props.closeModalWithoutReload}
					content={modalContent}
				/>
				{openedModal === 'orderedit' && (
					<OrderEditModal
						showModal={openedModal === 'orderedit'}
						orderId={orderId}
						handleCloseModal={this.handleCloseModal}
						closeModalWithoutReload={this.closeModalWithoutReload}
					/>
				)}
				{openedModal === 'relatedOrder' && (
					<NewRelatedOrder
						showModal={openedModal === 'relatedOrder'}
						planningOrderId={orderId}
						planningOrderRemainder={remainder}
						handleCloseModal={this.handleCloseModal}
						closeModalWithoutReload={this.closeModalWithoutReload}
					/>
				)}
			</div>
		);
	}
}

export default Modal;
