import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import { actionButton } from 'shared/icons';
import './style.css';
import { CustomModal } from 'shared/modal';
import ShipEdit from './forms/ship';
import { checkPermission } from 'permissions';
import ErrorOnLoading from 'shared/errorOnLoad';
import { customTitle } from 'util';
import NoData from 'shared/noDataNotification';
import FieldForm from './filterForm';
import { MILISECONDS_BEFORE_SEARCH } from '../../config';

const actionsButtonFormatter = () => actionButton;

class ShipsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			error: false,
			ship: '',
		};
	}
	timeout = null;

	setupData() {
		this.setState({ loading: true, error: false });

		const { ship } = this.state;

		api.search.ship(ship).then(response => {
			this.setState({ loading: false, error: false, data: response });
		});
	}

	componentDidMount() {
		this.setupData();
	}

	columns = [
		{
			name: 'actions',
			title: 'Darbības',
			field: 'id',
			cellClick: (e, cell) => this.handleOpenModal('edit', cell),
			formatter: () => actionsButtonFormatter(),
			headerTooltip: 'Atvērt darbību sarakstu',
			titleFormatter: column => customTitle(column, 'fas fa-edit', '#333334')
		},
		{
			name: 'ship',
			title: 'Kuģis',
			field: 'title',
			headerTooltip: 'Kuģis',
			titleFormatter: column => customTitle(column, 'fas fa-ship', '#333334')
		}
	];

	handleSearch = (e, whatToSearch) => {
		const _self = this;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ [whatToSearch]: e.target.value }, () => {
			this.timeout = setTimeout(() => {
				_self.setupData();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleOpenModal(openType, cell) {
		const id = openType == 'new' ? null : cell.getRow().getData().id;
		this.setState({ showModal: true, id });
	}

	render() {
		const { data, error, loading, showModal, id } = this.state;

		const filterItems = [
			{
				visible: true,
				title: 'Kuģis',
				inputID: 'ship',
				value: '',
				cb: this.handleSearch,
				cbToSearch: 'ship'
			},
		];

		return (
			<div className='bodyWrapper'>
				{error ? (
					<ErrorOnLoading />
				) : (
					<div>
						{checkPermission('add', 'transport') &&
							<div className='rightFloat'>
								<button
									type='button'
									className='btn btn-primary'
									onClick={() => this.handleOpenModal('new')}>
									Pievienot jaunu kuģi
								</button>
							</div>
						}

						<FieldForm items={filterItems} />

						<div className='clearBoth'></div>
						<div style={{ padding: 5 }} />

						{loading ? (
							<Loading />
						) : (
							<div>
								{data.length < 1 ? (
									<NoData />
								) : (
									<div className='tableWrapper'>
										<Tabulator
											columns={this.columns}
											options={{ pagination: false }}
											data={data}
										/>
									</div>
								)}
								<CustomModal
									title={
										id == null
											? 'Kuģa pievienošana'
											: 'Kuģa datu labošana'
									}
									showModal={showModal}
									handleCloseModal={this.handleToggleModal}
									content={
										<ShipEdit
											handleCloseModal={this.handleToggleModal}
											id={id}
										/>
									}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default ShipsTable;
