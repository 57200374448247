import { createSlice } from '@reduxjs/toolkit';
import api from 'api';

const initialState = {
	orders: [/* 34200, 34044 */],
	planning_data: {},
	loading: false,
	loading_error: false,
	modal_open: false,
	actual_section: 0,
	sections: [
		{ title: 'AGRO nodaļa', api: 'agro', id: 0 },
		{ title: 'MADONA', api: 'madona', id: 1 },
		{ title: 'RĒZEKNE', api: 'rezekne', id: 3 },
		{ title: 'CITI pasūtījumi', api: 'other', id: 2 },
		{ title: 'Bez piegādes virziena', api: 'wo_direction', id: 4 }
	]
};

const planningSlice = createSlice({
	name: 'planning',
	initialState,
	reducers: {
		startRequest: (state, action) => {
			state.loading = true;
		},
		setData: (state, action) => {
			state.loading = false;
			state.planning_data = action.payload;
		},
		includeOrder: (state, action) => {
			state.orders.push(action.payload);
		},
		clearIncludedOrders: (state, action) => {
			state.orders = [];
		},
		toggleModal: (state, action) => {
			state.modal_open = !state.modal_open;
		},
		setSection: (state, action) => {
			state.actual_section = action.payload;
		},
		removeOrder: (state, action) => {
			const currOrder = action.payload;

			return {
				...state,
				orders: state.orders.filter((order) => order !== currOrder)
			}
		},
	},
});

const {actions, reducer} = planningSlice;

export const loadData = () => (dispatch) => {
	dispatch(actions.startRequest())

	api.deliveries.planning2().then(response => {
		dispatch(actions.setData(response))
	});
}

export const handleInclusion = (orderId) => (dispatch, getState) => {
	const currentlyIncluded = getState().planning.orders;

	if (currentlyIncluded.includes(orderId)) {
		dispatch(actions.removeOrder(orderId))
		return;
	}

	dispatch(actions.includeOrder(orderId))
}

export const resetInclusions = () => (dispatch) => {
	dispatch(actions.clearIncludedOrders())
}

export const sectionDataSelector = state => {
	const {sections, actual_section} = state.planning;
	const sectionName = sections[actual_section].api;

	return state.planning.planning_data[sectionName];
}

export const {setSection, toggleModal} = actions;

export default reducer;
