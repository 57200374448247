//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import Card from 'shared/actionButtons/card';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '@api';
import { Collapse } from 'react-collapse';
import OrderEditModal from './newModal';
import Loading from 'shared/loading';
import { Radio } from '../../shared/form';
import { toast } from 'react-toastify';
import { object } from 'prop-types';
import Deliveries from '../deliveries/table';
import Header from '../deliveries/header';
import NoData from 'shared/noDataNotification';

class UzdevumiModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			orderNr: '',
			data: {},
			searching: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null
		};
	}

	componentDidMount() {
		const { orderId, orderNr, planningOrderId } = this.props;

		this.setState({ orderId, orderNr, planningOrderId }, () => this.setup());
	}

	handleCloseEditModal = () => {
		this.props.handleCloseEditModal();
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId || this.props.planningOrderId !== prevProps.planningOrderId) {
			const { orderId, orderNr, planningOrderId } = this.props;

			this.setState({ orderId, orderNr, planningOrderId }, () => this.setup());
		}
	}

	setup = (loader = true) => {
		const { orderId, planningOrderId } = this.state;

		if (orderId === null && planningOrderId === null) {
			//šis izpildās, kad aizver modal logu
			this.setState({ data: {} });
			return;
		}

		if (loader) {
			this.setState({ loading: true, error: false });
		}

		if (planningOrderId) {
			api.planning.order_tasks(planningOrderId).then(response => {
				this.setState({ searching: false, error: false, data: response });
			});
			return;
		}

		api.deliveries.deliveryOrder(orderId).then(response => {
			this.setState({ searching: false, error: false, data: response });
		});
	}

	closeModalWithoutReload = () => {
		this.setState({
			showEditModal: false
		});
	}

	render() {
		const { data, searching, orderNr, planningOrderId } = this.state;

		const title = planningOrderId ?
			`Plānošanas pasūtījuma #${planningOrderId} reģistrētie uzdevumi` :
			`Pasūtījuma #${orderNr} reģistrētie uzdevumi`;

		return (
			<div>
				<CustomModal
					modalClass='fullWidthModal'
					title={title}
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						searching ? (
							Loading()
						) : (
							<div>
								{data.length > 0 ? (
									data.map((delivery, ind) => (
										<div key={ind}>
											<Header data={delivery} mode='normal' resetData={this.setup} />
											<br />
											<Deliveries data={delivery.taskDeliveries.deliveries} reload_data={this.setup} />
										</div>
									))
								) : (
									<NoData />
								)}
							</div>
						)
					}
				/>
				<OrderEditModal
					showModal={this.state.showEditModal}
					handleCloseModal={this.handleCloseEditModal}
					closeModalWithoutReload={this.closeModalWithoutReload}
				/>
			</div>
		);
	}
}
//

export default UzdevumiModal;
