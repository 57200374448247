import api from './api';

import auth from './controllers/auth';
import bills from './controllers/bills';
import clients from './controllers/clients';
import orders from './controllers/orders';
import storage from './controllers/storage';
import search from './controllers/search';
import deliveries from './controllers/deliveries';
import filter from './controllers/filter';
import tasks from './controllers/tasks';
import mappost from './controllers/mappost';
import roles from './controllers/roles';
import reports from './controllers/reports';
import admin from './controllers/admin';
import user from './controllers/user';
import transportation from './controllers/transportation';
import favorites from './controllers/favorites';
import planning from './controllers/planning';

const apiWr = {
	auth,
	bills,
	clients,
	orders,
	storage,
	search,
	deliveries,
	filter,
	tasks,
	mappost,
	roles,
	admin,
	user,
	transportation,
	reports,
	favorites,
	planning
};

export default apiWr;
