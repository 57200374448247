import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import Edit from 'sections/ordersPlanning/editModal';

class OrderEditModal extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { orderId } = this.props;

		const title = orderId == null ? 'Plānošanas pasūtījuma reģistrēšana' : 'Plānošanas pasūtījuma labošana';

		return (
			<div>
				<CustomModal
					title={title}
					showModal={this.props.showModal}
					handleCloseModal={() => {
						console.log('handleCloseModal')
						this.props.closeModalWithoutReload()
					}}
					content={
						<div>
							<Edit
								orderId={orderId}
								handleClose={this.props.handleCloseModal}
								handleCloseMainModal={this.props.closeMainModal}
							/>
						</div>
					}
				/>
			</div>
		);
	}
}

export default OrderEditModal;
