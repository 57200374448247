//import './bootstrap.scss';
import './index.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
//import App from './components/App';
import store from './store';
/* import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing'; */
import AppContainer from './sections/app/appContainer';
/*
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.css';
 */
//import './fontawesome';

//import sendError from './sendErrors';

const isLatrapsProdEnv = process.env.REACT_APP_IS_PROD_LATRAPS;

if (isLatrapsProdEnv == 'true' && process.env.NODE_ENV === 'production') {
	/* Sentry.init({
		dsn: 'https://48c10d48466d4691bc4a0f39b8ab31e9@sentry.kartes.lv/6',
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0
	}); */
}

//const {whyDidYouUpdate} = require('why-did-you-update');
//whyDidYouUpdate(React);

render(
	<Provider store={store}>
		<AppContainer />
	</Provider>,
	document.getElementById('root')
);

/* window.onerror = function(message, source, lineno, colno, error) {
	if (error) message = error.stack;
	sendError(message, source, lineno);
}; */
