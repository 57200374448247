import moment from 'moment';

export const getCurrentYear = () => {
	return (new Date()).getFullYear();
}

export const getYesterday = () => {
	return moment().subtract( 1, 'day' );
}

export const getToday = () => {
	return moment();
}

export const formatDateForDB = inputData => {
	if (inputData === '' || inputData === null || typeof inputData === 'undefined') {
		return null;
	}
	//todo: šo nevar sakārtot? vienā chaain?  -> moment(inputData, "DD.MM.YYYY").format("YYYYMMDD")
	const dateInst = moment(inputData, 'DD.MM.YYYY');

	return moment(dateInst).format('YYYYMMDD');
};
