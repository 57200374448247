//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import Edit from '@sections/orders/edit/edit';

class OrderEditModal extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { orderId, clientRegNr, orderIdCopy, planningOrderId, planningOrderRemainder } = this.props;

		const title = orderId == null ? 'Pasūtījuma reģistrēšana' : 'Pasūtījuma labošana';

		return (
			<div>
				<CustomModal
					title={title}
					showModal={this.props.showModal}
					handleCloseModal={() => {
						console.log('handleCloseModal')
						this.props.closeModalWithoutReload()
					}}
					content={
						<div>
							<Edit
								orderId={orderId}
								orderIdCopy={orderIdCopy}
								planningOrderId={planningOrderId}
								planningOrderRemainder={planningOrderRemainder}
								clientRegNr={clientRegNr}
								handleClose={this.props.handleCloseModal}
								handleCloseMainModal={this.props.closeMainModal}
							/>
							{/** iespējams, vēl jāpadod klienta ID? */}
						</div>
					}
				/>
			</div>
		);
	}
}
//

export default OrderEditModal;
