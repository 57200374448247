import api from '../api';

const roles = {
	get_all: () => api.get(`roles/get_all`),

	data: role => api.get(`roles/get_role?role=${role}`),

	savePerms: ({ role, permissions }) => api.post(`roles/save_data`, { role, permissions })
};

export default roles;
