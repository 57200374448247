import React, { Component } from 'react';

import api from '@api';
import Loading from 'shared/loading';
import moment from 'moment';

import Filter from './filter';
import Table from './table';
import { apiEndpoint } from 'config';

class OrderList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			status: 'init',
			format: 'table',
			start: moment().startOf('month'),
			end: moment(),
			filters: {
				client: '', storage: '', destination: '', department: '', material: ''
			}
		};
	}

	setup() {
		const { start, end } = this.state;
		this.setState({ status: 'search' });

		api.reports.orders(start.format('YYYYMMDD'), end.format('YYYYMMDD')).then(response => {
			this.setState({ status: 'loadedData', data: response });
		});
	}

	exportData = () => {
		const { start, end } = this.state;

		const url = `${apiEndpoint}reports/orders?start=${start.format(
			'YYYYMMDD'
		)}&end=${end.format('YYYYMMDD')}&export`;
		window.open(url, '_blank');
	};

	componentDidMount() {
		this.setup();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.start !== this.state.start || prevState.end !== this.state.end) {
			this.setup();
		}
	}

	setDates = (start, end) => {
		this.setState({
			start,
			end
		});
	};

	handleFilterChange = (ev, field) => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;

		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				[name]: inputValue
			}
		}))
	}

	render() {
		const { status, data, start, end, filters } = this.state;

		if (status === 'search') {
			return (
				<div className='bodyWrapper'>
					<Loading />
				</div>
			);
		}

		return (
			<div className='bodyWrapper'>
				<Filter
					start={start}
					end={end}
					applyCallback={this.setDates}
					exportData={this.exportData}
					filters={filters}
					filterCB={this.handleFilterChange}
				/>
				<hr className='divider' />
				<Table data={data} filters={filters} />
				<div className='report_overview'>
					<h4>Kopsavilkums</h4>
					<div>Ierakstu skaits: {data.length}</div>
				</div>
			</div>
		);
	}
}

export default OrderList;
