//https://stackoverflow.com/questions/52447828/is-there-a-way-to-modify-the-page-title-with-react-router-v4
//https://github.com/supasate/connected-react-router
import React from 'react';
import api from 'api';

class Page extends React.Component {
	componentDidMount() {
		this.handleSetTitle();
		this.saveNewRoute();
	}

	saveNewRoute = () => {
		const newURL = this.props.match.url;
		api.admin.new_url({ url: newURL });
	};

	handleSetTitle = () => {
		console.log(this.props);
		const title = this.props.title ? `${this.props.title} – Latraps LLIS` : 'Latraps LLIS';
		document.title = title;
	};

	componentDidUpdate = prevProps => {
		if (this.props.title != prevProps.title) {
			//console.log('PAGE_CHANGE')
			this.handleSetTitle();
			this.saveNewRoute();
			//localStorage.setItem(`logistika-tabs-respect`, '');
		}
	};

	render() {
		const { component: PageComponent, ...otherProps } = this.props;

		return <PageComponent {...otherProps} />;
	}
}

export default Page;
