import React, { Fragment } from 'react';
import Tabulator from 'shared/table/Tabulator';

const columns = [
	{
		title: 'Datums',
		field: 'date'
	},
	{
		title: 'Klients',
		field: 'client',
		//headerFilter: 'input',
		width: 180
	},
	{
		title: 'No',
		field: 'storage',
		//headerFilter: 'input',
		width: 180
	},
	{
		title: 'Uz',
		field: 'destination',
		//headerFilter: 'input',
		width: 180
	},
	{
		title: 'Nodaļa',
		field: 'department',
		//headerFilter: 'input',
		width: 180
	},
	{
		title: 'Produkts',
		field: 'material',
		//headerFilter: 'input',
		width: 180
	},
	{
		title: 'Apjoms,<br />kopējais',
		align:"right", 
		field: 'amount'
	},
	{
		title: 'Pasūtītāja<br />cena',
		align:"right", 
		field: 'orderer_price'
	},
	{
		title: 'Pārvadātāja<br />cena',
		align:"right", 
		field: 'transporter_price'
	}
];

const Table = ({ data, filters }) => {
	if (data.length < 1) {
		return <Fragment></Fragment>;
	}

	let {client, storage, destination, department, material} = filters;
	client = client.toLowerCase();
	storage = storage.toLowerCase();
	destination = destination.toLowerCase();
	department = department.toLowerCase();
	material = material.toLowerCase();
	
	const filterObj = {client, storage, destination, department, material};

	let finalData = data.filter(entry => {
		for (var key in filterObj) {
			if (entry[key] === undefined || !entry[key].toLowerCase().includes(filterObj[key])) {
				return false;
			}
		}
		return true;
  
	})

	return (
		<Tabulator
			columns={columns}
			options={finalData.length < 50 ? { pagination: false } : { paginationSize: 50 }}
			data={finalData}
		/>
	);
};

export default Table;
