import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import Switch from 'react-bootstrap-switch';
import { Button } from 'react-bootstrap';
import { Input, Label, Select, Textarea, Radio, Check } from '../../shared/form';
import { isNumeric } from 'util';
import { checkPermission } from '../../permissions';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import api from '@api';
import { MILISECONDS_BEFORE_CLOSE } from 'config';

class MassDeliveryEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryCheckedForBill: false,
            ordererPriceApprove: false,
            addToInvoice: false,
            isSelected: props.isSelected,
            data: props.data,
			cena: '',
			piegades_statuss_id: '',
			pasutitajaCena: '',
			cenaPar: '',
			pasutCenaPar: '',
            carrierDeliveries: {},
            toSaveData: {},
			pricesSelect: [],
        };
    }

    componentDidMount() {
        this.groupSelectedDeliveriesByCarrier();
		const firstDelivery = this.state.data.find(d => this.state.isSelected.includes(d.deliveryId));
		api.deliveries
			.editInfo({ delivery: firstDelivery?.deliveryId, task: firstDelivery?.taskId })
			.then(response => {
				this.setState({
					pricesSelect: response.priceForData ?? [],
				});
			});

    }

    componentDidUpdate(prevProps) {
        const stateUpdates = {};
        if (prevProps.isSelected !== this.props.isSelected) {
            stateUpdates.isSelected = this.props.isSelected;
        }
        if (prevProps.data !== this.props.data) {
            stateUpdates.data = this.props.data;
        }
        if (Object.keys(stateUpdates).length > 0) {
            this.setState(stateUpdates, this.groupSelectedDeliveriesByCarrier);
        }
    }

    groupSelectedDeliveriesByCarrier = () => {
        const { isSelected, data } = this.state;
        const deliveriesGroupedByCarrierTitle = data.reduce((acc, delivery) => {
            if (isSelected.includes(delivery.deliveryId)) {
                const carrierTitle = delivery.carrierData?.title || 'unknown';
                if (!acc[carrierTitle]) {
                    acc[carrierTitle] = { deliveries: [] };
                }
                acc[carrierTitle].deliveries.push(delivery);
            }
            return acc;
        }, {});

        this.setState({ carrierDeliveries: deliveriesGroupedByCarrierTitle });
    };

    generateDeliveryGroupHeader = (deliveries) => {
        const carrierTitle = deliveries[0]?.carrierData?.title || "NAV ZINĀMS";
        const deliveryIds = deliveries.map(delivery => delivery.deliveryId).join(', ');
        return `Pārvadātājs: ${carrierTitle} (Piegādes ID: ${deliveryIds})`;
    };

    setDataToState = (carrierTitle, deliveryIds, key, value) => {
        const idsArray = Array.isArray(deliveryIds) ? deliveryIds : [deliveryIds];

        this.setState(prevState => {
            let newState = { ...prevState };

            idsArray.forEach(deliveryId => {
                const currentData = newState.toSaveData[deliveryId] || {};
                const updatedData = { ...currentData, [key]: value };
                const invoiceReceived = updatedData.invoiceNr && updatedData.invoiceDate && updatedData.invoiceTotal ? 1 : 0;

				if (['invoiceNr', 'invoiceDate', 'invoiceTotal'].includes(key)) {
					newState.toSaveData[deliveryId] = {
						...updatedData,
						invoiceReceived,
					};
				} else {
					newState.toSaveData[deliveryId] = {
						...updatedData,
					};
				}

				newState = { ...newState, [key]: value };

                // Find and update carrierDeliveries
                const carrierDelivery = newState.carrierDeliveries[carrierTitle];
                if (carrierDelivery && carrierDelivery.deliveries) {
                    carrierDelivery.deliveries = carrierDelivery.deliveries.map(delivery => {
                        if (delivery.deliveryId === deliveryId) {
                            return {
                                ...delivery,
                                [key]: value,
                                invoiceReceived,
                            };
                        }
                        return delivery;
                    });
                }
            });

            return newState;
        });
    };

    handleDateChange = (carrierTitle, deliveryIds, key) => event => {
        try {
            this.setDataToState(carrierTitle, deliveryIds, key, event.format('YYYYMMDD'));
        } catch (err) {
            console.error("Error handling date change", err);
        }
    };

    handleChange = (carrierTitle, deliveryIds, event) => {
        let inputValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const name = event.target.name;

        if (inputValue && typeof inputValue === 'string' && inputValue.length > 0 && ['invoiceTotal'].includes(name)) {
            if (!isNumeric(inputValue)) {
                return false;
            }
            inputValue = inputValue.trim();
        }

        this.setDataToState(carrierTitle, deliveryIds, name, inputValue);
    };

    handleSwitchAllDeliveries = (elem, state, name) => {
        this.setState(prevState => {
            const newToSaveData = { ...prevState.toSaveData };
            prevState.isSelected.forEach(deliveryId => {
                newToSaveData[deliveryId] = {
                    ...newToSaveData[deliveryId],
                    [name]: state
                };
            });
            return { toSaveData: newToSaveData };
        });
    };

    displayGroupedDeliveries = () => {
        const { carrierDeliveries } = this.state;

        return Object.entries(carrierDeliveries).map(([carrierTitle, group], index) => {
            return (
                <div key={index} style={{
                    backgroundColor: '#f0f0f0',
                    padding: '5px',
                    marginBottom: '5px',
                    marginTop: '5px',
                    borderRadius: '5px',
                }}>
                    <Label
                        labelClass=''
                        title={this.generateDeliveryGroupHeader(group.deliveries)}
                        name='carrierTitle'
                        style={{
                            color: '#333',
                            margin: '5px',
                            display: 'block',
                            whiteSpace: 'normal',
                            fontSize: '12px',
                        }}
                    />
                    {this.renderInvoiceFieldsForCarrierGroup(carrierTitle, group)}
                </div>
            );
        });
    }

    renderInvoiceFieldsForCarrierGroup = (carrierTitle, group) => {

        const { deliveries } = group;
        const firstDeliveryId = deliveries[0].deliveryId;
        const invoiceData = this.state.toSaveData[firstDeliveryId] || {};
        const { invoiceNr = '', invoiceDate = '', invoiceTotal = '', invoiceReceived = 0 } = invoiceData;

        return (
            <div style={{
                backgroundColor: '#fff',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}>
                <div className='row'>
                    <div className='col-sm-3'>
                        <Label labelClass='' title='Rēķina numurs' name='invoiceNr' />
                        <Input
                            inputClass=''
                            name='invoiceNr'
                            value={invoiceNr}
                            handleChange={(event) => this.handleChange(carrierTitle, deliveries.map(delivery => delivery.deliveryId), event)}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <Label labelClass='' title='Datums' name='invoiceDate' />
                        <Datetime
                            locale='lv'
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={this.handleDateChange(carrierTitle, deliveries.map(delivery => delivery.deliveryId), 'invoiceDate')}
                            defaultValue={invoiceDate !== 'Invalid date' ? invoiceDate : ''}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <Label labelClass='' title='Kopsumma' name='invoiceTotal' />
                        <Input
                            inputClass=''
                            name='invoiceTotal'
                            value={invoiceTotal}
                            handleChange={(event) => this.handleChange(carrierTitle, deliveries.map(delivery => delivery.deliveryId), event)}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <Label labelClass='' title='Rēķins saņemts' name='invoiceReceived' />
						<br />
                        <Switch
                            offColor='warning'
                            onColor='success'
                            onText='Jā'
                            offText='Nē'
                            value={Boolean(invoiceReceived)}
                            readonly={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

	handleSave = () => {
		const { toSaveData } = this.state;

		this.setState({ saving: true });

		api.deliveries
			.massUpdate({ body: toSaveData })
			.then(response => {
				if (response.status == 'error') {
					toast.warn(response.message, { autoClose: 10000 });
				} else if (response.status == 'ok') {
					toast.success('Dati saglabāti');
					this.setState({ saving: false });
					setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
				} else {
					toast.warn(`Ir problēmas! ${response.message}`, { autoClose: 7000 });
				}
			});
	}

    render() {
        const { showModal, handleCloseModal } = this.props;
        const { deliveryCheckedForBill, ordererPriceApprove, cena, pasutitajaCena, pricesSelect, cenaPar, pasutCenaPar, piegades_statuss_id, addToInvoice } = this.state;
        console.log("Mass edit state:", this.state.data.filter(d => this.state.isSelected.includes(d.deliveryId)));
        return (
            <div>
                <CustomModal
                    title="Labot atzīmētās piegādes"
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    content={
                        <div style={{
                            marginBottom: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            fontSize: '14px',
                            padding: '10px',
                        }}>
                            <div className='row'>
                                <Label
                                    labelClass='col-sm-6'
                                    title='Dati ir pārbaudīti un gatavi iekļaušanai rēķinā'
                                    name='deliveryCheckedForBill'
                                />
								<div className='col-sm-3'>
                                <Switch
                                    name='deliveryCheckedForBill'
                                    offColor='warning'
                                    onColor='success'
                                    onText='Jā'
                                    offText='Nē'
                                    defaultValue={
                                        deliveryCheckedForBill
                                    }
                                    onChange={(el, state) =>
                                        this.handleSwitchAllDeliveries(el, state, 'deliveryCheckedForBill')
                                    }
                                />
								</div>
                            </div>
                            <div className='row'>
                                <Label
                                    labelClass='col-sm-6'
                                    title='Pievienot rēķinam'
                                    name='addToInvoice'
                                />
								<div className='col-sm-3'>
                                <Switch
                                    name='addToInvoice'
                                    offColor='warning'
                                    onColor='success'
                                    onText='Jā'
                                    offText='Nē'
                                    defaultValue={
                                        addToInvoice
                                    }
                                    onChange={(el, state) =>
                                        this.handleSwitchAllDeliveries(el, state, 'addToInvoice')
                                    }
                                />
								</div>
                            </div>
                            <div className='row'>
                                <Label
                                    labelClass='col-sm-6'
                                    title='Klienta rēķina cena ir apstiprināta'
                                    name='ordererPriceApprove'
                                />
								<div className='col-sm-3'>
                                <Switch
                                    offColor='warning'
                                    onColor='success'
                                    onText='Jā'
                                    offText='Nē'
                                    defaultValue={
                                        ordererPriceApprove
                                    }
                                    onChange={(el, state) =>
                                        this.handleSwitchAllDeliveries(el, state, 'ordererPriceApprove')
                                    }
                                />
								</div>
                            </div>
							<div className='row'>
								<Label labelClass='col-sm-6' title='Pārvadātāja cena' name='cena' />
								<Input
									inputClass='col-sm-4'
									name='cena'
									value={cena}
									handleChange={(event) => this.handleChange(null, this.state.isSelected, event)}
								/>
								<Select
									selectClass='col-sm-2'
									handleChange={(event) => this.handleChange(null, this.state.isSelected, event)}
									name='cenaPar'
									value={cenaPar && pricesSelect ? pricesSelect.find(price => price.par === cenaPar || price.par_id === cenaPar)?.par_id : ''}
									placeholder='Izvēlies vērtību'
									options={
										pricesSelect
											? pricesSelect.map(price => ({
													label: price.par,
													value: price.par_id
											}))
											: []
									}
								/>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-6'
									title='Klienta rēķina cena'
									name='pasutitajaCena'
								/>
								<Input
									inputClass='col-sm-4'
									name='pasutitajaCena'
									value={pasutitajaCena}
									handleChange={(event) => this.handleChange(null, this.state.isSelected, event)}
								/>
								<Select
									selectClass='col-sm-2'
									handleChange={(event) => this.handleChange(null, this.state.isSelected, event)}
									name='pasutCenaPar'
									value={pasutCenaPar && pricesSelect ? pricesSelect.find(price => price.par === pasutCenaPar || price.par_id === pasutCenaPar)?.par_id : ''}
									placeholder='Izvēlies vērtību'
									options={
										pricesSelect
											? pricesSelect.map(price => ({
													label: price.par,
													value: price.par_id
											}))
											: []
									}
								/>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-6'
									title='Piegādes statuss'
									name='piegades_statuss_id'
								/>
								<Select
									selectClass='col-sm-6'
									handleChange={(event) => this.handleChange(null, this.state.isSelected, event)}
									name='piegades_statuss_id'
									value={piegades_statuss_id}
									placeholder='Izvēlies vērtību'
									options={
										[
											{label: 'piegādes formēšana', value: '1'},
											{label: 'atdots izpildē', value: '2'},
											{label: 'aizvests', value: '3'},
										]
									}
								/>
							</div>
                            <div>
                                {this.displayGroupedDeliveries()}
                            </div>
							<button
								type='button'
								className='btn btn-secondary btn-lg'
								onClick={this.handleSave}>
								Saglabāt
							</button>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default MassDeliveryEdit;
