import React, { Component } from 'react';
import api from '@api';
import { MILISECONDS_BEFORE_SEARCH } from '../../config';
import Loading from 'shared/loading';
import { checkPermission } from 'permissions';
import { CustomModal } from 'shared/modal';
import CreateClient from './create';

const formatter = (clientType, data) => {
	if (clientType === 'J') {
		return {
			icon: 'fas fa-building',
			nr: data['showLV'] + data['regNR'],
			nrType: 'PVN reģ. nr.'
		};
	} else if (clientType === 'F') {
		return {
			icon: 'fas fa-user',
			nr: `${data['regNR'].slice(0, 6)}-${data['regNR'].slice(-5)}`,
			nrType: 'Pers. kods'
		};
	}

	return { icon: '', nr: '', nrType: '' };
};

const searchItem = (client, ind) => {
	const configType = formatter(client.clientType, client);

	return (
		<div
			onClick={() => window.open(`#/client/${client['regNR']}`, '_self')}
			style={{ cursor: 'pointer' }}
			className='thumbnail'
			key={ind}>
			<div className='caption'>
				<span style={{ fontWeight: 'bolder' }}>
					<i className={configType.icon}></i> {client.clientName} {client.archived && <small>(nav aktīvs klients)</small>}
				</span>
				<br />
				{configType.nrType}: {configType.nr}
				<br />
				{client.address && <span>Adrese: {client.address}</span>}
				{client.phone && <>
						<br /><i className='fas fa-phone'></i> {client.phone}
					</>}
				{client.email && <>
						<br /><i className='fas fa-envelope'></i> {client.email}
					</>}
			</div>
		</div>
	);
};

class Search extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchValue: '',
			suggestions: [],
			searching: false,
			haveSearched: false,
			previousSearches: {},
			showModal: false
		};

		this.textInput = React.createRef();
	}

	componentDidMount() {
		this.textInput.current.focus();
	}

	handleSearch() {
		const { searchValue, previousSearches } = this.state;

		if (searchValue.length < 1) {
			this.setState({ suggestions: [], haveSearched: false }); //gļuko - pēc ātras nodžēšanas paliek rezultāti
			return;
		}
		if (searchValue in previousSearches) {
			this.setState({ suggestions: previousSearches[searchValue] });
			return;
		}

		this.setState({ searching: true, error: false });

		api.clients.searchClient(encodeURIComponent(searchValue)).then(response => {
			//console.log('...',response);
			this.setState({
				searching: false,
				error: false,
				suggestions: response,
				haveSearched: false,
				previousSearches: {
					...this.state.previousSearches,
					[searchValue]: response
				}
			});
		});
	}

	timeout = null;

	handleChange = event => {
		const inputValue = event.target.value;

		if (inputValue.length < 2) {
			clearTimeout(this.timeout);
		}

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.setState({ searchValue: inputValue, haveSearched: true }, () => {
			this.timeout = setTimeout(() => {
				this.handleSearch();
			}, MILISECONDS_BEFORE_SEARCH);
		});
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		const { searchValue, suggestions, searching, haveSearched, showModal } = this.state;

		const addClient = searchValue && suggestions.length == 0 && !searching && !haveSearched && checkPermission('add', 'clients');

		return (
			<div className='bodyWrapper'>
				<div className='row'>
					<div className='col-sm-3'>
						<input
							className='form-control'
							type='text'
							value={searchValue}
							onChange={this.handleChange}
							ref={this.textInput}
						/>
					</div>
				</div>
				<div style={{ paddingBottom: 10 }} />
				<div className='row' style={{ paddingLeft: 15 }}>
					{suggestionList({ searchValue, searching, suggestions, haveSearched })}
					{addClient &&
						<div style={{ paddingTop: 5 }}>
							<button
								type='button'
								className='btn btn-primary'
								onClick={this.handleToggleModal}>
								Pievienot jaunu klientu
							</button>
						</div>}
				</div>
				<CustomModal
					title='Pievienot jaunu klientu'
					showModal={showModal}
					handleCloseModal={this.handleToggleModal}
					content={<CreateClient handleCloseModal={this.handleToggleModal} />}
				/>
			</div>
		);
	}
}

const suggestionList = ({ searching, suggestions, searchValue, haveSearched }) => {
	if (searching) {
		return <Loading />;
	}

	if (searchValue.length == 0 || (suggestions.length == 0 && haveSearched)) {
		return '';
	}

	return (
		<div>
			{suggestions.length > 0
				? suggestions.map((client, ind) => searchItem(client, ind))
				: 'Nekas netika atrasts'}
		</div>
	);
};

export default Search;
