import React, { Component } from 'react';
import * as formatter from './formatters';
import { connect } from 'react-redux';

import MyCustomTable from '../../../latraps_table';

const handleOpenOrderer = data => {
	window.open(`#/client/${data.regNr}`, '_self');
};

class Table extends Component {
	openActionsModal = row => {
		this.props.modalOpeningHandler(row, 'actions');
	}
	columns = [
		{
			Header: () => (
				<i title='N.p.k.' className='fas fa-list-ol' style={{ color: '#333334' }}></i>
			),
			accessor: 'npk',
			width: 45,
			bgColor: row => formatter.npkColumnBackground(row),
			Cell: ({ row }) => formatter.npkFormatter(row, this.props.setAsViewedHandler)
		},
		{
			Header: () => (
				<i
					title='Atvērt darbību sarakstu'
					className='fas fa-edit'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			clickHandler: this.openActionsModal,
			accessor: 'orderId',
			Cell: ({ row }) => (
				<div>
					<i
						className='fas fa-ellipsis-v'
						style={{ fontSize: '1.6em', textAlign: 'center', color: 'green' }}></i>
				</div>
			)
		},
		{
			Header: () => (
				<i title='Piegādāt līdz datumam' className='fas fa-clock' style={{ color: '#333334' }}></i>
			),
			width: 45,
			accessor: 'deliverTillMandantory',
			Cell: ({ row }) => <div>{formatter.deliverTillMandantory(row.original.deliverTillMandantory)}</div>
		},
		{
			Header: () => (
				<i
					title='Uzdevumu skaits'
					className='fas fa-tasks'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			columnClass: 'orderedAmount',
			accessor: 'numberOfTasks',
			Cell: ({ row }) => (
				<div
					onClick={() => this.props.modalOpeningHandler(row.original, 'tasks')}>
					{row.original.numberOfTasks}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Vedumu skaits' className='fas fa-tasks' style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 45,
			align: 'center',
			columnClass: 'deliveredAmount',
			accessor: 'numberOfVedumi',
			Cell: ({ row }) => (
				<div
					onClick={() => this.props.modalOpeningHandler(row.original, 'vedumi')}>
					{row.original.numberOfVedumi}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Pas. nr.' className='fas fa-edit' style={{ color: '#333334' }}></i>
			),
			accessor: 'orderNr',
			Cell: ({ row }) => (
				<div>
					<b>#{row.original.orderNr}</b>
					<br />
					{row.original.plannedCompletionDate}
					<br />
					{row.original.planningOrderNr &&
						<b title='Saistītais plānošanas pas. nr.' style={{ fontSize: '90%' }}>#{row.original.planningOrderNr}
							{row.original.reservationOrder && <span style={{ color: 'red' }}> (rezervācija)</span>}
						</b>
					}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Pasūtītājs' className='fas fa-building' style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'orderer',
			Cell: ({ row }) => (
				<div className='shrinkLines' onClick={() => handleOpenOrderer(row.original)}>
					{formatter.addressFormatter({ data: row.original, type: 'orderer' })}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Noliktava' className='fas fa-warehouse' style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'storage',
			Cell: ({ row }) => (
				<div className='shrinkLines'>
					{formatter.addressFormatter({ data: row.original, type: 'storage' })}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Piegādes adrese'
					className='fas fa-map-pin'
					style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'delivery',
			Cell: ({ row }) => (
				<div className='shrinkLines'>
					{formatter.addressFormatter({ data: row.original, type: 'delivery' })}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Materiāls, piezīmes'
					className='fas fa-clipboard'
					style={{ color: '#333334' }}></i>
			),
			width: 140,
			accessor: 'material',
			Cell: ({ row }) => (
				<div className='shrinkLines'>{formatter.materialFormatter(row.original)}</div>
			)
		},
		{
			Header: () => (
				<i
					title='Pasūtītais daudzums'
					className='fas fa-shipping-fast'
					style={{
						color: '#e8e8e8',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			accessor: 'orderedAmount',
			columnClass: 'orderedAmount',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{formatter.amountFormatter(row.original, 'orderedAmount')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Pasūtījuma atlikums'
					className='fas fa-shipping-fast'
					style={{
						color: '#FFCCCC',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			accessor: 'remainder',
			columnClass: 'remainingAmount',
			Cell: ({ row }) => (
				<div>
					{formatter.amountFormatter(row.original, 'remainder')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Daudzums, kas pašlaik ir uzdevumos'
					className='fas fa-shipping-fast'
					style={{
						color: '#FFCC66',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			accessor: 'inTasks',
			columnClass: 'amountInTasks',
			Cell: ({ row }) => (
				<div>
					{formatter.amountFormatter(row.original, 'inTasks')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Aizvestais daudzums'
					className='fas fa-shipping-fast'
					style={{
						color: '#CCFF99',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			columnClass: 'deliveredAmount',
			accessor: 'deliveredByFact',
			Cell: ({ row }) => (
				<div>
					{formatter.amountFormatter(row.original, 'deliveredByFact')}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Izmaksas, EUR'
					className='fas fa-euro-sign'
					style={{ color: '#333334' }}></i>
			),
			accessor: 'expenses'
		},
		{
			Header: () => (
				<i title='Statuss' className='fas fa-signal' style={{ color: '#333334' }}></i>
			),
			accessor: 'status',
			Cell: ({ row }) => <div>{formatter.statusFormatter(row.original)}</div>
		},
		{
			Header: () => (
				<i title='Piegādāt līdz' className='fas fa-clock' style={{ color: '#333334' }}></i>
			),
			accessor: 'deliverTill',
			width: 100,
			Cell: ({ row }) => <div style={{ textAlign: 'center' }}>
					{formatter.deliverTillFormatter(row.original)}
					{row.original.responsibility === 2 &&
						<span>
							<br />
							<i title='Par piegādi atbildīgs klients' className='fas fa-people-carry' style={{ fontSize: '1.2em', color: 'red' }}></i>
						</span>
					}
				</div>
		},
		{
			Header: () => (
				<i title='Nepieciešams manipulators' className='fas fa-weight-hanging' style={{ color: '#333334' }}></i>
			),
			width: 45,
			accessor: 'manipulator',
			Cell: ({ row }) => <div>{row.original.manipulator ? 'Jā' : 'Nē'}</div>
		},
	];

	render() {
		const { orders, loading } = this.props;

		if (loading) {
			return '...';
		}

		if (!orders || orders.length == 0) {
			return 'Nav datu';
		}

		return (
			<div className='tableWrapper'>
				<MyCustomTable orders={orders} columns={this.columns} />
			</div>
		);
	}
}

const mapStateToPropsRes = state => {
	return {
		orders: state.orders.orders,
		loading: state.orders.loading
	};
};

export default connect(mapStateToPropsRes)(Table);
