export const getSum = invoices => {
	let summs = {tax: 0, woTax:0, summ: 0};
	invoices.map(invoice => {
		if (!invoice.billSumm) {
			return;
		}
		const [woTax, tax, summ] = invoice?.billSumm;

		summs = {
			woTax: summs.woTax + parseFloat(woTax),
			tax: summs.tax + parseFloat(tax),
			summ: summs.summ + parseFloat(summ),
		}
	})

	return summs;
}
