import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { getCurrPage } from 'util';

const ROWS_IN_ONE_PAGE = 20;

const initialState = {
	pages: 1,
	current_page: 1,
	loading: false,
	loading_error: false,
	orders: [],
	meta: {}
};

const ordersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		startRequest: (state, action) => {
			state.loading = true;
		},
		setData: (state, action) => {
			state.loading = false;
			state.orders = [...action.payload];
		},
		setMetadata: (state, action) => {
			state.meta = action.payload;
		},
		setPage: (state, action) => {
			state.current_page = action.payload;
		},
		setPageCount: (state, action) => {
			state.pages = action.payload;
		},
		clear: (state, action) => {
			state.current_page = 1;
			state.orders = [];
		}
	},
});

const {actions, reducer} = ordersSlice;

export const loadData = (data = null) => (dispatch, getState) => {
	const {current_page, meta: {department, year, client}} = getState().orders;

	dispatch(actions.startRequest());

	const currSection = getCurrPage();

	if (data) {
		dispatch(actions.setData(data));
		return;
	}

	api.orders
	.ordersDept({
		dept: department,
		rows: ROWS_IN_ONE_PAGE,
		year,
		client,
		howMData: 'all',
		page: current_page,
		section: currSection
	}) //howMData - salabot?
	.then(response => {
		dispatch(actions.setData(response))
	});
}

export const setPage = (page) =>  (dispatch) => {
	dispatch(actions.setPage(page))
	// dispatch(loadData());
}

export const changePage = (page) =>  (dispatch) => {
	dispatch(actions.setPage(page))
	dispatch(loadData());
}

export const {setMetadata, setPageCount, clear} = actions;

export default reducer;
