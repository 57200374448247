/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import { Input, Label, Select, Textarea } from '../../shared/form';
import FreePiegades from './allFreePiegades';
import api from '@api';
import { toast } from 'react-toastify';
import {
	allFieldsHaveValues,
	formatFullDate,
	allFieldsHaveValues_new,
	reloadPage
} from '../../util';
import Datetime from 'react-datetime';
import { checkPermission } from '../../permissions';
import { invoicePDFlink, MILISECONDS_BEFORE_CLOSE } from '../../config';
import Loading from 'shared/loading';
import './billEditWindow.css';
import OtherBillPayer from './otherPayer';
import { CustomModal } from 'shared/modal';
import setRespectTabs from 'shared/tabs/setRespectTabs';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

const formatDate = dateStr => {
	if (!dateStr) {
		return '';
	}
	return `${dateStr.substring(6, 8)}.${dateStr.substring(4, 6)}.${dateStr.substring(0, 4)}.`;
};

class EditBill extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLoading: false,
			currentlyEditingUser: null,
			showModal: false,
			toSaveData: {},
			name: '',
			gadget: 1,
			data: {},
			options: [],
			dataerrors: {},
			openModal: false
		};
	}

	setFieldError = field => {
		this.setState({
			dataerrors: {
				[field]: true
			}
		});
	};

	saveData = e => {
		//vēl jābūt obligātajiem laukiem
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const {
			data: { order }
		} = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			return;
		}

		const defaultFields = {
			order
		}; /*{
          'addressFrom':data.storage.title,
          'addressTo':data.delivery.title,
          'material':data.material,
          'cargoCount':1,//tā vismaz spriežu pēc vecā koda - 'uzd_cargo_count'
          'typeID':9
        }*/
		let try2Final = {};
		const dataToSendAsDefaults = [
			'billId',
			'billWritten',
			'billTerm',
			'payer',
			'clientCodeDefault',
			'billPaymentWay',
			'pvn',
			'paymentNotes',
			'paymentJustification',
			'billIdReal',
			'userWhoSignedBill',
			'billType',
			'billStatus',
			'criptedBillNr'
		]; //+ vēl vajag driver info!!! - bet tas vienmēr ir tukšs, tāpēc laikam pašlaik vienalga
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign({}, try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend));

		const nameMap = {
			billId: 'Rēķina ID',
			billWritten: 'Datums',
			billTerm: 'Termiņš',
			payer: 'Maksātājs',
			clientCodeDefault: 'Klienta kods',
			billPaymentWay: 'Apmaksas veids',
			pvn: 'PVN',
			paymentNotes: 'Piezīmes',
			paymentJustification: 'Pamatojums',
			userWhoSignedBill: 'Paraksta persona',
			billType: 'Rēķina veids',
			billStatus: 'Statuss'
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			[
				'billId',
				'billWritten',
				'billTerm',
				'payer',
				'clientCodeDefault',
				'billPaymentWay',
				'pvn',
				'paymentNotes',
				'paymentJustification',
				'billIdReal',
				'userWhoSignedBill',
				'billType',
				'billStatus',
				'criptedBillNr'
			],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			this.setFieldError(confirmAllFieldsHasValues.field);
			return;
		}

		if (finalToSend.clientCodeDefault == '') {
			toast.warn(`Nav aizpildīts klienta kods`, { autoClose: 7500 });
			this.setFieldError('clientCodeDefault');
			return;
		}

		// return;

		api.bills.update(finalToSend).then(response => {
			if (response.status == 'success') {
				toast.success('Dati saglabāti');
				//fixme! errors

				setRespectTabs();
				setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				//setTimeout(() => this.props.handleCloseModal(), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`, {
					autoClose: 4000
				});
			}
		});
	};

	handleCloseModal = () => {
		this.setState({ openModal: false });
		this.show();
	};

	openDiffPayer = () => {
		this.setState({ openModal: true });
	};

	componentDidMount() {
		this.show();
		//this.setState({data: {"billId":"1","billWritten":"05.11.2018","billTerm":"19.11.2018","newClientId":null,"payerOptions":[{"value":"58503007191","label":"LATRAPS, LPKS","selected":true}],"differentClientAvailability":true,"clientCodeOptions":[{"value":"1766","label":"LV59UNLA0008000460027 (EUR)","selected":true}],"billPaymentWay":"1","pvn":"21","paymentNotes":"","paymentJustification":"pamatojums","userWhoSignedBill":"45","usersToSignBills":[{"name":"Dzintra","surname":"V\u012bna","id":6},{"name":"Testa_izveide","surname":"Testa_izveide","id":46}],"billType":"D","billStatus":"1","criptedBillNr":null}});
	}

	show = () => {
		//{"payer":"427","type":"0b0f7dae5528caea8ed90525b6f3fceacc9419d4","readOnly":"0","order":"21974"}
		const { payer, type, order } = this.props.data;
		this.setState({ loading: true });
		api.bills.data_edit({ payer, type, order }).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'payerOptions',
				'billId',
				'billWritten',
				'billTerm',
				'newClientId',
				'payer',
				'differentClientAvailability',
				'clientCodeOptions',
				'clientCodeDefault',
				'billPaymentWay',
				'pvn',
				'paymentNotes',
				'paymentJustification',
				'billIdReal',
				'userWhoSignedBill',
				'usersToSignBills',
				'billType',
				'billStatus',
				'criptedBillNr'
			]);

			const attrTransformation = {
				billWritten: formatDate(moreAttrs.billWritten),
				billTerm: formatDate(moreAttrs.billTerm),
				paymentJustification:
					moreAttrs.paymentJustification == null ? '' : moreAttrs.paymentJustification
			}; //{lastUpdate:formatFullDate(moreAttrs.lastUpdate)};

			const newState = Object.assign(
				{ loading: false, error: false, data: response },
				moreAttrs,
				attrTransformation
			);

			this.setState(newState);
			//this.setState({ loading: false, error: false, data: response });
		});

		/*
        const urlOpen = apiEndpoint + '/bill/specific?' + encodeParams({
            maks:'714',
            pasId:'6276',
            type:'4afdfe43e65dd96620f860d1eb5cb8be6936d48e',
            do:'y',
            readOnly:0
        });

        fetch(urlOpen)
            .then(checkStatus)
            .then(parseJSON)
            //.then(this.buildPreviewHtml.bind(this))
            .then(this.setData.bind(this))
            .catch(this.setError.bind(this));
        */
	};

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleDateChange = val => event => {
		try {
			this.setDataToState(val, event.format('YYYYMMDD'));
		} catch (err) {
			return;
		}
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	setError() {
		this.setState({ error: true });
	}

	setData(data) {
		this.setState({ origData: data, data });
	}

	handleOpenInvoice = () => {
		const { criptedBillNr } = this.state.data;

		window.open(`${invoicePDFlink}${criptedBillNr}`, '_blank');
	};

	render() {
		const {
			data,
			loading,
			billId,
			billWritten,
			billTerm,
			newClientId,
			payerOptions,
			payer,
			differentClientAvailability,
			clientCodeOptions,
			clientCodeDefault,
			billPaymentWay,
			pvn,
			dataerrors,
			paymentNotes,
			paymentJustification,
			userWhoSignedBill,
			usersToSignBills,
			billType,
			billStatus,
			criptedBillNr,
			billIdReal
		} = this.state;
		const { payer: payerProp, type, order } = this.props.data;

		const billTypeForTable = type != 'OK' ? billIdReal : '0';

		const hasImportedToHorizon = this.props.hasBeenImported;

		/*

	if($_GET['type']!='OK'){
		$type = $row_DATA['pavad_id'];
	}else{
		$type=0;
    }
    */

		return (
			<div>
				{loading ? (
					<Loading />
				) : (
					<div>
						<div className='col-sm-6'>
							<div className='row'>
								<Label labelClass='col-sm-5' title='Rēķina numurs' name='billId' />
								<Input
									inputClass='col-sm-7'
									name='billId'
									value={billId}
									handleChange={this.handleChange}
									disabled={true}
								/>
								{/*onChange, placeholder*/}
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Izrakstīts'
									name='billWritten'
								/>
								<div className='col-sm-7'>
									<Datetime
										locale='lv'
										timeFormat={false}
										closeOnSelect={true}
										onChange={this.handleDateChange('billWritten')}
										defaultValue={
											billWritten !== 'Invalid date' ? billWritten : ''
										}
									/>
								</div>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Apmaksas termiņš'
									name='billTerm'
								/>
								<div className='col-sm-7'>
									<Datetime
										locale='lv'
										timeFormat={false}
										closeOnSelect={true}
										onChange={this.handleDateChange('billTerm')}
										defaultValue={billTerm !== 'Invalid date' ? billTerm : ''}
									/>
								</div>
							</div>
						</div>
						<div className='col-sm-6'>
							<div className='row'>
								<Label labelClass='col-sm-5' title='Maksātājs' name='payer' />
								<Select
									selectClass='col-sm-7'
									name='payer'
									value={payer}
									placeholder='Izvēlies vērtību'
									options={payerOptions}
									handleChange={this.handleChange}
								/>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Klienta kods'
									name='clientCodeDefault'
								/>
								{/* differentClientAvailability ? "Klients nav reģistrēts Horizon sistēmā vai arī nav piešķirts klienta Nr." :  */}
								<Select
									selectClass='col-sm-7'
									name='clientCodeDefault'
									handleChange={this.handleChange}
									value={clientCodeDefault}
									placeholder='Izvēlies vērtību'
									options={clientCodeOptions}
									isError={
										dataerrors.clientCodeDefault || clientCodeDefault == null
									}
								/>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Apmaksas veids'
									name='billPaymentWay'
								/>
								<Select
									selectClass='col-sm-7'
									name='billPaymentWay'
									handleChange={this.handleChange}
									value={billPaymentWay}
									isError={dataerrors.billPaymentWay}
									placeholder='Izvēlies vērtību'
									options={[
										{ value: '1', label: 'Bankas pārskaitījums' },
										{ value: '2', label: 'Savstarpējais ieskaits' }
									]}
								/>
							</div>
							<div className='row'>
								<Label labelClass='col-sm-5' title='PVN (%)' name='pvn' />
								<Select
									selectClass='col-sm-7'
									name='pvn'
									value={pvn}
									isError={dataerrors.pvn}
									handleChange={this.handleChange}
									placeholder='Izvēlies vērtību'
									options={[{ value: '1', label: pvn }]}
								/>
							</div>
						</div>
						<div className='col-sm-6'>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Rēķina piezīmes'
									name='paymentNotes'
								/>
								<Textarea
									inputClass='col-sm-7'
									name='paymentNotes'
									handleChange={this.handleChange}
									value={paymentNotes}
								/>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Rēķina pamatojums'
									name='paymentJustification'
								/>
								<Textarea
									inputClass='col-sm-7'
									name='paymentJustification'
									handleChange={this.handleChange}
									value={paymentJustification}
								/>
							</div>
						</div>
						<div className='col-sm-6'>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Rēķinu izrakstīja'
									name='userWhoSignedBill'
								/>
								<Select
									selectClass='col-sm-7'
									name='userWhoSignedBill'
									handleChange={this.handleChange}
									value={userWhoSignedBill}
									placeholder='Izvēlies vērtību'
									isError={dataerrors.userWhoSignedBill}
									options={usersToSignBills}
								/>
							</div>
							<div className='row'>
								<Label labelClass='col-sm-5' title='Rēķina veids' name='billType' />
								<Select
									selectClass='col-sm-7'
									name='billType'
									handleChange={this.handleChange}
									value={billType}
									isError={dataerrors.billType}
									placeholder='Izvēlies vērtību'
									options={[
										{ value: 'D', label: 'parastais' },
										{ value: 'K', label: 'kokmateriālu transportēšana' }
									]}
								/>
							</div>
							<div className='row'>
								<Label
									labelClass='col-sm-5'
									title='Rēķina statuss'
									name='billStatus'
								/>
								<Select
									selectClass='col-sm-7'
									name='billStatus'
									handleChange={this.handleChange}
									isError={dataerrors.billStatus}
									value={billStatus}
									placeholder='Izvēlies vērtību'
									options={[
										{ value: '0', label: 'atvērts' },
										{ value: '1', label: 'pabeigts' }
									]}
								/>
							</div>
						</div>
						<div>
							<div className='clearBoth'></div>
							<div className='btn-group' role='group'>
								<button
									type='button'
									className='btn btn-default'
									onClick={this.handleOpenInvoice}>
									<i className='fas fa-file'></i> Skatīt rēķinu
								</button>
								<button
									type='button'
									disabled={
										!checkPermission('edit', 'bills') || hasImportedToHorizon
									}
									className='btn btn-primary'
									onClick={this.saveData}>
									Saglabāt!
								</button>
								{differentClientAvailability && !hasImportedToHorizon && (
									<button
										type='button'
										disabled={!checkPermission('edit', 'bills')}
										className='btn btn-success'
										onClick={this.openDiffPayer}>
										Norādīt citu maksātāju!
									</button>
								)}
							</div>
							<FreePiegades
								readOnly={hasImportedToHorizon}
								payer={payerProp}
								type={billTypeForTable}
								pvz={criptedBillNr}
								order={order}
								stat={billStatus}
							/>
						</div>
					</div>
				)}
				{this.state.openModal && (
					<CustomModal
						title='Cita maksātāja norādīšana'
						showModal={this.state.openModal}
						handleCloseModal={this.handleCloseModal}
						content={
							<OtherBillPayer
								id={this.state.billIdReal}
								handleCloseModal={this.handleCloseModal}
							/>
						}
					/>
				)}
			</div>
		);
		//if(!$row_DATA['pavad_statuss'] || $row_DATA['pavad_statuss']<1){echo '0';}else{echo $row_DATA['pavad_statuss'];}
		/*

	if($_GET['type']!='OK'){
		$type = $row_DATA['pavad_id'];
	}else{
		$type=0;
    }
    */
	}
}

export default EditBill;
