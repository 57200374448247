import React from 'react';
/*
https://blog.stvmlbrn.com/2017/10/14/display-loading-indicator-in-react-during-ajax-requests.html

https://github.com/Lemoncode/react-promise-tracker
*/
const ErrorOnLoading = () => (
	<span>
		<i className='fas fa-exclamation-triangle' style={{ color: 'red' }}></i> Radās kļūda,
		ielādējot datus
	</span>
);

export default ErrorOnLoading;
