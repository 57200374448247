import App from 'sections/app/App';
import { checkLogin, logout } from '../auth/actions';
import { connect } from 'react-redux';

const mapDispatchToPropsRes = dispatch => {
	return {
		checkLogin: () => {
			dispatch(checkLogin());
		},
		logoutHandler: () => {
			dispatch(logout());
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		loggingIn: state.auth.loggingIn,
		isAuth: state.auth.loggedIn,
		read: state.auth.readPerms,
		user: state.auth.username,
		reports: state.auth.reports,
		serviceToggle: state.auth.serviceToggle,
		appError: state.auth.appError
	};
};

const AppContainer = connect(mapStateToPropsRes, mapDispatchToPropsRes)(App);

export default AppContainer;
