//export const New =
import React from 'react';

const Loading = () => (
	<span>
		<i className='fa fa-spinner fa-pulse fa-fw'></i> Dati tiek ielādēti
	</span>
);
const actionButton =
	'<i class="fas fa-ellipsis-v" style="font-size: 2em; text-align: center; color: green;"></i>';

export { Loading, actionButton };
